/*
 * @Description:
 * @Author:
 * @Date: 2021-03-18 18:38:22
 * @LastEditTime: 2021-03-19 11:10:33
 * @LastEditors:
 */
const {
  xhr
} = require('../lib/xhr')
const {
  outQuery_api,
  addoutCustomer_api,
  updateoutCustomer_api,
  removeoutCustomer_api
} = require('../api/OutCustomer.api')

module.exports = {
  outQuery_service(val) {
    return xhr(outQuery_api, val)
  },
  addoutCustomer_service(val) {
    return xhr(addoutCustomer_api, val)
  },
  updateoutCustomer_service(val) {
    return xhr(updateoutCustomer_api, val)
  },
  removeoutCustomer_service(val) {
    return xhr(removeoutCustomer_api, val)
  }
}

