/*
 * @Author: your name
 * @Date: 2021-05-25 15:08:01
 * @LastEditTime: 2021-08-31 21:09:19
 * @LastEditors: Please set LastEditors
 * @Description: 枚举
 * @FilePath: \ioac-tenant-html\src\static\utils\enum.js
 */

export default {
  // 居住情况
  liveMode: new Map([
    ['1', '独自居住'],
    ['2', '与子女合住'],
    ['3', '其他']
  ]),
  // 补贴等级
  subsidyLevel: new Map([
    ['1', '一类'],
    ['2', '二类'],
    ['3', '三类'],
    ['4', '其他']
  ]),
  // 医疗保障
  medicalSecurity: new Map([
    ['1', '商业保险'],
    ['2', '城镇职工医疗保险'],
    ['3', '城镇居民医疗保险'],
    ['4', '农村合作医疗'],
    ['5', '商业保险'],
    ['6', '其他']
  ]),
  // 长者状态
  oldState: new Map([
    ['1', '服务中长者'],
    ['2', '暂停服务长者'],
    ['3', '已注销长者'],
    ['4', '放弃服务长者']
  ]),
  // 劳动能力
  laborCapacity: new Map([
    ['1', '自理'],
    ['2', '半自理'],
    ['3', '瘫痪']
  ]),
  // 性别
  sex: new Map([
    ['1', '男'],
    ['2', '女']
  ]),
  // 政治面貌
  politicalOutlook: new Map([
    ['1', '党员'],
    ['2', '团员'],
    ['3', '其他']
  ]),
  // 服务周期
  serviceCycle: new Map([
    ['1', '周'],
    ['2', '月'],
    ['3', '年']
  ]),
  mealType: new Map([
    ['1', '补贴'],
    ['2', '自费']
  ]),
  accountType: new Map([
    ['allowance', '补贴账户'],
    ['individual', '个人账户']
  ]),
  // 充值类型
  DepositAcct: new Map([
    ['in', '收入'],
    ['out', '支出']
  ]),
  billingStatus: new Map([
    ['N', '未结算'],
    ['Y', '已结算']
  ]),
  visitType: new Map([
    ['1', '电话'],
    ['2', '走访']
  ]),
  elderType: new Map([
    ['1', '已入住'],
    // ['2', '退住'],
    ['3', '办理中'],
    ['4', '外出中'],
    ['5', '合同到期']
  ]),
  checkInState: new Map([
    ['1', '在院'],
    ['2', '离院']
  ]),
  serviceOrderState: new Map([
    ['0', '待指派'],
    ['1', '未开始'],
    ['2', '服务中'],
    ['3', '服务异常'],
    ['3_1', '派单超时'],
    ['3_2', '接单超时'],
    ['3_3', '订单疑似异常'],
    ['4', '已完成'],
    ['5', '已取消'],
    ['6', '待接单'],
    ['7', '已拒绝']
  ])
}
