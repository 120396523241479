/*
 * @Description:
 * @Author:
 * @Date: 2021-03-24 17:20:10
 * @LastEditTime: 2021-03-29 17:17:42
 * @LastEditors:
 */
let {
  ic
} = require('../config/api_spec')
module.exports = {
  queryChange_api: {
    url: `${ic}/mgr/live/change/query`,
    post: {
      changeType: '?',
      customerRegisterNo: '?',
      page: '?',
      rows: '?',
      state: '?'
    }
  },
  getChange_api: {
    url: `${ic}/mgr/live/change/getById`,
    post: {
      id: '?'
    }
  },
  // 查看客户已申请
  getCustomerChange_api: {
    url: `${ic}/mgr/live/change/get`,
    post: {
      changeType: '?',
      customerRegisterNo: '?'
    }
  },
  updateChange_api: {
    url: `${ic}/mgr/live/change/edit`,
    post: {
      afterFee: '?', // 变更后费用
      bedChangeAfterVal: '?',
      changeType: '?',
      customerRegisterNo: '?',
      nurseTypeChangeAfterVal: '?',
      remark: '?',
      id: '?'
    }
  },

  addChange_api: {
    url: `${ic}/mgr/live/change/add`,
    post: {
      afterFee: '?', // 变更后费用
      bedChangeAfterVal: '?',
      changeType: '?',
      customerRegisterNo: '?',
      nurseTypeChangeAfterVal: '?',
      remark: '?'
    }
  }

}
