/*
 * @Author: your name
 * @Date: 2021-06-02 13:21:23
 * @LastEditTime: 2021-06-02 13:23:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\cost.controller.js
 */
let {
  registrationCode_query_service,
  generateRegistrationCode_service,
  makeRegistrationCodeInvalid_service,
  distributeRegistrationCode_service,
  export_service
} = require('../service/RegistrationCodeMgr.service')

module.exports = {
  // 获取注册码一览
  getRegistrationCodeLists_controller(val) {
    console.log('control', val)
    return registrationCode_query_service(val)
  },
  // 批量生成注册码
  generateRegistrationCode_controller(val) {
    return generateRegistrationCode_service(val)
  },
  // 作废
  makeRegistrationCodeInvalid_controller(val) {
    return makeRegistrationCodeInvalid_service(val)
  },
  // 分配注册码
  distributeRegistrationCode_controller(val) {
    return distributeRegistrationCode_service(val)
  },
  // 导出注册码
  export_controller(val) {
    return export_service(val)
  }
}
// console.log(account_updateState_service)
