<template>
  <div class="view-scroll">
    <div class="m-box col m-mt-10 box">
      <a-row type="flex" justify="space-between">
        <a-col :span="5">
          <a-button type="primary" @click="setItem()">创建知识库</a-button>
        </a-col>
        <a-col :span="7">
          <a-input-search v-model="name" placeholder="请输入知识库名称" style="width: 100%" @search="onSearch" />
        </a-col>
      </a-row>
    </div>

    <div class="m-box m-mt-10">
      <a-table :columns="columns" :data-source="data" :pagination="false">
        <div slot="action" slot-scope="record" class="text-3d9">
          <span style="color: #666; padding-left: 15px">{{
            record.state === "1" ? "是" : "否"
          }}</span>
        </div>
        <div slot="use" slot-scope="row" class="text-3d9">
          <span
            style="color: #74A0FA;  cursor: pointer"
            @click="operation(row)"
          >
            {{ "操作" }}</span>
        </div>
      </a-table>

      <pagination
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        :total="total"
        class="paginationStyle"
        @pagination="getPages"
      />
    </div>

    <a-modal
      title="添加知识库"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <!-- <a-form :form="addForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
        <a-form-item label="姓名"> -->
      <a-col :span="8" style="margin-bottom: 20px">知识库名称</a-col>
      <a-input
        v-model="newName"
        :value="newName"
        size="large"
        style="border-radius: 8px"
      />
      <!-- </a-form-item> -->
      <!-- </a-form> -->
    </a-modal>
  </div>
</template>

<script>
import {
  knowledgeBaseAdd_controller,
  queryKnowledgeBase_controller
} from '../../static/controller/cost.controller'
const columns = [
  {
    title: '知识库名称',
    dataIndex: 'kName'
  },
  {
    title: '主题数量',
    dataIndex: 'subjNum'
  },
  {
    title: '更新时间',
    dataIndex: 'edt'
  },
  {
    title: '标记状态',
    // dataIndex: 'state',
    scopedSlots: {
      customRender: 'action'
    }
  },
  {
    title: '操作',
    // dataIndex: 'use',
    scopedSlots: {
      customRender: 'use'
    }
  }
]

const data = []
export default {
  filters: {},
  components: {
    pagination: () => import('../../components/Pagination')
  },
  data() {
    return {
      data,
      columns,
      selectedRowKeys: [],
      form: this.$form.createForm(this, {
        name: 'evalutaion'
      }),
      addForm: this.$form.createForm(this, {
        name: 'customer_list'
      }),
      visible: false,
      pageIndex: 1, // 当前页码
      pageSize: 10,
      pagination: {}, // tab分页
      total: 0,
      where: {},
      addInfo: {},
      //   新增知识库名称
      newName: '',
      ids: [],
      knowId: '',
      name: ''
    }
  },

  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },

  created() {
    console.log(queryKnowledgeBase_controller)
    this.getElderList()
  },
  methods: {
    getElderList() {
      queryKnowledgeBase_controller({
        page: this.pageIndex,
        rows: this.pageSize
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    },
    onSearch() {
      queryKnowledgeBase_controller({
        kname: this.name,
        page: this.pageIndex,
        rows: this.pageSize
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
        this.name = ''
      })
    },
    start() {
      this.loading = true
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      let ids = []
      for (let x of selectedRowKeys) {
        for (let i in this.data) {
          console.log(x, i)
          if (x === +i) {
            ids.push(this.data[i].id)
          }
        }
      }
      console.log(ids)
      this.ids = ids
      this.selectedRowKeys = selectedRowKeys
    },

    // handleSearch(e) {
    //   e.preventDefault()
    //   this.form.validateFields((error, values) => {
    //     console.log(error, values)
    //     this.pageIndex = 1
    //     this.where = values
    //     this.getElderList()
    //   })
    // },

    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.getElderList()
    },

    setItem(e) {
      //   console.log(e)
      //   this.addInfo = e
      this.visible = true
    },

    handleOk(e) {
      console.log(e)
      console.log(this.newName)
      knowledgeBaseAdd_controller({
        kname: this.newName
      })
        .then(res => {
          // console.log(res)
          this.visible = false
          this.newName = ''
          this.getElderList()
        })
    },

    handleCancel(e) {
      this.visible = false
    },

    updSuccess() {
      console.log('chenggong')
      this.getElderList()
    },
    operation(e) {
      //   this.knowId = e.knowId
      this.$router.push({
        name: 'operation',
        params: { id: e.kid }
      })
    }
  }
}
</script>
<style scoped lang="less">
.box .ant-form-item {
  margin: 10px 0 !important;
}

.ant-row {
  display: flex;
  align-items: center;
}

.m-mt-4 {
  margin-top: 4px;
}

.photo {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
</style>
