/*
 * @Author: your name
 * @Date: 2021-05-27 16:52:43
 * @LastEditTime: 2021-05-28 14:07:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\api\ServiceStaffInfoMgr.api.js
 */
let {
  hsc
} = require('../config/api_spec')

module.exports = {
  getCustomerList_api: {
    url: `${hsc}/mgr/home2shop/staff/queryPage`,
    post: {
      custType: '3',
      idNo: '?',
      mobile: '?',
      name: '?',
      state: '?',
      page: '?',
      rows: '?'
    }
  },
  addCustomerInfo_api: {
    url: `${hsc}/mgr/home2shop/staff/add`,
    post: {
      adminCityCode: '?',
      adminCityName: '?',
      adminHotelCode: '?',
      adminHotelName: '?',
      adminProvinceCode: '?',
      adminProvinceName: '?',
      adminAddr: '?',
      birthday: '?',
      certiImg: '?', // 证书图片
      cetiKind: '?', // 证书种类
      custType: 3, // 人员类型
      educationDegree: '?',
      homeAddr: '?',
      homeCityCode: '?',
      homeCityName: '?',
      homeHotelCode: '?',
      homeHotelName: '?',
      homeProvinceCode: '?',
      homeProvinceName: '?',
      idNo: '?',
      img: '?',
      maritalState: '?',
      mobile: '?',
      name: '?',
      nation: '?',
      oldJob: '?',
      // organCode: '?',
      otherTel: '?',
      serverHotelNames: '?', // 社区名称
      serverHotels: '?', // 服务社区
      serverItems: '?',
      serverStreetNames: '?',
      serverStreets: '?',
      sex: '?',
      state: '?'
      // tel: '?'
    }
  },
  updateCustomerInfo_api: {
    url: `${hsc}/mgr/home2shop/staff/update`,
    post: {
      id: '?',
      adminCityCode: '?',
      adminCityName: '?',
      adminHotelCode: '?',
      adminHotelName: '?',
      adminProvinceCode: '?',
      adminProvinceName: '?',
      adminAddr: '?',
      birthday: '?',
      certiImg: '?', // 证书图片
      cetiKind: '?', // 证书种类
      custType: 3, // 人员类型
      educationDegree: '?',
      homeAddr: '?',
      homeCityCode: '?',
      homeCityName: '?',
      homeHotelCode: '?',
      homeHotelName: '?',
      homeProvinceCode: '?',
      homeProvinceName: '?',
      idNo: '?',
      img: '?',
      maritalState: '?',
      mobile: '?',
      name: '?',
      nation: '?',
      oldJob: '?',
      otherTel: '?',
      serverHotelNames: '?', // 社区名称
      serverHotels: '?', // 服务社区
      serverItems: '?',
      serverStreetNames: '?',
      serverStreets: '?',
      sex: '?',
      state: '?'
    }
  },
  updateState_api: {
    url: `${hsc}/mgr/home2shop/staff/updateState`,
    post: {
      id: '?',
      state: '?'
    }
  },
  removeCustomerInfo_api: {
    url: `${hsc}/mgr/home2shop/staff/remove`,
    post: {
      id: '?'
    }
  },
  getCustomerInfoById_api: {
    url: `${hsc}/mgr/home2shop/staff/getById`,
    method: 'GET',
    post: {
      id: '?'
    }
  }
}
