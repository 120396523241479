/*
 * @Description:
 * @Author:
 * @Date: 2021-03-04 20:06:47
 * @LastEditTime: 2021-03-11 11:53:30
 * @LastEditors:
 */

const {
  xhr
} = require('../lib/xhr')
const {
  ContractTemplate_api
} = require('../api/ContractTemplate.api')

module.exports = {
  getContractTemplate_service(val) {
    return xhr(ContractTemplate_api, val)
  }
}
