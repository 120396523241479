/*
 * @Author: your name
 * @Date: 2021-04-08 16:40:14
 * @LastEditTime: 2021-04-09 17:49:03
 * @LastEditors: Please set LastEditors
 * @Description: 客户外出
 * @FilePath: \ioac-tenant-html\src\static\controller\ApplyGoOutMgr.controller.js
 */
let {
  queryGoOut_service,
  addGoOut_service,
  backGoOut_service
} = require('../service/ApplyGoOutMgr.service')

let {
  queryCheckInCustList_service
} = require('../service/ComeCustomerMgr.service')

module.exports = {
  queryGoOut_controller: (val) => {
    return queryGoOut_service(val)
  },
  addGoOut_controller: (val) => {
    return addGoOut_service(val)
  },
  queryCheckInCustList_controller: (val) => {
    return queryCheckInCustList_service(val)
  },
  backGoOut_controller: (val) => {
    return backGoOut_service(val)
  }
}
