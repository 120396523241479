/*
 * @Author: your name
 * @Date: 2021-03-09 09:59:31
 * @LastEditTime: 2021-05-12 15:27:57
 * @LastEditors:
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\api\HardwareMgr.api.js
 */

let {
  hc
} = require('../config/api_spec')
module.exports = {

  hardware_query_api: { // 查询
    url: `${hc}/mgr/hardware/infoMgr/query`,
    post: {
      code: '?',
      organCode: '?', // 机构编号
      name: '?', // 设备名称
      type: '?', // 设备类型
      state: '?', // 设备状态
      bindObjType: '?', // 绑定对象分类
      page: '?',
      rows: '?',
      isBindObj: '?'
    }
  },

  hardware_add_api: {
    url: `${hc}/mgr/hardware/infoMgr/add`,
    post: {
      code: '?', // 设备编号
      name: '?', // 设备名称
      type: '?', // 设备类型
      parentCode: '?', // 设备ID
      bindObjType: '?', // 绑定对象分类
      deviceId: '?', // 设备ID
      bindObjId: '?', // 绑定对象ID
      remark: '?', // 备注
      other: '?', // 备注
      state: '?' // 设备状态
    }
  },
  hardware_get_api: {
    url: `${hc}/mgr/hardware/infoMgr/get`,
    post: {
      code: '?' // 设备编号
    }
  },

  hardware_update_api: {
    url: `${hc}/mgr/hardware/infoMgr/update`,
    post: {
      code: '?', // 设备编号
      name: '?', // 设备名称
      type: '?', // 设备类型
      parentCode: '?', // 设备ID
      deviceId: '?', // 设备ID
      bindObjType: '?', // 绑定对象分类
      bindObjId: '?', // 绑定对象ID
      other: '?', // 备注
      remark: '?', // 备注
      state: '?'
    }
  },

  hardware_delete_api: {
    url: `${hc}/mgr/hardware/infoMgr/delete`,
    post: {
      code: '?' // 设备编号
    }

  },
  hardware_list_api: {
    url: `${hc}/mgr/hardware/infoMgr/list`,
    post: {
      bindObjType: '?', // 绑定对象分类
      isBindObj: '?',
      type: '?',
      ignoreCodes: '?'
    }
  },
  // 统计床卡数量
  bedCardcountMgr_api: {
    url: `${hc}/mgr/hardware/bedCard/count`,
    post: {

    }
  },
  hardware_bindObj_api: {
    url: `${hc}/mgr/hardware/infoMgr/check/bindObj`,
    post: {
      bindObjId: '?', // 绑定对象
      bindObjType: '?' // 绑定对象分类
    }
  },
  updatecardStatus_api: {
    url: `${hc}/mgr/hardware/bedCard/sync`,
    post: {
      code: '?'
    }
  },
  replaceubedCard_api: {
    url: `${hc}/mgr/hardware/bedCard/replace`,
    post: {
      newCode: '?',
      oldCode: '?'
    }
  },
  // 查询床卡上的客户信息
  getBedCardPushInfo_api: {
    url: `${hc}/mgr/hardware/bedCard/getBedCardPushInfo`,
    post: {
      code: '?'
    }
  },

  // 编辑要推送到床卡的客户信息
  updateBedCardPush_api: {
    url: `${hc}/mgr/hardware/bedCard/updateBedCardPush`,
    post: {
      bedId: '?',
      comeDate: '?',
      data: '?',
      nurseType: '?',
      risk: '?',
      sex: '?'
    }
  },
  // 查询床卡上客户信息列表
  queryBedCardPushInfo_api: {
    url: `${hc}/mgr/hardware/bedCard/queryBedCardPushInfo`,
    post: {
      bedId: '?',
      code: '?',
      name: '?',
      page: '?',
      rows: '?',
      state: '?'
    }
  },
  // 风险点
  riskList_api: {
    url: `${hc}/mgr/hardware/bedCard/risk/list`,
    post: {}
  },
  // 推送记录
  querypushLog_api: {
    url: `${hc}/mgr/hardware/bedCard/pushLog/query`,
    post: {
      page: '?',
      rows: '?'
    }
  },
  // 获取设备类型枚举
  getInfoMgrList_api: {
    url: `${hc}/mgr/hardware/infoMgr/getTypeEnum`,
    post: {}
  },

  delInfoMgr_api: {
    url: `${hc}/mgr/hardware/infoMgr/delete`,
    get: {
      code: '?'
    }
  },

  getData_api: {
    url: `${hc}/mgr/hardware/data/query`,
    post: {
      dataTypes: '?',
      deviceTypes: '?',
      deviceCode: '?',
      status: '?',
      startDate: '?',
      page: '?',
      rows: '?'
    }
  },

  getSetting_api: {
    url: `${hc}/mgr/hardware/settingWarning/listGroup`,
    post: {

    }
  },

  updSetting_api: {
    url: `${hc}/mgr/hardware/settingWarning/upsertGroup`,
    post: {
      bedEnd: '?',
      bedStart: '?',
      bedState: '?',

      bodyCeil: '?',
      bodyFloor: '?',
      bodyState: '?',

      breathCeil: '?',
      breathFloor: '?',
      breathState: '?',

      heartCeil: '?',
      heartFloor: '?',
      heartState: '?',

      doorEnd: '?',
      doorStart: '?',
      doorState: '?',
      doorStatus: '?',
      doorWeek: '?',

      manEnd: '?',
      manStart: '?',
      manState: '?',
      manStatus: '?',
      manWeek: '?',

      raderState: '?',
      smokeState: '?',

      sosState: '?',
      waterState: '?',
      gasState: '?',
      gatewayState: '?',

      minInterval: '?'
    }
  }

}
