/*
 * @Description:
 * @Author:
 * @Date: 2021-06-11 15:24:11
 * @LastEditTime: 2021-06-11 17:22:52
 * @LastEditors:
 */
const {
  xhr
} = require('../lib/xhr')

const {
  batchApply_api,
  listsubsidy_api,
  queryApply_api
} = require('../api/SubsidyApply.api')

module.exports = {
  batchApply_controller(val) {
    return xhr(batchApply_api, val)
  },
  listubsidy_controller(val) {
    return xhr(listsubsidy_api, val)
  },
  queryApply_controller(val) {
    return xhr(queryApply_api, val)
  }
}
