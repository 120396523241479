<!--
 * @Author: your name
 * @Date: 2021-03-09 10:23:41
 * @LastEditTime: 2021-06-21 13:46:21
 * @LastEditors: Please set LastEditors
 * @Description: 新增楼栋，弹窗形式
 * @FilePath: \ioac-tenant-html\src\components\sysset\addfloor.vue
-->
<template>
  <a-modal
    title="楼栋维护"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="楼栋编号" style="display: none">
        <a-input
          v-decorator="[
            'id',
            {
              initialValue: initValue.id,
            },
          ]"
          placeholder="请输入楼栋编号"
          :disabled="initValue.id ? true : false"
        />
      </a-form-item>
      <a-form-item :label="`${floorType==1?'楼栋':'楼层'}名称`">
        <a-input
          v-decorator="[
            'name',
            {
              rules: [{ required: true, message: '请输入楼栋名称!' }],
              initialValue: initValue.name,
            },
          ]"
          placeholder="请输入楼栋名称"
        />
      </a-form-item>
      <a-form-item label="添加类型">
        <a-radio-group
          v-model="floorType"
          v-decorator="[
            'type',
            {
              rules: [{ required: true, message: '请选择类型!' }],
              initialValue: initValue.type || '1',
            },
          ]"
        >
          <a-radio-button value="1"> 楼栋 </a-radio-button>
          <!-- <a-radio-button value="2"> 单元 </a-radio-button> -->
          <a-radio-button value="3"> 层 </a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="排序号">
        <a-input-number
          v-decorator="[
            'orders',
            {
              rules: [{ required: true, message: '请输入排序号!' }],
              initialValue: initValue.orders,
            },
          ]"
          placeholder="请输入排列序号"
          :min="0"
          :max="100"
        />
      </a-form-item>
      <a-form-item v-if="floorType === '3'" label="所属楼栋">
        <a-cascader
          v-decorator="[
            'parentId',
            {
              initialValue: initValue.initId,
            },
          ]"
          :options="options"
          change-on-select
          placeholder="请选择所属楼栋"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import {
  addFloor_controller,
  updateFloor_controller
} from '../../static/controller/BedMgr.controller'

function getRegionList(res) {
  return res.map((info) => {
    if (info.children && info.children.length > 0) {
      return {
        value: info.id,
        label: info.name
        // children: getRegionList(info.children)
      }
    } else {
      return { value: info.id, label: info.name }
    }
  })
}

export default {
  props: {
    visible: {
      // 是否展示
      default: () => {
        return false
      },
      type: Boolean
    },
    organCode: {
      // 机构编号
      default: () => {
        return ''
      },
      type: String
    },
    floor: {
      // 是否展示
      default: () => {
        return []
      },
      type: Array
    },
    initValue: Object
  },
  data() {
    return {
      confirmLoading: false,
      options: [],
      floorType: 1
    }
  },
  mounted() {
    console.log('陈发：', this.floor)
    this.options = getRegionList(this.floor)
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'time_related_controls' })
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, fieldsValue) => {
        console.log(err, fieldsValue)
        if (err) {
          return
        }
        this.confirmLoading = true
        if (this.initValue.id) {
          console.log('进入：：', this.initValue.id)
          // 表示编辑
          updateFloor_controller({
            id: fieldsValue.id,
            name: fieldsValue.name,
            orders: fieldsValue.orders,
            organCode: this.organCode,
            parentId:
              fieldsValue.parentId.length > 0
                ? this.floorType === '3'
                  ? fieldsValue.parentId[fieldsValue.parentId.length - 1]
                  : 0
                : 0,
            treePath: '',
            type: fieldsValue.type // 1:楼栋，2:单元，3：层，4：房间
          })
            .then((res) => {
              console.log(res)
              this.$message.success('修改成功！')
              this.confirmLoading = false
              this.$emit('update:visible', false)
              this.$emit('confirm')
            })
            .catch(() => {
              this.confirmLoading = false
              this.$emit('update:visible', false)
            })
        } else {
          console.log('添加：：', fieldsValue)
          addFloor_controller({
            name: fieldsValue.name,
            orders: fieldsValue.orders,
            organCode: this.organCode,
            parentId:
              fieldsValue.parentId && fieldsValue.parentId.length > 0
                ? fieldsValue.parentId[fieldsValue.parentId.length - 1]
                : 0,
            treePath: '',
            type: fieldsValue.type // 1:楼栋，2:单元，3：层，4：房间
          })
            .then((res) => {
              console.log(res)
              this.$message.success('添加成功！')
              this.confirmLoading = false
              this.$emit('update:visible', false)
              this.$emit('confirm')
            })
            .catch(() => {
              console.log('失败了')
              this.confirmLoading = false
              this.$emit('update:visible', false)
            })
        }
      })
    },
    handleCancel() {
      console.log('取消')
      this.$emit('update:visible', false)
    }
  }
}
</script>
