/*
 * @Author: your name
 * @Date: 2021-06-01 14:29:33
 * @LastEditTime: 2021-06-02 10:56:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\oldservicepackage.controller.js
 */
const {
  addCustomerMeal_service,
  deleteCustomerMeal_service,
  getCustomerMeal_service,
  getAvailableMealByCustomer_service,
  queryCustomerMeal_service,
  queryListCustomerMeal_service,
  updateCustomerMeal_service,
  addBatchCustomerMeal_service
} = require('../service/live.CustomerMealMgr.service')

module.exports = {
  addBatchCustomerMeal_controller(value) {
    return addBatchCustomerMeal_service(value)
  },
  addCustomerMeal_controller(value) {
    return addCustomerMeal_service(value)
  },
  deleteCustomerMeal_controller(value) {
    return deleteCustomerMeal_service(value)
  },
  getCustomerMeal_controller(value) {
    return getCustomerMeal_service(value)
  },
  getAvailableMealByCustomer_controller(value) {
    return getAvailableMealByCustomer_service(value)
  },
  queryCustomerMeal_controller(value) {
    return queryCustomerMeal_service(value)
  },
  queryListCustomerMeal_controller(value) {
    return queryListCustomerMeal_service(value)
  },
  updateCustomerMeal_controller(value) {
    return updateCustomerMeal_service(value)
  }
}
