<!--
 * @Author: your name
 * @Date: 2021-03-09 16:58:57
 * @LastEditTime: 2021-04-19 16:34:32
 * @LastEditors: Please set LastEditors
 * @Description: 房间维护
 * @FilePath: \ioac-tenant-html\src\components\sysset\addroom.vue
-->
<template>
  <a-modal
    title="房间维护"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :width="900"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item
        label="房间编号"
        :wrapper-col="{ span: 13 }"
        style="display: none"
      >
        <a-input
          v-decorator="[
            'code',
            {
              initialValue: initValue.id,
            },
          ]"
          :disabled="initValue.id ? true : false"
          placeholder="请输入楼栋编号"
        />
      </a-form-item>
      <a-form-item label="房间名称" :wrapper-col="{ span: 13 }">
        <a-input
          v-decorator="[
            'name',
            {
              rules: [{ required: true, message: '请输入房间名称!' }],
              initialValue: initValue.name,
            },
          ]"
          placeholder="请输入楼栋名称"
        />
      </a-form-item>
      <a-form-item label="排序号">
        <a-input-number
          v-decorator="[
            'orders',
            {
              initialValue: initValue.orders || '',
            },
          ]"
          placeholder="请输入排列序号"
          :min="0"
          :max="100"
          style="width: 150px"
        />
      </a-form-item>
      <a-form-item label="床位">
        <a-card v-if="form.getFieldValue('keys').length > 0">
          <a-card-grid
            v-for="k in form.getFieldValue('keys')"
            :key="k"
            style="width: 50%; padding: 15px"
          >
            <a-form-item
              label="床位编号"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              style="margin-bottom: 10px"
            >
              <a-input
                v-decorator="[
                  `bedCode[${k}]`,
                  {
                    rules: [
                      {
                        required: true,
                        message: '请传入床位编号!',
                      },
                    ],
                    initialValue:
                      initBed[k] && initBed[k].code ? initBed[k].code : '',
                  },
                ]"
                :disabled="initBed[k] && initBed[k].code ? true : false"
                placeholder="请传入床位编号"
              />
            </a-form-item>
            <a-form-item
              label="床位名称"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              style="margin-bottom: 10px"
            >
              <a-input
                v-decorator="[
                  `bedName[${k}]`,
                  {
                    rules: [
                      {
                        required: true,
                        message: '请传入床位名称!',
                      },
                    ],
                    initialValue:
                      initBed[k] && initBed[k].name ? initBed[k].name : '',
                  },
                ]"
                placeholder="请输入床位名称"
              />
            </a-form-item>
            <a-form-item
              label="排序号"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              style="margin-bottom: 10px"
            >
              <a-input-number
                v-decorator="[
                  `bedOrders[${k}]`,
                  {
                    initialValue:
                      initBed[k] && initBed[k].orders ? initBed[k].orders : '',
                  },
                ]"
                placeholder="请输入排列序号"
                :min="0"
                :max="100"
                style="width: 150px"
              />
            </a-form-item>
            <a-form-item
              label="备注"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              style="margin-bottom: 10px"
            >
              <a-textarea
                v-decorator="[
                  `bedRemark[${k}]`,
                  {
                    initialValue:
                      initBed[k] && initBed[k].remark ? initBed[k].remark : '',
                  },
                ]"
                placeholder="请输入床位备注"
              />
            </a-form-item>
            <a-form-item
              v-if="typeList.length > 0"
              label="床位分类"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              style="margin-bottom: 10px"
            >
              <a-select
                v-decorator="[
                  `bedType[${k}]`,
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择床位分类!',
                      },
                    ],
                    initialValue:
                      initBed[k] && initBed[k].type ? initBed[k].type : '',
                  },
                ]"
                placeholder="请选择床位分类"
              >
                <a-select-option
                  v-for="item in typeList"
                  :key="item.id"
                  :value="item.twoType"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-popconfirm
              v-if="initBed[k] && initBed[k].id"
              :title="`是否删除床位【${initBed[k].name}】?`"
              @confirm="removeBed(k, initBed[k].id)"
            >
              <a-button type="dashed" block>
                <a-icon type="delete" /> 删除
              </a-button>
            </a-popconfirm>
            <a-button v-else type="dashed" block @click="remove(k)">
              <a-icon type="delete" /> 删除
            </a-button>
          </a-card-grid>
        </a-card>
        <a-button type="dashed" style="width: 60%" @click="add">
          <a-icon type="plus" /> 新增床位
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import {
  twoListByOneType_controller,
  addFloor_controller,
  addHospitalBed_controller,
  hospitalBedQuery_controller,
  updateFloor_controller,
  updateHospitalBed_controller,
  removeHospitalBed_controller
} from '../../static/controller/BedMgr.controller'
let id = 0
export default {
  props: {
    visible: Boolean,
    floorInfo: Object,
    organCode: String,
    initValue: Object
  },
  data() {
    return {
      confirmLoading: false,
      typeList: [],
      initBed: [] // 编辑时床位初始化
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'time_related_controls' })
    this.form.getFieldDecorator('keys', { initialValue: [], preserve: true })
  },
  async mounted() {
    id = 0
    // 初始化类型
    twoListByOneType_controller({
      oneType: 1
    }).then((res) => {
      this.typeList = res
    })
    console.log('床位信息：', this.initValue.id)
    // 表示编辑
    if (this.initValue.id) {
      let bedRes = await hospitalBedQuery_controller({
        floorCode: this.initValue.id,
        organCode: this.initValue.organCode
      })

      this.initBed = bedRes
      for (let index = 0; index < bedRes.length; index++) {
        this.add()
      }
    }
  },
  methods: {
    handleOk(e) {
      this.confirmLoading = true
      this.form.validateFields(async(err, fieldsValue) => {
        console.log(fieldsValue)
        this.confirmLoading = false
        if (err) {
          return
        }
        if (this.initValue.id) {
          // 表示编辑楼层信息
          await updateFloor_controller({
            id: fieldsValue.code,
            name: fieldsValue.name,
            orders: fieldsValue.orders || 0,
            organCode: this.organCode,
            parentId: this.floorInfo.id[this.floorInfo.id.length - 1],
            treePath: '',
            type: 4 // 1:楼栋，2:单元，3：层，4：房间
          })
          // 编辑床位信息
          fieldsValue.keys.forEach(async(element, index) => {
            if (
              this.initBed.some(
                (info) => info.code === fieldsValue.bedCode[index]
              )
            ) {
              let id = this.initBed.filter(
                (info) => info.code === fieldsValue.bedCode[index]
              )[0].id
              console.log('id:::', id)
              // 存在修改
              await updateHospitalBed_controller({
                id: id,
                code: fieldsValue.bedCode[index],
                floorCode: fieldsValue.code,
                name: fieldsValue.bedName[index],
                orders: fieldsValue.bedOrders[index],
                organCode: this.organCode || 0,
                remark: fieldsValue.bedRemark[index] || '',
                type: fieldsValue.bedType[index]
              })
            } else {
              // 不存在添加
              await addHospitalBed_controller({
                code: fieldsValue.bedCode[index],
                floorCode: fieldsValue.code,
                name: fieldsValue.bedName[index],
                orders: fieldsValue.bedOrders[index],
                organCode: this.organCode,
                remark: fieldsValue.bedRemark[index],
                type: fieldsValue.bedType[index]
              })
            }
          })
          this.$message.success('修改成功！')
        } else {
          // 新增房间
          let roomlist = await addFloor_controller({
            id: fieldsValue.code,
            name: fieldsValue.name,
            orders: fieldsValue.orders,
            organCode: this.organCode,
            parentId: this.floorInfo.id[this.floorInfo.id.length - 1],
            treePath: '',
            type: 4 // 1:楼栋，2:单元，3：层，4：房间
          })

          console.log('新增房间：', roomlist)

          fieldsValue.keys.forEach(async(element, index) => {
            await addHospitalBed_controller({
              code: fieldsValue.bedCode[index],
              floorCode: roomlist.id,
              name: fieldsValue.bedName[index],
              orders: fieldsValue.bedOrders[index],
              organCode: this.organCode,
              remark: fieldsValue.bedRemark[index],
              type: fieldsValue.bedType[index]
            })
          })
          this.$message.success('新增成功！')
        }
        this.confirmLoading = false
        this.$emit('update:visible', false)
        this.$emit('RefreshRoom', false)
      })
    },
    handleCancel(e) {
      this.$emit('update:visible', false)
    },
    add() {
      const { form } = this
      const keys = form.getFieldValue('keys')
      const nextKeys = keys.concat(id++)
      form.setFieldsValue({
        keys: nextKeys
      })
    },
    remove(k) {
      const { form } = this
      const keys = form.getFieldValue('keys')
      form.setFieldsValue({
        keys: keys.filter((key) => key !== k)
      })
    },
    removeBed(k, id) {
      console.log(id)
      const { form } = this
      const keys = form.getFieldValue('keys')
      removeHospitalBed_controller({
        id: id
      }).then((res) => {
        this.$message.success('删除成功！')
        form.setFieldsValue({
          keys: keys.filter((key) => key !== k)
        })
      })
    }
  }
}
</script>
