/*
 * @Description:
 * @Author:
 * @Date: 2021-03-18 09:18:27
 * @LastEditTime: 2021-04-22 14:46:36
 * @LastEditors: Please set LastEditors
 */
let {
  queryHardwareMgr_service,
  addHardwareMgr_serveice,
  getHardwareMgr_service,
  updateHardwareMgr_service,
  deleteHardwareMgr_service,
  bedCardcountMgr_service,
  hardware_bindObj_service,
  hardware_list_service,
  updatecardStatus_service,
  replaceubedCard_service,
  updateBedCardPush_service,
  getBedCardPushInfo_service,
  queryBedCardPushInfo_service,
  riskList_service,
  querypushLog_service,
  getInfoMgrList_service,
  delInfoMgr_service,
  getData_service,
  updSetting_service,
  getSetting_service
} = require('../service/HardwareMgr.service')

module.exports = {
  queryHardwareMgr_controller(val) {
    return queryHardwareMgr_service(val)
  },
  addHardwareMgr_controller(val) {
    return addHardwareMgr_serveice(val)
  },
  getHardwareMgr_controller(val) {
    return getHardwareMgr_service(val)
  },
  updateHardwareMgr_controller(val) {
    return updateHardwareMgr_service(val)
  },
  deleteHardwareMgr_controller(val) {
    return deleteHardwareMgr_service(val)
  },
  bedCardcountMgr_controller(val) {
    return bedCardcountMgr_service(val)
  },
  bindObjHardware_controller(val) {
    return hardware_bindObj_service(val)
  },
  hardwareList_controller(val) {
    return hardware_list_service(val)
  },
  updatecardStatus_controller(val) {
    return updatecardStatus_service(val)
  },
  replaceubedCard_controller(val) {
    return replaceubedCard_service(val)
  },
  updateBedCardPush_controller(val) {
    return updateBedCardPush_service(val)
  },
  getBedCardPushInfo_controller(val) {
    return getBedCardPushInfo_service(val)
  },
  queryBedCardPushInfo_controller(val) {
    return queryBedCardPushInfo_service(val)
  },
  riskList_controller(val) {
    return riskList_service(val)
  },
  querypushLog_controller(val) {
    return querypushLog_service(val)
  },
  getInfoMgrList_controller(val) {
    return getInfoMgrList_service(val)
  },
  delInfoMgr_controller(val) {
    return delInfoMgr_service(val)
  },
  getData_controller(val) {
    return getData_service(val)
  },
  updSetting_controller(val) {
    return updSetting_service(val)
  },
  getSetting_controller(val) {
    return getSetting_service(val)
  }
}
