<!--
 * @Description: 列表展示
 * @Author:
 * @Date: 2021-02-24 11:00:21
 * @LastEditTime: 2021-03-01 15:33:00
 * @LastEditors: Please set LastEditors
-->
<template>
  <div class="app-main">
    <!-- 操作栏 -->
    <div class="app-filter">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="8" :sm="24">
            <a-form-item label="规则编号">
              <a-input v-model="queryParam.id" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="使用状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="0">全部</a-select-option>
                <a-select-option value="1">关闭</a-select-option>
                <a-select-option value="2">运行中</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :md="8" :sm="24">
            <a-form-item label="规则编号">
              <a-input v-model="queryParam.id" placeholder="" />
            </a-form-item>
          </a-col> -->
          <template v-if="advanced">
            <a-col :md="8" :sm="24">
              <a-form-item label="规则编号">
                <a-input v-model="queryParam.id" placeholder="" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="使用状态">
                <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                  <a-select-option value="0">全部</a-select-option>
                  <a-select-option value="1">关闭</a-select-option>
                  <a-select-option value="2">运行中</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </template>
          <a-col :md="!advanced && 8 || 24" :sm="24">
            <span class="search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary">查询</a-button>
              <a-button style="margin-left: 8px">重置</a-button>
              <a style="margin-left: 8px" @click="toggleAdvanced">
                {{ advanced ? '收起' : '展开' }}
                <a-icon :type="advanced ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="()=>visibleShow=true">新建</a-button>
      </div>
    </div>
    <!-- 列表栏 -->
    <div class="app-table">
      <a-table bordered size="default" :columns="columns" :data-source="data" :pagination="false">
        <template slot="status" slot-scope="text">
          <span v-if="text == '1'">正常</span>
          <span v-if="text == '2'">失效</span>
        </template>
        <template slot="action">
          <a-button type="primary">修改</a-button>
          <a-button type="danger">删除</a-button>
        </template>
      </a-table>
      <pagination
        :total="total"
        :page.sync="queryParam.page"
        :limit.sync="queryParam.limit"
        class="paginationStyle"
        @pagination="getpages"
      />
    </div>
    <!-- 弹出层 -->
    <a-modal
      title="新增"
      :visible="visibleShow"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="name" label="手机号" prop="phone">
          <a-input v-model="form.phone" placeholder="手机号" />
        </a-form-model-item>
        <a-form-model-item ref="name" label="运用场景" prop="stage">
          <a-select v-model="form.stage" placeholder="运用场景">
            <a-select-option value="养老院">
              Zone one
            </a-select-option>
            <a-select-option value="干休所">
              Zone two
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '年龄',
    dataIndex: 'age',
    key: 'age'
  },
  {
    title: '地址',
    dataIndex: 'address',
    key: 'address 1',
    ellipsis: true// 超长隐藏
  },
  {
    title: '状态',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park, New York No. 1 Lake Park',
    status: 1
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 2 Lake Park, London No. 2 Lake Park',
    status: 2
  },
  {
    key: '3',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 2 Lake Park, London No. 2 Lake Park',
    status: 1
  }
]
import Pagination from '@/components/Pagination'
// import { getListData_controller } from '../static/controller/login_controller'
import { createRequired, validatePhone } from '@/rules/index'
export default {
  components: { Pagination },
  data() {
    return {
      data,
      columns,
      total: 30,
      advanced: false,
      tableHeight: window.innerHeight - 250,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      visibleShow: false,
      confirmLoading: false,
      queryParam: {
        page: 1,
        limit: 10,
        id: '',
        status: ''
      },
      form: {
        name: '',
        stage: ''
      },
      rules: {
        phone: [createRequired({ validator: validatePhone })],
        stage: [{ required: true, message: '请选择', trigger: 'change' }]
      }
    }
  },
  created() {
    // var lett = this
    // document.onkeydown = function() { // 不能直接将事件添加写在input上，必须焦点在input上才能触发，所以可以直接绑定在document上
    //   var key = window.event.keyCode
    //   if (key === 13) {
    //     lett.handleSearch()
    //   }
    // }
  },
  methods: {
    // 搜索
    handleSearch() {
      this.queryParam.page = 1
      this.getList()
    },
    // 列表查询数据
    getList() {
      // const params = {
      //   page: this.queryParam.page,
      //   pageSize: this.queryParam.limit,
      //   id: this.queryParam.id,
      //   status: this.queryParam.status
      // }
      // getListData_controller(params).then((res) => {
      //   console.log('成功', res)
      // })
    },
    // 搜索条件显隐
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    // 分页事件
    getpages(params) {
      this.queryParam.page = params.page
      this.queryParam.limit = params.limit
      this.getList()
    },

    // 弹窗事件处理
    handleOk() {
      this.confirmLoading = true
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$message.success('操作成功')
          setTimeout(() => {
            this.visibleShow = false
            this.confirmLoading = false
          }, 1000)
        } else {
          this.confirmLoading = false
          return false
        }
      })
    },
    handleCancel() {
      console.log('Clicked cancel button')
      this.visibleShow = false
    }
  }
}
</script>

<style lang="less" scoped>

</style>
