<template>
  <div class="view-scroll">
    <div class="m-box col m-mt-10 box">
      <a-form
        :form="form"
        :label-col="{ span: 0 }"
        class="fff"
        :wrapper-col="{ span: 22 }"
        @submit="handleSearch"
      >
        <a-row type="flex">
          <a-col :span="3">
            <a-form-item label="">
              <a-cascader
                v-decorator="[
                  'organCode',
                  {
                    initialValue: [],
                    rules: [
                      {
                        type: 'array',
                        required: false,
                        message: '选择机构',
                      },
                    ],
                  },
                ]"
                placeholder="所有机构"
                change-on-select
                :options="companyData"
                :field-names="{
                  label: 'name',
                  value: 'companyNo',
                  children: 'children',
                }"
              />
            </a-form-item>
          </a-col>
          <a-col :span="2" class="ant-form-item">
            <a-button type="primary" class="m-mt-4" html-type="submit">查询</a-button>
          </a-col>
          <a-col :span="19" class="ant-form-item text-right">
            <a-button type="primary" class="m-mt-4 m-ml-10" @click="add()">新增机构</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="m-box m-mt-10">
      <a-row type="flex">
        <a-col :span="24">
          <a-table
            :columns="columns"
            :data-source="data"
            :pagination="false"
            :scroll="{ x: 1850 }"
          >
            >
            <div slot="photo" slot-scope="record" class="text-3d9">
              <img :src="record" class="photo">
            </div>
            <div slot="status" slot-scope="record" class="text-3d9">
              <span
                :style="
                  record.organStatus == '1'
                    ? 'color: #BEE6CF; padding-left: 15px'
                    : 'color: #FF1818; padding-left: 15px'
                "
              >{{ record.organStatus === "1" ? "使用中" : "已禁用" }}</span>
            </div>
            <div slot="createdTime" slot-scope="record" class="text-3d9">
              <span>{{ record.substr(0, 10) }}</span>
            </div>
            <div slot="lastLoginTime" slot-scope="record" class="text-3d9">
              <span>{{ record.substr(0, 10) }}</span>
            </div>
            <div slot="use" slot-scope="record" class="text-3d9">
              <a-button
                type="primary"
                style="
                  background: ##1890ff;
                  border-color: ##1890ff;
                  margin-right: 10px;
                "
                @click="password(record)"
              >密码</a-button>
              <a-button
                type="primary"
                style="background: ##1890ff; border-color: ##1890ff"
                @click="setItem(record)"
              >编辑</a-button>

              <a-button
                type="primary"
                class="m-ml-10"
                :style="
                  record.organStatus === '1'
                    ? 'background: #ff9d4d; border-color: #ff9d4d'
                    : 'background: rgb(56, 178, 107);border-color: rgb(56, 178, 107)'
                "
                @click="state(record)"
              >{{ record.organStatus === "1" ? "禁用" : "启用" }}
              </a-button>
              <a-button
                type="danger"
                class="m-ml-10"
                @click="del(record)"
              >删除</a-button>
              <a-button
                type="primary"
                class="m-ml-10"
                @click="distribute(record)"
              >分配注册码</a-button>
            </div>
          </a-table>
          <pagination
            :page.sync="pageIndex"
            :limit.sync="pageSize"
            :total="total"
            class="paginationStyle"
            @pagination="getPages"
          />
        </a-col>
      </a-row>
    </div>
    <!-- 添加 -->
    <a-modal
      :visible="visible"
      :footer="null"
      :mask-closable="false"
      @cancel="handleCancel"
    >
      <a-form
        :form="addForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
        @submit="handSave"
      >
        <a-form-item label="机构名称">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请输入机构名称',
                  },
                  {
                    max: 20,
                    message: '机构名称不能超过20个字',
                  }
                ],
                initialValue: addInfo.name,
              },
            ]"
            placeholder="请输入姓名"
          />
        </a-form-item>
        <a-form-item label="机构logo">
          <UploadImg
            :url.sync="frontUrl"
            tip="上传照片"
            :width="280"
            :height="100"
          />
        </a-form-item>
        <a-form-item label="用户登录账户">
          <a-input
            v-decorator="[
              'id',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请输入登录账户',
                  },
                ],
                initialValue: addInfo.age,
              },
            ]"
            placeholder=""
            :max-length="11"
          />
        </a-form-item>
        <a-form-item label="联系方式">
          <a-input
            v-decorator="[
              'phone',
              {
                rules: [
                  {
                    required: true,
                    validator: validatorPhone,
                    trigger: 'blur',
                  },
                ],
                initialValue: addInfo.phone,
              },
            ]"
            placeholder=""
          />
        </a-form-item>

        <a-form-item label="所属机构">
          <a-input
            v-decorator="[
              'companyName',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                  },
                ],
                initialValue: companyName,
              },
            ]"
            disabled
            placeholder=""
          />
        </a-form-item>
        <a-form-item label="角色访问授权">
          <a-select
            v-decorator="[
              'permissions',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请选择角色授权',
                  },
                ],
                initialValue: addInfo.permissions,
              },
            ]"
            placeholder=""
            :allow-clear="true"
          >
            <a-select-option
              v-for="(item, index) in rolelist"
              :key="index"
              :value="item.code"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item style="display: flex; justify-content: center">
          <div style="display: flex; justify-content: space-around">
            <a-button type="primary" html-type="submit" :size="size">
              确认
            </a-button>
            <a-button
              type="primary"
              :size="size"
              style="background: #999; border-color: #999"
              @click="handleCancel"
            >
              取消
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 修改 -->
    <a-modal :visible="visible1" :footer="null" @cancel="handleCancel">
      <a-form
        :form="updateForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
        @submit="handSave1"
      >
        <a-form-item label="机构名称">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请输入机构名称',
                  },
                  {
                    max: 20,
                    message: '机构名称不能超过20个字',
                  }
                ],
                initialValue: info.organName,
              },
            ]"
            placeholder="请输入姓名"
          />
        </a-form-item>
        <a-form-item label="机构logo">
          <UploadImg
            :url.sync="info.logo"
            tip="上传照片"
            :width="280"
            :height="100"
          />
        </a-form-item>
        <a-form-item label="登录账户">
          <a-input
            v-decorator="[
              'count',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请输入登录账户',
                  },
                ],
                initialValue: info.name,
              },
            ]"
            placeholder=""
          />
        </a-form-item>
        <a-form-item label="联系方式">
          <a-input
            v-decorator="[
              'phone',
              {
                rules: [
                  {
                    required: true,
                    validator: validatorPhone,
                    trigger: 'blur',
                  },
                ],
                initialValue: info.phone,
              },
            ]"
            placeholder=""
          />
        </a-form-item>

        <a-form-item label="所属机构">
          <a-input
            v-decorator="[
              'companyName',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                  },
                ],
                initialValue: companyName,
              },
            ]"
            disabled
            placeholder=""
          />
        </a-form-item>
        <a-form-item label="角色访问授权">
          <a-select
            v-decorator="[
              'permissions',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请选择角色授权',
                  },
                ],
                initialValue: info.roleId,
              },
            ]"
            placeholder=""
            :allow-clear="true"
          >
            <a-select-option
              v-for="(item, index) in rolelist"
              :key="index"
              :value="item.code"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item style="display: flex; justify-content: center">
          <div style="display: flex; justify-content: space-around">
            <a-button type="primary" html-type="submit" :size="size">
              确认
            </a-button>
            <a-button
              type="primary"
              :size="size"
              style="background: #999; border-color: #999"
              @click="handleCancel"
            >
              取消
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 密码 -->
    <a-modal :visible="visible2" :footer="null" @cancel="handleCancel">
      <a-form
        :form="passForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
        @submit="handSave2"
      >
        <a-form-item label="用户账户">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                  },
                ],
                initialValue: passinfo.name,
              },
            ]"
            placeholder="请输入姓名"
            disabled
          />
        </a-form-item>
        <a-form-item label="用户旧密码">
          <a-input v-model="number" type="password" disabled />
        </a-form-item>
        <a-form-item label="新的登录密码">
          <a-input
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入密码!',
                  },
                  {
                    validator: validateToNextPassword,
                  },
                ],
                initialValue: passinfo.password,
              },
            ]"
            type="password"
          />
        </a-form-item>
        <a-form-item label="重复新的密码">
          <a-input
            v-decorator="[
              'confirm',
              {
                rules: [
                  {
                    required: true,
                    message: '请再次输入密码!',
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
                initialValue: passinfo.password,
              },
            ]"
            type="password"
            @blur="handleConfirmBlur"
          />
        </a-form-item>
        <a-form-item style="display: flex; justify-content: center">
          <div style="display: flex; justify-content: space-around">
            <a-button type="primary" html-type="submit" :size="size">
              保存
            </a-button>
            <a-button
              type="primary"
              :size="size"
              style="background: #999; border-color: #999"
              @click="handleCancel"
            >
              取消
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
    <DistributeParams ref="distribute" @refresh="refresh" />
  </div>
</template>

<script>
import {
  deleteOrganById_controller,
  account_queryList_controller,
  account_queryTree_controller,
  account_save_controller,
  account_updatePassword_controller,
  account_updateState_controller
} from '@/static/controller/cost.controller'
import { rolelist_controller } from '@/static/controller/UserRole.controller'
import { validateReg } from '@/static/utils/regular'
import companyOptionsMixin from '@/mixins/companyOptionsMixin'
import organTreeSelectMixin from '@/mixins/organTreeSelectMixin'

const columns = [
  {
    title: '机构名称',
    width: 280,
    dataIndex: 'organName'
  },
  {
    title: '机构logo',
    width: 100,
    align: 'center',
    dataIndex: 'logo',
    scopedSlots: {
      customRender: 'photo'
    }
  },
  {
    title: '联系方式',
    width: 120,
    align: 'left',
    dataIndex: 'phone'
  },
  {
    title: '账号',
    width: 120,
    align: 'left',
    dataIndex: 'name'
  },
  {
    title: '角色',
    width: 180,
    align: 'left',
    dataIndex: 'roleName'
  },
  {
    title: '登录次数',
    width: 100,
    align: 'right',
    dataIndex: 'loginCount'
  },
  {
    title: '状态',
    width: 100,
    align: 'center',
    // dataIndex: 'organStatus',
    scopedSlots: {
      customRender: 'status'
    }
  },
  {
    title: '创建时间',
    width: 120,
    align: 'center',
    dataIndex: 'createdTime',
    scopedSlots: {
      customRender: 'createdTime'
    }
  },
  {
    title: '登录时间',
    width: 120,
    align: 'center',
    dataIndex: 'lastLoginTime',
    scopedSlots: {
      customRender: 'lastLoginTime'
    }
  },
  {
    title: '注册码分配数',
    width: 160,
    align: 'center',
    dataIndex: 'registrationCodeBindCount'
  },
  {
    title: '操作',
    fixed: 'right',
    scopedSlots: {
      customRender: 'use'
    }
  }
]
const data = []
const arry = []
const dataList = []
const generateList = (data) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i]
    const key = node.companyNo
    dataList.push({ key, title: node.name })
    if (node.children) {
      generateList(node.children)
    }
  }
}
export default {
  filters: {},
  components: {
    pagination: () => import('@/components/Pagination'),
    UploadImg: () => import('@/components/UploadImg'),
    DistributeParams: () => import('@/components/equipment/DistributeParams')
  },
  mixins: [companyOptionsMixin, organTreeSelectMixin],
  data() {
    return {
      number: '123123',
      expandedKeys: [''],
      searchValue: '',
      autoExpandParent: true,
      data,
      columns,
      gData: [],
      arry,
      replaceFields: {
        children: 'children',
        title: 'name',
        key: 'companyNo'
      },
      selectedRowKeys: [],
      form: this.$form.createForm(this, {
        name: 'evalutaion'
      }),
      addForm: this.$form.createForm(this, {
        name: 'customer_list'
      }),
      updateForm: this.$form.createForm(this, {
        name: 'customer_list2'
      }),
      passForm: this.$form.createForm(this, {
        name: 'customer_list1'
      }),
      visible: false,
      visible2: false,
      pageIndex: 1, // 当前页码
      pageSize: 10,
      pagination: {}, // tab分页
      total: 0,
      where: {},
      //   修改内容
      addInfo: {},
      //   新增知识库名称
      passinfo: {},
      newName: '',
      ids: [],
      index: false,
      size: 'default',
      info: {},
      checkCode: '',
      checkname: '',
      checkLevelCode: '',
      checkLevelname: '',
      visible1: false,
      phone: '',
      frontUrl: '',
      rolelist: '',
      targetOrganId: '',
      companyName: '',
      boll: false
    }
  },

  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  created() {
    let user = JSON.parse(localStorage.getItem('company'))
    if (user.companyNo === 'HLO1') {
      this.boll = true
    } else {
      this.boll = false
    }
    this.getElderList()
    this.getTreeList()
    this.getRoleList()
  },
  methods: {
    password(e) {
      this.visible2 = true
      this.passinfo = e
      console.log(e)
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.passForm
      if (value && value !== form.getFieldValue('password')) {
        callback('密码不一致!')
      } else {
        callback()
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.passForm
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    handleConfirmBlur(e) {
      console.log(e.target.value)
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    select(e) {
      account_queryList_controller({
        organId: e[e.length - 1],
        page: 1,
        rows: this.pageSize
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    },
    getElderList() {
      this.setWhere()
      account_queryList_controller({
        organId: this.where.organCode,
        page: this.pageIndex,
        rows: this.pageSize,
        status: '1'
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    },
    getRoleList() {
      rolelist_controller({}).then((res) => {
        this.rolelist = res
      })
    },
    getTreeList() {
      if (this.gData.length >= 1) {
        this.gData = []
      }
      account_queryTree_controller({}).then((res) => {
        this.gData.push(res)
        this.companyName = res.name
        this.targetOrganId = res.companyNo
        generateList(this.gData)
      })
    },

    start() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    handleChange(e) {
      console.log(e)
      this.checkLevelCode = e
      this.checkLevelname = this.arry[e]
    },
    handleChangeF(e) {
      this.checkCode = e
      this.checkname = this.list[e].name
      this.arry = this.list[e].arr
    },
    handleChange1(e) {
      this.addInfo.checkLevelCode = e
      this.addInfo.checkLevelName = this.arry[e]
    },
    handleChangeF1(e) {
      this.addInfo.checkCode = e
      this.addInfo.checkName = this.list[e].name
      this.arry = this.list[e].arr
    },
    handSave(e) {
      e.preventDefault()
      this.addForm.validateFields(async(error, values) => {
        if (error) {
          return
        }
        console.log(error, values)
        this.pageIndex = 1
        this.addWhere = values
        let arr = []
        arr.push(this.addWhere.permissions)
        account_save_controller({
          organLogo: this.frontUrl,
          organName: this.addWhere.name,
          roleIds: arr,
          targetOrganId: this.targetOrganId,
          userAccount: this.addWhere.id,
          phone: this.addWhere.phone
        }).then((res) => {
          this.frontUrl = ''
          this.visible = false
          this.addForm.resetFields()
          this.getTreeList()
          this.getElderList()
        })
      })
    },
    handSave1(e) {
      e.preventDefault()
      this.updateForm.validateFields(async(error, values) => {
        if (error) {
          console.log(error)
          return
        }
        console.log(error, values)
        this.pageIndex = 1
        let arr = []
        arr.push(values.permissions)
        account_save_controller({
          organLogo: this.info.logo,
          organName: values.name,
          roleIds: arr,
          targetOrganId: this.targetOrganId,
          userAccount: values.count,
          phone: values.phone,
          userId: this.info.userId
        }).then((res) => {
          this.visible1 = false
          this.$message.success('修改成功')
          this.getElderList()
        })
      })
    },
    handSave2(e) {
      e.preventDefault()
      this.passForm.validateFields(async(error, values) => {
        if (error) {
          return
        }
        console.log(error, values)
        this.pageIndex = 1
        account_updatePassword_controller({
          id: this.passinfo.organId,
          password: values.confirm
        }).then((res) => {
          this.passForm.resetFields()
          this.visible2 = false
          this.$message.success('设置成功')
          this.getElderList()
          this.getTreeList()
        })
      })
    },
    del(e) {
      deleteOrganById_controller({ id: String(e.organId) }).then((res) => {
        this.$message.success('删除成功')
        this.getElderList()
        this.getTreeList()
      })
    },
    distribute(record) {
      this.$refs.distribute.organCode = record.organId
      this.$refs.distribute.visible = true
    },
    state(e) {
      if (e.organStatus === '0') {
        account_updateState_controller({
          id: e.organId,
          state: '1'
        }).then((res) => {
          this.$message.success('修改成功')
          this.getElderList()
        })
      } else {
        account_updateState_controller({
          id: e.organId,
          state: '0'
        }).then((res) => {
          this.$message.success('修改成功')
          this.getElderList()
        })
      }
    },
    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.getElderList()
    },

    setItem(e) {
      this.where = JSON.parse(JSON.stringify(e))
      this.visible1 = true
      console.log(e)
      this.info = e
    },
    add() {
      this.visible = true
      this.frontUrl = ''
    },
    handleCancel(e) {
      this.visible = false
      this.visible1 = false
      this.visible2 = false
      this.frontUrl = ''
      console.log(this.frontUrl)
      this.passForm.resetFields()
      this.addForm.resetFields()
      this.updateForm.resetFields()
      // this.addInfo = this.where
    },
    //  短线验证
    validatorPhone(rule, value, callback) {
      console.log(value)
      let val = validateReg('phone', value)
      console.log(val)
      if (!val.validate) {
        callback(new Error('手机号格式不正确!'))
      } else {
        callback()
      }
    },
    handleSearch() {
      this.pageIndex = 1
      this.getElderList()
    },
    /**
     * 将画面输入的检索条件设置到变量中
     */
    setWhere() {
      this.where.organCode = this.form.getFieldValue('organCode') ? this.getSearchOrganCode(this.form.getFieldValue('organCode')) : ''
    },

    refresh() {
      this.getElderList()
    }
  }
}
</script>
<style scoped lang="less">
.index-img {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 0;
  right: 0;
}

.box .ant-form-item {
  margin: 10px 0 !important;
}

.m-mt-4 {
  margin-top: 4px;
}

.photo {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.type {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  color: #000;
  opacity: 0.65;
}
</style>
