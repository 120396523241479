/*
 * @Author: your name
 * @Date: 2021-06-10 18:44:44
 * @LastEditTime: 2021-06-10 19:26:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\VisitRecordMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')

const {
  addVisitRecord_api,
  queryVisitRecord_api
} = require('../api/VisitRecordMgr.api')

module.exports = {
  addVisitRecord_service(value) {
    return xhr(addVisitRecord_api, value)
  },
  queryVisitRecord_service(value) {
    return xhr(queryVisitRecord_api, value)
  }
}
