const {
  xhr
} = require('../lib/xhr')
const {
  getSubscriptionTemplateLists_api,
  updateTemplateStatus_api,
  getTemplateMetaDict_api,
  createTemplate_api,
  getTemplateDetails_api,
  getTemplateOptions_api,
  bindTemplate_api,
  getSubscriptionLists_api,
  getSubscriptionDetail_api,
  getSubscriptionDetailLists_api,
  checkTemplateNameDuplicate_api
} = require('../api/Subscription.api')

module.exports = {
  getSubscriptionTemplateLists_service(val) {
    return xhr(getSubscriptionTemplateLists_api, val)
  },
  updateTemplateStatus_service(val) {
    return xhr(updateTemplateStatus_api, val)
  },
  getTemplateMetaDict_service() {
    return xhr(getTemplateMetaDict_api)
  },
  createTemplate_service(val) {
    return xhr(createTemplate_api, val)
  },
  getTemplateDetails_service(val) {
    return xhr(getTemplateDetails_api, val)
  },
  getTemplateOptions_service(val) {
    return xhr(getTemplateOptions_api, val)
  },
  bindTemplate_service(val) {
    return xhr(bindTemplate_api, val)
  },
  getSubscriptionLists_service(val) {
    return xhr(getSubscriptionLists_api, val)
  },
  getSubscriptionDetail_service(val) {
    return xhr(getSubscriptionDetail_api, val)
  },
  getSubscriptionDetailLists_service(val) {
    return xhr(getSubscriptionDetailLists_api, val)
  },
  checkTemplateNameDuplicate_service(val) {
    return xhr(checkTemplateNameDuplicate_api, val)
  }
}
