export default {
  namespaced: true,
  state: () => ({
    organOptions: []
  }),
  actions: {
    async getOrganOptions({ commit }, fun) {
      const result = await fun()
      commit('setOrganOptions', result)
    }
  },
  mutations: {
    setOrganOptions(state, lists) {
      state.organOptions = lists
    }
  },
  getters: {}
}
