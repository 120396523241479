let {
  sc
} = require('../config/api_spec')

module.exports = {
  getSubscriptionTemplateLists_api: {
    url: `${sc}/mgr/equip/subscription/queryList`,
    method: 'get',
    post: {
      rows: '?',
      page: '?'
    }
  },
  updateTemplateStatus_api: {
    url: `${sc}/mgr/equip/subscription/updateStatus`,
    method: 'put',
    post: {
      id: '?'
    }
  },
  getTemplateMetaDict_api: {
    url: `${sc}/mgr/equip/subscription/metaDict`,
    method: 'get'
  },
  createTemplate_api: {
    url: `${sc}/mgr/equip/subscription/subscriptionTemplate`,
    method: 'post',
    post: {
      templateName: '?',
      templateType: '?',
      templateOrganId: '?',
      templateExpiry: '?',
      metaDictList: '?'
    }
  },
  getTemplateDetails_api: {
    url: `${sc}/mgr/equip/subscription/templateDetal`,
    method: 'get',
    post: {
      id: '?'
    }
  },
  getTemplateOptions_api: {
    url: `${sc}/mgr/equip/subscription/options`,
    method: 'get',
    post: {
      organId: '?'
    }
  },
  bindTemplate_api: {
    url: `${sc}/mgr/equip/subscription/bind`,
    method: 'post',
    post: {
      organId: '?',
      templateId: '?',
      startTime: '?',
      contractNum: '?'
    }
  },
  getSubscriptionLists_api: {
    url: `${sc}/mgr/equip/subscription/subscriptions`,
    method: 'get',
    post: {
      rows: '?',
      page: '?',
      organName: '?',
      templateName: '?'
    }
  },
  getSubscriptionDetail_api: {
    url: `${sc}/mgr/equip/subscription/subscriptionDetail`,
    method: 'get',
    post: {
      id: '?'
    }
  },
  getSubscriptionDetailLists_api: {
    url: `${sc}/mgr/equip/subscription/subscriptionDetailLists`,
    method: 'get',
    post: {
      rows: '?',
      page: '?',
      organName: '?',
      templateName: '?',
      userKeyword: '?'
    }
  },
  checkTemplateNameDuplicate_api: {
    url: `${sc}/mgr/equip/subscription/checkTemplateNameDuplicate`,
    method: 'get',
    post: {
      templateName: '?'
    }
  }
}
