/*
 * @Description:
 * @Author:
 * @Date: 2021-03-09 16:50:56
 * @LastEditTime: 2021-03-09 16:52:29
 * @LastEditors:
 */

let {
  getEvaluationApp_service
} = require('../service/TenantEvaluationApp.service')

module.exports = {
  getEvaluationApp_controller(val) {
    return getEvaluationApp_service(val)
  }
}
