/*
 * @Author: your name
 * @Date: 2021-03-15 15:34:09
 * @LastEditTime: 2021-06-01 13:48:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\waiter.controller.js
 */

const {
  getCustomerList_service,
  addCustomerInfot_service,
  updateState_service,
  removeCustomerInfo_service,
  getCustomerInfoById_service,
  updateCustomerInfo_service
} = require('../service/ServiceStaffInfoMgr.service')

const {
  querylist_service
} = require('../service/Live.ServiceItemMgr.service')

let {
  getBizTypCodeApp_service
} = require('../service/BizTypCodeApp.service')

module.exports = {
  getBizTypCodeApp_controller(val) {
    return getBizTypCodeApp_service(val)
  },
  getCustomerList_controller(val) {
    return getCustomerList_service(val)
  },
  querylist_controller(val) {
    return querylist_service(val)
  },
  addCustomerInfot_controller(val) {
    return addCustomerInfot_service(val)
  },
  updateState_controller(val) {
    return updateState_service(val)
  },
  removeCustomerInfo_controller(val) {
    return removeCustomerInfo_service(val)
  },
  getCustomerInfoById_controller(val) {
    return getCustomerInfoById_service(val)
  },
  updateCustomerInfo_controller(val) {
    return updateCustomerInfo_service(val)
  }
}
