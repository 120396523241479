/*
 * @Description:
 * @Author:
 * @Date: 2021-03-10 19:14:26
 * @LastEditTime: 2021-03-10 19:15:26
 * @LastEditors:
 */

const {
  xhr
} = require('../lib/xhr')
const {
  deleteEvaluationApp_api
} = require('../api/EvaluationApp.api')

module.exports = {

  deleteEvaluationApp_service(val) {
    return xhr(deleteEvaluationApp_api, val)
  }
}
