<!--
 * @Description: 后台管理首页部分
 * @Author:
 * @Date: 2021-03-01 13:02:22
 * @LastEditTime: 2021-03-01 15:05:20
 * @LastEditors:
-->
<template>
  <div class="app-main">
    <a-row type="flex" :gutter="24" align="middle">
      <a-col :span="8" :md="8" :sm="24">
        <a-card title="XX福利院模拟系统">
          <p> <a-button type="link" @click="onEdit(1)">快捷编辑</a-button></p>
          <p> <a-button type="link">点击进入</a-button></p>
        </a-card>
      </a-col>
      <a-col :span="8" :md="8" :sm="24">
        <a-card title="XX福利院模拟系统">
          <p> <a-button type="link">快捷编辑</a-button></p>
          <p> <a-button type="link">点击进入</a-button></p>
        </a-card>
      </a-col>
      <a-col :span="5" :md="5" :sm="24">
        <a-card class="active_add">
          <p> <a-button type="link" icon="plus" @click="onAdd">创建新系统</a-button></p>
        </a-card>
      </a-col>
    </a-row>

    <!-- 创建新系统 -->
    <a-modal
      title="创建新系统"
      width="600px"
      :visible="systemVisible"
      :confirm-loading="confirmLoading"
      @ok="onSubmit"
      @cancel="()=>systemVisible=false"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="agencyname" label="系统名称">
          <a-input v-model="form.agencyname" placeholder="手机号" />
        </a-form-model-item>
        <a-form-model-item ref="moudle" label="模块选择" prop="moudle">
          <a-select v-model="form.moudle" placeholder="模块选择">
            <a-select-option value="养老院">
              Zone one
            </a-select-option>
            <a-select-option value="干休所">
              Zone two
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="domain" label="域名" prop="domain">
          <a-input v-model="form.domain" placeholder="域名" />
        </a-form-model-item>
        <a-form-model-item ref="moudle" label="所属机构" prop="moudle">
          <a-select v-model="form.agency" placeholder="所属机构">
            <a-select-option value="养老院">
              Zone one
            </a-select-option>
            <a-select-option value="干休所">
              Zone two
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="adminuser" label="分配管理员">
          <a-select v-model="form.adminuser" placeholder="分配管理员">
            <a-select-option value="养老院">
              Zone one
            </a-select-option>
            <a-select-option value="干休所">
              Zone two
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { createRequired, validatePhone } from '@/rules/index'
export default {
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      systemVisible: false,
      confirmLoading: false,
      queryParam: {
        page: 1,
        limit: 10,
        id: '',
        status: ''
      },
      form: {
        name: '',
        stage: ''
      },
      rules: {
        phone: [createRequired({ validator: validatePhone })],
        stage: [{ required: true, message: '请选择', trigger: 'change' }]
      }
    }
  },
  methods: {
    onAdd() {
      this.systemVisible = true
      this.form = {}
    //   this.$refs['ruleForm'].resetFields()
    },
    // 编辑
    onEdit() {

    },
    // 提交
    onSubmit() {
      this.confirmLoading = true
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
        //   this.$message.success('操作成功')
          setTimeout(() => {
            this.systemVisible = false
            this.confirmLoading = false
          }, 1000)
        } else {
          this.confirmLoading = false
          return false
        }
      })
    }
  }

}
</script>

<style>

</style>
