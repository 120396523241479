/*
 * @Author: your name
 * @Date: 2021-03-09 09:59:31
 * @LastEditTime: 2021-06-29 14:57:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\api\HardwareMgr.api.js
 */

let {
  hc
} = require('../config/api_spec')
module.exports = {
  queryBuildingsFloor_api: { // 查询
    url: `${hc}/mgr/hardware/floor/queryBuildings`,
    post: {

    }
  },
  queryFloorByBuildingFloor_api: {
    url: `${hc}/mgr/hardware/floor/queryFloorByBuilding`,
    post: {
      parentId: '?',
      useState: '?'
    }
  }
}
