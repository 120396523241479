<!--
 * @Author: your name
 * @Date: 2021-03-09 13:22:43
 * @LastEditTime: 2021-06-22 15:02:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\components\home\header.vue
-->
<template>
  <a-row type="flex" justify="space-between">
    <a-col :span="5">
      <a-row type="flex" align="middle" style="height: 100%">
        <a-space size="large">
          <img
            v-if="companyInfo"
            :src="companyInfo.logo"
            data="../../assets/logo.png"
            alt=""
            srcset=""
            style="height: 30px"
          >
          <a-icon
            style="font-size: 18px"
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="triggerCollapsed"
          />
        </a-space>
        <div style="margin-left: 20px">{{ tips }}</div>
      </a-row>
    </a-col>

    <a-col>
      <a-row type="flex" justify="space-around">
        <a-col>
          <a-dropdown>
            <a-row type="flex" style="cursor: pointer">
              <a-col>
                <div class="user_info">
                  <div class="name">{{ user.userName }}</div>
                  <div class="tip">{{ user.realName }}</div>
                </div>
              </a-col>
              <a-col>
                <a-badge dot>
                  <a-avatar shape="square" :src="user.headImg" />
                </a-badge>
              </a-col>
            </a-row>
            <a-menu slot="overlay">
              <!-- <a-menu-item>
                <router-link :to="{ name: 'adduser', params: { id: user.id } }">
                  <a-icon type="user" />我的资料
                </router-link>
              </a-menu-item>
              <a-menu-item>
                <router-link
                  :to="{ name: 'addorgan', params: { id: user.organCode } }"
                >
                  <a-icon type="apartment" />机构信息
                </router-link>
              </a-menu-item> -->
              <a-menu-item @click="logout">
                <a-icon type="logout" />退出
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import {
  getStorageInfo,
  clearStorage,
  getStorageValue
} from '../../static/utils/localstorage'
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user: {},
      visible: false,
      config: {
        // 整体风格
        style: 1,
        // 导航模式
        mode: 1,
        // 固定header
        fixedHeader: true,
        // 固定侧边栏
        fixedSidebar: true
      },
      tips: '',
      companyInfo: null
    }
  },
  watch: {
    config: {
      handler(newVal) {
        console.log('新值：', newVal)
        this.$emit('setting', JSON.parse(JSON.stringify(newVal)))
      },
      deep: true
    }
  },
  mounted() {
    this.user = getStorageInfo('user')
    this.companyInfo = getStorageInfo('company')
    this.tips = getStorageValue('tips')
  },
  methods: {
    logout() {
      this.$confirm({
        content: '是否确认退出当前系统！',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          // 清除所有缓存
          this.$router.push({
            name: 'Login'
          })
          clearStorage()
        }
      })
    },
    triggerCollapsed() {
      this.$emit('update:collapsed', !this.collapsed)
    }
  }
}
</script>
<style lang="less" scoped>
@min-layout-bg1: #f0f2f5;
@min-layout-bg2: rgba(0, 21, 41, 0.85);
@min-layout-bg3: rgba(0, 21, 41, 0.85);

.setting {
  position: fixed;
  right: -1px;
  top: 50%;
}

.zindex1 {
  z-index: 1;
}

.light {
  background-color: @min-layout-bg1 !important;
}

.dark {
  background-color: @min-layout-bg2 !important;
}

h3 {
  margin-bottom: 10px;
}

.min-layout {
  position: relative;
  width: 44px;
  height: 36px;
  margin-right: 16px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.18);
  cursor: pointer;

  .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 33%;
    height: 100%;
    background-color: #fff;
  }

  .heade {
    position: absolute;
    top: 0;
    left: 0;
    height: 25%;
    width: 100%;
    background-color: #fff;
  }

  .checked {
    position: absolute;
    z-index: 1;
    right: 5px;
    bottom: 5px;
    color: #1890ff;
  }
}
</style>
