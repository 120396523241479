/*
 * @Author: 刘崇杰
 * @Date: 2021-03-22 14:20:47
 * @LastEditTime: 2021-06-22 14:16:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\ServiceTaskMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  queryServiceTask_api,
  addServiceTask_api,
  editExeUser_api,
  delServiceTask_api,
  editServiceTask_api,
  addBatchTask_api,
  cancelTask_api
} = require('../api/live.ServiceTaskMgr.api')

module.exports = {
  queryServiceTask_service(val) {
    return xhr(queryServiceTask_api, val)
  },
  addServiceTask_service(val) {
    return xhr(addServiceTask_api, val)
  },
  editExeUser_service(val) {
    return xhr(editExeUser_api, val)
  },
  delServiceTask_service(val) {
    return xhr(delServiceTask_api, val)
  },
  editServiceTask_service(val) {
    return xhr(editServiceTask_api, val)
  },
  addBatchTask_service(val) {
    return xhr(addBatchTask_api, val)
  },
  cancelTask_service(val) {
    return xhr(cancelTask_api, val)
  }
}
