/*
 * @Description:
 * @Author:
 * @Date: 2021-03-19 14:39:59
 * @LastEditTime: 2021-03-19 16:33:06
 * @LastEditors:
 */

const {
  xhr
} = require('../lib/xhr')
const {
  addfinance_api,
  removefinance_api,
  queryfinance_api,
  detailfinance_api,
  updatefinance_api
} = require('../api/FinanceOrderMgr.api')

module.exports = {
  addfinance_service(val) {
    return xhr(addfinance_api, val)
  },
  removefinance_service(val) {
    return xhr(removefinance_api, val)
  },
  queryfinance_service(val) {
    return xhr(queryfinance_api, val)
  },
  detailfinance_service(val) {
    return xhr(detailfinance_api, val)
  },
  updatefinance_service(val) {
    return xhr(updatefinance_api, val)
  }
}
