<template>
  <div>
    <a-modal
      title="生成注册码"
      :visible="visible"
      :footer="null"
      :mask-closable="false"
      @cancel="handleCancel"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 17 }"
        @submit.prevent="handleSubmit"
      >
        <a-form-item label="注册码数量">
          <a-input-number
            id="count"
            v-decorator="[
              'count',
              {
                rules: [
                  {
                    required: true,
                    message: '注册码数量不能为空!',
                  },
                  {
                    pattern: /^[1-9]\d*$/,
                    message: '请输入正整数!',
                  }
                ],
                validateTrigger: ['blur'],
              }
            ]"
            :min="1"
            :max="2000"
            :step="100"
          />
        </a-form-item>
        <a-form-item style="display: flex; justify-content: center">
          <div style="display: flex; justify-content: space-around">
            <a-button type="primary" html-type="submit" :size="size">
              确认
            </a-button>
            <a-button
              type="primary"
              :size="size"
              style="background: #999; border-color: #999"
              @click="handleCancel"
            >
              取消
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import BaseModal from '@/components/subscription/BaseModal'
import {
  generateRegistrationCode_controller
} from '@/static/controller/RegistrationCodeMgr.controller'

export default {
  extends: BaseModal,
  data() {
    return {
      count: 100,
      form: this.$form.createForm(this),
      rules: []
    }
  },
  methods: {
    handleSubmit() {
      this.form.validateFields([
        'count'
      ], { force: true }, (err, values) => {
        if (!err) {
          generateRegistrationCode_controller({ count: values.count }).then(() => {
            this.$message.success('注册码生成成功！')
            this.visible = false
            this.form.resetFields()
            this.$emit('refresh')
          })
        } else {
          console.log(err, values.count)
        }
      })
    }
  }
}
</script>

<style>
</style>
