<!--
 * @Description:
 * @Author:
 * @Date: 2021-05-24 11:42:31
 * @LastEditTime: 2021-05-24 11:51:14
 * @LastEditors:
-->
<template>
  <div style="text-align:center;margin-top:60px"><i class="iconfont" style="font-size:70px">&#xe60a;</i><span style="display:block">暂无数据</span></div>
</template>

<script>
export default {

}
</script>

<style>

</style>
