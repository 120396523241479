/*
 * @Author: your name
 * @Date: 2021-03-01 12:01:38
 * @LastEditTime: 2021-03-02 13:43:24
 * @LastEditors: Please set LastEditors
 * @Description: 验证码相关
 * @FilePath: \ioac-tenant-html\src\static\api\Captcha_api.js
 */
let {
  uc
} = require('../config/api_spec')
module.exports = {
  // 获取图片验证码
  getCaptcha_api: {
    url: `${uc}/app/user/captcha/getCaptcha`,
    post: {

    }
  },
  /**
   * 检验验证码
   */
  checkCaptcha_api: {
    url: `${uc}/app/user/captcha/checkCaptcha`,
    post: {
      originalImageBase64: '?',
      secretKey: '?',
      pointList: '?'
    }
  }
}
