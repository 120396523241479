/*
 * @Author: your name
 * @Date: 2021-06-02 13:07:26
 * @LastEditTime: 2021-06-10 14:02:02
 * @LastEditors: Please set LastEditors
 * @Description: 注册码
 */
let {
  uc
} = require('@/static/config/api_spec')

module.exports = {
  query_api: {
    url: `${uc}//mgr/equip/drugorder/queryList`,
    post: {
      mobile: '',
      organCode: '',
      page: 0,
      rows: 0,
      number: 0,
      size: 0
    }
  },
  approval_api: {
    url: `${uc}/mgr/equip/drugorder/approval`,
    post: {
      drugOrderId: 0
    }
  },
  approvalMulti_api: {
    url: `${uc}/mgr/equip/drugorder/approvalmulti`,
    post: {
      drugOrderIdList: []
    }
  },
  modify_api: {
    url: `${uc}/mgr/equip/drugorder/modify`,
    post: {
      id: '',
      phone: '',
      drugName: '',
      drugCompany: '',
      agree: 0,
      count: 0,
      address: '',
      memo: ''
    }
  },
  delete_api: {
    url: `${uc}/mgr/equip/drugorder/delete`,
    post: {
      drugOrderId: 0
    }
  },
  export_api: {
    url: `${uc}/mgr/equip/drugorder/export`,
    post: {
      mobile: '',
      organCode: ''
    }
  }
}
