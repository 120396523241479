/*
 * @Description:
 * @Author:
 * @Date: 2021-03-18 18:40:45
 * @LastEditTime: 2021-03-19 11:10:57
 * @LastEditors:
 */

let {
  outQuery_service,
  addoutCustomer_service,
  updateoutCustomer_service,
  removeoutCustomer_service
} = require('../service/OutCustomer.service')

module.exports = {
  outQuery_controller(val) {
    return outQuery_service(val)
  },
  addCustomer_controller(val) {
    return addoutCustomer_service(val)
  },
  updateCustomer_controller(val) {
    return updateoutCustomer_service(val)
  },
  removeCustomer_controller(val) {
    return removeoutCustomer_service(val)
  }
}
