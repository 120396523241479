<script>
import { mapActions } from 'vuex'
import { getOrganOptions_controller } from '../../static/controller/OrganMgr.controller'
import userMixin from '@/mixins/userMixin'
export default {
  mixins: [userMixin],
  data() {
    return {
      data: [],
      pageIndex: 1,
      pageSize: 10,
      pagination: {},
      total: 0
    }
  },
  created() {
    // 请求机构选项
    this.getOrganOptions(getOrganOptions_controller)
  },
  methods: {
    ...mapActions({
      getOrganOptions: 'organ/getOrganOptions'
    }),

    /**
     * 获取画面数据及分页信息
     */
    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.getList()
    },

    /**
     * 画面查询处理
     */
    handleSearch() {
      // 重置页码
      this.pageIndex = 1
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/styles/subscript.less";
</style>
