/*
 * @Description:
 * @Author:
 * @Date: 2021-04-28 18:11:32
 * @LastEditTime: 2021-05-10 17:47:11
 * @LastEditors:
 */
const {
  xhr, refreshxhr
} = require('../lib/xhr')

const {
  getList_api,
  addList_api,
  getDangerList,
  submitResult,
  logLIst,
  queryList,
  facilityList,
  getDetails,
  logDetails,
  WarningBindObj,
  setDetails_api,
  DetailsUpdate_api,
  deleteList,
  warningTask_api,
  handleWarningTask_api
} = require('../api/warnning.api')

module.exports = {
  warnningDelete_controller(val) {
    return xhr(deleteList, val)
  },
  warnningList_controller(val) {
    return xhr(getList_api, val)
  },
  warnningAddList_controller(val) {
    return xhr(addList_api, val)
  },
  warnningSetDetails_controller(val) {
    return xhr(setDetails_api, val)
  },
  warnningDetailsUpdate_controller(val) {
    return xhr(DetailsUpdate_api, val)
  },

  warnningDangerList_controller(val) {
    return refreshxhr(getDangerList, val)
  },
  warnningSubmit(val) {
    return xhr(submitResult, val)
  },
  warnningLoglist(val) {
    return xhr(logLIst, val)
  },
  warnningFacilityList(val) {
    return xhr(facilityList, val)
  },
  warnningQuerylist(val) {
    return xhr(queryList, val)
  },
  warnningGetDetails(val) {
    return xhr(getDetails, val)
  },
  warnningLogDetails(val) {
    return xhr(logDetails, val)
  },
  warningBindObjs_controller(val) {
    return refreshxhr(WarningBindObj, val)
  },
  warningTask_controller(val) {
    return xhr(warningTask_api, val)
  },

  handleWarningTask(val) {
    return xhr(handleWarningTask_api, val)
  }

}
