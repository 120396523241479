/*
 * @Description:
 * @Author:
 * @Date: 2021-04-28 18:11:32
 * @LastEditTime: 2021-06-03 14:03:36
 * @LastEditors:
 */
const {
  xhr
} = require('../lib/xhr')

const {
  queryForCamera_api,
  accessToken_api,
  ptzControl_api,
  getLiveAddress_api
} = require('../api/CameraMgr.api')

module.exports = {
  queryForCamera_controller(val) {
    return xhr(queryForCamera_api, val)
  },
  accessToken_controller(val) {
    return xhr(accessToken_api, val)
  },
  ptzControl_controller(val) {
    return xhr(ptzControl_api, val)
  },
  getLiveAddress_controller(val) {
    return xhr(getLiveAddress_api, val)
  }

}
