<!--
 * @Description:
 * @Author:
 * @Date: 2021-03-25 11:05:47
 * @LastEditTime: 2021-05-14 13:22:02
 * @LastEditors:
-->
<template>
  <div>
    <a-tabs type="card" style="margin-top:25px" @change="callback">
      <a-tab-pane v-for="(item,index) in typeList" :key="index" :tab="item.typeName">
        <a-table :data-source="data" style="margin:10px auto">
          <a-table-column :title="item.typeName" data-index="age" />
          <a-table-column key="address" title="Address" data-index="address" />
        </a-table>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
const data = [
  {
    key: '1',
    firstName: 'John',
    lastName: 'Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    tags: ['nice', 'developer']
  },
  {
    key: '2',
    firstName: 'Jim',
    lastName: 'Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    tags: ['loser']
  }
]
export default {
  data() {
    return {
      data,
      typeName: '',
      typeList: [{ type: 1, typeName: '血压' }, { type: 2, typeName: '血糖' }, { type: 3, typeName: '血氧' }]
    }
  },
  methods: {
    callback(value) {
      let data = parseInt(value)
      switch (data) {
        case 1:
          this.typeName = '血压'
          break
        case 2:
          this.typeName = '血糖'
          break
        case 3:
          this.typeName = '血氧'
          break
        case 4:
          this.typeName = '心电'
          break
        case 5:
          this.typeName = '体脂'
          break
        case 6:
          this.typeName = '胆固醇'
          break
        case 7:
          this.typeName = '尿酸'
          break
        case 8:
          this.typeName = '腰臀比'
          break
      }
    }
  }

}
</script>

<style>

</style>
