import {
  account_queryTree_controller
} from '@/static/controller/cost.controller'
export default {
  data() {
    return {
      companyData: []
    }
  },
  mounted() {
    this.getCompanyTreeList()
  },
  methods: {
    /**
      * 获取机构
      */
    getCompanyTreeList() {
      account_queryTree_controller({}).then((res) => {
        this.companyData.push(res)
      })
    }
  }
}
