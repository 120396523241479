/*
 * @Author: your name
 * @Date: 2021-03-04 13:38:11
 * @LastEditTime: 2021-03-04 14:57:16
 * @LastEditors: Please set LastEditors
 * @Description: 文件上传服务
 * @FilePath: \ioac-tenant-html\src\static\api\File.api.js
 */
let {
  bc
} = require('../config/api_spec')
module.exports = {
  getAliyunOssSign_api: {
    url: `${bc}/app/oss/ali/web/noback/getAliyunOssSign`,
    post: {
      isImg: '?'
    }
  }
}
