/*
 * @Author:
 * @Date: 2021-03-01 12:44:42
 * @LastEditTime: 2021-03-24 18:23:33
 * @LastEditors:
 * @Description: 菜品查询接口访问
 * @FilePath: \ioac-tenant-html\src\static\service\Captcha.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  getFoodMgr_api,
  deleteFoodMgr_api,
  addFoodMgr_api,
  editFoodMgr_api,
  detailFoodMgr_api

} = require('../api/FoodMgr.api')

module.exports = {
  getFoodMgr_service(val) {
    return xhr(getFoodMgr_api, val)
  },
  deleteFoodMgr_serveice(val) {
    return xhr(deleteFoodMgr_api, val)
  },
  addFoodMgr_service(val) {
    return xhr(addFoodMgr_api, val)
  },
  editFoodMgr_service(val) {
    return xhr(editFoodMgr_api, val)
  },
  detailFoodMgr_service(val) {
    return xhr(detailFoodMgr_api, val)
  }
}
