
<template>
  <div class="view-scroll">
    <div class="m-box col m-mt-10 box">
      <a-form
        :form="form"
        :label-col="{ span: 0 }"
        class="fff"
        :wrapper-col="{ span: 22 }"
        @submit="handleSearch"
      >
        <a-row type="flex">
          <a-col :span="4">
            <a-form-item label="">
              <a-input v-decorator="['typeName']" placeholder="请输入设备类型" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-input v-decorator="['sn']" placeholder="请输入SN" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-cascader
                v-decorator="[
                  'organCode',
                  {
                    initialValue: [],
                    rules: [
                      {
                        type: 'array',
                        required: false,
                        message: '选择机构',
                      },
                    ],
                  },
                ]"
                placeholder="所有机构"
                change-on-select
                :options="companyData"
                allow-clear
                :field-names="{
                  label: 'name',
                  value: 'companyNo',
                  children: 'children',
                }"
              />
            </a-form-item>
          </a-col>
          <a-col :span="3" class="ant-form-item">
            <a-button type="primary" class="m-mt-4" html-type="submit">
              查询
            </a-button>
          </a-col>
          <a-col :span="9" class="ant-form-item text-right">
            <a-button
              type="primary"
              class="m-mt-4"
              style="margin-right: 10px"
              @click="batchequi()"
            >
              批量分配
            </a-button>
            <a-button
              v-if="boll"
              type="primary"
              class="m-mt-4"
              style="margin-right: 10px"
              @click="batchadd()"
            >
              批量增加
            </a-button>
            <a-button
              v-if="boll"
              type="primary"
              class="m-mt-4"
              style="margin-right: 10px"
              @click="batchdel()"
            >
              批量删除
            </a-button>
            <a-button v-if="boll" type="primary" class="m-mt-4" @click="add()">
              新增设备
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="m-box m-mt-10">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
      ><div
         slot="guarant"
         slot-scope="record"
         class="text-3d9"
         :style="
           record === 1
             ? 'color:#39B26B'
             : record === 2
               ? 'color:#1890FF'
               : 'color:#FF1818'
         "
       >
         <span>
           {{ record === 1 ? "正常" : record === 2 ? "在保" : "过保" }}
         </span>
       </div>
        <div
          slot="state"
          slot-scope="record"
          class="text-3d9"
          :style="record === 1 ? 'color:#1890FF' : 'color:#FF1818'"
        >
          <span>{{ record === 1 ? "使用" : "停用" }}</span>
        </div>

        <div slot="action" slot-scope="record" class="text-3d9">
          <a-button
            type="primary"
            style="background: #38b26b; border-color: #38b26b"
            @click="setItem(record)"
          >分配</a-button>
          <a-button
            v-if="boll"
            class="m-ml-10"
            :disabled="record.organCode == 'HLO1'"
            @click="unbind(record)"
          >解绑</a-button>
          <a-button
            v-if="boll"
            type="primary"
            class="m-ml-10"
            @click="update(record)"
          >编辑</a-button>
          <a-button
            v-if="boll"
            type="danger"
            class="m-ml-10"
            @click="del(record)"
          >删除</a-button>
        </div>
      </a-table>
      <pagination
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        :total="total"
        class="paginationStyle"
        @pagination="getPages"
      />
    </div>

    <a-modal
      title="编辑设备"
      :visible="visible"
      @ok="handleOkup"
      @cancel="handleCancel"
    >
      <a-form
        :form="upForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-item label="设备类型">
          <a-select
            v-decorator="[
              'OneType',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '设备类型不能为空'
                  },
                ],
                initialValue: addInfo.typeId,
              },
            ]"
            :allow-clear="true"
          >
            <a-select-option v-for="item in equiList" :key="item.id">
              {{ item.typeName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="IMEI号">
          <a-input
            v-decorator="['imei', { initialValue: addInfo.imei }]"
            placeholder=""
          />
        </a-form-item>
        <a-form-item label="SN号">
          <a-input
            v-decorator="[
              'sn',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: 'sn不能为空'
                  },
                ],
                initialValue: addInfo.sn,
              },
            ]"
            placeholder=""
            :allow-clear="true"
          />
        </a-form-item>
        <a-form-item label="MAC">
          <a-input
            v-decorator="['mac', { initialValue: addInfo.mac }]"
            placeholder=""
          />
        </a-form-item>
        <a-form-item label="设备状态">
          <a-select
            v-decorator="[
              'state',
              {
                initialValue: addInfo.state,
              },
            ]"
            placeholder="保修状态"
            :allow-clear="true"
          >
            <a-select-option :value="1"> 使用 </a-select-option>
            <a-select-option :value="2"> 停用 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="地址">
          <a-input
            v-decorator="['address', { initialValue: addInfo.address }]"
            placeholder=""
          />
        </a-form-item>
        <a-form-item label="经度">
          <a-input
            v-decorator="['longitude', { initialValue: addInfo.longitude }]"
            placeholder=""
          />
        </a-form-item>
        <a-form-item label="纬度">
          <a-input
            v-decorator="['latitude', { initialValue: addInfo.latitude }]"
            placeholder=""
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 新增 -->
    <a-modal
      title="新增设备"
      :visible="visible1"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form
        :form="addForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-item label="设备类型">
          <a-select
            v-decorator="[
              'OneType',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '设备类型不能为空'
                  },
                ],
                initialValue: newInfo.typeName,
              },
            ]"
            :allow-clear="true"
          >
            <a-select-option v-for="item in equiList" :key="item.id">
              {{ item.typeName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="IMEI号">
          <a-input
            v-decorator="[
              'imei',
              {
                initialValue: newInfo.imei,
              },
            ]"
            placeholder=""
          />
        </a-form-item>
        <a-form-item label="SN号">
          <a-input
            v-decorator="[
              'sn',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: 'sn不能为空'
                  },
                ],
                initialValue: newInfo.sn,
              },
            ]"
            placeholder=""
            :allow-clear="true"
          />
        </a-form-item>
        <a-form-item label="MAC">
          <a-input
            v-decorator="[
              'mac',
              {
                initialValue: newInfo.mac,
              },
            ]"
            placeholder=""
          />
        </a-form-item>
        <a-form-item label="地址">
          <a-input
            v-decorator="['address']"
            placeholder=""
          />
        </a-form-item>
        <a-form-item label="经度">
          <a-input
            v-decorator="['longitude']"
            placeholder=""
          />
        </a-form-item>
        <a-form-item label="纬度">
          <a-input
            v-decorator="['latitude']"
            placeholder=""
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 分配 -->
    <a-modal
      title="分配设备"
      :visible="visible2"
      @ok="handleOk2"
      @cancel="handleCancel"
    >
      <a-form
        :form="assForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-item label="选择机构">
          <a-cascader
            v-decorator="[
              'equip',
              {
                initialValue: info.organArr,
                rules: [
                  {
                    type: 'array',
                    required: true,
                    message: '选择机构',
                  },
                ],
              },
            ]"
            placeholder="选择机构"
            change-on-select
            :options="gData"
            :field-names="{
              label: 'name',
              value: 'companyNo',
              children: 'children',
            }"
          />
        </a-form-item>
        <a-form-item label="设备类型">
          <a-input
            v-decorator="[
              'typeName',
              {
                rules: [
                  {
                    trigger: 'blur',
                  },
                ],
                initialValue: info.typeName,
              },
            ]"
            disabled
            placeholder=""
          />
        </a-form-item>
        <a-form-item label="设备号">
          <a-input
            v-decorator="[
              'sn',
              {
                rules: [
                  {
                    trigger: 'blur',
                  },
                ],
                initialValue: info.sn,
              },
            ]"
            disabled
            placeholder=""
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 批量分配 -->
    <a-modal
      title="分配设备"
      :visible="visible5"
      @ok="handleOk5"
      @cancel="handleCancel"
    >
      <a-form
        :form="batchFrom"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-item label="选择机构">
          <a-cascader
            v-decorator="[
              'equip',
              {
                initialValue: batchinfo.equip,
                rules: [
                  {
                    type: 'array',
                    required: true,
                    message: '选择机构',
                  },
                ],
              },
            ]"
            placeholder="选择机构"
            change-on-select
            :options="gData"
            :field-names="{
              label: 'name',
              value: 'companyNo',
              children: 'children',
            }"
          />
        </a-form-item>
        <a-form-item label="设备类型">
          <a-input
            v-decorator="[
              'typeName',
              {
                initialValue: batchinfo.typeName,
              },
            ]"
            disabled
            placeholder=""
          />
        </a-form-item>
        <a-form-item label="设备号">
          <a-input
            v-decorator="[
              'sn',
              {
                initialValue: batchinfo.sn,
              },
            ]"
            disabled
            placeholder=""
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 批量增加 -->
    <a-modal
      title="批量增加"
      :visible="visible3"
      @ok="handleOk3"
      @cancel="handleCancel"
    >
      <a-form
        :form="equiForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-item label="选择设备">
          <a-select
            v-decorator="[
              'OneType',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                  },
                ],
              },
            ]"
            :allow-clear="true"
            @select="change"
          >
            <a-select-option v-for="item in equiList" :key="item.id">
              {{ item.typeName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item style="width: 100%" label="导入设备号">
          <File ref="file" style="width: 100%" @updsuccess="updsuccess" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// import File from '../../components/file.vue'
import {
  equipment_add_controller,
  equipment_equip_controller,
  equipment_batchequip_controller,
  equipment_batchDeleteEquipInfo_controller,
  equipment_deleteEquipInfoById_controller,
  equipment_editEquipInfo_controller,
  //   equipment_getEquipInfoById_controller,
  equipment_query_controller,
  queryAllEquipTypeAndModel_controller,
  account_queryTree_controller,
  unbindOrgan_controller
} from '../../static/controller/cost.controller'
import companyOptionsMixin from '@/mixins/companyOptionsMixin'
import organTreeSelectMixin from '@/mixins/organTreeSelectMixin'
const columns = [
  {
    title: '设备类型',
    dataIndex: 'typeName'
  },
  {
    title: '机构名称',
    dataIndex: 'organName'
  },
  {
    title: 'IMEI',
    dataIndex: 'imei'
  },
  {
    title: 'SN',
    dataIndex: 'sn'
  },
  {
    title: 'MAC',
    dataIndex: 'mac'
  },
  {
    title: '保修状态',
    dataIndex: 'guaranteeState',
    scopedSlots: {
      customRender: 'guarant'
    }
  },
  {
    title: '使用状态',
    dataIndex: 'state',
    scopedSlots: {
      customRender: 'state'
    }
  },
  {
    title: '激活时间',
    dataIndex: 'cdt'
  },
  {
    title: '操作',

    scopedSlots: {
      customRender: 'action'
    }
  }
]

const data = []
export default {
  filters: {},
  components: {
    pagination: () => import('../../components/Pagination'),
    File: () => import('../../components/fileequi.vue')
  },
  mixins: [companyOptionsMixin, organTreeSelectMixin],
  data() {
    return {
      data,
      columns,
      selectedRowKeys: [],
      form: this.$form.createForm(this, {
        name: 'evalutaion'
      }),
      addForm: this.$form.createForm(this, {
        name: 'customer_list'
      }),
      upForm: this.$form.createForm(this, {
        name: 'customer_listup'
      }),
      assForm: this.$form.createForm(this, {
        name: 'customer_listass'
      }),
      equiForm: this.$form.createForm(this, {
        name: 'customer_listequi'
      }),
      batchFrom: this.$form.createForm(this, {
        name: 'customer_listequi5'
      }),
      visible: false,
      visible1: false,
      visible2: false,
      visible3: false,
      visible5: false,
      pageIndex: 1, // 当前页码
      pageSize: 10,
      pagination: {}, // tab分页
      total: 0,
      where: {},
      addInfo: {},
      ids: [],
      equis: [],
      newInfo: {
        typeId: '',
        imei: '',
        sn: '',
        mac: ''
      },
      info: {},
      typeId: '',
      success: false,
      equiList: '',
      gData: [],
      batchinfo: {},
      boll: false
    }
  },

  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }

  },

  created() {
    let user = JSON.parse(localStorage.getItem('company'))
    if (user.companyNo === 'HLO1') {
      this.boll = true
    } else {
      this.boll = false
    }
    // console.log(equipment_query_controller)
    this.getElderList()
    queryAllEquipTypeAndModel_controller().then((res) => {
      this.equiList = res
    })
    this.getTreeList()
  },
  methods: {
    getTreeList() {
      account_queryTree_controller({}).then((res) => {
        this.gData.push(res)
        this.companyName = res.name
        this.targetOrganId = res.companyNo
      })
    },
    change(e) {
      // console.log(e)
      // console.log(this.$refs.file.typeId)
      this.$refs.file.typeId = e
      // console.log(this.$refs.file.typeId)
    },
    getElderList() {
      this.selectedRowKeys = []
      equipment_query_controller({
        typeName: this.where.typeName,
        sn: this.where.sn,
        organCode: this.where.organCode,
        page: this.pageIndex,
        rows: this.pageSize
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    },

    start() {
      this.loading = true
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },

    onSelectChange(selectedRowKeys) {
      // console.log('selectedRowKeys changed: ', selectedRowKeys)
      let ids = []
      let equis = []
      for (let x of selectedRowKeys) {
        for (let i in this.data) {
          // console.log(x, i)
          if (x === +i) {
            ids.push(this.data[i].id)
            equis.push(this.data[i].typeName)
          }
        }
      }
      this.ids = ids
      this.equis = equis.filter((item, index) => {
        return equis.indexOf(item, 0) === index
      })
      this.selectedRowKeys = selectedRowKeys
    },
    batchadd() {
      this.visible3 = true
    },
    handleSearch(e) {
      e.preventDefault()
      this.form.validateFields((error, values) => {
        // console.log(error, values)
        this.pageIndex = 1
        this.where = values
        this.setWhere()
        this.getElderList()
      })
    },

    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.getElderList()
    },

    add() {
      this.visible1 = true
    },
    update(e) {
      this.addInfo = e
      this.upForm.setFieldsValue({
        OneType: this.addInfo.typeId
      })
      this.visible = true
    },
    del(e) {
      console.log('@@', e)
      const that = this
      this.$confirm({
        title: '注意',
        content: `确定删除设备号:${e.sn} 吗?`,
        onOk() {
          equipment_deleteEquipInfoById_controller({ id: e.id }).then((res) => {
            that.getElderList()
            that.$message.success('删除成功')
          })
        },
        onCancel() {
        }
      })
    },
    batchdel() {
      if (this.ids.length === '0') {
        this.$message.error('请勾选设备')
      } else {
        equipment_batchDeleteEquipInfo_controller({ ids: this.ids }).then(
          (res) => {
            this.$message.success('删除成功')
            this.getElderList()
          }
        )
      }
    },
    setItem(e) {
      this.visible2 = true
      this.info = e
    },
    batchequi() {
      if (this.ids.length === 0) {
        this.$message.success('选择设备')
        return
      }
      this.visible5 = true
      this.batchinfo.typeName = this.equis.join()
      this.batchinfo.sn = this.ids.join()
    },
    handleOk5(e) {
      e.preventDefault()
      this.batchFrom.validateFields((error, values) => {
        if (error) {
          return
        }
        let orangid = ''
        if (values.equip.length === 1) {
          orangid = values.equip[0]
        } else {
          orangid = values.equip[1]
        }
        equipment_batchequip_controller({
          snList: values.sn,
          assignCompany: orangid
        }).then((res) => {
          this.visible5 = false
          this.$message.success('修改成功')
          this.getElderList()
        })
      })
    },
    // 新增
    handleOk(e) {
      e.preventDefault()
      this.addForm.validateFields((error, values) => {
        if (error) {
          return
        }
        // console.log(values)
        equipment_add_controller({
          imei: values.imei,
          mac: values.mac,
          sn: values.sn,
          typeId: values.OneType,
          address: values.address,
          longitude: values.longitude,
          latitude: values.latitude
        }).then((res) => {
          this.visible1 = false
          this.$message.success('新增成功')
          this.getElderList()
          this.addForm.resetFields()
        })
      })
    },
    // 编辑
    handleOkup(e) {
      e.preventDefault()
      this.upForm.validateFields((error, values) => {
        if (error) {
          return
        }
        // console.log(values, '==========')
        equipment_editEquipInfo_controller({
          imei: values.imei,
          mac: values.mac,
          sn: values.sn,
          typeId: values.OneType,
          id: this.addInfo.id,
          state: values.state,
          address: values.address,
          longitude: values.longitude,
          latitude: values.latitude
        }).then((res) => {
          this.visible = false
          this.$message.success('修改成功')
          this.upForm.resetFields()
          this.getElderList()
        })
      })
    },
    // 分配
    handleOk2(e) {
      e.preventDefault()
      this.assForm.validateFields((error, values) => {
        if (error) {
          return
        }
        // if
        // console.log(values)
        let orangid = ''
        if (values.equip.length === 1) {
          orangid = values.equip[0]
        } else {
          orangid = values.equip[1]
        }
        equipment_equip_controller({
          sn: values.sn,
          assignCompany: orangid
        }).then((res) => {
          this.visible2 = false
          this.$message.success('修改成功')
          this.assForm.resetFields()
          this.getElderList()
        })
      })
    },
    handleOk3(e) {
      e.preventDefault()
      this.upForm.validateFields((error, values) => {
        if (error) {
          return
        }
        if (this.success) {
          this.$message.success('新增成功')
          this.getElderList()
          this.visible3 = false
        }
      })
    },
    handleCancel(e) {
      this.visible = false
      this.visible1 = false
      this.visible2 = false
      this.visible3 = false
      this.visible5 = false
      this.addForm.resetFields()
      this.form.resetFields()
      this.upForm.resetFields()
      this.assForm.resetFields()
      this.equiForm.resetFields()
      this.batchFrom.resetFields()
    },

    updsuccess() {
      // console.log('chenggong')
      this.success = true
    },

    setWhere() {
      this.where.organCode = this.getSearchOrganCode(this.form.getFieldValue('organCode'))
    },

    unbind(record) {
      unbindOrgan_controller({
        id: record.id
      }).then((res) => {
        this.$message.success('解绑成功')
        this.getElderList()
      })
    }
  }
}
</script>
<style scoped lang="less">
.index-img {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 0;
  right: 0;
}

.box .ant-form-item {
  margin: 10px 0 !important;
}

.m-mt-4 {
  margin-top: 4px;
}
.ant-upload.ant-upload-select {
  width: 100% !important;
}
</style>
