/*
 * @Description:
 * @Author:
 * @Date: 2021-03-11 11:54:13
 * @LastEditTime: 2021-03-11 11:57:01
 * @LastEditors:
 */
let {
  getContractTemplate_service
} = require('../service/ContractTemplate.service')

module.exports = {
  getContractTemplate_controller(val) {
    return getContractTemplate_service(val)
  }
}
