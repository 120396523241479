/*
 * @Author: your name
 * @Date: 2021-03-01 15:27:51
 * @LastEditTime: 2021-03-01 15:55:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\UserLogin2NamePass.service.js
 */
const {
  xhr
} = require('../lib/xhr')

const {
  loginByName2Password2Code_api,
  loginByName2Password2CodeNoComNo_api
} = require('../api/UserLogin2NamePass.api')

module.exports = {
  // 获取图形验证码
  loginByName2Password2Code_service(val) {
    return xhr(loginByName2Password2Code_api, val)
  },
  loginByName2Password2CodeNoComNo_service(val) {
    return xhr(loginByName2Password2CodeNoComNo_api, val)
  }
}
