var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.data.length > 0)?_c('div',[_c('a-page-header',{attrs:{"title":"选择评估","sub-title":"维护评估信息"}},[_c('template',{slot:"extra"})],2),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"pagination":false},scopedSlots:_vm._u([{key:"titles",fn:function(text, record){return _c('span',{},[_vm._v(_vm._s(record.title + " " + "(" + record.subTitle + ")"))])}},{key:"name",fn:function(text){return _c('a',{},[_vm._v(_vm._s(text))])}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a-popconfirm',{attrs:{"title":"你确定要删除该条数据吗?"},on:{"confirm":function($event){return _vm.userDelete(record.id)}}},[_c('a-button',{attrs:{"type":"link"}},[_vm._v("删除")])],1)],1)}}],null,false,201598217)},[_c('span',{attrs:{"slot":"customTitle"},slot:"customTitle"},[_c('a-icon',{attrs:{"type":"smile-o"}}),_vm._v(" Name")],1)]),_c('a-row',{attrs:{"type":"flex","justify":"center"}},[_c('a-button',{key:"1",attrs:{"type":"primary"},on:{"click":() => _vm.$router.push({ path: '/admission/checkin' })}},[_vm._v(" 确定 ")])],1)],1):_c('div',[_c('a-page-header',{staticStyle:{"margin-bottom":"20px"},attrs:{"ghost":false,"title":"选择评估","sub-title":"维护评估信息"}},[_c('template',{slot:"extra"})],2),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":"选择问卷"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'tenantEvaluationConfigId',
            { rules: [{ required: true, message: '请选择问卷!' }] },
          ]),expression:"[\n            'tenantEvaluationConfigId',\n            { rules: [{ required: true, message: '请选择问卷!' }] },\n          ]"}],attrs:{"mode":"multiple"}},_vm._l((_vm.wqQuers),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.evaluationModel.title + " " + "(" + item.evaluationModel.subTitle + ")")+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"评估人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'evaluationUserId',
            { rules: [{ required: true, message: '请选择评估人!' }] },
          ]),expression:"[\n            'evaluationUserId',\n            { rules: [{ required: true, message: '请选择评估人!' }] },\n          ]"}]},_vm._l((_vm.userQuerys),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.realName)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"抄送人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'checkUserIdList',
            { rules: [{ required: true, message: '请选择抄送人!' }] },
          ]),expression:"[\n            'checkUserIdList',\n            { rules: [{ required: true, message: '请选择抄送人!' }] },\n          ]"}],attrs:{"mode":"multiple"}},_vm._l((_vm.userQuerys),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.realName)+" ")])}),1)],1)],1),_c('a-row',{attrs:{"type":"flex","justify":"center"}},[_c('a-button',{key:"1",attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleSubmit(1)}}},[_vm._v(" 保存 ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }