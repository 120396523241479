/*
 * @Description:
 * @Author:
 * @Date: 2021-03-26 13:22:50
 * @LastEditTime: 2021-03-29 15:17:28
 * @LastEditors:
 */
let {
  submitGradeBill_service,
  getBillList_service,
  checkInfoByRegisterId_service,
  getBillDetail_service
} = require('../service/CustomerArchivesMgr.service')

module.exports = {
  submitGradeBill_controller(val) {
    return submitGradeBill_service(val)
  },
  getBillList_controller(val) {
    return getBillList_service(val)
  },
  checkInfoByRegisterId_controller(val) {
    return checkInfoByRegisterId_service(val)
  },
  getBillDetail_controller(val) {
    return getBillDetail_service(val)
  }
}
