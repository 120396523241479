/*
 * @Author: your name
 * @Date: 2021-06-02 13:21:23
 * @LastEditTime: 2021-06-02 13:23:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\cost.controller.js
 */
let {
  query_service,
  approval_service,
  approvalMulti_service,
  modify_service,
  delete_service,
  export_service
} = require('../service/DrugBooking.service')

module.exports = {
  // 获取一览
  getDrugOrderLists_controller(val) {
    return query_service(val)
  },
  // 确认
  approval_controller(val) {
    return approval_service(val)
  },
  approvalMulti_controller(val) {
    return approvalMulti_service(val)
  },
  // 修改
  modify_controller(val) {
    return modify_service(val)
  },
  // 删除
  delete_controller(val) {
    return delete_service(val)
  },
  // 导出注册码
  export_controller(val) {
    return export_service(val)
  }
}
