/*
 * @Author: your name
 * @Date: 2021-03-09 17:20:03
 * @LastEditTime: 2021-03-31 14:52:46
 * @LastEditors:
 * @Description: 收费项目管理
 * @FilePath: \ioac-tenant-html\src\static\api\FinanceFeeItemMgr.api.js
 */
let {
  ic
} = require('../config/api_spec')
module.exports = {
  twoListByOneType_api: {
    url: `${ic}/mgr/finance/feeItem/twoListByOneType`,
    method: 'GET',
    post: {
      oneType: '?'
    }
  },
  // 获取收费分类的二级列表
  getFeeTypeItemList_api: {
    url: `${ic}/mgr/finance/feeItem/getFeeTypeItemList`,
    post: {
    }
  },
  addfeeItem_api: {
    url: `${ic}/mgr/finance/feeItem/add`,
    post: {
      name: '?',
      price: '?',
      remark: '?',
      feeType: '?', // 1单次/2按期
      oneType: '?',
      twoType: '?',
      feeUnit: '?'
    }
  },

  updatefeeItem_api: {
    url: `${ic}/mgr/finance/feeItem/update`,
    post: {
      id: '?',
      name: '?',
      price: '?',
      remark: '?',
      feeType: '?', // 1单次/2按期
      oneType: '?',
      twoType: '?',
      feeUnit: '?'
    }
  },
  queryfeeItem_api: {
    url: `${ic}/mgr/finance/feeItem/query`,
    post: {
      feeType: '?',
      name: '?',
      oneType: '?',
      page: '?',
      rows: '?',
      twoType: '?'
    }
  },
  detailfeeItem_api: {
    url: `${ic}/mgr/finance/feeItem/get`,
    get: {
      id: '?'
    }
  },
  removefeeItem_api: {
    url: `${ic}/mgr/finance/feeItem/delete`,
    get: {
      id: '?'
    }
  },
  updateStatefeeItem_api: {
    url: `${ic}/mgr/finance/feeItem/updateState`,
    post: {
      id: '?',
      state: '?'
    }
  },
  feeItemlist_api: {
    url: `${ic}/mgr/finance/feeItem/list`,
    post: {
      feeType: '?',
      name: '?',
      oneType: '?'
    }
  }

}
