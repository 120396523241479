import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/index.less'
import './styles/common.css'

import {
  init_router_beforeEach
} from './static/utils/init_title'

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'

init_router_beforeEach(router)
// import 'ant-design-vue/dist/antd.css'
import GeminiScrollbar from 'vue-gemini-scrollbar'
// import './pemission.js'
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

import {
  Button,
  Input,
  Form,
  Table,
  Icon,
  layout,
  Row,
  Col,
  Menu,
  Breadcrumb,
  Avatar,
  Badge,
  ConfigProvider,
  Result,
  message,
  Pagination,
  Select,
  Tag,
  FormModel,
  Modal,
  Card,
  DatePicker,
  Dropdown,
  Divider,
  Popconfirm,
  Spin,
  Popover,
  Alert,
  Tabs,
  Upload,
  PageHeader,
  Radio,
  Checkbox,
  Transfer,
  Tree,
  Cascader,
  Steps,
  Statistic,
  Empty,
  InputNumber,
  Switch,
  Tooltip,
  Descriptions,
  List,
  Timeline,
  Space,
  TimePicker,
  Affix,
  Drawer,
  Mentions,
  Rate,
  Slider,
  AutoComplete,
  Collapse
} from 'ant-design-vue'

Vue.config.productionTip = false
Vue.prototype.$message = message
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$success = Modal.success
import {
  Print
} from '@/plugin/print.js'
Vue.prototype.$print = Print

Vue.use(Button)
  .use(Table)
  .use(Input)
  .use(Form)
  .use(Icon)
  .use(Select)
  .use(layout)
  .use(Row)
  .use(Col)
  .use(Menu)
  .use(Breadcrumb)
  .use(Avatar)
  .use(Badge)
  .use(ConfigProvider)
  .use(Result)
  .use(Card)
  .use(Pagination)
  .use(Tag)
  .use(FormModel)
  .use(Modal)
  .use(DatePicker)
  .use(Dropdown)
  .use(Divider)
  .use(Popconfirm)
  .use(Spin)
  .use(Popover)
  .use(Alert)
  .use(Tabs)
  .use(Upload)
  .use(PageHeader)
  .use(Radio)
  .use(Checkbox)
  .use(Transfer)
  .use(Tree)
  .use(Cascader)
  .use(Steps)
  .use(Statistic)
  .use(Empty)
  .use(Tooltip)
  .use(Descriptions)
  .use(InputNumber)
  .use(Switch)
  .use(List)
  .use(Descriptions)
  .use(GeminiScrollbar)
  .use(Timeline)
  .use(Space)
  .use(TimePicker)
  .use(Affix)
  .use(Drawer)
  .use(Mentions)
  .use(Rate)
  .use(Slider)
  .use(AutoComplete)
  .use(Collapse)
  .use(preview)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
