/*
 * @Description:
 * @Author:
 * @Date: 2021-03-10 19:08:39
 * @LastEditTime: 2021-03-31 18:05:31
 * @LastEditors:
 */

module.exports = {
  deleteEvaluationApp_api: {
    url: 'ioac-center/mgr/evaluation/customerEvaluationInfo/remove',
    post: {
      id: '?'
    }
  }
}

