/*
 * @Author: your name
 * @Date: 2021-06-02 13:19:11
 * @LastEditTime: 2021-06-02 13:19:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\AccountInfoMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')

const {
  query_api,
  approval_api,
  approvalMulti_api,
  modify_api,
  delete_api,
  export_api
} = require('../api/DrugBooking.api')

module.exports = {
  query_service(val) {
    console.log('service', val)
    return xhr(query_api, val)
  },
  approval_service(val) {
    return xhr(approval_api, val)
  },
  approvalMulti_service(val) {
    return xhr(approvalMulti_api, val)
  },
  modify_service(val) {
    return xhr(modify_api, val)
  },
  delete_service(val) {
    return xhr(delete_api, val)
  },
  export_service(val) {
    return xhr(export_api, val)
  }
}
