/*
 * @Description:
 * @Author:
 * @Date: 2021-03-26 11:58:41
 * @LastEditTime: 2021-04-09 10:11:36
 * @LastEditors: Please set LastEditors
 */

const {
  xhr
} = require('../lib/xhr')
const {
  submitGradeBill_api,
  getBillList_api,
  checkInfoByRegisterId_api,
  getBillDetail_api,
  getArchivesList_api
} = require('../api/CustomerArchivesMgr.api')

module.exports = {
  submitGradeBill_service(val) {
    return xhr(submitGradeBill_api, val)
  },
  getBillList_service(val) {
    return xhr(getBillList_api, val)
  },
  checkInfoByRegisterId_service(val) {
    return xhr(checkInfoByRegisterId_api, val)
  },
  getBillDetail_service(val) {
    return xhr(getBillDetail_api, val)
  },
  getArchivesList_service(val) {
    return xhr(getArchivesList_api, val)
  }
}
