/*
 * @Author: your name
 * @Date: 2021-05-26 10:32:41
 * @LastEditTime: 2021-06-02 20:12:22
 * @LastEditors: Please set LastEditors
 * @Description: SeniorsMgr_长者登记Customer Register Mgr Controller
 * @FilePath: \ioac-tenant-html\src\static\api\CustomerRegisterMgr.api.js
 */
let {
  sc
} = require('../config/api_spec')
module.exports = {
  register_api: {
    url: `${sc}/mgr/seniors/register/add`,
    post: {
      state: '?',
      homeCustomerFamilyModels: '?',
      homeCustomerModel: '?',
      remark: '?'
    }
  },
  // mgr/seniors/register/queryPage
  queryPageRegister_api: {
    url: `${sc}/mgr/seniors/register/queryPage`,
    post: {
      mobile: '?',
      name: '?',
      page: '?',
      rows: '?',
      state: '?'
    }
  },
  // /api/seniors-center/mgr/seniors/register/getById
  registerGetById_api: {
    url: `${sc}/mgr/seniors/register/getById`,
    get: {
      id: '?'
    }
  },
  // mgr/seniors/register/update
  registerUpdate_api: {
    url: `${sc}/mgr/seniors/register/update`,
    post: {
      id: '?',
      state: '?',
      homeCustomerFamilyModels: '?',
      homeCustomerModel: '?',
      remark: '?'
    }
  },
  // /api/seniors-center/mgr/seniors/register/removeById
  registerRemoveById_api: {
    url: `${sc}/mgr/seniors/register/removeById`,
    get: {
      id: '?'
    }
  },
  queryCheckInCustList_api: {
    url: `${sc}/mgr/seniors/register/list`,
    post: {
      nameIdNo: '?',
      comeWay: '3'
    }
  }
}
