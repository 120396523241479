/*
 * @Description:
 * @Author:
 * @Date: 2021-05-20 13:16:56
 * @LastEditTime: 2021-05-28 14:46:18
 * @LastEditors:
 */
let {
  hc
} = require('../config/api_spec')
module.exports = {
  robotQuery_api: { // 查询
    url: `${hc}/mgr/hardware/robot/query/elder`,
    post: {
      idCard: '?',
      name: '?',
      page: '?',
      rows: '?'
    }
  },
  elderDetail_api: { // 个人详情
    url: `${hc}/mgr/hardware/robot/elder/detail`,
    post: {
      customerNo: '?'
    }
  },
  elderCheck_api: { // 个人日志
    url: `${hc}/mgr/hardware/robot/elder/check`,
    post: {
      customerNo: '?',
      page: '?',
      rows: '?',
      type: '?'// 是否正常
    }
  },
  monitoringQuery_api: { // 检查日志
    url: `${hc}/mgr/hardware/robot/query/monitoring`,
    post: {
      dataType: '?',
      idCard: '?',
      name: '?',
      type: '?',
      page: '?',
      rows: '?'
    }
  },
  eldermonitorQuery_api: { // 检查异常日志
    url: `${hc}/mgr/hardware/robot/elder/monitor`,
    post: {
      customerNo: '?',
      dataType: '?',
      type: '?',
      page: '?',
      rows: '?'
    }
  },
  elderstatistics_api: { // 个人检测统计
    url: `${hc}/mgr/hardware/robot/elder/statistics`,
    post: {
      customerNo: '?',
      dataType: '?',
      type: '?'
    }
  },
  checkcount_api: { // 首页检测统计
    url: `${hc}/mgr/hardware/robot/check/count`,
    get: {
    }
  },
  categorycount_api: { // 首页分类统计
    url: `${hc}/mgr/hardware/robot/category/count`,
    get: {
    }
  },
  robotattention_api: { // 重点关注对象
    url: `${hc}/mgr/hardware/robot/attention`,
    post: {
      customerNo: '?',
      dataType: '?',
      page: '?',
      rows: '?',
      type: '?'
    }
  },
  homestatistics_api: { // 健康首页检测趋势图
    url: `${hc}/mgr/hardware/robot/home/statistics`,
    get: {
    }
  },
  robotlocate_api: { // 定位获取token
    url: `${hc}/mgr/hardware/robot/locate`,
    get: {
    }
  }

}
