/*
 * @Description:
 * @Author:
 * @Date: 2021-03-09 17:34:42
 * @LastEditTime: 2021-03-13 10:59:50
 * @LastEditors:
 */

let {
  getEvaluationMainApp_service,
  evaluationQuery_service,
  detailEvaluation_service,
  deleteEvaluation_service,
  updateEvaluation_service
} = require('../service/EvaluationMainApp.service')

module.exports = {
  getEvaluationMainApp_controller(val) {
    return getEvaluationMainApp_service(val)
  },
  evaluationQuery_controller(val) {
    return evaluationQuery_service(val)
  },
  detailEvaluation_controller(val) {
    return detailEvaluation_service(val)
  },
  deleteEvaluation_controller(val) {
    return deleteEvaluation_service(val)
  },
  updateEvaluation_controller(val) {
    return updateEvaluation_service(val)
  }
}
