/*
 * @Author: your name
 * @Date: 2021-03-04 13:38:11
 * @LastEditTime: 2021-03-04 14:57:16
 * @LastEditors: Please set LastEditors
 * @Description: 文件上传服务
 * @FilePath: \ioac-tenant-html\src\static\api\File.api.js
 */
let {
  nc
} = require('../config/api_spec')

module.exports = {
  addUser_api: {
    url: `${nc}/mgr/hl/service-staff-info/save`,
    post: {
      name: '?',
      sex: '?',
      staffNo: '?',
      idNo: '?',
      img: '?',
      myTel: '?',
      addr: '?',
      hireDate: '?'
    }
  },

  userList_api: {
    url: `${nc}/mgr/hl/service-staff-info/query`,
    post: {
      myTel: '?',
      name: '?',
      page: '?',
      rows: '?'
    }
  },

  userList_api2: {
    url: `${nc}/mgr/hl/service-staff-info/listWithoutGroup`,
    post: {
      myTel: '?',
      name: '?',
      page: '?',
      rows: '?'
    }
  },

  userList_api3: {
    url: `${nc}/mgr/hl/service-group/getAndWithNoJoinedStaff`,
    method: 'GET',
    post: {
      id: '?'
    }
  },

  userDetail_api: {
    url: `${nc}/mgr/hl/service-staff-info/get`,
    method: 'GET',
    post: {
      id: '?'
    }
  },

  updUser_api: {
    url: `${nc}/mgr/hl/service-staff-info/update`,
    post: {
      id: '?',
      name: '?',
      sex: '?',
      img: '?',
      staffNo: '?',
      idNo: '?',
      myTel: '?',
      addr: '?',
      hireDate: '?',
      state: '?'
    }
  },

  teamList_api: {
    url: `${nc}/mgr/hl/service-group/query`,
    post: {
      name: '?',
      leaderNo: '?',
      page: '?',
      rows: '?'
    }
  },

  addTeam_api: {
    url: `${nc}/mgr/hl/service-group/save`,
    post: {
      name: '?',
      leaderId: '?',
      staffIds: '?'
    }
  },

  teamDetail_api: {
    url: `${nc}/mgr/hl/service-group/get`,
    method: 'GET',
    post: {
      id: '?'
    }
  },

  updTeam_api: {
    url: `${nc}/mgr/hl/service-group/update`,
    post: {
      id: '?',
      name: '?',
      leaderId: '?',
      staffIds: '?'
    }
  },

  scheduleList_api: {
    url: `${nc}/mgr/hl/scheduling/list`,
    post: {
      page: '?',
      rows: '?'
    }
  },

  addSchedule_api: {
    url: `${nc}/mgr/hl/scheduling/save`,
    post: {
      name: '?',
      startTime: '?',
      endTime: '?',
      type: '?'
    }
  },

  updSchedule_api: {
    url: `${nc}/mgr/hl/scheduling/update`,
    post: {
      id: '?',
      name: '?',
      startTime: '?',
      endTime: '?',
      type: '?'
    }
  },

  scheduleDetail_api: {
    url: `${nc}/mgr/hl/scheduling/get`,
    method: 'GET',
    post: {
      id: '?'
    }
  },

  addNursing_api: {
    url: `${nc}/mgr/hl/nursing-item/save`,
    post: {
      name: '?',
      type: '?',
      duration: '?',
      remark: '?'
    }
  },

  nursingList_api: {
    url: `${nc}/mgr/hl/nursing-item/query`,
    post: {
      name: '?',
      type: '?',
      page: '?',
      rows: '?'
    }
  },

  nursingDetail_api: {
    url: `${nc}/mgr/hl/nursing-item/get`,
    method: 'GET',
    post: {
      id: '?'
    }
  },

  updNursing_api: {
    url: `${nc}/mgr/hl/nursing-item/update`,
    post: {
      id: '?',
      name: '?',
      type: '?',
      duration: '?',
      remark: '?'
    }
  },

  workList_api: {
    url: `${nc}/mgr/hl/staff-scheduling/query`,
    post: {
      startDate: '?',
      endDate: '?',
      page: '?',
      rows: '?'
    }
  },

  frequencyList_api: {
    url: `${nc}/mgr/hl/nursing-item-frequency/query`,
    post: {
      name: '?',
      page: '?',
      rows: '?'
    }
  },

  addFrequency_api: {
    url: `${nc}/mgr/hl/nursing-item-frequency/save`,
    post: {
      name: '?',
      day: '?',
      num: '?',
      remark: '?'
    }
  },

  updFrequency_api: {
    url: `${nc}/mgr/hl/nursing-item-frequency/update`,
    post: {
      id: '?',
      name: '?',
      day: '?',
      num: '?',
      remark: '?'
    }
  },

  frequencyDetail_api: {
    url: `${nc}/mgr/hl/nursing-item-frequency/get`,
    method: 'GET',
    post: {
      id: '?'
    }
  },

  batchAddWork_api: {
    url: `${nc}/mgr/hl/staff-scheduling/batch/save2update`,
    post: {
      schedulingParams: '?'
    }
  },

  addTemplate_api: {
    url: `${nc}/mgr/hl/plan-template/save`,
    post: {
      detailSaveParams: '?',
      name: '?'
    }
  },

  updTemplate_api: {
    url: `${nc}/mgr/hl/plan-template/update`,
    post: {
      id: '?',
      detailSaveParams: '?',
      name: '?'
    }
  },

  templeatDetail_api: {
    url: `${nc}/mgr/hl/plan-template/get`,
    method: 'GET',
    post: {
      id: '?'
    }
  },

  templeatList_api: {
    url: `${nc}/mgr/hl/plan-template/query`,
    post: {
      name: '?'
    }
  },

  delTemplate_api: {
    url: `${nc}/mgr/hl/plan-template/delete`,
    method: 'GET',
    post: {
      id: '?'
    }
  },

  planList_api: {
    url: `${nc}/mgr/hl/nursing-plan/query`,
    post: {
      customerName: '?',
      startDate: '?',
      endDate: '?',
      page: '?',
      rows: '?',
      isWithoutTask: '?'
    }
  },

  addPlan_api: {
    url: `${nc}/mgr/hl/nursing-plan/save`,
    post: {
      customerName: '?',
      bedCode: '?',
      customerRegisterNo: '?',
      customerSex: '?',
      startDate: '?',
      endDate: '?',
      detailSaveParams: '?',
      customerNursingLevel: '?'
    }
  },

  delPlan_api: {
    url: `${nc}/mgr/hl/nursing-plan/delete`,
    method: 'GET',
    post: {
      id: '?'
    }
  },

  taskList_api: {
    url: `${nc}/mgr/hl/nursing-plan-item-task/query`,
    post: {
      name: '?',
      endDate: '?',
      startDate: '?',
      page: '?',
      rows: '?'
    }
  },

  taskList_api2: {
    url: `${nc}/mgr/hl/nursing-plan-item-task/list`,
    post: {
      customerNursingLevel: '?',
      state: '?'
    }
  },

  recordList_api: {
    url: `${nc}/mgr/hl/nursing-plan-item-task/nursing/record`,
    post: {
      staffName: '?',
      finishDate: '?',
      staffNo: '?',
      page: '?',
      rows: '?'
    }
  },

  planTaskList_api: {
    url: `${nc}/mgr/hl/nursing-plan-task/query`,
    post: {
      page: '?',
      rows: '?',
      endDate: '?',
      startDate: '?'
    }
  },

  addTask_api: {
    url: `${nc}/mgr/hl/nursing-plan-task/batch/save`,
    post: {
      saveParams: '?'
    }
  },

  taskDetailList_api: {
    url: `${nc}/mgr/hl/nursing-plan/get`,
    method: 'GET',
    post: {
      id: '?'
    }
  },

  updPlan_api: {
    url: `${nc}/mgr/hl/nursing-plan/update`,
    post: {
      id: '?',
      customerName: '?',
      bedCode: '?',
      customerRegisterNo: '?',
      customerSex: '?',
      startDate: '?',
      endDate: '?',
      detailSaveParams: '?',
      customerNursingLevel: '?'
    }
  },

  bedCard_api: {
    url: `${nc}/mgr/hl/nursing-plan-task/queryBedInfo`,
    post: {
      customerNursingLevel: '?',
      isEmptyBed: '?',
      rows: 99
    }
  },

  delUser_api: {
    url: `${nc}/mgr/hl/service-staff-info/delete`,
    method: 'GET',
    post: {
      id: '?'
    }
  },

  delTeam_api: {
    url: `${nc}/mgr/hl/service-group/delete`,
    method: 'GET',
    post: {
      id: '?'
    }
  },

  delSchedule_api: {
    url: `${nc}/mgr/hl/scheduling/delete`,
    method: 'GET',
    post: {
      id: '?'
    }
  },

  updBreakup_api: {
    url: `${nc}/mgr/hl/nursing-plan/break-off`,
    method: 'GET',
    post: {
      id: '?'
    }
  },

  delNursing_api: {
    url: `${nc}/mgr/hl/nursing-item/delete`,
    method: 'GET',
    post: {
      id: '?'
    }
  }
}
