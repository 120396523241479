var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"新增套餐","visible":_vm.visible,"footer":null,"mask-closable":false},on:{"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 17 }},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'templateName',
            {
              rules: [
                {
                  required: true,
                  message: '名称不能为空',
                },
                { validator: _vm.checkTemplateNameDuplicate }
              ],
              validateTrigger: ['blur'],
            },
          ]),expression:"[\n            'templateName',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '名称不能为空',\n                },\n                { validator: checkTemplateNameDuplicate }\n              ],\n              validateTrigger: ['blur'],\n            },\n          ]"}],attrs:{"placeholder":"请输入套餐名称"}})],1),_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'templateType',
            {
              rules: [
                {
                  required: true,
                  message: '类型不能为空',
                },
              ],
              validateTrigger: ['blur'],
            },
          ]),expression:"[\n            'templateType',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '类型不能为空',\n                },\n              ],\n              validateTrigger: ['blur'],\n            },\n          ]"}],attrs:{"placeholder":"请选择类型"},on:{"change":_vm.handleTypeChange}},[_c('a-select-option',{attrs:{"value":0}},[_vm._v(" 通用套餐 ")]),_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 机构专属套餐 ")])],1)],1),(_vm.organSelect)?_c('a-form-item',{attrs:{"label":"专属机构"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'templateOrganId',
            {
              rules: [
                {
                  required: true,
                  message: '专属机构不能为空',
                },
              ],
              validateTrigger: ['blur'],
            }]),expression:"[\n            'templateOrganId',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '专属机构不能为空',\n                },\n              ],\n              validateTrigger: ['blur'],\n            }]"}],attrs:{"placeholder":"请选择机构"}},_vm._l((_vm.organOptions),function(item){return _c('a-select-option',{key:item.companyNo,attrs:{"value":item.companyNo}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"规则","required":true}},[_c('MetaDictInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'rules',
            {
              initialValue: {},
              rules: [{ validator: _vm.checkRulesInput }],
              validateTrigger: ['submit'],
            },
          ]),expression:"[\n            'rules',\n            {\n              initialValue: {},\n              rules: [{ validator: checkRulesInput }],\n              validateTrigger: ['submit'],\n            },\n          ]"}],ref:"metaDictInput"})],1),_c('a-form-item',{attrs:{"label":"时长"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'templateExpiry',
            {
              rules: [
                {
                  required: true,
                  message: '时长不能为空',
                },
              ],
              validateTrigger: ['blur'],
            },
          ]),expression:"[\n            'templateExpiry',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '时长不能为空',\n                },\n              ],\n              validateTrigger: ['blur'],\n            },\n          ]"}],attrs:{"placeholder":"请选择时长"}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v(" 6个月 ")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" 1年 ")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v(" 2年 ")])],1)],1),_c('a-form-item',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","size":_vm.size}},[_vm._v(" 确认 ")]),_c('a-button',{staticStyle:{"background":"#999","border-color":"#999"},attrs:{"type":"primary","size":_vm.size},on:{"click":_vm.handleCancel}},[_vm._v(" 取消 ")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }