<!--
 * @Description:
 * @Author:
 * @Date: 2021-03-09 14:26:27
 * @LastEditTime: 2021-07-01 15:05:46
 * @LastEditors: Please set LastEditors
-->
<template>
  <div>
    <a-page-header
      :ghost="false"
      title="基础信息设置"
      sub-title="维护基础信息"
      style="margin-bottom: 20px; border-bottom: 1px solid rgb(235, 237, 240)"
    >
      <!-- <template slot="extra">
        <a-button key="1" type="primary" @click="submitNext"> 下一步 </a-button>
      </template> -->
    </a-page-header>
    <div style="padding: 10px">
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :span="12">
            <a-form-item label="照片">
              <UploadImg :url.sync="imageUrl" :width="128" :height="100" />
            </a-form-item>
            <a-form-item label="姓名">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [{ required: true, message: '请输入姓名!' }],
                    initialValue: initValue ? initValue.customerModel.name : '',
                  },
                ]"
                placeholder="请输入姓名"
              />
            </a-form-item>
            <a-form-item label="联系电话">
              <a-input
                v-decorator="[
                  'myTel',
                  {
                    rules: [
                      {
                        validator: validatorPhone,
                        message: '请输入正确的手机号码！',
                      },
                    ],
                    initialValue: initValue
                      ? initValue.customerModel.myTel
                      : '',
                  },
                ]"
                placeholder="请输入联系电话"
              />
            </a-form-item>
            <!-- <a-form-item label="联系电话">
              <a-input
                v-decorator="[
                  'myTel',
                  {
                    rules: [
                      {
                        validator: validatorPhone,
                        message: '请输入正确的手机号码！',
                      },
                    ],
                    initialValue: initValue
                      ? initValue.customerModel.myTel
                      : '',
                  },
                ]"
                placeholder="请输入联系电话"
              />
            </a-form-item> -->
            <a-form-item label="性别">
              <a-select
                v-decorator="[
                  'sex',
                  {
                    rules: [{ required: true, message: '请选择性别!' }],
                    initialValue: initValue ? initValue.customerModel.sex : '',
                  },
                ]"
                :get-popup-container="
                  (triggerNode) => {
                    return triggerNode.parentNode || document.body;
                  }
                "
                placeholder="请选择性别"
              >
                <a-select-option :value="1"> 男 </a-select-option>
                <a-select-option :value="2"> 女 </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="身份证">
              <a-input
                v-decorator="[
                  'idNo',
                  {
                    rules: [
                      { required: true, message: '请输入身份证!' },
                      {
                        required: true,
                        validator: validatorIdCard,
                        trigger: 'blur',
                        message: '请输入正确的身份证号码！',
                      },
                    ],
                    initialValue: initValue ? initValue.customerModel.idNo : '',
                  },
                ]"
                placeholder="请输入身份证"
                @blur="getBirth"
              />
            </a-form-item>
            <a-form-item label="出生年月">
              <a-date-picker
                v-decorator="[
                  'birthday',
                  {
                    initialValue: initValue
                      ? initValue.customerModel.birthday
                      : '',
                  },
                ]"
                disabled
                placeholder="根据身份证自动获取"
              />
            </a-form-item>

            <a-form-item label="民族">
              <a-select
                v-decorator="[
                  'nation',
                  {
                    initialValue: initValue
                      ? initValue.customerModel.nation
                      : '',
                  },
                ]"
                placeholder="请选择民族"
              >
                <a-select-option
                  v-for="item in selectData.nation"
                  :key="item.nonceStr"
                  :value="item.vval"
                >
                  {{ item.vname }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="婚姻状况">
              <a-select
                v-decorator="[
                  'maritalState',
                  {
                    initialValue: initValue
                      ? initValue.customerModel.maritalState
                      : '',
                  },
                ]"
                placeholder="请选择婚姻状况"
              >
                <a-select-option
                  v-for="item in selectData.marital_state"
                  :key="item.nonceStr"
                  :value="item.vval"
                >
                  {{ item.vname }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="文化程度">
              <a-select
                v-decorator="[
                  'educationDegree',
                  {
                    initialValue: initValue
                      ? initValue.customerModel.educationDegree
                      : '',
                  },
                ]"
                placeholder="请选择文化程度"
              >
                <a-select-option
                  v-for="item in selectData.education_degree"
                  :key="item.nonceStr"
                  :value="item.vval"
                >
                  {{ item.vname }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="原职业">
              <a-select
                v-decorator="[
                  'oldJob',
                  {
                    initialValue: initValue
                      ? initValue.customerModel.oldJob
                      : '',
                  },
                ]"
                placeholder="请选择原职业"
              >
                <a-select-option
                  v-for="item in selectData.job"
                  :key="item.nonceStr"
                  :value="item.vval"
                >
                  {{ item.vname }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <!-- <a-form-item v-if="initOrganCode" label="户籍地区">
              <RegionSelect
                v-if="initOrganCode.length > 0"
                v-decorator="[
                  'area',
                  {
                    rules: [
                      { required: false, message: '请选择户籍所属地区！' },
                    ],
                  },
                ]"
                :area.sync="area"
                :init-value="[initOrganCode]"
              />
              <RegionSelect
                v-else
                v-decorator="[
                  'area',
                  {
                    rules: [
                      { required: false, message: '请选择户籍所属地区！' },
                    ],
                  },
                ]"
                :area.sync="area"
                :init-value="[]"
              />
            </a-form-item> -->

            <!-- <a-form-item label="详细地址">
              <a-input
                v-decorator="[
                  'adminAddr',
                  {
                    rules: [{ required: false, message: '户籍详细地址!' }],
                    initialValue: initValue
                      ? initValue.customerModel.adminAddr
                      : '',
                  },
                ]"
                placeholder="请输入户籍详细地址"
              />
            </a-form-item> -->

            <a-form-item label="居住地址">
              <a-input
                v-decorator="[
                  'homeAddr',
                  {
                    rules: [{ required: true, message: '居住地址不能为空！' }],
                    initialValue: initValue
                      ? initValue.customerModel.homeAddr
                      : '',
                  },
                ]"
                placeholder="家庭地址"
              />
            </a-form-item>
            <a-form-item label="目前服用药物">
              <a-textarea
                v-decorator="[
                  'remark',
                  {
                    initialValue: initValue ? initValue.remark : '',
                  },
                ]"
                placeholder="请备注目前服用药物，如果没有请备注无"
              />
            </a-form-item>
            <a-form-item label="申请入院理由">
              <a-textarea
                v-decorator="[
                  'comeReason',
                  {
                    initialValue: initValue ? initValue.comeReason : '',
                  },
                ]"
                placeholder="请备注申请入院理由"
              />
            </a-form-item>
          </a-col>
          <a-col
            :span="12"
          ><a-card title="家庭成员信息">
            <a-button
              slot="extra"
              type="primary"
              icon="usergroup-add"
              @click="addFamily"
            >
              添加家庭成员
            </a-button>
            <a-card-grid v-if="familyInfo.length === 0" style="width: 100%">
              <a-empty
                :image-style="{
                  height: '60px',
                }"
              >
                <span slot="description"> 请至少添加一条家庭成员信息 </span>
                <a-button type="primary" @click="addFamily">
                  立即添加
                </a-button>
              </a-empty>
            </a-card-grid>
            <a-card-grid
              v-for="(item, index) in familyInfo"
              v-else
              :key="index"
              style="width: 100%; line-height: 25px"
            >
              <a-row type="flex" justify="space-between">
                <a-col :span="11">
                  <a-row type="flex">
                    <a-col
                      style="text-align: right"
                      type="flex"
                      :span="9"
                    >姓名：</a-col>
                    <a-col>{{ item.name }}</a-col>
                  </a-row>
                </a-col>
                <a-col>
                  <a-popconfirm
                    v-if="item.type != 1"
                    title="是否设置为担保人?"
                    @confirm="setGuarantee(item)"
                  >
                    <a style="color: #fe8411"> 设为担保人 </a>
                  </a-popconfirm>
                  <a-divider type="vertical" />
                  <a-popconfirm
                    v-if="item.type != 1"
                    title="是否删除当前家属?"
                    @confirm="delGuarantee(item.idNo)"
                  >
                    <a style="color: #fe8411"> 删除 </a>
                  </a-popconfirm>
                  <a-button
                    type="link"
                    @click="editFamily(item, index)"
                  >编辑</a-button>
                </a-col>
              </a-row>
              <a-row v-if="item.type == 1">
                <a-col :span="4" style="text-align: right">账号类型：</a-col>
                <a-col>
                  <a-tag color="red">
                    <a-badge status="processing" />
                    该账号为担保人，可用于家属端登录主账号
                  </a-tag>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="4" style="text-align: right">称谓：</a-col>
                <a-col><a-tag color="blue"> {{ item.relType }} </a-tag></a-col>
              </a-row>
              <a-row>
                <a-col :span="4" style="text-align: right">性别：</a-col>
                <a-col>{{ item.sex === "1" ? "男" : "女" }}</a-col>
              </a-row>
              <!-- <a-row>
                <a-col :span="4" style="text-align: right">身份证：</a-col>
                <a-col><a-badge status="processing" />{{ item.idNo }}</a-col>
              </a-row> -->
              <a-row>
                <a-col :span="4" style="text-align: right">联系电话：</a-col>
                <a-col style="color: #fe8411">{{ item.mobile }}</a-col>
              </a-row>
              <!-- <a-row>
                <a-col :span="4" style="text-align: right">户籍地区：</a-col>
                <a-col><a-breadcrumb>
                  <a-breadcrumb-item
                    v-for="info in item.registerAreaNames"
                    :key="info"
                  >{{ info }}</a-breadcrumb-item>
                </a-breadcrumb></a-col>
              </a-row> -->
              <!-- <a-row>
                <a-col :span="4" style="text-align: right">详细地址：</a-col>
                <a-col>{{ item.registAddr }}</a-col>
              </a-row> -->
              <a-row>
                <a-col :span="4" style="text-align: right">居住地址：</a-col>
                <a-col>{{ item.homeAddr }}</a-col>
              </a-row>
            </a-card-grid>
          </a-card></a-col>
        </a-row>
        <!-- <a-form-item :wrapper-col="{ span: 12, offset: 3 }">
        <a-button type="primary" html-type="submit"> 下一步 </a-button>
      </a-form-item> -->
      </a-form>

      <a-form
        :form="form3"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      >
        <a-row v-if="endController" type="flex">
          <a-col :span="24">
            <a-page-header
              title="扩展信息"
              sub-title="扩展信息"
            /></a-col>
          <a-col v-for="item in endController" :key="item.id" :span="12">
            <a-form-item :label="item.data.label">
              <a-input
                v-if="item.type === 'Input' && !item.data.textarea"
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        message: item.data.placeholder,
                      },
                      {
                        validator: validator,
                        validatorType: item.data.ruleType,
                        validatorAttr: item.data.ruleType,
                        trigger: 'blur',
                      },
                    ],
                  },
                ]"
                :placeholder="item.data.placeholder"
                :max-length="item.data.maxLength"
              />

              <a-textarea
                v-if="item.type === 'Input' && item.data.textarea"
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        message: item.data.placeholder,
                      },
                      {
                        validator: validator,
                        validatorType: item.data.ruleType,
                        validatorAttr: item.data.ruleType,
                        trigger: 'blur',
                      },
                    ],
                  },
                ]"
                :placeholder="item.data.placeholder"
                :auto-size="{ minRows: 3, maxRows: 5 }"
                :max-length="item.data.maxLength"
              />

              <a-input-number
                v-if="item.type === 'InputNumber'"
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        message: item.data.placeholder,
                      },
                    ],
                  },
                ]"
                :min="item.data.min"
                :max="item.data.max"
                :precision="item.data.precision"
                :placeholder="item.data.placeholder"
              />
              <a-select
                v-if="item.type === 'Tag'"
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        message: item.data.placeholder,
                      },
                    ],
                  },
                ]"
                mode="multiple"
                :placeholder="item.data.placeholder"
              >
                <a-select-option
                  v-for="item in item.data.list"
                  :key="item.value"
                >
                  {{ item.value }}
                </a-select-option>
              </a-select>
              <a-select
                v-if="item.type === 'Select'"
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        message: item.data.placeholder,
                      },
                    ],
                  },
                ]"
                :placeholder="item.data.placeholder"
              >
                <a-select-option
                  v-for="item in item.data.list"
                  :key="item.value"
                >
                  {{ item.value }}
                </a-select-option>
              </a-select>
              <a-checkbox-group
                v-if="item.type === 'Checkbox'"
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        message: item.data.placeholder,
                      },
                    ],
                  },
                ]"
              >
                <a-row style="width: 100%">
                  <a-col
                    v-for="item in item.data.list"
                    :key="item.id"
                    :span="8"
                  >
                    <a-checkbox :value="item.value">
                      {{ item.value }}</a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
              <a-radio-group
                v-if="item.type === 'Radio'"
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        message: item.data.placeholder,
                      },
                    ],
                  },
                ]"
              >
                <a-radio
                  v-for="item in item.data.list"
                  :key="item.id"
                  :value="item.value"
                >
                  {{ item.value }}
                </a-radio>
              </a-radio-group>
              <a-switch
                v-if="item.type === 'Switch'"
                v-decorator="[`${item.id}`]"
              />

              <a-time-picker
                v-if="item.type === 'TimePicker'"
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        message: item.data.placeholder,
                      },
                    ],
                  },
                ]"
                :placeholder="item.data.placeholder"
              />

              <a-date-picker
                v-if="
                  item.type === 'DatePicker' &&
                    item.data.dataType === 'DatePicker'
                "
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        message: item.data.placeholder,
                      },
                    ],
                  },
                ]"
                :placeholder="item.data.placeholder"
              />

              <a-month-picker
                v-if="
                  item.type === 'DatePicker' &&
                    item.data.dataType === 'MonthPicker'
                "
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        message: item.data.placeholder,
                      },
                    ],
                  },
                ]"
                :placeholder="item.data.placeholder"
              />

              <a-range-picker
                v-if="item.type === 'RangePicker'"
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        message: item.data.placeholder,
                      },
                    ],
                  },
                ]"
                :placeholder="item.data.placeholder"
              />

              <a-mentions
                v-if="item.type === 'Mentions'"
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        message: item.data.placeholder,
                      },
                    ],
                  },
                ]"
                :prefix="item.data.mark"
                :placeholder="item.data.placeholder"
              >
                <a-mentions-option
                  v-for="item in item.data.list"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.value }}
                </a-mentions-option>
              </a-mentions>
              <a-rate
                v-if="item.type === 'Rate'"
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        message: item.data.placeholder,
                      },
                    ],
                  },
                ]"
              />
              <a-slider
                v-if="item.type === 'Slider'"
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        message: item.data.placeholder,
                      },
                    ],
                  },
                ]"
              />
              <UploadImg
                v-if="item.type === 'Upload'"
                v-decorator="[
                  `${item.id}`,
                  {
                    rules: [
                      {
                        required: item.data.required,
                        validator: validatorImg,
                        img: item.data.imgUrl,
                        trigger: 'blur',
                      },
                    ],
                  },
                ]"
                name="files"
                :url.sync="item.data.imgUrl"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-row type="flex" justify="center">
        <a-button key="1" type="primary" @click="submitNext"> 保存 </a-button>
      </a-row>
    </div>

    <a-modal
      title="家庭成员维护"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form
        :form="form2"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 18 }"
        @submit="handleSubmitInfo"
      >
        <a-form-item label="称谓">
          <a-input
            v-decorator="[
              'relType',
              {
                rules: [{ required: true, message: '请输入称谓!' }],
                initialValue: familyValue.relType,
              },
            ]"
            :max-length="maxFou"
            placeholder="请输入称谓"
          />
        </a-form-item>
        <a-form-item label="姓名">
          <a-input
            v-decorator="[
              'reName',
              {
                rules: [{ required: true, message: '请输入姓名!' }],
                initialValue: familyValue.reName,
              },
            ]"
            placeholder="请输入姓名"
          />
        </a-form-item>
        <a-form-item label="性别">
          <a-select
            v-decorator="[
              'reSex',
              {
                initialValue: familyValue.reSex,
              },
            ]"
            placeholder="请选择性别"
          >
            <a-select-option value="1"> 男 </a-select-option>
            <a-select-option value="2"> 女 </a-select-option>
          </a-select>
        </a-form-item>
        <!-- <a-form-item label="身份证">
          <a-input
            v-decorator="[
              'reIdNo',
              {
                rules: [
                  { required: true, message: '请输入身份证号码!' },
                  ,
                  {
                    required: true,
                    validator: validatorIdCard,
                    trigger: 'blur',
                    message: '请输入正确的身份证号码！',
                  },
                ],
                initialValue: familyValue.reIdNo,
              },
            ]"
            placeholder="请输入身份证号码"
          />
        </a-form-item> -->
        <a-form-item label="联系电话">
          <a-input
            v-decorator="[
              'reMobile',
              {
                rules: [
                  { required: true, message: '请输入联系电话!' },
                  {
                    required: true,
                    validator: validatorPhone,
                    trigger: 'blur',
                    message: '请输入正确的手机号码！',
                  },
                ],
                initialValue: familyValue.reMobile,
              },
            ]"
            placeholder="请输入联系电话"
          />
        </a-form-item>
        <!-- <a-form-item label="户籍地区">
          <RegionSelect
            v-if="initAreaCode.length > 0"
            v-decorator="[
              'reArea',
              {
                rules: [{ required: true, message: '请选择户籍所属地区！' }],
              },
            ]"
            :area.sync="reArea"
            :init-value="[initAreaCode]"
          />
          <RegionSelect
            v-else
            v-decorator="[
              'reArea',
              {
                rules: [{ required: false, message: '请选择户籍所属地区！' }],
              },
            ]"
            :area.sync="reArea"
            :init-value="[]"
          />
        </a-form-item> -->
        <!-- <a-form-item label="详细地址">
          <a-input
            v-decorator="[
              'reAddr',
              {
                rules: [{ required: false, message: '请输入户籍详细地址!' }],
                initialValue: familyValue.reAddr,
              },
            ]"
            placeholder="请输入户籍详细地址"
          />
        </a-form-item> -->
        <a-form-item label="居住地址">
          <a-input
            v-decorator="[
              'reHomeAddr',
              {
                rules: [{ required: true, message: '请输入居住地址!' }],
                initialValue: familyValue.reHomeAddr,
              },
            ]"
            placeholder="请输入居住地址"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { getBizTypCodeApp_controller } from '../../static/controller/BizTypCodeApp.controller'
import { validateReg } from '../../static/utils/regular'
import moment from 'moment'
import {
  addComeCustomerMgr_controller,
  detailComeCustomerMgr_controller,
  editComeCustomerMgr_controller,
  confirmCheckIn_controller
} from '../../static/controller/ComeCustomerMgr.controller'

import { queryDynamicPage_controller } from '../../static/controller/oldcheckin.controller'

export default {
  components: {
    // RegionSelect: () => import('../../components/RegionSelect'),
    UploadImg: () => import('../../components/UploadImg')
  },
  data() {
    return {
      imageUrl: '',
      visible: false,
      confirmLoading: false,
      selectData: {},
      area: {},
      reArea: {},
      familyInfo: [],
      initValue: null,
      initOrganCode: null,
      comeWay: 1, // 入住方式
      familyValue: {},
      initAreaCode: [],
      isEdit: '', // 是否编辑
      editIndex: '',
      replenish: false,
      idNo: '', // 根据身份证号判断从咨询带过来的数据
      maxFou: 4,
      customerBookingId: '',
      endController: null
    }
  },
  async mounted() {
    // 查询控件
    let res = await queryDynamicPage_controller({
      objCode: 'elder_come'
    })

    if (res.records.length > 0) {
      this.endController = JSON.parse(res.records[0].pageContent)
    }

    this.initSelectData()
    if (this.$route.params.id && this.$route.params.id !== '0') {
      this.initData()
    } else if (this.$route.params.tid) {
      this.customerBookingId = this.$route.params.tid
      // 初始化入住咨询
      this.initRegionInfo()
      this.comeWay = 2
      this.replenish = true
    } else {
      this.initOrganCode = []
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'dynamic_form_item' })
    this.form2 = this.$form.createForm(this, { name: 'dynamic_form_info' })
    this.form3 = this.$form.createForm(this, { name: 'dynamic_form_item3' })
  },
  methods: {
    handleSubmit(e) {},
    submitNext(e) {
      // 保存下一步
      e.preventDefault()
      console.log(this.area)
      this.form.setFieldsValue({
        area: this.area && this.area.data ? this.area : null
      })

      this.form.validateFields(async(error, values) => {
        if (error) {
          return
        }
        // if (!this.imageUrl) {
        //   this.$message.warning('请上传照片！')
        //   return
        // }
        if (this.familyInfo.length === 0) {
          this.$message.warning('请至少添加一名家庭联系成员！')
          return
        }

        this.form3.validateFields(async(e, v) => {
          if (e) {
            return
          }
          // 表示编辑
          if (this.$route.params.id && this.$route.params.id !== '0') {
            editComeCustomerMgr_controller({
              id: this.$route.params.id,
              homeCustomerModel: {
                name: values.name,
                img: this.imageUrl,
                birthday: moment(values.birthday).format('YYYY-MM-DD'),
                nation: values.nation,
                oldJob: values.oldJob,
                sex: values.sex,
                maritalState: values.maritalState,
                educationDegree: values.educationDegree,
                homeAddr: values.homeAddr,
                adminAddr: values.adminAddr,
                idNo: values.idNo,
                myTel: values.myTel,
                adminProvinceCode:
                  values.area && values.area.data && values.area.data.length
                    ? values.area.data[0].value
                    : null, // 省
                adminProvinceName:
                  values.area && values.area.data && values.area.data.length
                    ? values.area.data[0].label
                    : null,
                adminCityCode:
                  values.area && values.area.data && values.area.data.length
                    ? values.area.data[1].value
                    : null, // 市
                adminCityName:
                  values.area && values.area.data && values.area.data.length
                    ? values.area.data[1].label
                    : null,
                adminHotelCode:
                  values.area && values.area.data && values.area.data.length
                    ? values.area.data[2].value
                    : null, // 区
                adminHotelName:
                  values.area && values.area.data && values.area.data.length
                    ? values.area.data[2].label
                    : null,
                extendContent: { ...v }
              }, // 租户信息
              homeCustomerFamilyModels: this.familyInfo,
              comeReason: values.comeReason,
              remark: values.remark
            }).then((res) => {
              // 存在id的情况
              // this.$emit('sendstep', 2)
              this.$message.success('修改成功！')
              this.$router.push({ name: 'checkIndex' })
            })
          } else {
            // 新增
            // if (this.replenish) {
            //   this.$message.error('家庭成员信息不完善，请补充后提交')
            //   return
            // }
            addComeCustomerMgr_controller({
              homeCustomerModel: {
                name: values.name,
                img: this.imageUrl,
                birthday: moment(values.birthday).format('YYYY-MM-DD'),
                nation: values.nation,
                oldJob: values.oldJob,
                sex: values.sex,
                maritalState: values.maritalState,
                educationDegree: values.educationDegree,
                homeAddr: values.homeAddr,
                adminAddr: values.adminAddr,
                idNo: values.idNo,
                myTel: values.myTel,
                adminProvinceCode:
                  values.area && values.area.data && values.area.data.length
                    ? values.area.data[0].value
                    : null, // 省
                adminProvinceName:
                  values.area && values.area.data && values.area.data.length
                    ? values.area.data[0].label
                    : null,
                adminCityCode:
                  values.area && values.area.data && values.area.data.length
                    ? values.area.data[1].value
                    : null, // 市
                adminCityName:
                  values.area && values.area.data && values.area.data.length
                    ? values.area.data[1].label
                    : null,
                adminHotelCode:
                  values.area && values.area.data && values.area.data.length
                    ? values.area.data[2].value
                    : null, // 区
                adminHotelName:
                  values.area && values.area.data && values.area.data.length
                    ? values.area.data[2].label
                    : null,
                extendContent: { ...v }
              }, // 租户信息
              homeCustomerFamilyModels: this.familyInfo,
              comeReason: values.comeReason,
              remark: values.remark,
              comeWay: this.comeWay,
              customerBookingId: this.customerBookingId
            }).then((res) => {
              // this.$emit('sendstep', 2)
              // if (this.customerBookingId) {
              //   // 入住咨询防止选床位时报错
              //   this.$router.push({
              //     name: 'addCheck',
              //     params: {
              //       id: res.id,
              //       tid: this.$route.params.tid ? this.$route.params.tid : 0
              //     }
              //   })
              // } else {
              //   this.$router.push({
              //     name: 'addCheck',
              //     params: {
              //       id: res.id
              //     }
              //   })
              // }
              this.$message.success('新增成功！')
              this.$router.push({ name: 'checkIndex' })
            })
          }
        })
      })
    },
    // 更新数据时
    async initData() {
      // 初始化数据
      detailComeCustomerMgr_controller({
        id: this.$route.params.id
      }).then((res) => {
        this.familyInfo = res.comeCustomerFamilyModelList
        this.imageUrl = res.customerModel.img
        this.initValue = res
        this.initOrganCode = [
          res.customerModel.adminProvinceCode,
          res.customerModel.adminCityCode,
          res.customerModel.adminHotelCode
        ]
        console.log('初始化：222', res)
        this.form3.setFieldsValue({
          ...res.customerModel.extendContent
        })
      })
    },
    initRegionInfo() {
      // 初始化入住咨询
      confirmCheckIn_controller({
        id: this.$route.params.tid
      }).then((res) => {
        // 根据入住等级初始信息
        this.initOrganCode = res.customerModel.registerAreaCodes
        this.initValue = {
          customerModel: {
            name: res.customerModel.name,
            myTel: res.customerModel.myTel,
            sex: res.customerModel.sex,
            birthday: res.customerModel.birthday,
            idNo: '',
            nation: '',
            maritalState: '',
            educationDegree: '',
            oldJob: '',
            homeAddr: res.customerModel.homeAddr
          },
          remark: res.remark,
          comeReason: res.comeReason
        }
        let family = res.comeCustomerFamilyModelList
        family.map((item) => {
          let obj = {
            relType: item.relType,
            name: item.name,
            sex: item.sex,
            idNo: item.idNo,
            mobile: item.mobile,
            registerAreaNames: [],
            registAddr: '',
            homeAddr: item.homeAddr,
            type: item.type
          }
          this.familyInfo.push(obj)
          this.idNo = item.idNo
        })
      })
    },
    handleSubmitInfo(e) {},
    async initSelectData() {
      let one = await getBizTypCodeApp_controller({ typeCodes: 'nation' })
      let tow = await getBizTypCodeApp_controller({
        typeCodes: 'marital_state'
      })
      let three = await getBizTypCodeApp_controller({
        typeCodes: 'education_degree'
      })
      let four = await getBizTypCodeApp_controller({ typeCodes: 'job' })

      this.selectData = {
        nation: one.records,
        marital_state: tow.records,
        education_degree: three.records,
        job: four.records
      }
    },
    validator(rule, value, callback) {
      if (rule.validatorType) {
        let val = validateReg(rule.validatorType, value)
        if (!val.validate) {
          callback(new Error(val.msg))
        } else {
          callback()
        }
      } else {
        callback()
      }
    },
    handleOk(e) {
      e.preventDefault()

      this.form2.setFieldsValue({
        reArea: this.reArea && this.reArea.data ? this.reArea : null
      })
      this.form2.validateFields((error, values) => {
        if (error) {
          return
        }
        console.log('myfamily:::', [...this.familyInfo])
        let lastFamily = [...this.familyInfo]
        if (this.editIndex === 0 || this.editIndex) {
          // 编辑
          if (lastFamily.length > 1) {
            lastFamily = lastFamily.filter(
              (res, index) => index !== this.editIndex
            )
            if (lastFamily.some((res) => res.mobile === values.reMobile)) {
              this.$message.warning(
                '已经存在该联系电话，请核对身份信息，确保联系电话唯一！'
              )
              return
            }
          }
        } else {
          if (this.familyInfo.length > 0) {
            console.log('新增')
            if (this.familyInfo.some((res) => res.mobile === values.reMobile)) {
              this.$message.warning(
                '已经存在该联系电话，请核对身份信息，确保联系电话唯一！'
              )
              return
            }
          }
        }
        let valuesInfo = {
          relType: values.relType,
          name: values.reName,
          sex: values.reSex,
          idNo: values.reIdNo,
          mobile: values.reMobile,
          registAddr: values.reAddr,
          homeAddr: values.reHomeAddr,
          registerAreaCodes: values.reArea ? values.reArea.value : null,
          registerAreaNames: values.reArea
            ? values.reArea.data.map((res) => res.label)
            : null,
          type: this.familyInfo.length === 0 ? 1 : 0
        }
        if (this.editIndex === 0 || this.editIndex) {
          valuesInfo.type = this.familyInfo[this.editIndex].type
          this.familyInfo[this.editIndex] = valuesInfo
        } else {
          valuesInfo.type = this.familyInfo.length === 0 ? 1 : 0
          this.familyInfo.push(valuesInfo)
        }
        // this.familyInfo.map((item) => { // 入住咨询时判断地区是否补充完全
        //   if (item.idNo === this.idNo) {
        //     if (item.registerAreaCodes && item.registAddr) {
        //       this.replenish = false
        //     }
        //   }
        // })
        this.visible = false
        this.confirmLoading = false
      })
    },
    // 设置担保人
    setGuarantee(row) {
      console.log(row)
      this.familyInfo.forEach((res) =>
        res.mobile === row.mobile ? (res.type = 1) : (res.type = 0)
      )
    },
    delGuarantee(e) {
      console.log(e)
      this.familyInfo = this.familyInfo.filter((res) => res.idNo !== e)
    },
    handleCancel() {
      this.visible = false
      this.confirmLoading = false
    },
    addFamily() {
      this.form2.resetFields()
      this.visible = true
      this.familyValue = {}
      this.editIndex = ''
    },
    editFamily(row, index) {
      this.visible = true
      this.editIndex = ''
      this.familyValue = {}
      this.editIndex = index
      this.form2.resetFields()
      this.familyValue = {
        relType: row.relType,
        reName: row.name,
        reSex: row.sex,
        reIdNo: row.idNo,
        reMobile: row.mobile,
        reAddr: row.registAddr,
        reHomeAddr: row.homeAddr,
        type: row.type
      }
      this.initAreaCode = row.registerAreaCodes || []
    },
    validatorPhone(rule, value, callback) {
      let val = validateReg('phone', value)
      if (!val.validate) {
        callback(new Error('手机号格式不正确!'))
      } else {
        callback()
      }
    },
    validatorIdCard(rule, value, callback) {
      let val = validateReg('idCrad', value)
      if (!val.validate) {
        callback(new Error('身份证号码格式不正确!'))
      } else {
        callback()
      }
    },
    getNode(node) {
      console.log(node)
      return node.parentNode
    },
    getBirth(e) {
      let cardNo = e.target.value
      this.form.validateFields(['idNo'], (err) => {
        if (!err) {
          var birthday = ''
          if (cardNo.length === 15) {
            let org_birthday = cardNo.substring(6, 12)
            birthday =
              '19' +
              org_birthday.substring(0, 2) +
              '-' +
              org_birthday.substring(2, 4) +
              '-' +
              org_birthday.substring(4, 6)
          } else if (cardNo.length === 18) {
            let org_birthday = cardNo.substring(6, 14)
            birthday =
              org_birthday.substring(0, 4) +
              '-' +
              org_birthday.substring(4, 6) +
              '-' +
              org_birthday.substring(6, 8)
          }
          this.form.setFieldsValue({
            birthday: birthday
          })
        }
      })
    }
  }
}
</script>
<style >
.userInfo .ant-list-item-meta-title {
  margin-bottom: 0;
}
.userInfo .ant-list-item-meta {
  margin-bottom: 0;
}
.userInfo .ant-list-item-action {
  margin-top: 4px;
}
</style>
