<!--
 * @Author: your name
 * @Date: 2021-03-05 18:14:17
 * @LastEditTime: 2021-03-10 18:57:06
 * @LastEditors: Please set LastEditors
 * @Description: 床
 * @FilePath: \ioac-tenant-html\src\components\RoomLayout\Bed.vue
-->
<template>
  <div>
    <div class="cama">
      <div class="fs" />
      <div class="cama-al">
        <div class="cama-al__front face" />
        <div class="cama-al__back face" />
        <div class="cama-al__right face" />
        <div class="cama-al__left face" />
        <div class="cama-al__top face" />
        <div class="cama-al__bottom face" />
      </div>
      <div class="cama-ac">
        <div class="cama-ac__front face" />
        <div class="cama-ac__back face" />
        <div class="cama-ac__right face" />
        <div class="cama-ac__left face" />
        <div class="cama-ac__top face" />
        <div class="cama-ac__bottom face" />
      </div>
      <div class="cama-ar">
        <div class="cama-ar__front face" />
        <div class="cama-ar__back face" />
        <div class="cama-ar__right face" />
        <div class="cama-ar__left face" />
        <div class="cama-ar__top face" />
        <div class="cama-ar__bottom face" />
      </div>
      <div class="cama-bc">
        <div class="cama-bc__front face" />
        <div class="cama-bc__back face" />
        <div class="cama-bc__right face" />
        <div class="cama-bc__left face" />
        <div class="cama-bc__top face" />
        <div class="cama-bc__bottom face" />
      </div>
      <div class="cama-almos">
        <div class="cama-almo">
          <div class="cama-almo__sh face" />
          <div class="cama-almo__front face" />
          <div class="cama-almo__back face" />
          <div class="cama-almo__right face" />
          <div class="cama-almo__left face" />
          <div class="cama-almo__top face" />
          <div class="cama-almo__bottom face" />
        </div>
        <div class="cama-almo">
          <div class="cama-almo__sh face" />
          <div class="cama-almo__front face" />
          <div class="cama-almo__back face" />
          <div class="cama-almo__right face" />
          <div class="cama-almo__left face" />
          <div class="cama-almo__top face" />
          <div class="cama-almo__bottom face" />
        </div>
      </div>
    </div>
    <Mark :info="bedInfo" />
  </div>
</template>
<script>
export default {
  components: {
    Mark: () => import('./Mark')
  },
  props: {
    bedInfo: Object
  }
}
</script>
<style lang="less" scoped>
.cama {
  transform-origin: 0;
  transform: scale(0.7);
}
</style>
