<!--
 * @Description:
 * @Author:
 * @Date: 2021-03-10 14:27:55
 * @LastEditTime: 2021-03-10 19:24:13
 * @LastEditors:
-->
<template>
  <div>
    <div style="padding: 16px 24px;">
      <a-descriptions title="评估信息" layout="vertical">
        <a-descriptions-item label="发起人" />
        <a-descriptions-item label="发起时间">
          1810000000
        </a-descriptions-item>
        <a-descriptions-item label="被评估长者">
          Hangzhou, Zhejiang
        </a-descriptions-item>
        <a-descriptions-item label="参与人">
          empty
        </a-descriptions-item>
      </a-descriptions>
      <a-divider />
      <a-descriptions title="评估结果">
        <a-descriptions-item label="问卷详情">
          {{ baseData.content }}
        </a-descriptions-item>
        <a-descriptions-item label="问卷结果">
          {{ baseData.content }}
        </a-descriptions-item>
        <a-descriptions-item label="评估人">
          Hangzhou, Zhejiang
        </a-descriptions-item>
        <a-descriptions-item label="评估时间">
          empty
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      baseData: {}
    }
  },
  mounted() {
    console.log(this.data)
    this.baseData = this.data
  }

}
</script>

  <style />
