/*
 * @Author: your name
 * @Date: 2021-05-28 16:46:20
 * @LastEditTime: 2021-05-28 16:46:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\live.Service.Project.controller.js
 */
let {
  queryServiceItem_service,
  addServiceItem_service,
  deleteServiceItem_service,
  updateState_service,
  getServiceItem_service,
  updateServiceItem_service,
  querylist_service
} = require('../service/Live.ServiceItemMgr.service')

module.exports = {
  queryServiceItem_controller(val) {
    return queryServiceItem_service(val)
  },
  addServiceItem_controller(val) {
    return addServiceItem_service(val)
  },
  deleteServiceItem_controller(val) {
    return deleteServiceItem_service(val)
  },
  updateState_controller(val) {
    return updateState_service(val)
  },
  getServiceItem_controller(val) {
    return getServiceItem_service(val)
  },
  updateServiceItem_controller(val) {
    return updateServiceItem_service(val)
  },
  querylist_controller(val) {
    return querylist_service(val)
  }
}
