<!--
 * @Description:选择床位
 * @Author:
 * @Date: 2021-03-06 14:45:22
 * @LastEditTime: 2021-05-17 15:24:37
 * @LastEditors:
-->
<template>
  <div>
    <!-- <p class="formTitle">选择床位</p> -->
    <a-page-header
      :ghost="false"
      title="选择床位"
      sub-title="维护床位信息"
      style="margin-bottom: 20px; border-bottom: 1px solid rgb(235, 237, 240)"
    >
      <template slot="extra">
        <a-button key="3" @click="thirdpre"> 上一步 </a-button>
        <a-button key="1" type="primary" @click="handleSubmit(2)">
          下一步
        </a-button>
        <a-button v-if="jushow" key="2" type="danger" style="margin-left:30px" @click="preNext"> 跳过 </a-button>
      </template>
    </a-page-header>
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
      <a-form-item label="楼层选择">
        <a-cascader
          v-decorator="[
            'customerBed',
            {
              rules: [{ required: true, message: '请选择楼层' }],
              initialValue: initValue.customerBed,
            },
          ]"
          :options="floorList"
          expand-trigger="hover"
          :field-names="fieldNames"
          placeholder="楼栋号、单元号、房间号"
          @change="changeBed"
        />
      </a-form-item>

      <a-form-item label="床位号选择">
        <a-select
          v-decorator="[
            'hospitalBedNo',
            {
              rules: [{ required: true, message: '请选择床位号' }],
              initialValue: initValue.hospitalBedNo,
            },
          ]"
          @change="changeBedNo"
        >
          <a-select-option
            v-for="(item, index) in bedList"
            :key="index"
            :value="item.code"
          >
            {{ item.code }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="床位费">
        <a-input
          v-decorator="['cusFee', { initialValue: initValue.cusFee }]"
          disabled
        />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { addFloorMgr_controller } from '../../static/controller/CustomerBookingMg.controller'
import { queryBedMgr_controller } from '../../static/controller/HospitalBedMgr.controller'
import { twoListByOneType_controller } from '../../static/controller/BedMgr.controller'
import {
  updateSimpleMgr_controller,
  detailComeCustomerMgr_controller
} from '../../static/controller/ComeCustomerMgr.controller'

import { detailCusBookingMg_controller } from '../../static/controller/CustomerBookingMg.controller'
export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: 'bedchoose' }),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      floorList: [],
      bedList: [],
      type: '', //
      cusId: '',
      initValue: {},
      bedNo: '',
      jushow: false,
      fieldNames: {
        value: 'id',
        label: 'name',
        children: 'children'
      }
    }
  },
  created() {
    this.addFloorMgr()
    this.cusId = this.$route.params.id
    if (this.$route.params.tid) {
      // 初始化入住咨询
      this.initRegionInfo()
    } else {
      this.initData()
    }
    let data = JSON.parse(sessionStorage.getItem('roles'))
    console.log(data)
    data.map((item) => {
      if (item.indexOf('003') > -1) {
        this.jushow = true
      } else {
        this.jushow = false
      }
    })
  },
  methods: {
    initData() {
      let id = this.cusId
      detailComeCustomerMgr_controller({ id: id }).then((res) => {
        this.bedNo = res.hospitalBedNo
        if (this.bedNo) {
          let floorList = res.hospitalBedInfoModel.floorTreeList
          let copyfloor = [...floorList]
          let floorlast = copyfloor.pop()
          let type = res.hospitalBedInfoModel.type
          let bedNum = res.hospitalBedNo
          queryBedMgr_controller({ floorCode: floorlast }).then((res) => {
            this.bedList = res
            console.log(this.bedList)
            twoListByOneType_controller({ oneType: 1 }).then((res) => {
              let data = res
              let Fee = data.find((item) => item.twoType === type)
              let cusFee = Fee ? Fee.price : ''
              this.initValue = {
                customerBed: floorList,
                hospitalBedNo: bedNum,
                cusFee: cusFee
              }
            })
          })
        }
      })
    },
    initRegionInfo() {
      // 初始化入住咨询
      detailCusBookingMg_controller({
        id: this.$route.params.tid
      }).then((res) => {
        // 根据入住等级初始信息
        console.log('初始化入住咨询：', res)
        this.bedNo = res.hospitalBedInfoModel.code
        if (this.bedNo) {
          let floorList = res.hospitalBedInfoModel.floorTreeList
          let copyfloor = [...floorList]
          let floorlast = copyfloor.pop()
          let type = res.hospitalBedInfoModel.type
          let bedNum = res.hospitalBedInfoModel.code
          queryBedMgr_controller({ floorCode: floorlast }).then((res) => {
            this.bedList = res
            twoListByOneType_controller({ oneType: 1 }).then((res) => {
              let data = res
              let Fee = data.find((item) => item.twoType === type)
              let cusFee = Fee ? Fee.price : ''
              this.initValue = {
                customerBed: floorList,
                hospitalBedNo: bedNum,
                cusFee: cusFee
              }
            })
          })
        }
      })
    },
    handleSubmit(num) {
      this.form.validateFields((error, values) => {
        if (error) {
          return
        }
        let params = {
          id: this.cusId,
          hospitalBedNo: values.hospitalBedNo
        }
        updateSimpleMgr_controller(params).then((res) => {
          if (num === 1) {
            this.$message.success('保存成功')
            return
          } else {
            this.$emit('fourthstep', 4)
          }
        })
      })
    },
    addFloorMgr() {
      addFloorMgr_controller().then((res) => {
        this.floorList = res
      })
    },
    changeBed(value) {
      let id = [...value].pop()
      this.querybedMrg(id)
      this.form.setFieldsValue({
        hospitalBedNo: '',
        cusFee: ''
      })
    },
    querybedMrg(data) {
      queryBedMgr_controller({ floorCode: data }).then((res) => {
        this.bedList = res
      })
    },
    getFee() {
      twoListByOneType_controller({ oneType: 1 }).then((res) => {
        let data = res
        let Fee = data.find((item) => item.twoType === this.type)
        let cusFee = Fee ? Fee.price : ''
        this.form.setFieldsValue({
          cusFee: cusFee
        })
      })
    },
    changeBedNo(value) {
      this.bedList.map((item) => {
        if (item.code === value) {
          this.type = item.type
          this.getFee()
        }
      })
    },
    thirdpre() {
      this.$emit('thirdpre', 2)
      // this.$router.push({ path: '/admission/addcheck/' + this.cusId })
    },
    preNext() {
      this.$emit('fourthstep', 4)
    },
    goback() {
      // this.$router.push({ path: '/admission/checkin' })
    }
  }
}
</script>

<style>
</style>
