/*
 * @Description:
 * @Author:
 * @Date: 2021-03-19 14:41:29
 * @LastEditTime: 2021-03-19 17:20:24
 * @LastEditors:
 */

let {
  addfinance_service,
  removefinance_service,
  queryfinance_service,
  detailfinance_service,
  updatefinance_service
} = require('../service/FinanceOrderMgr.service')

let { getFeeTypeItemList_service } = require('../service/FinanceFeeItemMgr.service')

module.exports = {
  addFinance_controller(val) {
    return addfinance_service(val)
  },
  removeFinance_controller(val) {
    return removefinance_service(val)
  },
  queryFinance_controller(val) {
    return queryfinance_service(val)
  },
  detailFinance_controller(val) {
    return detailfinance_service(val)
  },
  updateFinance_controller(val) {
    return updatefinance_service(val)
  },
  getFeeTypeItemList_controller(val) {
    return getFeeTypeItemList_service(val)
  }
}
