/*
 * @Description:
 * @Author:
 * @Date: 2021-03-11 11:47:48
 * @LastEditTime: 2021-03-11 11:49:57
 * @LastEditors:
 */
module.exports = {
  ContractTemplate_api: {
    url: 'ioac-center/mgr/contract/template/getTemplateByType',
    post: {
      type: '?'
    }
  }
}
