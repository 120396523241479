/*
 * @Description:
 * @Author:
 * @Date: 2021-03-09 17:31:40
 * @LastEditTime: 2021-03-13 10:59:02
 * @LastEditors:
 */
const {
  xhr
} = require('../lib/xhr')
const {
  getEvaluationMain_api,
  evaluationQuery_api,
  detailEvaluation_api,
  deleteEvaluation_api,
  updateEvaluation_api
} = require('../api/EvaluationMainApp.api')

module.exports = {
  getEvaluationMainApp_service(val) {
    return xhr(getEvaluationMain_api, val)
  },
  evaluationQuery_service(val) {
    return xhr(evaluationQuery_api, val)
  },
  detailEvaluation_service(val) {
    return xhr(detailEvaluation_api, val)
  },
  deleteEvaluation_service(val) {
    return xhr(deleteEvaluation_api, val)
  },
  updateEvaluation_service(val) {
    return xhr(updateEvaluation_api, val)
  }

}
