/*
 * @Author: your name
 * @Date: 2021-03-12 13:19:29
 * @LastEditTime: 2021-03-16 13:46:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\ServiceItemMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  queryServiceItem_api,
  addServiceItem_api,
  deleteServiceItem_api,
  getServiceItem_api,
  updateState_api,
  updateServiceItem_api,
  querylist_api
} = require('../api/ServiceItemMgr.api')

module.exports = {
  queryServiceItem_service(val) {
    return xhr(queryServiceItem_api, val)
  },
  addServiceItem_service(val) {
    return xhr(addServiceItem_api, val)
  },
  deleteServiceItem_service(val) {
    return xhr(deleteServiceItem_api, val)
  },
  getServiceItem_service(val) {
    return xhr(getServiceItem_api, val)
  },
  updateState_service(val) {
    return xhr(updateState_api, val)
  },
  updateServiceItem_service(val) {
    return xhr(updateServiceItem_api, val)
  },
  querylist_service(val) {
    return xhr(querylist_api, val)
  }
}
