/*
 * @Description:
 * @Author:
 * @Date: 2021-04-07 14:43:14
 * @LastEditTime: 2021-04-07 17:16:03
 * @LastEditors:
 */
let {
  dc
} = require('../config/api_spec')
module.exports = {
  hospitalCount_api: {
    url: `${dc}/mgr/welfare/data/hospitalCount`,
    post: {
      getDays: '?',
      state: '?'
    }
  },
  userCount_api: {
    url: `${dc}/mgr/welfare/data/userCount`,
    post: {
      getDays: '?',
      state: '?'
    }
  },
  birthCount_api: {
    url: `${dc}/mgr/welfare/data/birthCount`,
    post: {
      getDays: '?',
      state: '?'
    }
  },
  ageCount_api: {
    url: `${dc}/mgr/welfare/data/ageCount`,
    post: {
      getDays: '?',
      state: '?'
    }
  },
  levelCount_api: {
    url: `${dc}/mgr/welfare/data/levelCount`,
    post: {
      getDays: '?',
      state: '?'
    }
  }
}
