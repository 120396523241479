/*
 * @Description:
 * @Author:
 * @Date: 2021-03-09 17:14:09
 * @LastEditTime: 2021-04-29 18:07:36
 * @LastEditors:
 */
module.exports = {
  getEvaluationMain_api: { // 给登记客户分配评估表
    url: 'ioac-center/mgr/evaluation/customerEvaluationInfo/distribute',
    post: {
      checkUserIdList: '?', // 审核人，抄送人
      evaluationUserId: '?', // 评估人
      customerRegisterNo: '?', // 登记编号
      tenantEvaluationConfigId: '?', // 租户评估表配置编号
      useType: '?'// 评估用途
    }
  },
  evaluationQuery_api: { // 评估列表
    url: 'ioac-center/mgr/evaluation/customerEvaluationInfo/query',
    post: {
      page: '?',
      rows: '?',
      evaluationConfigNo: '?', // 评估表配置编号
      customerRegisterNo: '?',
      useType: '?',
      customerName: '?',
      state: '?'

    }
  },
  // 查询客户评估表
  detailEvaluation_api: {
    url: 'ioac-center/mgr/v2/evaluation/customerEvaluationInfo/get',
    get: {
      id: '?'
    }
  },
  deleteEvaluation_api: { // 删除
    url: 'ioac-center/mgr/evaluation/customerEvaluationInfo/remove',
    get: {
      id: '?'
    }
  },
  // 更新登记客户评估表信息
  updateEvaluation_api: {
    url: 'ioac-center/mgr/evaluation/customerEvaluationInfo/update',
    post: {
      id: '?',
      customerRegisterNo: '?',
      evaluationUserId: '?',
      checkUserIdList: '?'
    }
  }

}
