/*
 * @Author: 刘崇杰
 * @Date: 2021-03-22 14:24:23
 * @LastEditTime: 2021-06-22 14:20:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\workorder.controller.js
 */
const {
  queryServiceTask_service,
  addServiceTask_service,
  editExeUser_service,
  delServiceTask_service,
  editServiceTask_service,
  addBatchTask_service,
  cancelTask_service
} = require('../service/live.ServiceTaskMgr.service')

const {
  queryCheckInCustList_service
} = require('../service/CustomerRegisterMgr.service')

const {
  getCustomerDetailById_service
} = require('../service/live.ServiceTaskCustomerMgr.service')

const {
  addVisitRecord_service
} = require('../service/VisitRecordMgr.service')

module.exports = {
  queryServiceTask_controller(val) {
    return queryServiceTask_service(val)
  },
  addServiceTask_controller(val) {
    return addServiceTask_service(val)
  },
  queryCheckInCustList_controller(val) {
    return queryCheckInCustList_service(val)
  },
  editExeUser_controller(val) {
    return editExeUser_service(val)
  },
  delServiceTask_controller(val) {
    return delServiceTask_service(val)
  },
  editServiceTask_controller(val) {
    return editServiceTask_service(val)
  },
  getCustomerDetailById_controller(val) {
    return getCustomerDetailById_service(val)
  },
  addBatchTask_controller(val) {
    return addBatchTask_service(val)
  },
  addVisitRecord_controller(val) {
    return addVisitRecord_service(val)
  },
  cancelTask_controller(val) {
    return cancelTask_service(val)
  }
}
