<!--
 * @Description:
 * @Author:
 * @Date: 2021-03-25 11:05:47
 * @LastEditTime: 2021-05-31 13:10:27
 * @LastEditors:
-->
<template>
  <div>
    <a-tabs type="card" style="margin-top:25px" @change="callback">
      <a-tab-pane v-for="(item,index) in indicatorList" :key="index" :tab="item.label">
        <div v-if="item.value===dataType" id="myCharts_2" ref="myCharts_2" style="height:310px; width:100%" />
        <a-table :data-source="data" style="margin:10px auto" :columns="columns" :pagination="false">
          <span slot="dataType" slot-scope="record">{{ getLabel(indicatorList,record.dataType) }}</span>
          <span slot="dataType2" slot-scope="text,record">{{ getContents(text,record) }}</span>
          <span slot="tip" slot-scope="text,record" :class="{'red':record.checkData.state==='2'}">{{ text }}</span>
        </a-table>
        <pagination
          :page.sync="pageIndex"
          :limit.sync="pageSize"
          :total="total"
          class="paginationStyle"
          @pagination="getPages"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
var echarts = require('echarts')
var xyChart = null
import { eldermonitorQuery_controller, elderstatistics_controller } from '../../static/controller/MonitoringApp.controller'
import { indicatorList } from '../../static/enum/index'

const columns = [
  {
    title: '检测项目',
    scopedSlots: { customRender: 'dataType' }
  },
  {
    title: '检测值',
    dataIndex: 'dataType',
    scopedSlots: { customRender: 'dataType2' }
  },
  {
    title: '检测结果',
    dataIndex: 'checkData.tip',
    scopedSlots: { customRender: 'tip' }
  },
  {
    title: '检测时间',
    dataIndex: 'cdt',
    key: 'cdt'
  },
  {
    title: '数据来源',
    dataIndex: 'deviceCode',
    key: 'deviceCode'
  }
]
export default {
  components: {
    pagination: () => import('../../components/Pagination')

  },
  data() {
    return {
      data: [],
      columns,
      typeName: '',
      indicatorList,
      pageIndex: 1, // 当前页码
      pageSize: 10,
      total: 0,
      getxList: [], // x轴坐标
      legends: [], // 指示标
      seriesList: [], // 列表数据
      dataType: 'blood_pressure'
    }
  },
  mounted() {
    this.initData2()
    this.initData()
  },
  methods: {
    initData() {
      elderstatistics_controller({
        customerNo: this.$route.params.id,
        dataType: this.dataType
      }).then((res) => {
        console.log(res)
        this.getxList = res.x
        if (this.dataType === 'blood_sugar' || this.dataType === 'temperature') {
          let data = []
          if (this.dataType === 'temperature') {
            let cr = []// 成人额温
            let newcr = []// 成人额温
            let et = []// 儿童额温
            let newet = []// 儿童额温
            let ew = []// 耳温
            let newew = []// 耳温
            let ww = []// 物温
            let newww = []// 物温
            if (res.cr.length) { // 成人额温
              res.cr.map((item) => {
                let obj = {
                  time: item.cdt,
                  value: item.aa
                }
                cr.push(obj)
              })
              cr.map((ite) => {
                let i = Object.values(ite)
                newcr.push(i)
              })
              let crobj = {
                name: '成人额温',
                data: newcr,
                type: 'line',
                smooth: true
              }
              data.push(crobj)
              this.legends.push('成人额温')
            }
            if (res.et.length) { // 儿童额温
              res.et.map((item) => {
                let obj = {
                  time: item.cdt,
                  value: item.aa
                }
                et.push(obj)
              })
              et.map((ite) => {
                let i = Object.values(ite)
                newet.push(i)
              })
              let etobj = {
                name: '儿童额温',
                data: newet,
                type: 'line',
                smooth: true
              }
              data.push(etobj)
              this.legends.push('儿童额温')
            }
            if (res.ew.length) { // 耳温
              res.ew.map((item) => {
                let obj = {
                  time: item.cdt,
                  value: item.aa
                }
                ew.push(obj)
              })
              ew.map((ite) => {
                let i = Object.values(ite)
                newew.push(i)
              })
              let ewobj = {
                name: '耳温',
                data: newet,
                type: 'line',
                smooth: true
              }
              data.push(ewobj)
              this.legends.push('耳温')
            }
            if (res.ww.length) { // 物温
              res.ww.map((item) => {
                let obj = {
                  time: item.cdt,
                  value: item.aa
                }
                ww.push(obj)
              })
              ww.map((ite) => {
                let i = Object.values(ite)
                newww.push(i)
              })
              let wwobj = {
                name: '物温',
                data: newww,
                type: 'line',
                smooth: true
              }
              data.push(wwobj)
              this.legends.push('物温')
            }
          }
          if (this.dataType === 'blood_sugar') {
            let wch1 = []// 晚餐后
            let nwch1 = []// 晚餐后
            let wcq = []// 午餐前
            let nwcq = []// 午餐前
            let zcq = []// 早餐前
            let nzcq = []// 早餐前
            let wcq1 = []// 晚餐前
            let nwcq1 = []// 晚餐前
            let wch = []// 午餐后
            let nwch = []// 午餐后
            let zch = []// 早餐后
            let nzch = []// 早餐后
            let sq = []// 睡前
            let nsq = []// 睡前
            if (res.wch1.length) { // 晚餐后
              res.wch1.map((item) => {
                let obj = {
                  time: item.cdt,
                  value: item.aa
                }
                wch1.push(obj)
              })
              wch1.map((ite) => {
                let i = Object.values(ite)
                nwch1.push(i)
              })
              let wch1obj = {
                name: '晚餐后',
                data: nwch1,
                type: 'line',
                smooth: true
              }
              data.push(wch1obj)
              this.legends.push('晚餐后')
            }
            if (res.wcq.length) { // 午餐前
              res.wcq.map((item) => {
                let obj = {
                  time: item.cdt,
                  value: item.aa
                }
                wcq.push(obj)
              })
              wcq.map((ite) => {
                let i = Object.values(ite)
                nwcq.push(i)
              })
              let wcqobj = {
                name: '午餐前',
                data: nwcq,
                type: 'line',
                smooth: true
              }
              data.push(wcqobj)
              this.legends.push('午餐前')
            }

            if (res.zcq.length) { // 早餐前
              res.zcq.map((item) => {
                let obj = {
                  time: item.cdt,
                  value: item.aa
                }
                zcq.push(obj)
              })
              zcq.map((ite) => {
                let i = Object.values(ite)
                nzcq.push(i)
              })
              let zcqobj = {
                name: '早餐前',
                data: nzcq,
                type: 'line',
                smooth: true
              }
              data.push(zcqobj)
              this.legends.push('早餐前')
            }
            if (res.wcq1.length) { // 晚餐前
              res.wcq1.map((item) => {
                let obj = {
                  time: item.cdt,
                  value: item.aa
                }
                wcq1.push(obj)
              })
              wcq1.map((ite) => {
                let i = Object.values(ite)
                nwcq1.push(i)
              })
              let wcq1obj = {
                name: '晚餐前',
                data: nwcq1,
                type: 'line',
                smooth: true
              }
              data.push(wcq1obj)
              this.legends.push('晚餐前')
            }
            if (res.wch.length) { // 午餐后
              res.wch.map((item) => {
                let obj = {
                  time: item.cdt,
                  value: item.aa
                }
                wch.push(obj)
              })
              wch.map((ite) => {
                let i = Object.values(ite)
                nwch.push(i)
              })
              let wchobj = {
                name: '午餐后',
                data: nwch,
                type: 'line',
                smooth: true
              }
              data.push(wchobj)
              this.legends.push('午餐后')
            }
            if (res.zch && res.zch.length) { // 早餐后
              res.zch.map((item) => {
                let obj = {
                  time: item.cdt,
                  value: item.aa
                }
                zch.push(obj)
              })
              zch.map((ite) => {
                let i = Object.values(ite)
                nzch.push(i)
              })
              let zchobj = {
                name: '早餐后',
                data: nzch,
                type: 'line',
                smooth: true
              }
              data.push(zchobj)
              this.legends.push('早餐后')
            }
            if (res.sq.length) { // 睡前
              res.sq.map((item) => {
                let obj = {
                  time: item.cdt,
                  value: item.aa
                }
                sq.push(obj)
              })
              sq.map((ite) => {
                let i = Object.values(ite)
                nsq.push(i)
              })
              let sqobj = {
                name: '睡前',
                data: nsq,
                type: 'line',
                smooth: true
              }
              data.push(sqobj)
              this.legends.push('睡前')
            }
          }
          this.seriesList = data
          this.$nextTick(() => {
            this.xyCharts()
            this.initData2()
          })
        } else {
          if (res.Y && res.Y.length > 0) {
            this.getxList = res.x
            let data = []
            let series = []
            res.Y.map((item) => {
              data.push(item.title)
              let obj = {
                name: item.title,
                data: item.data,
                type: 'line',
                smooth: true
              }
              series.push(obj)
            })
            this.legends = data
            this.seriesList = series
            this.$nextTick(() => {
              this.xyCharts()
              this.initData2()
            })
          } else {
            this.getxList = res.x
            this.seriesList = []
          }
        }
      })
    },
    initData2() {
      eldermonitorQuery_controller({
        customerNo: this.$route.params.id,
        page: this.pageIndex,
        rows: this.pageSize,
        dataType: this.dataType
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    },
    callback(value) {
      let name = ''
      switch (parseInt(value)) {
        case 0:
          name = 'blood_pressure'
          break
        case 1:
          name = 'blood_sugar'
          break
        case 2:
          name = 'blood_oxygen'
          break
        case 3:
          name = 'temperature'
          break
        case 4:
          name = 'cholesterol'
          break
        case 5:
          name = 'uricacid'
          break
        default:
          name = 'blood_pressure'
      }
      this.dataType = name
      this.initData()
      this.initData2()
    },
    xyCharts() {
      if (xyChart) {
        xyChart.dispose()
      }
      xyChart = echarts.init(document.getElementById('myCharts_2'))
      xyChart.setOption({
        tooltip: {
          trigger: 'axis'
        },
        legend: { data: this.legends },
        grid: [{
          left: '4%',
          right: '4%',
          containLabel: true
        }],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.getxList
        },
        yAxis: {
          type: 'value'
        },
        series: this.seriesList

      }, true)
    },
    getLabel(list, value) {
      let label = ''
      list.map((item) => {
        if (item.value === value) {
          label = item.label
        }
      })
      return label
    },
    getContents(type, data) {
      console.log(type, data)
      let content = ''
      switch (type) {
        case 'blood_pressure':
          content = data.checkData.systolicBloodPressure + ' / ' + data.checkData.diastolicBloodPressure
          break
        case 'blood_oxygen':
          content = data.checkData.spo2 + ' / ' + data.checkData.pulseRate
          break
        case 'blood_sugar':
          content = data.checkData.glu
          break
        case 'cholesterol':
          content = data.checkData.cholesterol
          break
        case 'uricacid':
          content = data.checkData.uricAcid
          break
        // case 'whr':
        //   content = data.checkData.waistline + '/' + data.checkData.hip + '/' + data.checkData.whr
        //   break
        case 'temperature':
          content = data.checkData.temp
          break
      }
      return content
    },
    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.initData2()
    }

  }

}
</script>

<style>

</style>
