<template>
  <a-col class="m-box col m-mt-10 box" style="height: 400px">
    <div style="width: 100%; height: 30px; display: flex">
      <span
        v-for="(item, index) in datalist"
        :key="index"
        :class="!item.bool ? 'one' : 'two'"
        @click="callback(item.name, index)"
      >
        {{ item.name }}</span>
    </div>

    <a-row type="flex" style="width: 100%; height: 310px; margin: 0 auto">
      <a-col :span="4" style="border-right: 1px solid #ccc; height: 100%">
        <div class="m-box">
          <p>总检测次数</p>
          <h2>{{ leftlist.totalDetect }}次</h2>
          <p>检测次数排行：{{ leftlist.rankNo }}</p>
        </div>
        <div
          style="background: #ccc; width: 80%; height: 1px; margin: 0 auto"
        />
        <div class="m-box">
          <a-col
            style="display: flex; justify-content: space-between; width: 100%"
          >
            <span>平均</span>
            <span>{{ leftlist.avgVal }}</span>
          </a-col>
          <a-col
            style="display: flex; justify-content: space-between; width: 100%"
          >
            <span>最高</span>
            <span>{{ leftlist.maxVal }}</span>
          </a-col>
          <a-col
            style="display: flex; justify-content: space-between; width: 100%"
          >
            <span>最低</span>
            <span>{{ leftlist.minVal }}</span>
          </a-col>
        </div>
      </a-col>
      <a-col :span="14" style="border-right: 1px solid #ccc; height: 100%">
        <div
          id="myCharts5"
          ref="myCharts5"
          style="height: 330px; width: 100%"
        />
      </a-col>
      <a-col :span="6" style="height: 100%">
        <div id="myChartspie1" ref="myChartspie1" style="height: 330px" />
      </a-col>
    </a-row>
  </a-col>
</template>

<script>
var echarts = require('echarts')
export default {
  filters: {},
  props: { bottomlist: Object },
  data() {
    return {
      list: {},
      datalist: [
        {
          name: '尿酸',
          bool: true
        },
        {
          name: '总胆固醇',
          bool: false
        },
        {
          name: '血糖',
          bool: false
        },
        {
          name: '血氧',
          bool: false
        },
        {
          name: '血压',
          bool: false
        },
        {
          name: '体温',
          bool: false
        },
        {
          name: '心电',
          bool: false
        },
        {
          name: '体脂',
          bool: false
        }
      ],
      leftlist: {},
      centerlist: [],
      right: [],
      rightlist: [],
      // colorlist: ['#57BA1C', '#FFE350', '#FFAF4C', '#FFAF4C', '#FF5050', '#FFAF4C', '#FF5050'],
      colorlist: [{ color: '#FFE350' }, { color: '#FFAF4C' }, { color: '#57BA1C' }, { color: '#FFAF4C' }, { color: '#FF5050' }, { color: '#FFAF4C' }, { color: '#FF5050' }, { color: '#FFAF4C' }, { color: '#FF5050' }]
    }
  },

  computed: {},
  watch: {
    bottomlist(val, newval) {
      this.list = val
      this.getlist()
      this.leftlist = this.list.variousDetectSituationRankMgrModelMap['尿酸']
      this.centerlist = this.list.uricAcidCountList
      this.right = this.list.variousDetectPercentageMgrModelMap['尿酸']
      if (this.right) {
        this.right.forEach((item, index) => {
          item.value = item.count
          item.name = item.tip
          this.colorlist.forEach((val, ind) => {
            if (index === ind) {
              item.itemStyle = val
            }
          })
        })
        this.rightlist = this.right.map((res) => {
          return res.type
        })
        console.log(this.right, '<<<<<<<<<<<<<<<==========')
        console.log(this.rightlist, '<<<<<<<<<<<<<<<==========')
      }
    }
    // leftlist(val) {
    // this.myCharts5()
    // this.myChartspie1()
    // }
    // centerlist: [],
  },
  created() {},
  updated() {
    this.myCharts5()
    this.myChartspie1()
  },
  mounted() {},
  methods: {
    callback(name, index) {
      for (let i = 0; i < this.datalist.length; i++) {
        this.datalist[i].bool = false
      }
      this.datalist[index].bool = true
      console.log(name)
      this.leftlist = this.list.variousDetectSituationRankMgrModelMap[name]
      this.centerlist =
        index === 0 ? this.list.uricAcidCountList : index === 1 ? this.list.cholesterolCountList : index === 2 ? this.list.bloodSugarCountList : index === 3 ? this.list.bloodOxygenCountList
          : index === 4 ? this.list.bloodPressureCountList : index === 5 ? this.list.temperatureCountList : index === 6 ? this.list.ecgCountList : this.list.inbodyCountList
      this.right = this.list.variousDetectPercentageMgrModelMap[name]
      this.right.forEach((item, index) => {
        item.value = item.count
        item.name = item.tip
        this.colorlist.forEach((val, ind) => {
          if (index === ind) {
            item.itemStyle = val
          }
          if (item.name === '糖耐量异常') {
            item.itemStyle = { color: '#FFE350' }
          }
          if (item.name === '血糖正常') {
            item.itemStyle = { color: '#57BA1C' }
          }
          if (item.name === '正常血压') {
            item.itemStyle = { color: '#57BA1C' }
          }
          if (item.name === '正常高值') {
            item.itemStyle = { color: '#BCD03C' }
          }
          if (item.name === '3级高血压水平') {
            item.itemStyle = { color: '#FF5050' }
          }
        })
      })
      this.rightlist = this.right.map((res) => {
        return res.type
      })
    },
    getlist() {},
    myChartspie1() {
      // var myChartspie = echarts.init(document.getElementById('myChartspie'))
      var chartDom = document.getElementById('myChartspie1')
      var myChart = echarts.init(chartDom)
      this.xData = []
      this.YData = []
      var option
      option = {
        title: {
          text: '结果占比'
        },
        tooltip: {
          trigger: 'item'
        },
        // color: this.colorlist,
        // legend: {
        //   orient: 'vertical',
        //   left: 'left',
        //   top: '20px'
        // },
        series: [
          {
            name: '结果占比',
            type: 'pie',
            radius: '50%',
            data: this.right,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      myChart.clear()
      option && myChart.setOption(option)
    },
    myCharts5() {
      var chartDom = document.getElementById('myCharts5')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        title: {
          text: '整体检测趋势'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.list.monthList
        },
        yAxis: {
          type: 'value',
          splitNumber: 4
        },
        series: [
          {
            data: this.centerlist,
            type: 'line',
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#DCE7FE' // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#EBF1FE' //   0% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            },
            color: '#DCE7FE', // 线条颜色
            smooth: true // 线条平滑
          }
        ]
      }
      myChart.clear()
      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
.sp {
  font-size: 36px;
  color: #ff8847;
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box {
  background: #fff;
  padding: 5px 0;
  margin-top: 10px;
}
.one {
  margin-right: 40px;
  line-height: 30px;
  font-size: 16px;
  box-sizing: border-box;
  cursor: pointer;
}
.two {
  margin-right: 40px;
  line-height: 20px;
  font-size: 16px;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 2px solid #fa6400;
}
</style>
