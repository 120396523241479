/*
 * @Author: your name
 * @Date: 2021-03-06 13:44:47
 * @LastEditTime: 2021-03-08 16:14:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\OrganMgr.controller.js
 */
const {
  getRegionInfo_service
} = require('../service/RegionInfo.service')

const {
  organQuery_service,
  organAdd_service,
  organRemove_service,
  organUpdate_service,
  queryByUser_service,
  getOrgan_service,
  getOrganOptions_service
} = require('../service/OrganMgr.service')

module.exports = {
  getRegionInfo_controller() {
    return getRegionInfo_service()
  },
  organQuery_controller(val) {
    return organQuery_service(val)
  },
  organAdd_controller(val) {
    return organAdd_service(val)
  },
  organRemove_controller(val) {
    return organRemove_service(val)
  },
  organUpdate_controller(val) {
    return organUpdate_service(val)
  },
  queryByUser_controller() {
    return queryByUser_service()
  },
  getOrgan_controller(val) {
    return getOrgan_service(val)
  },
  getOrganOptions_controller(val) {
    return getOrganOptions_service(val)
  }
}
