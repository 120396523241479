/*
 * @Author: your name
 * @Date: 2021-06-02 13:07:26
 * @LastEditTime: 2021-06-10 14:02:02
 * @LastEditors: Please set LastEditors
 * @Description: 反馈中心
 * @FilePath: \ioac-tenant-html\src\static\api\AccountInfoMgr.api.js
 */
let {
  hsc
} = require('../config/api_spec')
module.exports = {
  addAccountInfo_api: {
    url: `${hsc}/mgr/finance/accountInfo/add`,
    post: {
      accountObj: '?',
      customerNo: '?'
    }
  },
  getByCustomerNo_api: {
    url: `${hsc}/mgr/finance/accountInfo/getByCustomerNo`,
    post: {
      customerNo: '?'
    }
  },
  // mgr/finance/accountInfo/query
  queryAccountInfo_api: {
    url: `${hsc}/mgr/finance/accountInfo/query`,
    post: {
      customerNo: '?',
      name: '?',
      page: '?',
      rows: '?',
      type: '?'
    }
  },
  // mgr/finance/accountInfo/querylist
  // 反馈中心

  queryList_api: {
    url: `${hsc}/mgr/equip/feedback/queryList`,
    get: {
      name: '?',
      state: '?',
      page: '?',
      rows: '?'
    }
  },

  // 反馈中心
  // /mgr/equip/feedback/updateState
  updateState_api: {
    url: `${hsc}/mgr/equip/feedback/updateState`,
    post: {
      id: '',
      name: '?',
      state: '?',
      page: '?',
      rows: '?'
    }
  },

  //   mgr/finance/accountInfo/depositAcct
  depositAcct_api: {
    url: `${hsc}/mgr/finance/accountInfo/depositAcct`,
    post: {
      customerNo: '?',
      depositType: '?',
      fund: '?',
      memo: '?',
      type: '?'
    }
  },

  //  管家服务
  // /mgr/equip/service/butler/batchDelete   批量删除
  // depositAcct_api: {
  //   url: `${hsc}/mgr/equip/service/butler/batchDelete`,
  //   post: {
  //     customerNo: '?',
  //     depositType: '?',
  //     fund: '?',
  //     memo: '?',
  //     type: '?'
  //   }
  // },

  // /mgr/equip/service/butler/deleteById    根据id删除
  butler_batchDelete_api: {
    url: `${hsc}/mgr/equip/service/butler/deleteById`,
    get: {
      id: '?'
    }
  },
  // /mgr/equip/service/butler/export   导出用户
  butler_export_api: {
    url: `${hsc}/mgr/equip/service/butler/export`,
    get: {}
  },
  // /mgr/equip/service/butler/getInfoById 根据id查看详情
  butler_getInfoById_api: {
    url: `${hsc}/mgr/equip/service/butler/getInfoById`,
    post: {
      id: '?'
    }
  },
  // /mgr/equip/service/butler/importUser   导入用户
  butler_importUser_api: {
    url: `${hsc}/mgr/equip/service/butler/importUser`,
    post: {
      file: '?'
    }
  },
  // /mgr/equip/service/butler/queryList 列表分页查询
  butler_queryList_api: {
    url: `${hsc}/mgr/equip/service/butler/queryList`,
    get: {
      name: '?',
      page: '?',
      rows: '?'
    }
  },
  // /mgr/equip/service/butler/save   保存
  butler_save_api: {
    url: `${hsc}/mgr/equip/service/butler/save`,
    post: {
      age: '?',
      avatar: '?',
      id: '?',
      idcard: '?',
      name: '?',
      organId: '?',
      organName: '?',
      phone: '?',
      sex: '?',
      page: ''
    }
  },
  // /mgr/equip/service/butler/updatePassword   修改密码
  butler_updatePassword_api: {
    url: `${hsc}/mgr/equip/service/butler/updatePassword`,
    post: {
      id: '?',
      password: '?'
    }
  },

  // 知识库
  // /mgr/equip/knowledge/base/knowledgeBaseAdd  知识库新增
  knowledgeBaseAdd_api: {
    url: `${hsc}/mgr/equip/knowledge/base/knowledgeBaseAdd`,
    post: {
      kname: '?'
      // password: '?'
    }
  },

  // /mgr/equip/knowledge/base/queryKnowledgeBase    查询知识库信息

  queryKnowledgeBase_api: {
    url: `${hsc}/mgr/equip/knowledge/base/queryKnowledgeBase`,
    post: {
      kname: '?',
      page: '?',
      rows: '?'
    }
  },

  // 检测分析
  //   /mgr/equip/analysis/AddEquipAnalysis
  // 添加检测分析信息
  AddEquipAnalysis_api: {
    url: `${hsc}/mgr/equip/analysis/AddEquipAnalysis`,
    post: {
      checkCode: 'string',
      checkLevelCode: 'string',
      checkLevelName: 'string',
      checkName: 'string',
      // organCode: 'string',
      suggestContent: 'string'
      // tenantCode: 'string'
    }
  },

  // /mgr/equip/analysis/UpdateEquipAnalysis
  // 修改检测分析信息
  UpdateEquipAnalysis_api: {
    url: `${hsc}/mgr/equip/analysis/UpdateEquipAnalysis`,
    post: {
      checkCode: 'string',
      checkLevelCode: 'string',
      checkLevelName: 'string',
      checkName: 'string',
      id: '?',
      suggestContent: 'string'
      // tenantCode: 'string'
    }
  },

  // /mgr/equip/analysis/equipAnalysisQueryAll
  // 查询所有检测分析信息
  equipAnalysisQueryAll_api: {
    url: `${hsc}/mgr/equip/analysis/equipAnalysisQueryAll`,
    post: {
      // kname: '?',
      keyWord: '?',
      page: '?',
      rows: '?'
    }
  },

  // /mgr/equip/analysis/disableEquipAnalysisById
  // 根据id禁用(开启)检测分析信息
  disableEquipAnalysisById_api: {
    url: `${hsc}/mgr/equip/analysis/disableEquipAnalysisById`,
    post: {
      id: '?',
      state: '?'
    }
  },

  // /mgr/equip/analysis/getEquipAnalysisById
  // 根据id查询检测分析信息
  getEquipAnalysisById_api: {
    url: `${hsc}/mgr/equip/analysis/getEquipAnalysisById`,
    get: {
      id: '?'
    }
  },

  // /mgr/equip/analysis/removeEquipAnalysisById
  // 根据id删除检测分析信息
  removeEquipAnalysisById_api: {
    url: `${hsc}/mgr/equip/analysis/removeEquipAnalysisById`,
    post: {
      id: '?'
    }
  },

  // 知识库操作

  //   /mgr/equip/knowledge/subject/BatchRemoveKnowledgeSubjectInfo
  // 批量删除知识库主题内容信息
  BatchRemoveKnowledgeSubjectInfo_api: {
    url: `${hsc}/mgr/equip/knowledge/subject/BatchRemoveKnowledgeSubjectInfo`,
    post: {
      sid: [],
      kid: '?'
    }
  },

  // /mgr/equip/knowledge/subject/UpdateBaseInfoBySid
  // 根据sid修改主题内容
  UpdateBaseInfoBySid_api: {
    url: `${hsc}/mgr/equip/knowledge/subject/UpdateBaseInfoBySid`,
    post: {
      answerList: [],
      questionList: [],
      sid: 'string',
      sname: 'string',
      kid: ''
    }
  },

  // /mgr/equip/knowledge/subject/UpdateBaseKNameByKid
  // 根据kid修改知识库名称
  UpdateBaseKNameByKid_api: {
    url: `${hsc}/mgr/equip/knowledge/subject/UpdateBaseKNameByKid`,
    post: {
      kid: '?',
      kname: '?'
    }
  },

  // /equip/knowledge/subject/createKnowledgeSubjectInfo
  // 创建知识库主题信息
  createKnowledgeSubjectInfo_api: {
    url: `${hsc}/mgr/equip/knowledge/subject/createKnowledgeSubjectInfo`,
    post: {
      answer: [],
      problem: [],
      kid: '?',
      sname: 'string'
    }
  },

  // /mgr/equip/knowledge/subject/queryBySubjectSid
  // 根据sid查询主题内容
  queryBySubjectSid_api: {
    url: `${hsc}/mgr/equip/knowledge/subject/queryBySubjectSid`,
    get: {
      sid: '?'
    }
  },

  // /mgr/equip/knowledge/subject/queryKnowledgeSubjectInfoList
  // 根据知识库Kid查询知识库列表信息
  queryKnowledgeSubjectInfoList_api: {
    url: `${hsc}/mgr/equip/knowledge/subject/conditionQueryKnowledgeList`,
    post: {
      kid: '?',
      page: '?',
      rows: '?',
      param: '?'
    }
  },

  // /mgr/equip/knowledge/subject/releaseKnowledgeSubjectInfoList
  // 发布
  releaseKnowledgeSubjectInfoList_api: {
    url: `${hsc}/mgr/equip/knowledge/subject/releaseKnowledgeSubjectInfoList`,
    post: {
      kid: '?'
    }
  },

  // 设备信息管理

  // /equip-manage/mgr/equip/info/add
  // 添加设备信息
  equipment_add_api: {
    url: `${hsc}/mgr/equip/info/add`,
    post: {
      imei: '?',
      mac: '?',
      sn: '?',
      typeId: '?',
      address: '',
      longitude: '',
      latitude: ''
    }
  },

  // POST
  // /mgr/equip/info/assign/equip
  // 设备分配
  equipment_equip_api: {
    url: `${hsc}/mgr/equip/info/assign/equip`,
    post: {
      sn: '?',
      assignCompany: '?'
    }
  },

  // POST
  // /mgr/equip/info/batch/assign/equip
  // 设备分配批量
  equipment_batchequip_api: {
    url: `${hsc}/mgr/equip/info/batch/assign/equip`,
    post: {
      snList: [],
      assignCompany: '?'
    }
  },

  // POST
  // /mgr/equip/info/batchAdd
  // 批量新增设备
  // equipment_batchAdd_api: {
  //   url: `${hsc}/mgr/equip/info/batchAdd`,
  //   post: {
  //     file: '?'
  //   }
  // },

  // GET
  // /mgr/equip/info/deleteEquipInfoById
  // 根据ID删除设备信息
  equipment_deleteEquipInfoById_api: {
    url: `${hsc}/mgr/equip/info/deleteEquipInfoById`,
    get: {
      id: '?'
    }
  },

  // /equip-manage/mgr/equip/info/batchDeleteEquipInfo
  // 批量删除设备信息
  equipment_batchDeleteEquipInfo_api: {
    url: `${hsc}/mgr/equip/info/batchDeleteEquipInfo`,
    post: {
      ids: '?'
    }
  },
  // POST
  // /mgr/equip/info/editEquipInfo
  // 编辑设备信息
  equipment_editEquipInfo_api: {
    url: `${hsc}/mgr/equip/info/editEquipInfo`,
    post: {
      id: '',
      imei: '',
      mac: '',
      sn: '',
      state: '',
      typeId: '',
      address: '',
      longitude: '',
      latitude: ''
    }
  },

  // GET
  // /mgr/equip/info/getEquipInfoById
  // 根据ID获取设备信息
  equipment_getEquipInfoById_api: {
    url: `${hsc}/mgr/equip/info/getEquipInfoById`,
    get: {
      id: '?'
    }
  },

  // POST
  // /mgr/equip/info/query
  // 分页查询设备列表
  equipment_query_api: {
    url: `${hsc}/mgr/equip/info/query`,
    get: {
      page: '?',
      rows: '?',
      sn: '?',
      typeName: '?',
      organCode: '?'
    }
  },
  // /equip-manage/mgr/equip/type/queryAllEquipTypeAndModel
  // 查询所有设备类型和型号
  queryAllEquipTypeAndModel_api: {
    url: `${hsc}/mgr/equip/type/queryAllEquipTypeAndModel`,
    get: {}
  },

  // 设备数据类型

  //   /equip-manage/mgr/equip/type/add
  // 添加设备类型
  type_add_api: {
    url: `${hsc}/mgr/equip/type/add`,
    post: {
      guaranteeNum: '',
      model: '',
      supplier: '',
      typeName: ''
    }
  },

  // POST
  // /equip-manage/mgr/equip/type/deleteEquipTypeInfoById
  // 根据ID删除设备类型
  deleteEquipTypeInfoById_api: {
    url: `${hsc}/mgr/equip/type/deleteEquipTypeInfoById`,
    post: {
      id: ''
    }
  },
  // POST
  // /equip-manage/mgr/equip/type/editEquipTypeInfo
  // 编辑设备类型信息
  editEquipTypeInfo_api: {
    url: `${hsc}/mgr/equip/type/editEquipTypeInfo`,
    post: {
      guaranteeNum: '',
      model: '',
      supplier: '',
      typeName: '',
      id: ''
    }
  },
  // GET
  // /equip-manage/mgr/equip/type/getEquipTypeInfoById
  // 根据ID获取设备类型信息
  getEquipTypeInfoById_api: {
    url: `${hsc}/mgr/equip/type/getEquipTypeInfoById`,
    get: {
      id: ''
    }
  },
  // POST
  // /equip-manage/mgr/equip/type/query
  // 分页查询设备类型列表
  type_query_api: {
    url: `${hsc}/mgr/equip/type/query`,
    post: {
      guaranteeNum: '',
      model: '',
      supplier: '',
      typeName: '',
      page: '',
      rows: ''
    }
  },

  // 机构管理  账户信息查询
  //   POST
  // /equip-manage/mgr/equip/organization/account/deleteOrganById
  // 根据id删除机构
  deleteOrganById_api: {
    url: `${hsc}/mgr/equip/organization/account/deleteOrganById/`,
    get: {
      id: ''
    }
  },

  // GET
  // /equip-manage/mgr/equip/organization/account/queryList
  // 列表分页查询
  account_queryList_api: {
    url: `${hsc}/mgr/equip/organization/account/queryList`,
    get: {
      organId: '',
      page: '',
      rows: '',
      status: '?'
    }
  },

  // GET
  // /equip-manage/mgr/equip/organization/account/queryTree
  // 查询树机构
  account_queryTree_api: {
    url: `${hsc}/mgr/equip/organization/account/queryTree`,
    get: {

    }
  },
  // POST
  // /equip-manage/mgr/equip/organization/account/save
  // 保存机构
  account_save_api: {
    url: `${hsc}/mgr/equip/organization/account/save`,
    post: {
      organLogo: '/',
      organName: '/',
      parentId: '/',
      phone: '/',
      roleIds: [],
      targetOrganId: '',
      userAccount: '/',
      userId: ''
    }
  },
  // POST
  // /equip-manage/mgr/equip/organization/account/updatePassword
  // 修改密码
  account_updatePassword_api: {
    url: `${hsc}/mgr/equip/organization/account/updatePassword`,
    post: {
      id: '',
      password: ''
    }
  },
  // POST
  // /equip-manage/mgr/equip/organization/account/updateState
  // 修改机构状态
  account_updateState_api: {
    url: `${hsc}/mgr/equip/organization/account/updateState`,
    post: {
      id: '',
      state: ''
    }
  },

  // 健康档案
  //   GET
  // /equip-manage/mgr/equip/enduser/query
  // 根据id查看详情
  enduser_query_api: {
    url: `${hsc}/mgr/equip/enduser/query`,
    get: {
      id: '?'
    }
  },
  // POST
  // /equip-manage/mgr/equip/enduser/queryList
  // 列表分页查询
  enduser_queryList_api: {
    url: `${hsc}/mgr/equip/enduser/queryList`,
    post: {
      status: '?',
      fuzzySearch: '?',
      sex: '?',
      page: '?',
      rows: '?'
    }
  },
  // POST
  // /equip-manage/mgr/equip/enduser/update
  // 修改健康档案
  enduser_update_api: {
    url: `${hsc}/mgr/equip/enduser/update/personalFile`,
    post: {
      avatar: '?',
      id: '?',
      idcard: '?',
      name: '?',
      birth: '?',
      phone: '?',
      sex: '?',
      weight: '?',
      height: '?',
      remark: '?'
    }
  },

  // /mgr/equip/data/center/query
  // 查询数据中心

  center_query_api: {
    url: `${hsc}/mgr/equip/data/center/query`,
    post: {
      beginDate: '',
      endDate: ''
    }
  },

  // 数据中心
  //   /equip-manage/mgr/equip/data/center/ageGenderRank
  // 统计注册人员的年龄分布、检测排行

  ageGenderRank_api: {
    url: `${hsc}/mgr/equip/data/center/ageGenderRank`,
    post: {
      beginDate: '',
      endDate: ''
    }
  },

  // /equip-manage/mgr/equip/data/center/deviceTypeDetect
  // 按设备类型统计整体检测趋势
  deviceTypeDetect_api: {
    url: `${hsc}/mgr/equip/data/center/deviceTypeDetect`,
    post: {
      beginDate: '',
      endDate: ''
    }
  },

  // /equip-manage/mgr/equip/data/center/genderDetectRank
  // 按性别统计检测次数排行、异常检测排行
  genderDetectRank_api: {
    url: `${hsc}/mgr/equip/data/center/genderDetectRank`,
    post: {
      beginDate: '',
      endDate: ''
    }
  },

  // /equip-manage/mgr/equip/data/center/usage
  // 统计设备使用情况
  usage_api: {
    url: `${hsc}/mgr/equip/data/center/usage`,
    post: {
      beginDate: '',
      endDate: ''
    }
  },

  // /equip-manage/mgr/equip/data/center/userDetectSos
  // 数据中心今日注册、用户总量、检测量、SOS触发量
  userDetectSos_api: {
    url: `${hsc}/mgr/equip/data/center/userDetectSos`,
    post: {
      beginDate: '',
      endDate: ''
    }
  },

  // /equip-manage/mgr/equip/data/center/variousDetectSituation
  // 数据中心底部各项检测趋势
  variousDetectSituation_api: {
    url: `${hsc}/mgr/equip/data/center/variousDetectSituation`,
    post: {
      beginDate: '',
      endDate: ''
    }
  },
  enduserByType_api: {
    url: `${hsc}/mgr/equip/enduser/queryVitalSigns`,
    post: {
      beginDate: '',
      endDate: '',
      id: '',
      name: ''
    }
  },
  // 解除机构绑定
  unbindOrgan_api: {
    url: `${hsc}/mgr/equip/info/relieve/equip`,
    get: {
      id: '?'
    }
  }
}
