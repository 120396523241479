<!--
 * @Description:选择评估
 * @Author:
 * @Date: 2021-03-06 14:45:22
 * @LastEditTime: 2021-07-02 17:08:44
 * @LastEditors: Please set LastEditors
-->
<template>
  <div>
    <!-- 编辑是显示 -->
    <div v-if="data.length > 0">
      <!-- <p class="formTitle">评估信息</p> -->

      <a-page-header
        title="选择评估"
        sub-title="维护评估信息"
      >
        <template slot="extra">
          <!-- <a-button key="3" @click="prestep"> 上一步 </a-button> -->
          <!-- <a-button key="1" type="primary" @click="preNext"> 下一步 </a-button> -->
          <!-- <a-button key="1" type="primary" @click="preNext"> 跳过 </a-button> -->
        </template>
      </a-page-header>
      <a-table :columns="columns" :data-source="data" :pagination="false">
        <span slot="titles" slot-scope="text, record">{{
          record.title + " " + "(" + record.subTitle + ")"
        }}</span>
        <a slot="name" slot-scope="text">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
        <span slot="action" slot-scope="text, record">
          <a-popconfirm
            title="你确定要删除该条数据吗?"
            @confirm="userDelete(record.id)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </span>
      </a-table>
      <a-row type="flex" justify="center">
        <a-button
          key="1"
          type="primary"
          @click="() => $router.push({ path: '/admission/checkin' })"
        >
          确定
        </a-button>
      </a-row>
      <!-- <a-divider orientation="center" style="margin-top: 50px"> -->
      <!-- <a-button type="primary" style="margin-right:50px" @click="prestep"> 保存 </a-button> -->
      <!-- <a-button type="primary" @click="preNext"> 保存 </a-button> -->
      <!-- <a-button type="primary" style="margin-right: 40px" @click="prestep">
          上一步
        </a-button>
        <a-button type="primary" style="margin-right: 40px" @click="goback">
          取消
        </a-button>
        <a-button type="primary" @click="preNext"> 下一步 </a-button>
      </a-divider> -->
    </div>
    <!-- 添加时显示 -->
    <div v-else>
      <a-page-header
        :ghost="false"
        title="选择评估"
        sub-title="维护评估信息"
        style="margin-bottom: 20px"
      >
        <template slot="extra">
          <!-- <a-button key="3" @click="prestep"> 上一步 </a-button> -->
          <!-- <a-button key="1" type="primary" @click="handleSubmit(1)">
            下一步
          </a-button> -->
          <!-- <a-button
            key="1"
            type="danger"
            style="margin-left: 30px"
            @click="preNext"
          >
            跳过
          </a-button> -->
        </template>
      </a-page-header>
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="选择问卷">
          <a-select
            v-decorator="[
              'tenantEvaluationConfigId',
              { rules: [{ required: true, message: '请选择问卷!' }] },
            ]"
            mode="multiple"
          >
            <a-select-option
              v-for="(item, index) in wqQuers"
              :key="index"
              :value="item.id"
            >
              {{
                item.evaluationModel.title +
                  " " +
                  "(" +
                  item.evaluationModel.subTitle +
                  ")"
              }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="评估人">
          <a-select
            v-decorator="[
              'evaluationUserId',
              { rules: [{ required: true, message: '请选择评估人!' }] },
            ]"
          >
            <a-select-option
              v-for="(item, index) in userQuerys"
              :key="index"
              :value="item.id"
            >
              {{ item.realName }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="抄送人">
          <a-select
            v-decorator="[
              'checkUserIdList',
              { rules: [{ required: true, message: '请选择抄送人!' }] },
            ]"
            mode="multiple"
          >
            <a-select-option
              v-for="(item, index) in userQuerys"
              :key="index"
              :value="item.id"
            >
              {{ item.realName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <a-row type="flex" justify="center">
        <a-button key="1" type="primary" @click="handleSubmit(1)">
          保存
        </a-button>
      </a-row>
    </div>
  </div>
</template>

<script>
import { getEvaluationApp_controller } from '../../static/controller/TenantEvaluationApp.controller'
import { userQuery_controller } from '../../static/controller/UserRole.controller'
import {
  getEvaluationMainApp_controller,
  evaluationQuery_controller,
  deleteEvaluation_controller
} from '../../static/controller/EvaluationMainApp.controller'
import { queryCheckInCustList_controller } from '../../static/controller/ComeCustomerMgr.controller'
const columns = [
  {
    title: '客户名称',
    key: 'customerName',
    dataIndex: 'customerName'
  },
  {
    title: '评估问卷名称',
    key: 'title',
    dataIndex: 'title',
    scopedSlots: { customRender: 'titles' }
  },
  {
    title: '评估人',
    dataIndex: 'evaluationUserName',
    key: 'evaluationUserName'
  },
  {
    title: '抄送人',
    dataIndex: 'checkUserNameList',
    key: 'checkUserNameList'
  },
  {
    title: '评估时间',
    key: 'evaluationDate',
    dataIndex: 'evaluationDate'
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: 'evalutaion' }),
      columns,
      data: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      userQuerys: [],
      wqQuers: [],
      elderList: [], // 长者列表
      cusId: '', // 客户登记编号
      newcusId: '' // 登记完成保存的用户登记编号
    }
  },
  mounted() {
    this.getEvaluate()
    this.userQuery()
    this.elderQuery()
    this.newcusId = localStorage.getItem('cusRegisterNo')
    this.cusId = this.$route.params.id
    this.initData()
  },
  methods: {
    initData() {
      evaluationQuery_controller({
        customerRegisterNo: this.cusId,
        useType: 1,
        page: 1,
        rows: 100
      }).then((res) => {
        let data = res.records
        if (data.length > 0) {
          let ress = []
          data.map((item) => {
            let obj = {}
            obj.customerName = item.customerEvaluationInfo.customerName
            obj.evaluationUserName =
              item.customerEvaluationInfo.evaluationUserName
            obj.customerRegisterNo =
              item.customerEvaluationInfo.customerRegisterNo
            obj.evaluationDate = item.customerEvaluationInfo.evaluationDate
            obj.checkUserNameList =
              item.customerEvaluationInfo.checkUserNameList.join(',')
            obj.state = item.customerEvaluationInfo.state
            obj.title = item.title
            obj.subTitle = item.subTitle
            obj.id = item.customerEvaluationInfo.id
            ress.push(obj)
          })
          this.data = ress
        } else {
          this.data = []
        }
      })
    },
    handleSubmit(num) {
      this.form.validateFields((error, values) => {
        if (error) {
          return
        }
        if (values.checkUserIdList.includes(values.evaluationUserId)) {
          this.$message.error('评估人和抄送人不能为同一人')
          return
        }
        // 新增
        getEvaluationMainApp_controller({
          ...values,
          useType: 1,
          customerRegisterNo: this.$route.params.id
        }).then((res) => {
          // this.$emit('thirdstep', 3)
          this.$message.success('添加评估成功！')
          this.$router.push({ path: '/admission/checkin' })
        })
      })
    },
    userDelete(id) {
      deleteEvaluation_controller({ id: id }).then((res) => {
        this.$message.success('删除成功！')
        this.initData()
      })
    },
    getEvaluate() {
      getEvaluationApp_controller({ page: 1, rows: 100 }).then((res) => {
        this.wqQuers = res.records
      })
    },
    userQuery() {
      userQuery_controller({ page: 1, rows: 100 })
        .then()
        .then((res) => {
          this.userQuerys = res.records
        })
    },
    elderQuery() {
      queryCheckInCustList_controller().then((res) => {
        this.elderList = res
      })
    },
    prestep() {
      this.$emit('secondpre', 1)
    },
    // 编辑是下一步操作
    preNext() {
      this.$emit('thirdstep', 3)
    },
    goback() {
    }
  }
}
</script>

<style>
</style>
