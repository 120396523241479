/*
 * @Author: your name
 * @Date: 2021-03-15 15:32:31
 * @LastEditTime: 2021-05-27 17:19:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\CustomerInfoMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  getCustomerList_api,
  addCustomerInfo_api,
  updateState_api,
  removeCustomerInfo_api,
  getCustomerInfoById_api,
  updateCustomerInfo_api
} = require('../api/ServiceStaffInfoMgr.api')

module.exports = {
  getCustomerList_service(val) {
    return xhr(getCustomerList_api, val)
  },
  addCustomerInfot_service(val) {
    return xhr(addCustomerInfo_api, val)
  },
  updateState_service(val) {
    return xhr(updateState_api, val)
  },
  removeCustomerInfo_service(val) {
    return xhr(removeCustomerInfo_api, val)
  },
  getCustomerInfoById_service(val) {
    return xhr(getCustomerInfoById_api, val)
  },
  updateCustomerInfo_service(val) {
    return xhr(updateCustomerInfo_api, val)
  }
}
