<template>
  <div class="gutter-example">
    <a-row :gutter="16">
      <a-col class="gutter-row" :span="12">
        <div class="gutter-box">
          <div class="imgbox">
            <img
              v-show="onelistbool"
              src="https://hlkj-img.oss-cn-shanghai.aliyuncs.com/16324665589149303bcd6aea9a383b7bb1199b99a45bf空状态-暂无数据.png?p=d2g9NTA1LTM0Mg=="
              alt=""
              style="height: auto; width: auto"
            >
          </div>
          <div
            v-show="!onelistbool"
            id="myCharts"
            ref="myCharts"
            style="height: 320px; width: 100%"
          />
        </div>
      </a-col>
      <a-col class="gutter-row" :span="6">
        <div class="gutter-box">
          <div class="imgbox">
            <img
              v-show="listbool"
              src="https://hlkj-img.oss-cn-shanghai.aliyuncs.com/16324665589149303bcd6aea9a383b7bb1199b99a45bf空状态-暂无数据.png?p=d2g9NTA1LTM0Mg=="
              alt=""
              style="height: auto; width: auto"
            >
          </div>
          <div
            v-show="!listbool"
            id="myChartnum"
            ref="myChartnum"
            style="height: 320px; width: 100%"
          />
        </div>
      </a-col>
      <a-col class="gutter-row" :span="6">
        <div class="gutter-box">
          <div class="imgbox">
            <img
              v-show="listonebool"
              src="https://hlkj-img.oss-cn-shanghai.aliyuncs.com/16324665589149303bcd6aea9a383b7bb1199b99a45bf空状态-暂无数据.png?p=d2g9NTA1LTM0Mg=="
              alt=""
              style="height: auto; width: auto"
            >
          </div>
          <div
            v-show="!listonebool"
            id="myChartabnormal"
            ref="myChartabnormal"
            style="height: 320px; width: 100%"
          />
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
var echarts = require('echarts')
export default {
  filters: {},
  props: { twolist: Array, twoOnelist: Array },
  data() {
    return {
      list: [],
      wholelist: {},
      numlist: {},
      abnormallist: {},
      oneList: [],
      listbool: false,
      onelistbool: false,
      listonebool: false
    }
  },
  watch: {
    twolist: function(val, newval) {
      this.list = val
      this.getlist()
      // if (
      //   this.list[1].maleDetectPercentageList.length === 0 &&
      // this.list[1].womanDetectPercentageList.length === 0
      // ) {
      //   this.listbool = true
      // } else {
      // this.listbool = false
      this.myChartnum()
      // }
      // if (
      //   this.list[0].maleDetectPercentageList.length === 0 &&
      // this.list[0].womanDetectPercentageList.length === 0
      // ) {
      //   this.listonebool = true
      // } else {
      // this.listonebool = false
      this.myChartabnormal()
    },
    twoOnelist: function(val, newval) {
      this.oneList = val
      this.oneGetList()
      // if (
      //   this.oneList[0].inspectionBoxEveryMonthDetectList.length === 0 &&
      //   this.oneList[0].machineEveryMonthDetectList.length === 0 &&
      //   this.oneList[0].robotEveryMonthDetectList.length === 0
      // ) {
      //   this.onelistbool = true
      // } else {
      // this.onelistbool = false
      this.myEcharts()
      // }
    }
  },
  created() {},
  updated() {},
  mounted() {},
  methods: {
    getlist() {
      this.numlist = this.list[1]
      this.abnormallist = this.list[0]
    },
    oneGetList() {
      this.wholelist = this.oneList[0]
    },
    myChartabnormal() {
      let that = this
      var chartDom = document.getElementById('myChartabnormal')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        title: {
          text: '异常检测项排行'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          top: '20px',
          left: '0',
          data: ['男性', '女性']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          // type: 'value',
          show: false,
          type: 'value',
          splitNumber: 10,
          boundaryGap: ['0', '100%'],
          min: 0,
          max: 100,
          scale: true,
          splitArea: { show: true },
          formatter: (a, b, c) => {
            return a.data.value + '%'
          }
        },
        yAxis: {
          type: 'category',
          data: that.abnormallist.typeList
        },
        series: [
          {
            name: '男性',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: that.abnormallist.maleDetectPercentageList
          },
          {
            name: '女性',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: that.abnormallist.womanDetectPercentageList
          }
        ]
      }
      myChart.clear()
      option && myChart.setOption(option)
    },
    myChartnum() {
      let that = this
      var chartDom = document.getElementById('myChartnum')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        title: {
          text: '检测次数排行'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          top: '20px',
          left: '0',
          data: ['男性', '女性']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          show: false,
          // type: 'value'
          type: 'value',
          splitNumber: 10,
          boundaryGap: ['0', '100%'],
          min: 0,
          max: 100,
          scale: true,
          splitArea: { show: true }
          // formatter: (a, b, c) => {
          //   console.log(a, '图标的数据转化', b, '第二个数据的转化')
          //   return a.item.data + '%'
          // }
        },
        axisTick: {
          show: false,
          formatter: (a, b, c) => {
            return a.data.value + '%'
          }
        },
        yAxis: {
          type: 'category',
          data: that.numlist.typeList
        },
        series: [
          {
            name: '男性',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: that.numlist.maleDetectPercentageList
            // data: ['10', '10', '10', '10', '10', '10', '10']
          },
          {
            name: '女性',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: that.numlist.womanDetectPercentageList
            // data: ['10%', '10%', '10%', '10%', '10%', '10%', '10%']
          }
        ]
      }

      myChart.clear()
      option && myChart.setOption(option, true)
    },
    myEcharts() {
      var chartDom = document.getElementById('myCharts')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        title: {
          text: '检测次数趋势'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['质检箱', '一体机', '机器人']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.wholelist.monthList
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '质检箱',
            type: 'line',
            data: this.wholelist.inspectionBoxEveryMonthDetectList
          },
          {
            name: '一体机',
            type: 'line',
            data: this.wholelist.machineEveryMonthDetectList
          },
          {
            name: '机器人',
            type: 'line',
            data: this.wholelist.robotEveryMonthDetectList
          }
        ]
      }
      myChart.clear()
      option && myChart.setOption(option, true)
    }
  }
}
</script>

<style scoped>
.sp {
  font-size: 36px;
  color: #ff8847;
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box {
  background: #fff;
  padding: 5px 0;
  margin-top: 10px;
}
.imgbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
</style>
