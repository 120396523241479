/*
 * @Description: 入住咨询
 * @Author:
 * @Date: 2021-03-03 10:51:35
 * @LastEditTime: 2021-03-18 15:36:11
 * @LastEditors:
 */

const {
  xhr
} = require('../lib/xhr')
const {
  getCustomerBookingMg_api,
  deleteCustomerBookingMg_api,
  confirmCustomerBookingMg_api,
  addCustomerBookingMg_api,
  addFloorMgr_api,
  detailCustomerBookingMg_api,
  editCustomerBookingMg_api
} = require('../api/CustomerBookingMg.api')

module.exports = {
  getCustomerBookingMg_service(val) {
    return xhr(getCustomerBookingMg_api, val)
  },
  deleteCustomerBookingMg_serveice(val) {
    return xhr(deleteCustomerBookingMg_api, val)
  },
  confirmCustomerBookingMg_service(val) {
    return xhr(confirmCustomerBookingMg_api, val)
  },
  addCustomerBookingMg_service(val) {
    return xhr(addCustomerBookingMg_api, val)
  },
  detailCustomerBookingMg_service(val) {
    return xhr(detailCustomerBookingMg_api, val)
  },

  // 房间号
  addFloorMgr_service(val) {
    return xhr(addFloorMgr_api, val)
  },
  editCustomerBookingMg_service(val) {
    return xhr(editCustomerBookingMg_api, val)
  }

}

