<template>
  <div>
    <a-modal
      title="新增套餐"
      :visible="visible"
      :footer="null"
      :mask-closable="false"
      @cancel="handleCancel"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 17 }"
        @submit.prevent="handleSubmit"
      >
        <a-form-item label="名称">
          <a-input
            v-decorator="[
              'templateName',
              {
                rules: [
                  {
                    required: true,
                    message: '名称不能为空',
                  },
                  { validator: checkTemplateNameDuplicate }
                ],
                validateTrigger: ['blur'],
              },
            ]"
            placeholder="请输入套餐名称"
          />
        </a-form-item>
        <a-form-item label="类型">
          <a-select
            v-decorator="[
              'templateType',
              {
                rules: [
                  {
                    required: true,
                    message: '类型不能为空',
                  },
                ],
                validateTrigger: ['blur'],
              },
            ]"
            placeholder="请选择类型"
            @change="handleTypeChange"
          >
            <a-select-option :value="0"> 通用套餐 </a-select-option>
            <a-select-option :value="1"> 机构专属套餐 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="organSelect" label="专属机构">
          <a-select
            v-decorator="[
              'templateOrganId',
              {
                rules: [
                  {
                    required: true,
                    message: '专属机构不能为空',
                  },
                ],
                validateTrigger: ['blur'],
              }]"
            placeholder="请选择机构"
          >
            <a-select-option
              v-for="item in organOptions"
              :key="item.companyNo"
              :value="item.companyNo"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="规则" :required="true">
          <MetaDictInput
            ref="metaDictInput"
            v-decorator="[
              'rules',
              {
                initialValue: {},
                rules: [{ validator: checkRulesInput }],
                validateTrigger: ['submit'],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="时长">
          <a-select
            v-decorator="[
              'templateExpiry',
              {
                rules: [
                  {
                    required: true,
                    message: '时长不能为空',
                  },
                ],
                validateTrigger: ['blur'],
              },
            ]"
            placeholder="请选择时长"
          >
            <a-select-option value="0"> 6个月 </a-select-option>
            <a-select-option value="1"> 1年 </a-select-option>
            <a-select-option value="2"> 2年 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item style="display: flex; justify-content: center">
          <div style="display: flex; justify-content: space-around">
            <a-button type="primary" html-type="submit" :size="size">
              确认
            </a-button>
            <a-button
              type="primary"
              :size="size"
              style="background: #999; border-color: #999"
              @click="handleCancel"
            >
              取消
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MetaDictInput from './MetaDictInput.vue'
import {
  createTemplate_controller,
  checkTemplateNameDuplicate_controller
} from '@/static/controller/Subscription.controller'
import BaseModal from '@/components/subscription/BaseModal'
export default {
  components: {
    MetaDictInput
  },
  extends: BaseModal,
  data() {
    return {
      form: this.$form.createForm(this),
      size: 'large',
      organSelect: false
    }
  },
  computed: {
    ...mapState({
      templateMetaDict: (state) => state.subscription.templateMetaDict
    })
  },
  methods: {
    handleCancel(e) {
      this.visible = false
      this.form.resetFields()
    },
    handleSubmit() {
      this.form.validateFields([
        'templateName',
        'templateType',
        'templateOrganId',
        'rules',
        'templateExpiry'
      ], { force: true }, (err, values) => {
        if (!err) {
          createTemplate_controller({
            templateName: values.templateName,
            templateType: values.templateType,
            templateOrganId: values.templateOrganId,
            templateExpiry: values.templateExpiry,
            metaDictList: this.$refs.metaDictInput.format()
          }).then((res) => {
            this.$message.success('新增成功！')
            this.visible = false
            this.form.resetFields()
            this.$refs.metaDictInput.resetForm()
            this.$emit('refresh')
          })
        }
      })
    },
    handleTypeChange(val) {
      this.organSelect = val === 1
    },

    /**
     * 规则的验证规则
     */
    checkRulesInput(rule, value, callback) {
      this.$refs.metaDictInput.validate(rule, value, callback)
    },

    /**
     * 套餐名称不重复验证规则
     */
    checkTemplateNameDuplicate(rule, value, callback) {
      checkTemplateNameDuplicate_controller({
        templateName: value
      }).then((res) => {
        if (res === false) {
          callback()
        } else {
          callback(new Error('套餐名称已存在！'))
        }
      })
    }
  }
}
</script>

<style>
.count-input {
  width: 100px;
}
</style>
