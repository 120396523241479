/*
 * @Author: your name
 * @Date: 2021-03-03 16:12:53
 * @LastEditTime: 2021-03-03 16:13:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\PermissionMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  permissionQueryTree_api
} = require('../api/PermissionMgr.api')

module.exports = {
  permissionQueryTree_service() {
    return xhr(permissionQueryTree_api)
  }
}
