/*
 * @Description:
 * @Author:
 * @Date: 2021-03-11 10:14:33
 * @LastEditTime: 2021-03-24 14:12:30
 * @LastEditors:
 */

const {
  xhr
} = require('../lib/xhr')

const {
  getBedMgr_api,
  addHospitalBed_api,
  hospitalBedQuery_api,
  updateHospitalBed_api,
  removeHospitalBed_api
} = require('../api/HospitalBedMgr.api')

module.exports = {
  getBedMgr_service(val) {
    return xhr(getBedMgr_api, val)
  },
  addHospitalBed_service(val) {
    return xhr(addHospitalBed_api, val)
  },
  hospitalBedQuery_service(val) {
    return xhr(hospitalBedQuery_api, val)
  },
  updateHospitalBed_service(val) {
    return xhr(updateHospitalBed_api, val)
  },
  removeHospitalBed_service(val) {
    return xhr(removeHospitalBed_api, val)
  }
}
