/*
 * @Author: your name
 * @Date: 2021-03-03 19:19:29
 * @LastEditTime: 2021-04-12 16:47:55
 * @LastEditors: Please set LastEditors
 * @Description: 租户服务
 * @FilePath: \ioac-tenant-html\src\static\api\TenantApp.api.js
 */
let {
  uc
} = require('../config/api_spec')

module.exports = {
  // 获取当前租户模块(应用)列表
  getTenantApplications_api: {
    url: `${uc}/app/t/tenant/getTenantApplications`,
    method: 'GET',
    post: {}
  }
}
