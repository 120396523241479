/*
 * @Author: your name
 * @Date: 2021-06-22 14:41:28
 * @LastEditTime: 2021-06-22 14:58:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\TenantCompanyMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  getCompany_api
} = require('../api/TenantCompanyMgr.api')

module.exports = {
  getCompany_service(val) {
    return xhr(getCompany_api, val)
  }
}
