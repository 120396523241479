/*
 * @Description:
 * @Author:
 * @Date: 2021-03-09 16:48:20
 * @LastEditTime: 2021-03-09 16:57:28
 * @LastEditors:
 */

const {
  xhr
} = require('../lib/xhr')
const {
  getEvaluationApp_api
} = require('../api/TenantEvaluationApp.api')

module.exports = {
  getEvaluationApp_service(val) {
    return xhr(getEvaluationApp_api, val)
  }
}
