/*
 * @Author: your name
 * @Date: 2021-02-23 17:18:08
 * @LastEditTime: 2021-05-12 10:00:36
 * @LastEditors:
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import organ from './modules/organ'
import subscription from './modules/subscription'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    loadding: false,
    serviceList: [],
    menus: [],
    roles: [],
    tips: ''
  },
  mutations: {
    // loading 状态
    loadState(info, e) {
      this.state.loadding = e
    },
    GET_SERVICE: (state, data) => {
      state.serviceList = data
    },
    GET_MENUS: (state, data) => {
      state.menus = data
    },
    GET_ROLES: (state, roles) => {
      state.roles = roles
    }
  },
  actions: {
    async GetService({
      commit,
      state
    }) {
      commit('GET_SERVICE', state)
    },
    async ASYNC_GET_ROLES({
      commit
    }, data) {
      commit('GET_ROLES', data)
    },
    async GET_ACTIONMENUS({
      commit
    }, data) {
      console.log(data)
      // getMenu_controller().then((res) => {
      // 获取当前用户的菜单权限
      commit('GET_MENUS', data)
      // })
    }
  },
  modules: {
    organ,
    subscription
  }
})
