/*
 * @Author: your name
 * @Date: 2021-03-17 09:22:26
 * @LastEditTime: 2021-03-23 15:24:39
 * @LastEditors: Please set LastEditors
 * @Description: 服务_服务任务管理
 * @FilePath: \ioac-tenant-html\src\static\api\ServiceTaskMgr.api.js
 */
let {
  ic
} = require('../config/api_spec')

module.exports = {
  queryServiceTask_api: {
    url: `${ic}/mgr/service/task/query`,
    post: {
      customerName: '?',
      exeState: '?',
      page: '?',
      rows: '?'
    }
  },
  addServiceTask_api: {
    url: `${ic}/mgr/service/task/add`,
    post: {
      customerRegisterNo: '?',
      customerNo: '?',
      customerName: '?',
      planExeDate: '?',
      serviceItemId: '?',
      type: '?'
    }
  },
  editExeUser_api: {
    url: `${ic}/mgr/service/task/editExeUser`,
    post: {
      customerNo: '?',
      id: '?'
    }
  },
  delServiceTask_api: {
    url: `${ic}/mgr/service/task/del`,
    get: {
      id: '?'
    }
  },
  editServiceTask_api: {
    url: `${ic}/mgr/service/task/edit`,
    post: {
      id: '?',
      customerRegisterNo: '?',
      customerNo: '?',
      customerName: '?',
      planExeDate: '?',
      serviceItemId: '?',
      type: '?'
    }
  }
}
