/*
 * @Description:
 * @Author:
 * @Date: 2021-03-04 15:55:53
 * @LastEditTime: 2021-04-14 19:39:01
 * @LastEditors:
 */
let {
  getRegionInfo_service,
  getqueryLowerAll_service
} = require('../service/RegionInfo.service')

module.exports = {
  getRegionInfo_controller(val) {
    return getRegionInfo_service(val)
  },
  getqueryLowerAll_controller(val) {
    return getqueryLowerAll_service(val)
  }
}
