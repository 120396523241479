/*
 * @Author: your name
 * @Date: 2021-03-08 17:50:35
 * @LastEditTime: 2021-03-10 19:22:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\FloorMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  floorTree_api,
  addFloor_api,
  removeFloor_api,
  updateFloor_api,
  roomsByFloor_api,
  amountByType_api
} = require('../api/FloorMgr.api')

module.exports = {
  floorTree_service(val) {
    return xhr(floorTree_api, val)
  },
  addFloor_service(val) {
    return xhr(addFloor_api, val)
  },
  removeFloor_service(val) {
    return xhr(removeFloor_api, val)
  },
  updateFloor_service(val) {
    return xhr(updateFloor_api, val)
  },
  roomsByFloor_service(val) {
    return xhr(roomsByFloor_api, val)
  },
  amountByType_service(val) {
    return xhr(amountByType_api, val)
  }
}
