/*
 * @Description:
 * @Author:
 * @Date: 2021-04-07 14:53:02
 * @LastEditTime: 2021-04-07 15:11:55
 * @LastEditors:
 */

const {
  xhr
} = require('../lib/xhr')

const {
  hospitalCount_api,
  userCount_api,
  birthCount_api,
  ageCount_api,
  levelCount_api
} = require('../api/WelfareMgr.api')

module.exports = {
  hospitalCount_service(val) {
    return xhr(hospitalCount_api, val)
  },
  userCount_service(val) {
    return xhr(userCount_api, val)
  },
  birthCount_service(val) {
    return xhr(birthCount_api, val)
  },
  ageCount_service(val) {
    return xhr(ageCount_api, val)
  },
  levelCount_service(val) {
    return xhr(levelCount_api, val)
  }
}
