/*
 * @Description:
 * @Author:
 * @Date: 2021-03-11 16:05:51
 * @LastEditTime: 2021-04-26 13:27:59
 * @LastEditors:
 */
const {
  xhr
} = require('../lib/xhr')

const {
  addContract_api,
  detailContract_api,
  updateContract_api,
  contractQuery_api,
  contractHtml_api,
  getByRegisterId_api,
  contractcustQuery_api,
  getSimplecust_api

} = require('../api/ContractCustomer.api')

module.exports = {
  addContract_service(val) {
    return xhr(addContract_api, val)
  },
  detailContract_service(val) {
    return xhr(detailContract_api, val)
  },
  updateContract_service(val) {
    return xhr(updateContract_api, val)
  },
  contractQuery_service(val) {
    return xhr(contractQuery_api, val)
  },
  contractHtml_service(val) {
    return xhr(contractHtml_api, val)
  },
  getByRegisterId_service(val) {
    return xhr(getByRegisterId_api, val)
  },
  contractcustQuery_service(val) {
    return xhr(contractcustQuery_api, val)
  },
  getSimplecust_service(val) {
    return xhr(getSimplecust_api, val)
  }
}

