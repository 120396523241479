/*
 * @Author: your name
 * @Date: 2021-03-09 17:22:12
 * @LastEditTime: 2021-03-31 14:53:22
 * @LastEditors:
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\FinanceFeeItemMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  twoListByOneType_api,
  getFeeTypeItemList_api,
  addfeeItem_api,
  updatefeeItem_api,
  queryfeeItem_api,
  detailfeeItem_api,
  removefeeItem_api,
  updateStatefeeItem_api,
  feeItemlist_api
} = require('../api/FinanceFeeItemMgr.api')

module.exports = {
  twoListByOneType_service(val) {
    return xhr(twoListByOneType_api, val)
  },
  getFeeTypeItemList_service(val) {
    return xhr(getFeeTypeItemList_api, val)
  },
  addfeeItem_service(val) {
    return xhr(addfeeItem_api, val)
  },
  updatefeeItem_service(val) {
    return xhr(updatefeeItem_api, val)
  },
  queryfeeItem_service(val) {
    return xhr(queryfeeItem_api, val)
  },
  detailfeeItem_service(val) {
    return xhr(detailfeeItem_api, val)
  },
  removefeeItem_service(val) {
    return xhr(removefeeItem_api, val)
  },
  updateStatefeeItem_service(val) {
    return xhr(updateStatefeeItem_api, val)
  },
  feeItemlist_service(val) {
    return xhr(feeItemlist_api, val)
  }
}
