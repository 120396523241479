/*
 * @Description:
 * @Author:
 * @Date: 2021-03-09 16:44:21
 * @LastEditTime: 2021-05-25 17:07:47
 * @LastEditors: Please set LastEditors
 */

module.exports = {
  getEvaluationApp_api: {
    url: 'ioac-center/mgr/evaluation/tenantEvaluation/config/query',
    post: {
      page: '?',
      rows: '?'
    }
  }
}
