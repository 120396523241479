<template>
  <div>
    <a-modal
      title="订阅绑定"
      :visible="visible"
      :footer="null"
      :mask-closable="false"
      @cancel="handleCancel"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 17 }"
        @submit.prevent="handleSubmit"
      >
        <a-form-item label="机构">
          <a-select
            v-decorator="[
              'organId',
              {
                rules: [
                  {
                    required: true,
                    message: '机构不能为空',
                  },
                ],
                validateTrigger: ['blur'],
              },
            ]"
            placeholder="请选择机构"
            allow-clear
            @change="handleOrganChange"
          >
            <a-select-option
              v-for="item in organOptions"
              :key="item.companyNo"
              :value="item.companyNo"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="订阅名称">
          <a-select
            v-decorator="[
              'templateId',
              {
                rules: [
                  {
                    required: true,
                    message: '订阅名称不能为空',
                  },
                ],
                validateTrigger: ['blur'],
              },
            ]"
            placeholder="请选择订阅名称"
            allow-clear
            @change="handleTemplateChange"
          >
            <a-select-option
              v-for="item in templateOptions"
              :key="item.id"
              :value="item.id"
            >
              {{ item.templateName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-show="rules.length > 0" label="订阅内容">
          <div>
            <table style="width: 100%">
              <tr v-for="item in rules" :key="item.id">
                <td :key="item.id" style="width: 70%">
                  <a-checkbox :checked="true" disabled>
                    {{ item.metaAttributeZhname }}
                  </a-checkbox>
                </td>
                <td :key="item.id" style="30%">
                  <a-input
                    :value="noLimitConvert(item.metaLimitValue, item.metaLimitValue)"
                    class="count-input"
                    disabled
                  />
                </td>
              </tr>
            </table>
          </div>
        </a-form-item>
        <a-form-item v-show="expiry !== ''" label="订阅有效期">
          <a-select v-model="expiry" disabled>
            <a-select-option value="0" seleted> 6个月 </a-select-option>
            <a-select-option value="1"> 1年 </a-select-option>
            <a-select-option value="2"> 2年 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="订阅生效期">
          <a-date-picker
            v-decorator="[
              'startTime',
              {
                rules: [
                  {
                    required: true,
                    message: '订阅生效期不能为空',
                  },
                ],
                validateTrigger: ['change'],
              },
            ]"
            placeholder="请选择订阅生效期"
          />
        </a-form-item>
        <a-form-item label="合同编号">
          <a-input
            v-decorator="[
              'contractNum',
              {
                rules: [
                  {
                    required: true,
                    message: '合同编号不能为空',
                  },
                  {
                    max: 20,
                    message: '合同编号不能超过20个字',
                  }
                ],
                validateTrigger: ['blur'],
              },
            ]"
            placeholder="合同编号"
          />
        </a-form-item>
        <a-form-item style="display: flex; justify-content: center">
          <div style="display: flex; justify-content: space-around">
            <a-button type="primary" html-type="submit" :size="size">
              确认
            </a-button>
            <a-button
              type="primary"
              :size="size"
              style="background: #999; border-color: #999"
              @click="handleCancel"
            >
              取消
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import BaseModal from '@/components/subscription/BaseModal'
import {
  getTemplateOptions_controller,
  getTemplateDetails_controller,
  bindTemplate_controller
} from '@/static/controller/Subscription.controller'
import { mapState, mapActions } from 'vuex'
import subscriptMixin from '@/mixins/subscriptMixin'
export default {
  extends: BaseModal,
  mixins: [subscriptMixin],
  data() {
    return {
      form: this.$form.createForm(this),
      rules: [],
      expiry: ''
    }
  },
  computed: {
    ...mapState({
      templateOptions: (state) => state.subscription.templateOptions
    })
  },
  methods: {
    ...mapActions({
      getTemplateOptions: 'subscription/getTemplateOptions'
    }),
    handleSubmit() {
      this.form.validateFields([
        'organId',
        'templateId',
        'startTime',
        'contractNum'
      ], { force: true }, (err, values) => {
        if (!err) {
          bindTemplate_controller({
            organId: values.organId,
            templateId: values.templateId,
            startTime: values.startTime,
            contractNum: values.contractNum
          }).then((res) => {
            this.$message.success('绑定成功！')
            this.visible = false
            this.form.resetFields()
            this.$emit('refresh')
          })
        }
      })
    },
    handleTemplateChange(val) {
      getTemplateDetails_controller({ id: val }).then((res) => {
        this.rules = res.ruleLists
        this.expiry = res.templateExpiry
      })
    },
    handleOrganChange(val) {
      this.getTemplateOptions({ fun: getTemplateOptions_controller, params: { organId: val }})
    }
  }
}
</script>

<style>
</style>
