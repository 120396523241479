/*
 * @Author: your name
 * @Date: 2021-03-12 10:41:48
 * @LastEditTime: 2021-04-13 13:53:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\api\ServiceItemMgr.api.js
 */
let {
  ic
} = require('../config/api_spec')

module.exports = {
  queryServiceItem_api: {
    url: `${ic}/mgr/service/serviceItem/query`,
    post: {
      name: '?',
      page: '?',
      rows: '?',
      serviceGroup: '?',
      state: '?',
      type: '?'
    }
  },
  addServiceItem_api: {
    url: `${ic}/mgr/service/serviceItem/add`,
    post: {
      amount: '?',
      detail: '?',
      img: '?',
      name: '?',
      price: '?',
      priceUnit: '?',
      remark: '?',
      serviceGroup: '?',
      timeValidLen: '?',
      type: '?',
      state: '?',
      timeLen: '?'
    }
  },
  deleteServiceItem_api: {
    url: `${ic}/mgr/service/serviceItem/delete`,
    post: {
      id: '?'
    }
  },
  getServiceItem_api: {
    url: `${ic}/mgr/service/serviceItem/get`,
    post: {
      id: '?'
    }
  },
  updateState_api: {
    url: `${ic}/mgr/service/serviceItem/updateState`,
    post: {
      id: '?',
      state: '?'
    }
  },
  updateServiceItem_api: {
    url: `${ic}/mgr/service/serviceItem/update`,
    post: {
      id: '?',
      amount: '?',
      detail: '?',
      img: '?',
      name: '?',
      price: '?',
      priceUnit: '?',
      remark: '?',
      serviceGroup: '?',
      type: '?',
      state: '?',
      timeLen: '?',
      timeValidLen: '?'
    }
  },
  querylist_api: {
    url: `${ic}/mgr/service/serviceItem/querylist`,
    post: {
      name: '?',
      organCode: '?',
      serviceGroup: '?',
      state: '?',
      type: '?'
    }
  }
}
