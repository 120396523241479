/*
 * @Description:
 * @Author:
 * @Date: 2021-03-18 18:32:01
 * @LastEditTime: 2021-03-19 11:10:11
 * @LastEditors:
 */

let {
  ic
} = require('../config/api_spec')

module.exports = {
  // 查询
  outQuery_api: {
    url: `${ic}/mgr/customer/out/list`,
    post: {
      page: '?',
      rows: '?',
      name: '?',
      startDate: '?',
      endDate: '?'
    }
  },
  addoutCustomer_api: {
    url: `${ic}/mgr/customer/out/add`,
    post: {
      comeCustomerNo: '?',
      outDate: '?',
      outType: '?',
      outRemark: '?'
    }
  },
  updateoutCustomer_api: {
    url: `${ic}/mgr/customer/out/update`,
    post: {
      id: '?',
      comeCustomerNo: '?',
      outDate: '?',
      outType: '?',
      outRemark: '?'
    }
  },
  removeoutCustomer_api: {
    url: `${ic}/mgr/customer/out/remove`,
    post: {
      id: '?'
    }
  }

}
