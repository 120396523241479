/*
 * @Author: your name
 * @Date: 2021-02-23 17:18:07
 * @LastEditTime: 2021-04-21 18:15:02
 * @LastEditors:
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\config\index.js
 */
const {
  URL_INFO
} = require('./url')

module.exports = {
  POST_URL: URL_INFO._localUrl
}
