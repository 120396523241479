<!--
 * @Author: your name
 * @Date: 2021-03-03 09:47:43
 * @LastEditTime: 2021-05-11 14:08:39
 * @LastEditors: Please set LastEditors
 * @Description: 用户管理
 * @FilePath: \ioac-tenant-html\src\components\sysset\usermgr.vue
-->
<template>
  <div id="components-form-demo-advanced-search">
    <a-form
      class="ant-advanced-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="8">
          <div class="gutter-box">
            <a-form-item label="员工角色">
              <a-select
                v-decorator="['role']"
                show-search
                placeholder="请选择用户角色"
                option-filter-prop="children"
                :filter-option="filterOption"
                @focus="handleFocus"
                @blur="handleBlur"
                @change="selRole"
              >
                <a-select-option
                  v-for="item in roleList"
                  :key="item.code"
                  :value="item.code"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="gutter-box">
            <a-form-item label="用户名">
              <a-input
                v-decorator="['realName']"
                placeholder="请输入用户名"
              />
            </a-form-item>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="gutter-box">
            <a-form-item label="员工手机">
              <a-input v-decorator="['mobile']" placeholder="请输入员工手机" />
            </a-form-item>
          </div>
        </a-col>
      </a-row>
      <a-row type="flex" justify="space-between">
        <a-col>
          <router-link :to="{ name: 'adduser' }">
            <a-button
              type="primary"
              html-type="submit"
              icon="user-add"
            >新增员工
            </a-button>
          </router-link>
        </a-col>
        <a-col>
          <a-button type="primary" html-type="submit"> 搜索 </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
            重置
          </a-button>
          <!-- <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
            折叠 <a-icon :type="expand ? 'up' : 'down'" />
          </a> -->
        </a-col>
      </a-row>
    </a-form>
    <div class="search-result-list">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        bordered
        @change="handleChange"
      >
        <span slot="headImg" slot-scope="text">
          <a-avatar shape="square" :src="text" />
        </span>
        <span slot="realName" slot-scope="text">
          <a-tag color="#2db7f5">{{ text }}</a-tag>
        </span>
        <span slot="roleModels" slot-scope="text, record">
          <a-tag v-for="item in record.roleModels" :key="item.id" color="blue">
            {{ item.name }}
          </a-tag>
        </span>
        <span slot="mobile" slot-scope="text">
          {{ text ? text : "---" }}
        </span>
        <span slot="userState" slot-scope="text">
          <a-tag :color="text == 1 ? 'cyan' : 'red'">{{
            text == 1 ? "正常" : text == 2 ? "停用" : "删除"
          }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <router-link :to="{ name: 'adduser', params: { id: record.id } }">
            <a-button type="link"> 编辑 </a-button>
          </router-link>
          <a-divider type="vertical" />
          <a-popconfirm
            title="是否确认停用该账号?"
            ok-text="确认"
            cancel-text="取消"
            @confirm="confirm(record.id, record.userState == 1 ? 2 : 1)"
          >
            <a-button type="link">
              {{ record.userState == 1 ? "停用" : "启用" }}
            </a-button>
          </a-popconfirm>
          <a-divider v-if="record.userState != 3" type="vertical" />
          <a-popconfirm
            v-if="record.userState != 3"
            title="是否确认删除该账号?"
            ok-text="确认"
            cancel-text="取消"
            @confirm="confirm(record.id, 3)"
          >
            <a-button type="link"> 删除 </a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
import {
  userallQuery_controller,
  changeState_controller,
  rolelist_controller
} from '../../static/controller/UserRole.controller'

const columns = [
  {
    title: '员工编号',
    dataIndex: 'id',
    key: 'id',
    width: 200
  },
  {
    title: '头像',
    dataIndex: 'headImg',
    key: 'headImg',
    scopedSlots: { customRender: 'headImg' }
  },
  {
    title: '用户名',
    dataIndex: 'userName',
    key: 'userName'
  },
  {
    title: '姓名',
    dataIndex: 'realName',
    key: 'realName'
  },
  {
    title: '角色',
    key: 'roleModels',
    dataIndex: 'roleModels',
    scopedSlots: { customRender: 'roleModels' }
  },
  {
    title: '手机',
    key: 'mobile',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    title: '描述',
    key: 'remark',
    dataIndex: 'remark'
  },
  {
    title: '状态',
    key: 'userState',
    dataIndex: 'userState',
    scopedSlots: { customRender: 'userState' }
  },
  {
    title: '操作',
    key: 'action',
    width: 250,
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  data() {
    return {
      expand: false,
      form: this.$form.createForm(this, { name: 'advanced_search' }),
      pagination: {}, // tab分页
      data: [],
      pageIndex: 1, // 当前页码
      pageSize: 10,
      columns,
      roleList: [],
      where: {}
    }
  },
  computed: {
    count() {
      return this.expand ? 11 : 7
    }
  },
  mounted() {
    this.initUserInfo()
    this.initRoleList()
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.form.validateFields((error, values) => {
        console.log(error)
        this.pageIndex = 1
        this.where = {
          roleCode: values.role,
          mobile: values.mobile, // 手机号
          nickName: values.realName // 昵称
        }
        this.initUserInfo()
      })
    },
    initUserInfo() {
      userallQuery_controller({
        ...this.where,
        page: this.pageIndex, // 页码
        rows: this.pageSize // 每页记录数
      }).then((res) => {
        console.log('当前用户：', res)
        this.data = res ? res.records : []
        this.pagination = {
          pageSize: this.pageSize,
          total: res ? res.total : 0,
          showTotal: () => `共 ${res.total} 条`,
          showSizeChanger: true,
          current: res ? res.current : 1
        }
      })
    },
    initRoleList() {
      rolelist_controller().then((res) => {
        this.roleList = res
      })
    },
    handleReset() {
      this.form.resetFields()
    },
    handleBlur() {
      console.log('blur')
    },
    handleFocus() {
      console.log('focus')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    // 确认停用或者删除
    confirm(id, state) {
      // 展示全局loading
      changeState_controller({
        id: id, // 用户id
        state: state // 1：正常，2：冻结 ，3：删除
      }).then((res) => {
        // 弹窗
        this.$message.success(
          state === 1 ? '启用成功！' : state === 2 ? '停用成功！' : '删除成功！'
        )
        // 重新加载
        this.initUserInfo()
      })
    },
    handleChange(e) {
      this.pageSize = e.pageSize
      this.pageIndex = e.current
      this.initUserInfo()
    },
    selRole(e) {
      console.log('选择角色：', e)
    }
    // toggle() {
    //   this.expand = !this.expand
    // }
  }
}
</script>
<style>

</style>
