/*
 * @Description:
 * @Author:
 * @Date: 2021-03-04 17:24:26
 * @LastEditTime: 2021-03-04 17:25:29
 * @LastEditors:
 */

let {
  getBizTypCodeApp_service
} = require('../service/BizTypCodeApp.service')

module.exports = {
  getBizTypCodeApp_controller(val) {
    return getBizTypCodeApp_service(val)
  }
}
