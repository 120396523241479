<template>
  <table style="width: 100%">
    <tr v-for="(item, index) in templateMetaDict" :key="item.id">
      <td style="width: 70%">
        <a-checkbox v-model="keys[index]" @change="changeInput">
          {{ item.metaAttributeZhname }}
        </a-checkbox>
      </td>
      <td style="30%">
        <a-input
          v-model="values[index]"
          class="count-input"
          placeholder="请输入数量"
          @change="changeInput"
        />
      </td>
    </tr>
  </table>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      values: [],
      keys: []
    }
  },
  computed: {
    ...mapState({
      templateMetaDict: (state) => state.subscription.templateMetaDict
    })
  },
  methods: {
    /**
     * 输入修改时事件
     */
    changeInput(e) {
      this.$emit('change', this.$data)
    },
    /**
     * 验证规则
     */
    validate(rule, value, callback) {
      const msg = '规则输入不正确:'
      if (!this.validate_empty()) { // 非空验证
        return callback(new Error(msg + '至少输入一条规则'))
      } else if (!this.validate_mismatching()) { // 不匹配验证
        return callback(new Error(msg + '规则和数量必须同时存在'))
      } else if (!this.validate_scope()) { // 数量范围验证
        return callback(new Error(msg + '数量必须大于0或者等于-1(-1代表无限制)'))
      }
      callback()
    },
    /**
     * 验证规则-输入非空:true-不为空,false-为空
     */
    validate_empty() {
      let result = true
      if (this.values.length === 0 && this.keys.length === 0) {
        result = false
      } else {
        let count = 0 // 空项计数器
        for (let i = 0; i < this.templateMetaDict.length; i++) {
          if (!this.values[i] && !this.keys[i]) {
            count++
          }
        }
        if (count === this.templateMetaDict.length) {
          result = false
        }
      }
      return result
    },
    /**
     * 验证规则-输入不匹配:true-全部匹配,false-不匹配
     */
    validate_mismatching() {
      let result = true
      for (let i = 0; i < this.templateMetaDict.length; i++) {
        if (!this.values[i] && this.keys[i]) {
          result = false
        } else if (!this.keys[i] && this.values[i]) {
          result = false
        }
      }
      return result
    },
    /**
     * 验证规则-限制值必须大于0或者等于-1:true-全部匹配,false-不匹配
     */
    validate_scope() {
      let result = true
      for (let i = 0; i < this.templateMetaDict.length; i++) {
        if (this.values[i] && (parseInt(this.values[i]) <= 0 && parseInt(this.values[i]) !== -1)) {
          console.log(i, parseInt(this.values[i]) < 0)
          result = false
        }
      }
      return result
    },
    /**
     * 数据保存前的格式化
     */
    format() {
      let result = []
      for (let i = 0; i < this.templateMetaDict.length; i++) {
        if (this.keys[i] && this.values[i]) {
          result.push({
            metaAttributeDictId: this.templateMetaDict[i].id,
            metaLimitValue: this.values[i]
          })
        }
      }
      return result
    },

    /**
     * 清除表单数据
     */
    resetForm() {
      this.values = []
      this.keys = []
    }
  }
}
</script>

<style>
</style>
