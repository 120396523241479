<template>
  <div class="view-scroll">
    <div class="m-box col m-mt-10 box">
      <a-form
        :form="form"
        :label-col="{ span: 0 }"
        class="fff"
        :wrapper-col="{ span: 22 }"
        @submit="handleSearch"
      >
        <a-row type="flex">
          <a-col :span="4">
            <a-form-item label="">
              <a-input v-decorator="['organName']" placeholder="请输入机构名" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-input
                v-decorator="['templateName']"
                placeholder="请输入套餐名"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-input
                v-decorator="['userKeyword']"
                placeholder="请输入个人用户手机号或姓名"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :span="3" class="ant-form-item">
            <a-button type="primary" class="m-mt-4" html-type="submit">
              查询
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="m-box m-mt-10">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :scroll="{ x: 2400 }"
      >
        <div slot="templateType" slot-scope="row">
          <span>{{ row === "0" ? "通用套餐" : "机构专属套餐" }}</span>
        </div>
        <div slot="action" slot-scope="record" class="text-3d9">
          <a-button
            type="primary"
            class="m-ml-10"
            @click="detal(record)"
          >订阅详情</a-button>
        </div>
      </a-table>

      <Pagination
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        :total="total"
        class="paginationStyle"
        @pagination="getPages"
      />
    </div>
  </div>
</template>

<script>
import Base from '@/components/subscription/Base'
import Pagination from '@/components/Pagination'
import { getSubscriptionDetailLists_controller } from '../../static/controller/Subscription.controller'

export default {
  components: {
    Pagination
  },
  extends: Base,
  data() {
    return {
      columns: [
        {
          title: '订阅机构',
          dataIndex: 'organName'
        },
        {
          title: '个人账户',
          dataIndex: 'userPhone',
          customRender: (text, record) => {
            return record.userPhone ? record.userPhone : '无用户绑定'
          }
        },
        {
          title: '个人用户姓名',
          dataIndex: 'userName',
          customRender: (text, record) => {
            return record.userName ? record.userName : '无用户绑定'
          }
        },
        {
          title: '个人用户状态',
          dataIndex: 'deleted',
          customRender: (text, record) => {
            console.log('@', record.deleted)
            if (record.deleted === '') {
              return '无用户绑定'
            } else if (record.deleted === '0') {
              return '绑定中'
            } else if (record.deleted === '1') {
              return '已解绑'
            }
          }
        },
        {
          title: '绑定订阅',
          dataIndex: 'subscriptName'
        },
        {
          title: '订阅状态',
          dataIndex: 'a5',
          customRender: (text, row, index) => {
            const start = new Date(row.startDate).getTime()
            const end = new Date(row.endDate).getTime()
            const now = new Date().getTime()
            if (start > now) {
              return '待生效'
            } else if (end < now) {
              return '已失效'
            } else {
              return '生效中'
            }
          }
        },
        {
          title: '订阅服务周期',
          dataIndex: 'a6',
          customRender: (text, record) => {
            return record.startDate + ' 至 ' + record.endDate
          }
        },
        {
          title: '视频医生次数',
          dataIndex: 'a7',
          customRender: (text, record) => {
            return record.videoInquiryCount
          }
        },
        {
          title: '电话问诊次数',
          dataIndex: 'a8',
          customRender: (text, record) => {
            return record.phoneInquiryCount
          }
        },
        {
          title: '养老顾问次数',
          dataIndex: 'a9',
          customRender: (text, record) => {
            return record.commuciationCount
          }
        },
        {
          title: '血压测量次数',
          dataIndex: 'bloodPressure'
        },
        {
          title: '心电测量次数',
          dataIndex: 'ecg'
        },
        {
          title: '血氧测量次数',
          dataIndex: 'bloodOxygen'
        },
        {
          title: '总胆测量次数',
          dataIndex: 'cholesterol'
        },
        {
          title: '血糖测量次数',
          dataIndex: 'bloodSugar'
        },
        {
          title: '尿酸测量次数',
          dataIndex: 'uricacid'
        },
        {
          title: '体温测量次数',
          dataIndex: 'temperature'
        },
        {
          title: '腰臀测量次数',
          dataIndex: 'whr'
        },
        {
          title: '总次数',
          dataIndex: 'total'
        }
      ],
      form: this.$form.createForm(this)
    }
  },
  mounted() {
    // 请求画面数据
    this.getList()
  },
  methods: {
    /**
     * 请求画面数据
     */
    getList() {
      getSubscriptionDetailLists_controller({
        page: this.pageIndex,
        rows: this.pageSize,
        organName: this.form.getFieldValue('organName'),
        templateName: this.form.getFieldValue('templateName'),
        userKeyword: this.form.getFieldValue('userKeyword')
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    }
  }
}
</script>

<style>
</style>
