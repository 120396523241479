var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-page-header',{attrs:{"title":"角色维护","sub-title":"添加角色、编辑、查看"},on:{"back":() => _vm.$router.go(-1)}}),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 2 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-page-header',{attrs:{"sub-title":"基础信息"}})],1),_c('a-form-item',{attrs:{"label":"角色编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'roleCode',
          {
            rules: [{ required: true, message: '请输入角色编号！' }],
            initialValue: _vm.initValue.roleCode,
          },
        ]),expression:"[\n          'roleCode',\n          {\n            rules: [{ required: true, message: '请输入角色编号！' }],\n            initialValue: initValue.roleCode,\n          },\n        ]"}],staticStyle:{"width":"300px"},attrs:{"placeholder":"请输入角色编号"}})],1),_c('a-form-item',{attrs:{"label":"角色名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'roleName',
          {
            rules: [{ required: true, message: '请输入角色名称！' }],
            initialValue: _vm.initValue.roleName,
          },
        ]),expression:"[\n          'roleName',\n          {\n            rules: [{ required: true, message: '请输入角色名称！' }],\n            initialValue: initValue.roleName,\n          },\n        ]"}],staticStyle:{"width":"300px"},attrs:{"placeholder":"请输入角色名称"}})],1),_c('a-form-item',{attrs:{"label":"角色描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'roleDes',
          {
            rules: [{ required: true, message: '请输入角色描述' }],
            initialValue: _vm.initValue.roleDes,
          },
        ]),expression:"[\n          'roleDes',\n          {\n            rules: [{ required: true, message: '请输入角色描述' }],\n            initialValue: initValue.roleDes,\n          },\n        ]"}],staticStyle:{"width":"300px"},attrs:{"placeholder":"请输入角色描述"}})],1),_c('a-form-item',[_c('a-page-header',{attrs:{"sub-title":"更多信息"}})],1),_c('a-form-item',{attrs:{"label":"分配模块"}},[(_vm.treeInfo && _vm.transferDataSource.length > 0)?_c('a-transfer',{staticClass:"tree-transfer",attrs:{"data-source":_vm.transferDataSource,"show-select-all":false,"target-keys":_vm.targetKeys,"render":(item) => item.title,"list-style":{
          width: '300px',
          height: '300px',
        }},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"children",fn:function({
            props: { direction, selectedKeys },
            on: { itemSelect },
          }){return [(direction === 'left')?_c('a-tree',{attrs:{"block-node":"","checkable":"","check-strictly":"","default-expand-all":"","checked-keys":[...selectedKeys, ..._vm.targetKeys],"tree-data":_vm.treeData},on:{"check":(_, props) => {
                _vm.onChecked(
                  _,
                  props,
                  [...selectedKeys, ..._vm.targetKeys],
                  itemSelect
                );
              },"select":(_, props) => {
                _vm.onChecked(
                  _,
                  props,
                  [...selectedKeys, ..._vm.targetKeys],
                  itemSelect
                );
              }}}):_vm._e()]}}],null,false,3773541682)}):_vm._e()],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 2 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 保存 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }