/*
 * @Author: your name
 * @Date: 2021-04-08 16:35:50
 * @LastEditTime: 2021-06-11 17:22:27
 * @LastEditors:
 * @Description: 客户外出
 * @FilePath: \ioac-tenant-html\src\static\api\ApplyGoOutMgr.api.js
 */
let {
  sc
} = require('../config/api_spec')

module.exports = {
  // 类别列表查询(无分页)
  listsubsidy_api: {
    url: `${sc}/mgr/subsidy/type/list`,
    method: 'post',
    post: {
      subsidyMoney: '?',
      subsidyType: '?'
    }
  },
  // 批量申请
  batchApply_api: {
    url: `${sc}/mgr/subsidy/apply/batch/apply`,
    method: 'post',
    post: {
      applyDataList: []
    }
  },
  // 申请列表分页查询
  queryApply_api: {
    url: `${sc}/mgr/subsidy/apply/query`,
    method: 'post',
    post: {
      applicantIdCard: '?',
      rows: '?',
      page: '?',
      subsidyType: '?',
      applicantName: '?'
    }
  }
}
