/*
 * @Description:
 * @Author:
 * @Date: 2021-03-26 11:56:06
 * @LastEditTime: 2021-04-09 10:13:17
 * @LastEditors: Please set LastEditors
 */
let {
  ic
} = require('../config/api_spec')
module.exports = {
  // 提交告知书
  submitGradeBill_api: {
    url: `${ic}/mgr/customer/archives/submitGradeBill`,
    post: {
      customerGrade: '?',
      customerName: '?',
      customerRegisterNo: '?',
      userIds: '?',
      type: '?',
      inoutOpinion: '?'
    }
  },
  getBillList_api: {
    url: `${ic}/mgr/customer/archives/getBillList`,
    post: {
      customerRegisterNo: '?'
    }
  },
  // 获取签字情况
  checkInfoByRegisterId_api: {
    url: `${ic}/mgr/customer/archives/checkInfoByRegisterId`,
    post: {
      customerRegisterNo: '?',
      type: '?'
    }
  },
  // 获取档案信息
  getBillDetail_api: {
    url: `${ic}/mgr/customer/archives/getBillDetail`,
    post: {
      customerRegisterNo: '?',
      type: '?'
    }
  },
  // mgr/customer/archives/list
  getArchivesList_api: {
    url: `${ic}/mgr/customer/archives/list`,
    post: {
      nameIdenti: '?',
      page: '?',
      rows: '?'
    }
  }
}
