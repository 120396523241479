/*
 * @Description:
 * @Author:
 * @Date: 2021-03-04 15:55:53
 * @LastEditTime: 2021-04-14 19:39:01
 * @LastEditors:
 */
let {
  addUser_service,
  userList_service,
  userList_service2,
  userDetail_service,
  updUser_service,
  teamList_service,
  addTeam_service,
  teamDetail_service,
  updTeam_service,
  scheduleList_service,
  addSchedule_service,
  updSchedule_service,
  scheduleDetail_service,
  addNursing_service,
  nursingList_service,
  nursingDetail_service,
  updNursing_service,
  workList_service,
  frequencyList_service,
  updFrequency_service,
  addFrequency_service,
  frequencyDetail_service,
  batchAddWork_service,
  addTemplate_service,
  templeatDetail_service,
  updTemplate_service,
  templeatList_service,
  delTemplate_service,
  planList_service,
  addPlan_service,
  delPlan_service,
  taskList_service,
  recordList_service,
  planTaskList_service,
  addTask_service,
  taskDetailList_service,
  updPlan_service,
  bedCard_service,
  delUser_service,
  delTeam_service,
  delSchedule_service,
  updBreakup_service,
  delNursing_service,
  userList_service3,
  taskList_service2
} = require('../service/Nursing.service')

module.exports = {
  addUser_controller(val) {
    return addUser_service(val)
  },

  userList_controller(val) {
    return userList_service(val)
  },

  userList_controller2(val) {
    return userList_service2(val)
  },

  userDetail_controller(val) {
    return userDetail_service(val)
  },

  updUser_controller(val) {
    return updUser_service(val)
  },

  teamList_controller(val) {
    return teamList_service(val)
  },

  addTeam_controller(val) {
    return addTeam_service(val)
  },

  teamDetail_controller(val) {
    return teamDetail_service(val)
  },

  updTeam_controller(val) {
    return updTeam_service(val)
  },

  scheduleList_controller(val) {
    return scheduleList_service(val)
  },

  addSchedule_controller(val) {
    return addSchedule_service(val)
  },

  updSchedule_controller(val) {
    return updSchedule_service(val)
  },

  scheduleDetail_controller(val) {
    return scheduleDetail_service(val)
  },

  addNursing_controller(val) {
    return addNursing_service(val)
  },

  nursingList_controller(val) {
    return nursingList_service(val)
  },

  nursingDetail_controller(val) {
    return nursingDetail_service(val)
  },

  updNursing_controller(val) {
    return updNursing_service(val)
  },

  workList_controller(val) {
    return workList_service(val)
  },

  frequencyList_controller(val) {
    return frequencyList_service(val)
  },

  addFrequency_controller(val) {
    return addFrequency_service(val)
  },

  updFrequency_controller(val) {
    return updFrequency_service(val)
  },

  frequencyDetail_controller(val) {
    return frequencyDetail_service(val)
  },

  batchAddWork_controller(val) {
    return batchAddWork_service(val)
  },

  addTemplate_controller(val) {
    return addTemplate_service(val)
  },

  updTemplate_controller(val) {
    return updTemplate_service(val)
  },

  templeatDetail_controller(val) {
    return templeatDetail_service(val)
  },

  templeatList_controller(val) {
    return templeatList_service(val)
  },

  delTemplate_controller(val) {
    return delTemplate_service(val)
  },

  planList_controller(val) {
    return planList_service(val)
  },

  addPlan_controller(val) {
    return addPlan_service(val)
  },

  delPlan_controller(val) {
    return delPlan_service(val)
  },

  taskList_controller(val) {
    return taskList_service(val)
  },

  recordList_controller(val) {
    return recordList_service(val)
  },

  planTaskList_controller(val) {
    return planTaskList_service(val)
  },

  addTask_controller(val) {
    return addTask_service(val)
  },

  taskDetailList_controller(val) {
    return taskDetailList_service(val)
  },

  updPlan_controller(val) {
    return updPlan_service(val)
  },

  bedCard_controller(val) {
    return bedCard_service(val)
  },

  delUser_controller(val) {
    return delUser_service(val)
  },

  delTeam_controller(val) {
    return delTeam_service(val)
  },

  delSchedule_controller(val) {
    return delSchedule_service(val)
  },

  updBreakup_controller(val) {
    return updBreakup_service(val)
  },

  delNursing_controller(val) {
    return delNursing_service(val)
  },

  userList_controller3(val) {
    return userList_service3(val)
  },

  taskList_controller2(val) {
    return taskList_service2(val)
  }
}
