/*
 * @Author: your name
 * @Date: 2021-05-25 17:09:23
 * @LastEditTime: 2021-05-25 17:10:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\TenantDynamicPageConfigMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  add2edit_api,
  queryDynamicPage_api
} = require('../api/TenantDynamicPageConfigMgr.api')

module.exports = {
  add2edit_service(val) {
    return xhr(add2edit_api, val)
  },
  queryDynamicPage_service(val) {
    return xhr(queryDynamicPage_api, val)
  }
}
