/*
 * @Author: your name
 * @Date: 2021-03-03 15:43:25
 * @LastEditTime: 2021-06-28 15:53:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\RoleMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  rolelist_api,
  roleSave_api,
  roleSaveByApp_api,
  roleDelete_api,
  getRole_api,
  updateByApp_api,
  roleUpdate_api
} = require('../api/RoleMgr.api')

module.exports = {
  rolelist_service() {
    return xhr(rolelist_api, {})
  },
  roleSave_service(val) {
    return xhr(roleSave_api, val)
  },
  roleUpdate_service(val) {
    return xhr(roleUpdate_api, val)
  },
  roleSaveByApp_service(val) {
    return xhr(roleSaveByApp_api, val)
  },
  roleDelete_service(val) {
    return xhr(roleDelete_api, val)
  },
  getRole_service(val) {
    return xhr(getRole_api, val)
  },
  updateByApp_service(val) {
    return xhr(updateByApp_api, val)
  }
}
