/*
 * @Author: your name
 * @Date: 2021-06-10 19:27:41
 * @LastEditTime: 2021-06-10 19:28:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\returnvisitrecord.controller.js
 */
let {
  queryVisitRecord_service
} = require('../service/VisitRecordMgr.service')

module.exports = {
  queryVisitRecord_controller(val) {
    return queryVisitRecord_service(val)
  }
}
