/*
 * @Description:
 * @Author:
 * @Date: 2021-04-02 17:53:23
 * @LastEditTime: 2021-04-29 17:29:10
 * @LastEditors:
 */
// import Vue from 'vue'
import router from './router'
// import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  if (!localStorage.getItem('token')) {
    return next(`/login?redirect=${to.path}`)
  }
  next()
})

router.afterEach((to, from, next) => {
  NProgress.done()
})

