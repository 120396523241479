<!--
 * @Description:
 * @Author:
 * @Date: 2021-03-25 10:16:56
 * @LastEditTime: 2021-04-30 11:55:52
 * @LastEditors:
-->
<template>
  <div>
    <div style="padding:10px 24px">
      <p class="formTitle">长者信息</p>
      <a-descriptions
        :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
      >
        <a-descriptions-item label="姓名">
          {{ elderIfo.name }}
        </a-descriptions-item>
        <a-descriptions-item label="性别">
          {{ elderIfo.sex==='0'?'未知':elderIfo.sex==='1'?'男':'女' }}

        </a-descriptions-item>
        <a-descriptions-item label="出生年月">
          {{ elderIfo.birthday }}
        </a-descriptions-item>
        <a-descriptions-item label="身份证号">
          {{ elderIfo.idNo }}
        </a-descriptions-item>
        <a-descriptions-item label="联系电话">
          {{ elderIfo.myTel }}
        </a-descriptions-item>
        <a-descriptions-item label="婚姻状态">
          {{ marital_state }}
        </a-descriptions-item>
        <a-descriptions-item label="文化状态">
          {{ education_degree }}
        </a-descriptions-item>
        <a-descriptions-item label="原职业">
          {{ old_job }}
        </a-descriptions-item>

        <a-descriptions-item label="户籍详细地址">
          {{ elderIfo.adminProvinceName+elderIfo.adminCityName+elderIfo.adminHotelName+elderIfo.homeAddr }}
        </a-descriptions-item>
      </a-descriptions>
      <a-divider />
      <a-descriptions
        :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
      >
        <a-descriptions-item label="担保人姓名">
          {{ data.name }}
        </a-descriptions-item>
        <a-descriptions-item label="联系电话">
          {{ data.mobile }}
        </a-descriptions-item>
        <a-descriptions-item label="联系地址">
          {{ data.homeAddr }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>

<script>
import { detailComeCustomerMgr_controller } from '../../static/controller/ComeCustomerMgr.controller'
import { getLabel } from '../../static/enum/index'
const columns = [
  {
    title: '称谓',
    dataIndex: 'relType'
  },
  {
    title: '姓名',
    className: 'name',
    dataIndex: 'name'
  },
  {
    title: '性别',
    dataIndex: 'sex',
    scopedSlots: { customRender: 'sex' }
  },
  {
    title: '身份证号',
    dataIndex: 'idNo'
  },
  {
    title: '手机号',
    dataIndex: 'mobile'
  },
  {
    title: '户籍地区',
    dataIndex: 'registerAreaNames',
    scopedSlots: { customRender: 'areanames' }
  },
  {
    title: '户籍详细地址',
    key: 'homeAddr',
    dataIndex: 'homeAddr'
  }

]
export default {
  data() {
    return {
      elderIfo: {},
      familyList: [],
      comeReason: '',
      remark: '',
      columns,
      data: {},
      nation: [],
      job: [],
      nationName: '',
      selectData: {},
      marital_state: '',
      education_degree: '',
      old_job: ''
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      detailComeCustomerMgr_controller({ id: this.$route.params.id }).then(async(res) => {
        this.elderIfo = res.customerModel
        this.comeReason = res.comeReason
        this.remark = res.remark
        let familyList = res.comeCustomerFamilyModelList
        if (familyList.length === 1) {
          this.data = familyList[0]
        } else {
          familyList.map((item) => {
            if (item.type === '1') {
              this.data = item
            }
          })
        }
        this.marital_state = await getLabel('marital_state', String(this.elderIfo.maritalState))
        this.old_job = await getLabel('job', String(this.elderIfo.oldJob))
        this.education_degree = await getLabel('education_degree', String(this.elderIfo.educationDegree))
      })
    }
  }

}
</script>

<style>
.ant-descriptions-row > td{
    padding-bottom: 35px;
}

</style>
