/*
 * @Author: your name
 * @Date: 2021-03-04 15:00:59
 * @LastEditTime: 2021-03-04 15:01:59
 * @LastEditors: Please set LastEditors
 * @Description: 图片上传
 * @FilePath: \ioac-tenant-html\src\static\controller\UploadImg.controller.js
 */
let {
  getAliyunOssSign_service
} = require('../service/AliYunOssWeb.service')

module.exports = {
  getAliyunOssSign_controller(val) {
    return getAliyunOssSign_service(val)
  }
}
