<!--
 * @Author: your name
 * @Date: 2021-02-23 17:18:07
 * @LastEditTime: 2021-06-18 17:55:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\App.vue
-->
<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>

    <!-- <div v-if="total > 0" class="hover-box" @click="goList">
      <img class="warning-img" src="./assets/img/warning.png">
      <div>预警{{ total }}项</div>
    </div> -->
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import {
  getMenu_controller
} from './static/controller/Home.controller'
import {
  setRouter
} from './static/utils/auth/router'

export default {
  data() {
    return {
      locale: zhCN,
      total: 0
      // moment
    }
  },
  mounted() {
    getMenu_controller(
      {
        showPlatform: '1'
      }
    ).then((res) => {
      // 获取当前用户的菜单权限
      this.menuList = res
      console.log('获取：：', res)
      // 把当前菜单权限加入路由
      setRouter(this.$router, res)
    })
  },
  methods: {

    goList() {
      this.$router.push({
        path: '/warning/index'
      })
    }
    // handleScroll() {
    //   document.getElementsByTagName('a')[0].focus()
    //   console.log('进入：：', document.getElementsByClassName('ant-select')[0])
    // }
  }
}
</script>
<style>
	.ant-advanced-search-form {
		padding: 24px;
		background: #fafafa;
		border: 1px solid #e8e8e8;
		/* border-radius: 6px; */
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	#components-form-demo-advanced-search .ant-form {
		max-width: none;
	}

	#components-form-demo-advanced-search .search-result-list {
		margin-top: 16px;
		/* border: 1px dashed #e9e9e9; */
		/* border-radius: 6px; */
		/* background-color: #fafafa; */
		min-height: 200px;
		text-align: center;
		/* padding: 10px; */
		box-sizing: border-box;
	}

	.gutter-box {
		padding: 0 8px;
	}

	.row {
		display: flex;
		flex-direction: row;
	}

	.column {
		display: flex;
		flex-direction: column;
	}

	.col {
		flex: 1;
	}

	.wrap {
		flex-wrap: wrap;
	}

	.justify-start {
		justify-content: flex-start;
	}

	.justify-center {
		justify-content: center;
	}

	.justify-end {
		justify-content: flex-end;
	}

	.justify-around {
		justify-content: space-around;
	}

	.justify-between {
		justify-content: space-between;
	}

	.items-start {
		align-items: flex-start;
	}

	.items-center {
		align-items: center;
	}

	.items-end {
		align-items: flex-end;
	}

	.wrap {
		flex-wrap: wrap;
	}

	.hover-box {
		position: fixed;
		right: 0;
		bottom: 0;
		width: 100px;
		height: 130px;
		background-color: #ffd7d8;
		text-align: center;
		cursor: pointer;
		border-radius: 20px 20px 0 0 ;
		color: #ff0a0e;

	}

	.warning-img {
		width: 60px;
		height: 60px;
		margin-top: 20px;
		margin-bottom: 10px;
	}
</style>
