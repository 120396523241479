<!--
 * @Description:
 * @Author:
 * @Date: 2021-03-18 09:18:27
 * @LastEditTime: 2021-05-12 10:04:22
 * @LastEditors:
-->
<template>
  <div class="column view-scroll">
    <div class="col column">
      <a-form :form="form" :label-col="{ span: 4 }" class="fff" :wrapper-col="{ span: 17 }">
        <div class="row items-center">
          <div style="margin: 0 30px;">
            温江区
          </div>
          <div style="width: 250px;">
            <a-form-item label="街道">
              <a-select default-value="全部街道">
                <a-select-option value="全部街道">
                  全部街道
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>

          <div style="width: 250px;">
            <a-form-item label="时间">
              <a-select default-value="2021年度">
                <a-select-option value="2021年度">
                  2021年度
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>

          <div class="col m-mr-30" style="text-align: right;">
            <a-button type="primary" html-type="submit"> 搜索 </a-button>
            <a-button :style="{ marginLeft: '8px' }">
              重置
            </a-button>
          </div>
        </div>
      </a-form>
      <div class="m-mt-10 col row">
        <div class="col row m-mr-10">
          <div class="col m-mr-10 column">
            <div class="col m-box column justify-between">
              <div class="size-16 text-weight-bold relative">长者总量
                <img class="index-img" src="../assets/image/index1.png">
              </div>
              <div class="size-30">100,000</div>
            </div>
            <div class="col m-box column justify-between m-mt-10">
              <div class="size-16 text-weight-bold relative">服务商数量
                <img class="index-img" src="../assets/image/index2.png">
              </div>
              <div class="size-30">100,000</div>
            </div>
          </div>
          <div class="col column">
            <div class="col m-box column justify-between">
              <div class="size-16 text-weight-bold relative">志愿者数量
                <img class="index-img" src="../assets/image/index3.png">
              </div>
              <div class="size-30">100,000</div>
            </div>
            <div class="col m-box column justify-between m-mt-10">
              <div class="size-16 text-weight-bold relative">老年社工数量
                <img class="index-img" src="../assets/image/index4.png">
              </div>
              <div class="size-30">100,000</div>
            </div>
          </div>
        </div>
        <div class="m-box col column">
          <div class="size-16 text-weight-bold">服务人员对比</div>
          <div class="col relative">
            <div id="myCharts" class="charts" />
          </div>
        </div>
      </div>
    </div>

    <div class="m-box m-mt-10 col column" style="overflow: hidden;">
      <div class="size-16 text-weight-bold">服务管理对比</div>
      <div class="col relative">
        <div id="myCharts2" class="charts" />
      </div>
    </div>
  </div>
</template>
<script>
var echarts = require('echarts')

export default {
  components: { // 组件
  },
  data() {
    return {
      colors: ['#5B8FF9', '#7AE0B7', '#FF8B33', '#FF7171'],
      form: this.$form.createForm(this, {
        name: 'evalutaion'
      })
    }
  },

  mounted() {
    // this.myEcharts()
    // this.myEcharts2()
  },
  methods: {
    myEcharts() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('myCharts'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          top: '40%',
          left: '10%'
        },
        series: [{
          type: 'pie',
          radius: '50%',
          data: [{
            value: 1048,
            name: '志愿者'
          },
          {
            value: 735,
            name: '工作人员'
          }
          ],
          itemStyle: {
            normal: {
              color: (params) => {
                return this.colors[params.dataIndex]
              }
            }
          }
        }]
      })
    },

    myEcharts2() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('myCharts2'))
      // 绘制图表
      myChart.setOption({
        title: {
          subtext: '单位：万',
          subtextStyle: {}
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['服务订单总量', '完成服务订单总量'],
          textStyle: {},
          right: '10%'
        },
        axisLabel: {
          textStyle: {}
        },
        xAxis: {
          type: 'category',
          // 设置坐标轴字体颜色和宽度
          axisLine: {
            lineStyle: {}
          },
          data: ['1月', '2月', '3月', '4月', '5月', '6月']
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          axisLine: {
            lineStyle: {}
          }
        },
        series: [{
          name: '服务订单总量',
          type: 'bar',
          barWidth: 20, // 柱图宽度
          color: ['#5B8FF9'],
          data: [15, 5, 9, 20, 6, 15]
        },
        {
          name: '完成服务订单总量',
          type: 'bar',
          barWidth: 20, // 柱图宽度
          color: ['#73DEB3'],
          data: [22, 23, 15, 30, 12, 30]
        }
        ]
      })
    }

  }
}
</script>
<style scoped lang="less">
	.index-img {
		width: 42px;
		height: 42px;
		position: absolute;
		top: 0;
		right: 0;
	}

	.ant-form-item {
		margin: 10px 0;
	}

	.ant-row {
		display: flex;
		align-items: center;
	}
</style>
