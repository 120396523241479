/*
 * @Author: your name
 * @Date: 2021-03-04 14:57:31
 * @LastEditTime: 2021-03-04 14:59:48
 * @LastEditors: Please set LastEditors
 * @Description: 图片上传服务
 * @FilePath: \ioac-tenant-html\src\static\service\AliYunOssWeb.service.js
 */
const {
  xhr
} = require('../lib/xhr')

const {
  getAliyunOssSign_api
} = require('../api/AliYunOssWeb.api')

module.exports = {
  getAliyunOssSign_service(val) {
    return xhr(getAliyunOssSign_api, val)
  }
}
