/*
 * @Description:
 * @Author:
 * @Date: 2021-02-23 17:30:23
 * @LastEditTime: 2021-06-30 09:36:28
 * @LastEditors:
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  //   {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  //   redirect: '/index',
  //   children: [{
  //     path: '/index',
  //     name: 'Index',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue')
  //   }, {
  //     path: '/result/403',
  //     name: '403',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/result/403.vue')
  //   }, {
  //     path: '/result/500',
  //     name: '500',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/result/500.vue')
  //   }, {
  //     path: '/hello',
  //     name: 'hello',
  //     component: () => import(/* webpackChunkName: "about" */ '../components/HelloWorld.vue')
  //   }]
  // },
  // {
  //   path: '/health',
  //   name: 'health',
  //   component: Home,
  //   redirect: '/health/index',
  //   meta: {
  //     title: '健康管理',
  //     icon: 'home'
  //   },
  //   children: [{
  //     path: 'index',
  //     name: 'healthIndex',
  //     meta: {
  //       title: '健康管理',
  //       icon: 'home'
  //     },
  //     component: () => import('../views/health/index.vue')
  //   },
  //   {
  //     path: 'index2',
  //     name: 'healthIndex2',
  //     meta: {
  //       title: '健康管1理',
  //       icon: 'home'
  //     },
  //     component: () => import('../views/Index.vue')
  //   }
  //   ]

  // },

  // {
  //   path: '/admission',
  //   name: 'Admission',
  //   component: Home,
  //   redirect: '/admission/index',
  //   meta: {
  //     title: '入住管理',
  //     icon: 'home'
  //   },
  //   children: [{
  //     path: 'index',
  //     name: 'admissionIndex',
  //     meta: {
  //       title: '入住咨询',
  //       icon: 'home'
  //     },
  //     component: () => import('../views/admission/List.vue')
  //   },
  //   {
  //     path: 'checkin',
  //     name: 'checkIndex',
  //     meta: {
  //       title: '入住登记',
  //       icon: 'home'
  //     },
  //     component: () => import('../views/admission/checkinList.vue')
  //   },
  //   {
  //     path: 'viewconsult/:id',
  //     name: 'viewConsult',
  //     meta: {
  //       title: '咨询详情',
  //       icon: 'home'
  //     },
  //     component: () => import('../views/admission/viewconsult.vue')
  //   },
  //   {
  //     path: 'addcheck/:id?/:tid?',
  //     name: 'addCheck',
  //     meta: {
  //       title: '新建登记',
  //       icon: 'home'
  //     },
  //     component: () => import('../views/admission/addcheck.vue')
  //   },
  //   {
  //     path: 'viewcheck/:id',
  //     name: 'viewcheck',
  //     meta: {
  //       title: '登记详情',
  //       icon: 'home'
  //     },
  //     component: () => import('../views/admission/viewcheckin.vue')
  //   }
  //   ]
  // },
  // {
  //   path: '/evalutaion',
  //   name: 'evalutaion',
  //   component: Home,
  //   children: [{
  //     path: 'index',
  //     name: 'evalutaionIndex',
  //     meta: {
  //       title: '评估列表'
  //     },
  //     component: () => import(/* webpackChunkName: "about" */ '../views/evaluation/List.vue')
  //   },
  //   {
  //     path: 'addevalute',
  //     name: 'addEvalute',
  //     meta: {
  //       title: '添加评估'
  //     },

  //     component: () => import(/* webpackChunkName: "about" */ '../views/evaluation/addevalute.vue')
  //   },
  //   {
  //     path: 'previewevate',
  //     name: 'previewEvate',
  //     meta: {
  //       title: '查看评估'
  //     },

  //     component: () => import(/* webpackChunkName: "about" */ '../views/evaluation/preview.vue')
  //   }
  //   ]
  // }
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: Home,
  //   redirect: '/home/index',
  //   children: [{
  //     path: 'index',
  //     name: 'homeIndex',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/home/home.vue')
  //   }]
  // },
  // {
  //   path: '/food',
  //   name: 'Food',
  //   component: Home,
  //   redirect: '/food/index',
  //   children: [{
  //     path: 'index',
  //     name: 'foodIndex',
  //     component: () => import('../views/food/List.vue')
  //   },
  //   {
  //     path: 'addfood/:id?',
  //     name: 'Addfood',
  //     component: () => import('../views/food/addfood.vue')
  //   }
  //   ]
  // },

  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/user/Login.vue')
  } // {  //   path: '/about',  //   name: 'About',  //   component: () => import('../views/About.vue')  // }
  // {
  //   path: '/hardware',
  //   name: 'Hardware',
  //   component: Home,
  //   redirect: '/hardware/bedcard',
  //   children: [{
  //     path: 'bedcard',
  //     name: 'bedcard',
  //     component: () => import('../views/hardware/bedcard.vue')
  //   },
  //   {
  //     path: 'webcamIndex',
  //     name: 'webcamIndex',
  //     meta: { title: '摄像头' },
  //     component: () => import('../views/hardware/webcam/index.vue')
  //   },
  //   {
  //     path: 'viewwebcam/:id',
  //     name: 'viewwebcam',
  //     meta: { title: '摄像头' },
  //     component: () => import('../views/hardware/webcam/viewwebcam.vue')
  //   }
  //   ]
  // }

  // {
  //   path: '/subsidymanage',
  //   name: 'subsidymanage',
  //   meta: { title: '补贴管理' },
  //   component: Home,
  //   redirect: '/subsidymanage/addapplicate',
  //   children: [
  //     {
  //       path: 'subsidymanageIndex',
  //       name: 'subsidymanageIndex',
  //       meta: { title: '补贴管理' },
  //       component: () => import('../views/subsidymanage/list.vue')
  //     },
  //     {
  //       path: 'addapplicate',
  //       name: 'addapplicate',
  //       meta: { title: '添加申请' },
  //       component: () => import('../views/subsidymanage/addapplicate.vue')
  //     }
  //   ]
  // }
  // {
  //   path: '/live',
  //   name: 'Live',
  //   component: Home,
  //   children: [{
  //     path: 'contract',
  //     name: 'Contract',
  //     component: () => import('../views/live/contract.vue')
  //   },
  //   {
  //     path: 'out',
  //     name: 'Out',
  //     component: () => import('../views/live/out.vue')
  //   },
  //   {
  //     path: 'out/addout/:id?',
  //     name: 'Addout',
  //     component: () => import('../views/live/addout.vue')
  //   },
  //   {
  //     path: 'change',
  //     name: 'Change',
  //     component: () => import('../views/live/change.vue')
  //   },
  //   {
  //     path: 'addchange/:id?/:cusId?',
  //     name: 'Addchange',
  //     component: () => import('../views/live/addchange.vue')
  //   },
  //   {
  //     path: 'print/:id',
  //     name: 'print',
  //     component: () => import('../views/live/print.vue')
  //   },
  //   {
  //     path: 'editcontract/:id',
  //     name: 'editontract',
  //     component: () => import('../views/live/editcontract.vue')
  //   }
  //   ]
  // }
  // 财务
  // {
  //   path: '/finance',
  //   name: 'Finance',
  //   component: Home,
  //   redirect: '/finance/item',
  //   meta: {
  //     title: '财务管理'
  //   },
  //   children: [{
  //     path: 'item',
  //     name: 'Item',
  //     meta: {
  //       title: '费用项目管理'
  //     },
  //     component: () => import('../views/finance/item.vue')
  //   },
  //   {
  //     path: 'bill',
  //     name: 'Bill',
  //     meta: {
  //       title: '缴费账单'
  //     },
  //     component: () => import('../views/finance/bill.vue')
  //   },
  //   {
  //     path: 'addbill/:type?',
  //     name: 'Addbill',
  //     meta: {
  //       title: '添加缴费账单'
  //     },
  //     component: () => import('../views/finance/addbill.vue')
  //   },
  //   {
  //     path: 'additem/:type?',
  //     name: 'Additem',
  //     meta: {
  //       title: '添加费用项目账单'
  //     },
  //     component: () => import('../views/finance/additem.vue')
  //   },
  //   {
  //     path: 'elderfee',
  //     name: 'Elderfee',
  //     meta: {
  //       title: '长者费用'
  //     },
  //     component: () => import('../views/finance/elderfee.vue')
  //   },
  //   {
  //     path: 'detailfee/:id?',
  //     name: 'Detailfee',
  //     meta: {
  //       title: '长者费用'
  //     },
  //     component: () => import('../views/finance/detailfee.vue')
  //   },
  //   {
  //     path: 'addfund/:id?/:type?',
  //     name: 'Addfund',
  //     meta: {
  //       title: '添加充值备用金'
  //     },
  //     component: () => import('../views/finance/addfund.vue')
  //   }
  //   ]
  // },
  // {
  //   path: '/elder',
  //   name: 'Elder',
  //   component: Home,
  //   redirect: '/elder/index',
  //   meta: { title: '长者档案' },
  //   children: [{
  //     path: 'index',
  //     name: 'elderIndex',
  //     meta: { title: '长者档案' },
  //     component: () => import('../views/elder/list.vue')
  //   },
  //   {
  //     path: 'viewInfo/:id',
  //     name: 'viewInfo',
  //     meta: { title: '查看详情' },
  //     component: () => import('../views/elder/viewInfo.vue')
  //   },
  //   {
  //     path: 'files/:id',
  //     name: 'Files',
  //     meta: { title: '查看档案' },
  //     component: () => import('../views/elder/files.vue')
  //   },
  //   {
  //     path: 'evaluteList/:id?',
  //     name: 'evaluteList',
  //     meta: { title: '评估结果列表' },
  //     component: () => import('../views/elder/pglist.vue')
  //   },
  //   {
  //     path: 'levelcare/:id/:type?',
  //     name: 'Levelcare',
  //     meta: { title: '照护等级评估' },
  //     component: () => import('../views/elder/levelcare.vue')
  //   },
  //   {
  //     path: 'checkapply/:id/:type?',
  //     name: 'Checkapply',
  //     meta: { title: '入住申请' },
  //     component: () => import('../views/elder/checkapply.vue')
  //   },
  //   {
  //     path: 'approval/:id/:type?',
  //     name: 'Approval',
  //     meta: { title: '入住审批' },
  //     component: () => import('../views/elder/approval.vue')
  //   }
  //   ]
  // }
  // { path: '/robot',
  //   name: 'robot',
  //   component: Home,
  //   redirect: '/robot/index',
  //   meta: { title: '健康机器人' },
  //   children: [
  //     {
  //       path: 'detail/:id',
  //       name: 'robotDetail',
  //       meta: { title: '康养详情' },
  //       component: () => import('../views/robot/detail.vue')
  //     },
  //     {
  //       path: 'statistics',
  //       name: 'rootstatistics',
  //       meta: { title: '健康统计' },
  //       component: () => import('../views/robot/statistics.vue')
  //     },
  //     {
  //       path: 'userlist',
  //       name: 'userlist',
  //       meta: { title: '人员列表' },
  //       component: () => import('../views/robot/userlist.vue')
  //     },
  //     {
  //       path: 'detectlog',
  //       name: 'detectlog',
  //       meta: { title: '检测日志' },
  //       component: () => import('../views/robot/detectlog.vue')
  //     },
  //     {
  //       path: 'location',
  //       name: 'location',
  //       meta: { title: '定位系统' },
  //       component: () => import('../views/location/index.vue')
  //     }
  //   ]
  // }

]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
