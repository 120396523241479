/*
 * @Author: 刘崇杰
 * @Date: 2021-03-02 11:58:42
 * @LastEditTime: 2021-06-11 15:34:21
 * @LastEditors:
 * @Description: 接口前缀
 * @FilePath: \ioac-tenant-html\src\static\config\api_spec.js
 */
// 前缀判断
const prefix = process.env.NODE_ENV === 'production' ? 'equip-manage' : 'equip-manage'
module.exports = {
  // tc: 'tenant-center',
  // pc: 'platform-center',
  // ic: 'ioac-center',
  // bc: 'basic-center',
  // uc: 'user-center',
  // hc: 'hardware-center',
  // dc: 'data-center',
  // nc: 'nursing-center',
  // sc: 'seniors-center',
  // hsc: 'home2shop-service-center',
  // cvc: 'civil-service-center',
  // mon: 'monitor'

  tc: prefix,
  pc: prefix,
  ic: prefix,
  bc: prefix,
  uc: prefix,
  hc: prefix,
  dc: prefix,
  nc: prefix,
  sc: prefix,
  hsc: prefix,
  cvc: prefix
}
