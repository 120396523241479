let {
  hc
} = require('../config/api_spec')

module.exports = {
  // 报警设置
  getList_api: {
    url: `${hc}/mgr/hardware/settingWarning/query`,
    method: 'post',
    post: {
      hardwareType: '?',
      page: '?',
      rows: '?'
    }
  },
  addList_api: {
    url: `${hc}/mgr/hardware/settingWarning/add`,
    method: 'post',
    post: {
      hardwareType: '?',
      organCode: '?',
      state: '?',
      remark: '?',
      warningParamConfig: '?',
      timeLimitType: '?',
      timeLimitStart: '?',
      timeLimitEnd: '?',
      msgTemplate: ''
    }
  },
  setDetails_api: {
    url: `${hc}/mgr/hardware/settingWarning/get`,
    method: 'get',
    post: {
      id: '?'

    }
  },
  DetailsUpdate_api: {
    url: `${hc}/mgr/hardware/settingWarning/update`,
    method: 'post',
    post: {
      hardwareType: '?',
      organCode: '?',
      state: '?',
      remark: '?',
      warningParamConfig: '?',
      timeLimitType: '?',
      timeLimitStart: '?',
      timeLimitEnd: '?',
      id: '?',
      msgTemplate: '?'
    }
  },
  deleteList: {
    url: `${hc}/mgr/hardware/settingWarning/delete`,
    method: 'get',
    post: {
      id: '?'
    }
  },
  // 报警列表
  getDangerList: {
    url: `${hc}/mgr/hardware/warning/task/query`,
    method: 'post',
    post: {
      bindObjId: '?',
      bindObjType: '?',
      handleState: '?',
      handleUserId: '?',
      hardwareCode: '?',
      hardwareType: '?',
      page: '?',
      rows: '?',
      hardwareTypes: []

    }
  },
  // 报警处理
  submitResult: {
    url: `${hc}/mgr/hardware/warning/task/handleWarningTask`,
    method: 'post',
    post: {
      handleState: '?',
      id: '?',
      handleRemark: '?'
    }
  },
  // 数据日志
  logLIst: {
    url: `${hc}/mgr/hardware/data/query`,
    method: 'post',
    post: {
      page: '?',
      rows: '?',
      deviceCode: '',
      types: '?',
      endDate: '?',
      startDate: '?',
      dataTypes: [],
      deviceTypes: [],
      deviceBindObjTypes: []
    }
  },
  logDetails: {
    url: `${hc}/mgr/hardware/data/get`,
    method: 'get',
    post: {
      id: '?'
    }
  },
  // 电量列表
  queryList: {
    url: `${hc}/mgr/hardware/infoMgr/query`,
    method: 'post',
    post: {
      page: '?',
      rows: '?',
      devicePowerNumLe: '',
      type: '?',
      endDate: '?',
      startDate: '?'

    }
  },
  // 设备列表
  facilityList: {
    url: `${hc}/mgr/hardware/infoMgr/onOffStatistic`,
    method: 'post',
    post: {}
  },
  // 获取电量低于10%详情
  getDetails: {
    url: `${hc}/mgr/hardware/infoMgr/get`,
    method: 'get',
    post: {
      code: '?'
    }
  },
  // 报警任务统计根据绑定对象
  WarningBindObj: {
    url: `${hc}/mgr/hardware/warning/task/hardwareWarningTaskStaticByBindObj`,
    method: 'post',
    post: {
      bindObjId: '?',
      bindObjType: '?',
      hardwareType: '?'
    }
  },

  warningTask_api: {
    url: `${hc}/mgr/hardware/warning/task/query`,
    method: 'post',
    loading: true,
    post: {
      hardwareCode: '?',
      handleState: '?',
      page: '?',
      rows: '?'
    }
  },

  handleWarningTask_api: {
    url: `${hc}/mgr/hardware/warning/task/handleWarningTask`,
    method: 'post',
    post: {
      handleRemark: '?',
      handleState: '?',
      id: '?'
    }
  }
}
