/*
 * @Author: 刘崇杰
 * @Date: 2021-03-01 15:20:27
 * @LastEditTime: 2021-03-30 16:56:26
 * @LastEditors: Please set LastEditors
 * @Description: 用户登录
 * @FilePath: \ioac-tenant-html\src\static\api\UserLogin2NamePass.api.js
 */
let {
  uc
} = require('../config/api_spec')

module.exports = {
  // (用户名+密码+验证码)登录
  loginByName2Password2Code_api: {
    url: `${uc}/app/user/login/loginByName2Password2Code`,
    post: {
      captchaVerification: '?', // 图形验证码KEY
      password: '?', // 密码
      userName: '?' // 用户名
    }
  },
  loginByName2Password2CodeNoComNo_api: {
    url: `${uc}/app/user/login/loginByName2Password2CodeNoTenant`,
    post: {
      captchaVerification: '?', // 图形验证码KEY
      password: '?', // 密码
      userName: '?' // 用户名
    }
  }
}
