/*
 * @Author: your name
 * @Date: 2021-05-25 17:11:14
 * @LastEditTime: 2021-05-31 16:57:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\oldcheckin.controller.js
 */
const {
  add2edit_service,
  queryDynamicPage_service
} = require('../service/TenantDynamicPageConfigMgr.service')

const {
  register_service,
  queryPageRegister_service,
  registerGetById_service,
  registerUpdate_service,
  registerRemoveById_service
} = require('../service/CustomerRegisterMgr.service')

module.exports = {
  queryDynamicPage_controller(value) {
    return queryDynamicPage_service(value)
  },
  add2edit_controller(value) {
    return add2edit_service(value)
  },
  register_controller(value) {
    return register_service(value)
  },
  queryPageRegister_controller(value) {
    return queryPageRegister_service(value)
  },
  registerGetById_controller(value) {
    return registerGetById_service(value)
  },
  registerUpdate_controller(value) {
    return registerUpdate_service(value)
  },
  registerRemoveById_controller(value) {
    return registerRemoveById_service(value)
  }
}
