
<template>
  <div id="components-form-demo-advanced-search">
    <a-form
      class="ant-advanced-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="8">
          <div class="gutter-box">
            <a-form-item label="费用项目">
              <a-input
                v-decorator="['name']"
                placeholder="请输入费用项目"
              />
            </a-form-item>
          </div>
        </a-col>
        <a-col :span="8">
          <a-button type="primary" html-type="submit"> 搜索 </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
            重置
          </a-button>
        </a-col>
      </a-row>
      <a-row type="flex" justify="space-between">
        <a-col />
        <a-col>
          <router-link :to="{ path: '/finance/additem/1' }">
            <a-button
              type="primary"
              icon="plus"
            >新增费用项目
            </a-button>
          </router-link>
        </a-col>
      </a-row>
    </a-form>
    <div class="search-result-list">
      <a-row :gutter="24">
        <a-col :span="6">
          <div style="padding: 20px; border:8px solid #ececec">
            <a-tree
              v-if="codeList.length>0"
              :show-line="showtrue"
              :show-icon="showtrue"
              default-expand-all
              :default-selected-keys="['0-0-0']"
            >
              <a-icon slot="icon" type="carry-out" />
              <a-tree-node v-for="(item,index) in codeList" :key="index">
                <div slot="title" @click="querysearch(item.twoType)">
                  <a-row>
                    <a-col :span="8">
                      <span>{{ item.name }}</span>
                    </a-col>
                    <a-col :span="8">
                      <!-- <a-divider
                            style="margin:0 11px"
                            type="vertical"
                          /> -->
                      <a-icon
                        style="margin:0 11px 0 24px;color:#409EFF"
                        type="edit"
                        @click="editCode(item)"
                      />
                    </a-col>
                    <a-col :span="8">
                      <a-divider
                        style="margin:0 11px"
                        type="vertical"
                      />
                      <a-popconfirm
                        style="margin:0 11px"
                        :title="`是否删除【${item.name}】？`"
                        @confirm="removeCode(item.id)"
                      ><a-icon type="delete" style="color:#F56C6C" />
                      </a-popconfirm>
                    </a-col>
                  </a-row>
                </div>
              </a-tree-node>
            </a-tree>
            <div v-else style="text-align:center;margin-top:20px"><i class="iconfont" style="font-size:50px">&#xe60a;</i><span style="display:block;font-size:12px">暂无数据</span></div>

            <a-button
              type="primary"
              block
              icon="plus"
              style="margin-top: 15px"
              @click="addfee"
            >
              新增费用类目
            </a-button></div>
        </a-col>
        <a-col :span="18">
          <a-table
            :columns="columns"
            :data-source="data"
            :pagination="false"
            bordered
          >
            <span slot="headImg" slot-scope="text">
              <a-avatar shape="square" :src="text" />
            </span>
            <span slot="realName" slot-scope="text">
              <a-tag color="#2db7f5">{{ text }}</a-tag>
            </span>
            <span slot="roleModels" slot-scope="text, record">
              <a-tag v-for="item in record.roleModels" :key="item.id" color="blue">
                {{ item.name }}
              </a-tag>
            </span>
            <span slot="state" slot-scope="text">
              <a-tag :color="text == '1' ? 'cyan' : 'red'">{{
                text == '1' ? "启用" : text == '0' ? "停用" : ""
              }}</a-tag>
            </span>
            <span slot="action" slot-scope="text, record">
              <router-link :to="{ path: '/finance/additem/1', query:{ id:record.id }}">
                <a-button type="link"> 编辑 </a-button>
              </router-link>
              <a-divider type="vertical" />
              <a-button type="link" @click="setstop(record.id,record.state)">
                {{ record.state == '1' ? "停用" : "启用" }}
              </a-button>
              <a-divider type="vertical" />
              <a-popconfirm
                title="是否确认删除该条数据?"
                ok-text="确认"
                cancel-text="取消"
                @confirm="confirm(record.id)"
              >
                <a-button type="link"> 删除 </a-button>
              </a-popconfirm>
            </span>
          </a-table>
          <pagination
            :page.sync="pageIndex"
            :limit.sync="pageSize"
            :total="total"
            class="paginationStyle"
            @pagination="getPages"
          />
        </a-col>
      </a-row>

    </div>
    <a-modal
      title="新增费用类别"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form
        :form="form2"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      >
        <a-form-item label="分类名称">
          <a-input
            v-decorator="['vname',{
              rules: [{ required: true, message: '请填写分类名称!' }],
              initialValue: initValue.vname,
            }]"
            placeholder="分类名称"
          />
        </a-form-item>
        <a-form-item label="分类值">
          <a-input-number
            v-decorator="['vval',{
              rules: [{ required: true, message: '请填写分类值!' }],
              initialValue: initValue.vval,
            }]"
            :disabled="showDisabled"
            placeholder="分类值"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { queryfeeItem_controller, removefeeItem_controller, updateStatefeeItem_controller, addfeeItem_controller, updatefeeItem_controller, feeItemlist_controller } from '../../static/controller/FinanceFeeItemMgr.controller'
const columns = [
  {
    title: '费用项目',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '金额',
    dataIndex: 'price',
    key: 'price'
  },
  {
    title: '状态',
    key: 'state',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' }
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark'
  },
  {
    title: '操作',
    key: 'action',
    width: 250,
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  components: {
    pagination: () => import('../../components/Pagination')
  },
  data() {
    return {
      expand: false,
      form: this.$form.createForm(this, { name: 'advanced_search' }),
      form2: this.$form.createForm(this, { name: 'add-type' }),
      data: [],
      pageIndex: 1, // 当前页码
      pageSize: 10,
      total: 0,
      columns,
      roleList: [],
      where: {},
      showtrue: true,
      floorList: [],
      visible: false,
      confirmLoading: false,
      initValue: {},
      codeList: [],
      codeId: '', // 编辑的id
      sorts: '',
      oneType: '',
      showDisabled: false
    }
  },
  mounted() {
    this.initCode()
    this.initData()
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.form.validateFields((error, values) => {
        console.log(error)
        this.pageIndex = 1
        this.where = {
          name: values.name
        }
        this.initData()
      })
    },
    initData() {
      queryfeeItem_controller({
        ...this.where,
        page: this.pageIndex, // 页码
        rows: this.pageSize, // 每页记录数
        oneType: this.oneType,
        feeType: 1
      }).then((res) => {
        this.data = res ? res.records : []
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    },
    querysearch(val) {
      this.oneType = val
      this.initData()
    },
    handleReset() {
      this.form.resetFields()
    },
    // 确认删除
    confirm(id) {
      removefeeItem_controller({
        id: id
      }).then((res) => {
        this.$message.success('删除成功！')
        this.initData()
      })
    },
    // 启用禁用
    setstop(id, state) {
      let newstate = state === '1' ? 0 : 1
      updateStatefeeItem_controller({
        id: id,
        state: newstate
      }).then((res) => {
        this.$message.success(`${newstate === 1 ? '启用成功！' : '禁用成功！'}`)
        // 重新加载
        this.initData()
      })
    },
    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.initData()
    },
    addfee() {
      this.visible = true
      this.form2.resetFields()
      this.initValue = {}
      this.showDisabled = false
    },
    initCode() {
      feeItemlist_controller({
        feeType: 1,
        oneType: 0
      }).then((res) => {
        console.log(res)
        this.codeList = res || []
      })
    },
    // 类别新增
    handleOk(e) {
      this.form2.validateFields((err, values) => {
        if (err) {
          return
        }
        this.confirmLoading = true
        if (this.initValue.id) {
          updatefeeItem_controller({
            id: this.initValue.id,
            name: values.vname,
            feeType: 1,
            oneType: 0,
            twoType: values.vval,
            price: 0
          }).then((res) => {
            setTimeout(() => {
              this.visible = false
              this.confirmLoading = false
              this.initCode()
            }, 1000)
          })
        } else {
          addfeeItem_controller({
            name: values.vname,
            feeType: 1,
            oneType: 0,
            twoType: values.vval,
            price: 0
          }).then((res) => {
            setTimeout(() => {
              this.visible = false
              this.confirmLoading = false
              this.initCode()
            }, 1000)
          }).catch((err) => {
            console.log(err)
            this.visible = true
            this.confirmLoading = false
          })
        }
      })
    },
    handleCancel(e) {
      this.visible = false
      this.form2.resetFields()
    },
    editCode(row) {
      this.form2.resetFields()
      this.visible = true
      this.showDisabled = true
      this.initValue = {
        vname: row.name,
        vval: row.twoType,
        id: row.id
      }
    },
    removeCode(id) {
      removefeeItem_controller({ id: id }).then((res) => {
        this.$message.success('删除成功')
        setTimeout(() => {
          this.visible = false
          this.confirmLoading = false
          this.initCode()
        }, 1000)
      })
    },
    onSelect(selectedKeys, info) {
      console.log('selected', selectedKeys, info)
    }
  }
}
</script>
<style>
.ant-tree li .ant-tree-node-content-wrapper{
  height: 30px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  background-color: transparent;
}
.ant-tree.ant-tree-show-line li{
  text-align: left;
}
.ant-advanced-search-form {
  padding: 24px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  /* border-radius: 6px; */
}

</style>
