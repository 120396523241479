/*
 * @Author: your name
 * @Date: 2021-06-01 14:05:39
 * @LastEditTime: 2021-06-01 14:07:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\live.CustomerMealMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  addBatchCustomerMeal_api,
  addCustomerMeal_api,
  deleteCustomerMeal_api,
  getCustomerMeal_api,
  getAvailableMealByCustomer_api,
  queryCustomerMeal_api,
  queryListCustomerMeal_api,
  updateCustomerMeal_api
} = require('../api/live.CustomerMealMgr.api')

module.exports = {
  addBatchCustomerMeal_service(val) {
    return xhr(addBatchCustomerMeal_api, val)
  },
  addCustomerMeal_service(val) {
    return xhr(addCustomerMeal_api, val)
  },
  deleteCustomerMeal_service(val) {
    return xhr(deleteCustomerMeal_api, val)
  },
  getCustomerMeal_service(val) {
    return xhr(getCustomerMeal_api, val)
  },
  getAvailableMealByCustomer_service(val) {
    return xhr(getAvailableMealByCustomer_api, val)
  },
  queryCustomerMeal_service(val) {
    return xhr(queryCustomerMeal_api, val)
  },
  queryListCustomerMeal_service(val) {
    return xhr(queryListCustomerMeal_api, val)
  },
  updateCustomerMeal_service(val) {
    return xhr(updateCustomerMeal_api, val)
  }
}
