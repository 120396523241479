<!--
 * @Author: your name
 * @Date: 2021-03-04 14:49:56
 * @LastEditTime: 2021-04-23 10:01:19
 * @LastEditors:
 * @Description: 上传图片
 * @FilePath: \ioac-tenant-html\src\components\UploadImg.vue
-->
<template>
  <a-upload
    name="avatar"
    list-type="picture-card"
    class="avatar-uploader"
    :show-upload-list="false"
    :before-upload="beforeUpload"
    :custom-request="imgUpload"
    :style="`height:${height ? height + 'px' : 'auto'};width:${
      width ? width + 'px' : 'auto'
    }`"
  >
    <img v-if="imageUrl || url" :src="imageUrl || url" alt="avatar" style="height:100px;">
    <div v-else>
      <a-icon :type="loading ? 'loading' : 'plus'" />
      <div class="ant-upload-text">选择图片</div>
    </div>
  </a-upload>
</template>
<script>
import { xhr_custom } from '../static/lib/xhr'
import { getAliyunOssSign_controller } from '../static/controller/UploadImg.controller'

export default {
  props: { url: String, width: Number, height: Number },
  data() {
    return {
      loading: false,
      imageUrl: ''
    }
  },
  watch: {
    url(val, newval) {
      if (newval === '') {
        // url = ''
        this.imageUrl = ''
      }
    }
  },
  methods: {
    imgUpload(e) {
      this.loading = true
      getAliyunOssSign_controller({
        isImg: true
      }).then((res) => {
        let formData = new FormData()
        formData.append('OSSAccessKeyId', res.accessid)
        formData.append('policy', res.policy)
        formData.append('callback', res.callback)
        formData.append('signature', res.signature)
        formData.append('key', this.guid() + e.file.name)
        formData.append('file', e.file)
        formData.append('success_action_status', '200')
        xhr_custom(res.host, {
          method: 'post',
          upload: true,
          data: formData
        })
          .then((res) => {
            this.imageUrl = res.fileUrl
            this.loading = false
            this.$emit('update:url', this.imageUrl)
          })
          .catch(() => {
            this.loading = false
          })
      })
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        // file.type === "image/gif" ||
        file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('只能上传图片!')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('图片大小只能小于 2MB!')
      }
      console.log(file)
      return isJpgOrPng && isLt2M
    },
    guid() {
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
      }
      return (
        new Date().getTime() +
        S4() +
        S4() +
        S4() +
        S4() +
        S4() +
        S4() +
        S4() +
        S4()
      )
    }
  }
}
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.avatar-uploader img {
  max-width: 100%;
  max-height: 100%;
}
</style>
