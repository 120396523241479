/*
 * @Author: your name
 * @Date: 2021-03-03 10:50:26
 * @LastEditTime: 2021-04-27 10:38:18
 * @LastEditors:
 * @Description: 用户管理
 * @FilePath: \ioac-tenant-html\src\static\service\UserMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')

const {
  userQuery_api,
  userallQuery_api,
  changeState_api,
  userSave_api,
  getUserInfo_api,
  updateUser_api
} = require('../api/UserMgr.api')

module.exports = {
  userQuery_service(val) {
    return xhr(userQuery_api, val)
  },
  userallQuery_service(val) {
    return xhr(userallQuery_api, val)
  },
  changeState_service(val) {
    return xhr(changeState_api, val)
  },
  userSave_service(val) {
    return xhr(userSave_api, val)
  },
  getUserInfo_service(val) {
    return xhr(getUserInfo_api, val)
  },
  updateUser_service(val) {
    return xhr(updateUser_api, val)
  }
}
