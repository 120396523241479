/*
 * @Author: your name
 * @Date: 2021-03-01 19:14:04
 * @LastEditTime: 2021-06-22 14:59:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\Home.controller.js
 */
const {
  getMenu_service
} = require('../service/UserPermissionCheck.service')

const {
  getCompany_service
} = require('../service/TenantCompanyMgr.service')

const {
  setStorage,
  getStorageInfo
} = require('../utils/localstorage')

module.exports = {
  getMenu_controller() { // 获取菜单
    return new Promise((a, b) => {
      let menu = getStorageInfo('menu')
      // 是否存在菜单缓存，存在菜单缓存返回缓存信息
      if (!menu) { // 不存在缓存
        getMenu_service().then(async res => {
          let CompanyInfo = await getCompany_service({
            companyNo: getStorageInfo('user').organCode
          })
          setStorage('company', CompanyInfo)
          // 加入缓存
          setStorage('menu', res)
          a(res)
        }).catch(res => {
          b(res)
        })
      } else {
        a(menu)
      }
    })
  },
  initMenu_controller() {
    console.log('eeee')
  }
}
