/*
 * @Description: 地区
 * @Author:
 * @Date: 2021-03-04 10:20:12
 * @LastEditTime: 2021-04-14 19:52:25
 * @LastEditors:
 */
module.exports = {
  getRegionInfo_api: {
    url: 'basic-center/app/base/regionInfo/queryAll',
    get: {
      level: '3'
    }
  },
  getqueryLowerAll_api: {
    url: 'basic-center/app/base/regionInfo/queryLowerAll',
    post: {
      parentCode: '?'
    }
  }

}
