/*
 * @Author: your name
 * @Date: 2021-04-16 15:54:46
 * @LastEditTime: 2021-06-22 10:05:23
 * @LastEditors: Please set LastEditors
 * @Description: 接单人员信息
 * @FilePath: \ioac-tenant-html\src\static\api\ServiceTaskCustomerMgr.api.js
 */
let {
  hsc
} = require('../config/api_spec')

module.exports = {
  // mgr/service/task/customer/getDetailById
  getCustomerDetailById_api: {
    url: `${hsc}/mgr/service/task/customer/getDetailById`,
    get: {
      id: '?'
    }
  }
}
