/*
 * @Author: 刘崇杰
 * @Date: 2021-03-22 14:24:23
 * @LastEditTime: 2021-06-10 18:46:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\workorder.controller.js
 */
const {
  queryServiceTask_service,
  addServiceTask_service,
  editExeUser_service,
  delServiceTask_service,
  editServiceTask_service
} = require('../service/ServiceTaskMgr.service')

const {
  queryCheckInCustList_service
} = require('../service/ComeCustomerMgr.service')

const {
  getCustomerDetailById_service
} = require('../service/ServiceTaskCustomerMgr.service')

module.exports = {
  queryServiceTask_controller(val) {
    return queryServiceTask_service(val)
  },
  addServiceTask_controller(val) {
    return addServiceTask_service(val)
  },
  queryCheckInCustList_controller(val) {
    return queryCheckInCustList_service(val)
  },
  editExeUser_controller(val) {
    return editExeUser_service(val)
  },
  delServiceTask_controller(val) {
    return delServiceTask_service(val)
  },
  editServiceTask_controller(val) {
    return editServiceTask_service(val)
  },
  getCustomerDetailById_controller(val) {
    return getCustomerDetailById_service(val)
  }
}
