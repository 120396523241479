/*
 * @Description:
 * @Author:
 * @Date: 2021-03-24 17:29:45
 * @LastEditTime: 2021-03-29 17:18:11
 * @LastEditors:
 */
const {
  xhr
} = require('../lib/xhr')

const {
  queryChange_api,
  getChange_api,
  updateChange_api,
  addChange_api,
  getCustomerChange_api
} = require('../api/ChangeWorkMgr.api')

module.exports = {
  queryChange_service(val) {
    return xhr(queryChange_api, val)
  },
  getChange_service(val) {
    return xhr(getChange_api, val)
  },
  updateChange_service(val) {
    return xhr(updateChange_api, val)
  },
  addChange_service(val) {
    return xhr(addChange_api, val)
  },
  getCustomerChange_service(val) {
    return xhr(getCustomerChange_api, val)
  }
}

