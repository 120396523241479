/*
 * @Author: your name
 * @Date: 2021-06-29 15:01:43
 * @LastEditTime: 2021-07-01 13:29:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\HardwareFloorMgr.controller.js
 */
let {
  queryBuildingsFloor_service,
  queryFloorByBuildingFloor_serveice
} = require('../service/HardwareFloorMgr.service')

let {
  listDataForSbj_service
} = require('../service/HardwareDataMgr.service')

let {
  getData_service
} = require('../service/HardwareMgr.service')

module.exports = {
  queryFloorByBuildingFloor_controller: (val) => {
    return queryFloorByBuildingFloor_serveice(val)
  },
  queryBuildingsFloor_controller: (val) => {
    return queryBuildingsFloor_service(val)
  },
  listDataForSbj_controller: (val) => {
    return listDataForSbj_service(val)
  },
  getData_controller: (val) => {
    return getData_service(val)
  }
}
