/*
 * @Author: your name
 * @Date: 2021-03-01 19:14:04
 * @LastEditTime: 2021-03-24 18:28:45
 * @LastEditors:
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\Home.controller.js
 */
let {
  getFoodMgr_service,
  deleteFoodMgr_serveice,
  addFoodMgr_service,
  editFoodMgr_service,
  detailFoodMgr_service
} = require('../service/FoodMgr.service')

module.exports = {
  getFoodMgr_controller(val) {
    return getFoodMgr_service(val)
  },
  deleteFoodMgr_controller(val) {
    return deleteFoodMgr_serveice(val)
  },
  addFoodMgr_controller(val) {
    return addFoodMgr_service(val)
  },
  editFoodMgr_controller(val) {
    return editFoodMgr_service(val)
  },
  detailFoodMgr_controller(val) {
    return detailFoodMgr_service(val)
  }
}
