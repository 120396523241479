/*
 * @Author: your name
 * @Date: 2021-06-02 13:21:23
 * @LastEditTime: 2021-06-02 13:23:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\cost.controller.js
 */
let {
  addAccountInfo_service,
  getByCustomerNo_service,
  queryAccountInfo_service,
  querylist_service,
  depositAcct_service,
  updateState_service,
  butler_batchDelete_service,
  butler_export_service,
  butler_getInfoById_service,
  butler_importUser_service,
  butler_queryList_service,
  butler_save_service,
  butler_updatePassword_service,
  knowledgeBaseAdd_service,
  queryKnowledgeBase_service,
  AddEquipAnalysis_service,
  UpdateEquipAnalysis_service,
  equipAnalysisQueryAll_service,
  disableEquipAnalysisById_service,
  getEquipAnalysisById_service,
  removeEquipAnalysisById_service,
  BatchRemoveKnowledgeSubjectInfo_service,
  UpdateBaseInfoBySid_service,
  UpdateBaseKNameByKid_service,
  createKnowledgeSubjectInfo_service,
  queryBySubjectSid_service,
  queryKnowledgeSubjectInfoList_service,
  releaseKnowledgeSubjectInfoList_service,
  equipment_add_service,
  equipment_equip_service,
  equipment_batchequip_service,
  equipment_deleteEquipInfoById_service,
  equipment_editEquipInfo_service,
  equipment_getEquipInfoById_service,
  equipment_query_service,
  queryAllEquipTypeAndModel_service,
  type_add_service,
  deleteEquipTypeInfoById_service,
  editEquipTypeInfo_service,
  getEquipTypeInfoById_service,
  type_query_service,
  deleteOrganById_service,
  account_queryList_service,
  account_queryTree_service,
  account_save_service,
  account_updatePassword_service,
  account_updateState_service,
  enduser_query_service,
  enduser_queryList_service,
  enduser_update_service,
  center_query_service,
  equipment_batchDeleteEquipInfo_service,
  ageGenderRank_service,
  deviceTypeDetect_service,
  genderDetectRank_service,
  usage_service,
  userDetectSos_service,
  variousDetectSituation_service,
  enduserByType_service,
  unbindOrgan_service
} = require('../service/AccountInfoMgr.service')

module.exports = {
  addAccountInfo_controller(val) {
    return addAccountInfo_service(val)
  },
  getByCustomerNo_controller(val) {
    return getByCustomerNo_service(val)
  },
  queryAccountInfo_controller(val) {
    return queryAccountInfo_service(val)
  },
  querylist_controller(val) {
    return querylist_service(val)
  },
  depositAcct_controller(val) {
    return depositAcct_service(val)
  },
  updateState_controller(val) {
    return updateState_service(val)
  },

  // 管家
  butler_batchDelete_controller(val) {
    return butler_batchDelete_service(val)
  },
  butler_export_controller(val) {
    return butler_export_service(val)
  },
  butler_getInfoById_controller(val) {
    return butler_getInfoById_service(val)
  },
  butler_importUser_controller(val) {
    return butler_importUser_service(val)
  },
  butler_save_controller(val) {
    return butler_save_service(val)
  },
  butler_queryList_controller(val) {
    return butler_queryList_service(val)
  },
  butler_updatePassword_controller(val) {
    return butler_updatePassword_service(val)
  },
  // 知识库
  knowledgeBaseAdd_controller(val) {
    return knowledgeBaseAdd_service(val)
  },
  queryKnowledgeBase_controller(val) {
    return queryKnowledgeBase_service(val)
  },
  // 检测分析
  AddEquipAnalysis_controller(val) {
    return AddEquipAnalysis_service(val)
  },
  UpdateEquipAnalysis_controller(val) {
    return UpdateEquipAnalysis_service(val)
  },
  equipAnalysisQueryAll_controller(val) {
    return equipAnalysisQueryAll_service(val)
  },
  disableEquipAnalysisById_controller(val) {
    return disableEquipAnalysisById_service(val)
  },
  getEquipAnalysisById_controller(val) {
    return getEquipAnalysisById_service(val)
  },
  removeEquipAnalysisById_controller(val) {
    return removeEquipAnalysisById_service(val)
  },
  // 知识库操作

  BatchRemoveKnowledgeSubjectInfo_controller(val) {
    return BatchRemoveKnowledgeSubjectInfo_service(val)
  },
  UpdateBaseInfoBySid_controller(val) {
    return UpdateBaseInfoBySid_service(val)
  },
  UpdateBaseKNameByKid_controller(val) {
    return UpdateBaseKNameByKid_service(val)
  },
  createKnowledgeSubjectInfo_controller(val) {
    return createKnowledgeSubjectInfo_service(val)
  },
  queryBySubjectSid_controller(val) {
    return queryBySubjectSid_service(val)
  },
  queryKnowledgeSubjectInfoList_controller(val) {
    return queryKnowledgeSubjectInfoList_service(val)
  },
  releaseKnowledgeSubjectInfoList_controller(val) {
    return releaseKnowledgeSubjectInfoList_service(val)
  },
  //  设备信息管理
  equipment_add_controller(val) {
    return equipment_add_service(val)
  },
  equipment_equip_controller(val) {
    return equipment_equip_service(val)
  },
  equipment_batchequip_controller(val) {
    return equipment_batchequip_service(val)
  },
  equipment_deleteEquipInfoById_controller(val) {
    return equipment_deleteEquipInfoById_service(val)
  },
  equipment_editEquipInfo_controller(val) {
    return equipment_editEquipInfo_service(val)
  },
  equipment_getEquipInfoById_controller(val) {
    return equipment_getEquipInfoById_service(val)
  },
  equipment_query_controller(val) {
    return equipment_query_service(val)
  },
  queryAllEquipTypeAndModel_controller(val) {
    return queryAllEquipTypeAndModel_service(val)
  },
  type_add_controller(val) {
    return type_add_service(val)
  },
  deleteEquipTypeInfoById_controller(val) {
    return deleteEquipTypeInfoById_service(val)
  },
  editEquipTypeInfo_controller(val) {
    return editEquipTypeInfo_service(val)
  },
  getEquipTypeInfoById_controller(val) {
    return getEquipTypeInfoById_service(val)
  },
  type_query_controller(val) {
    return type_query_service(val)
  },
  deleteOrganById_controller(val) {
    return deleteOrganById_service(val)
  },
  account_queryList_controller(val) {
    return account_queryList_service(val)
  },
  account_queryTree_controller(val) {
    return account_queryTree_service(val)
  },
  account_save_controller(val) {
    return account_save_service(val)
  },
  account_updatePassword_controller(val) {
    return account_updatePassword_service(val)
  },
  account_updateState_controller(val) {
    return account_updateState_service(val)
  },
  enduser_query_controller(val) {
    return enduser_query_service(val)
  },
  enduser_queryList_controller(val) {
    return enduser_queryList_service(val)
  },
  enduser_update_controller(val) {
    return enduser_update_service(val)
  },
  center_query_controller(val) {
    return center_query_service(val)
  },
  equipment_batchDeleteEquipInfo_controller(val) {
    return equipment_batchDeleteEquipInfo_service(val)
  },
  ageGenderRank_controller(val) {
    return ageGenderRank_service(val)
  },
  deviceTypeDetect_controller(val) {
    return deviceTypeDetect_service(val)
  },
  genderDetectRank_controller(val) {
    return genderDetectRank_service(val)
  },
  usage_controller(val) {
    return usage_service(val)
  },
  userDetectSos_controller(val) {
    return userDetectSos_service(val)
  },
  variousDetectSituation_controller(val) {
    return variousDetectSituation_service(val)
  },
  enduserByType_controller(val) {
    return enduserByType_service(val)
  },
  unbindOrgan_controller(val) {
    return unbindOrgan_service(val)
  }
}
// console.log(account_updateState_service)
