<template>
  <div>
    <a-modal
      title="查看详情"
      :visible="visible"
      :footer="null"
      :mask-closable="false"
      @cancel="handleCancel"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
        <a-form-item label="名称">
          <a-input
            v-model="data.templateName"
            disabled
          />
        </a-form-item>
        <a-form-item label="内容">
          <div>
            <table style="width: 100%">
              <tr v-for="item in data.ruleLists" :key="item.id">
                <td :key="item.id" style="width: 70%">
                  <a-checkbox :checked="true" disabled>
                    {{ item.metaAttributeZhname }}
                  </a-checkbox>
                </td>
                <td :key="item.id" style="30%">
                  <a-input
                    :value="noLimitConvert(item.metaLimitValue, item.metaLimitValue)"
                    class="count-input"
                    disabled
                  />
                </td>
              </tr>
            </table>
          </div>
        </a-form-item>
        <a-form-item label="时长">
          <a-select
            v-model="data.templateExpiry"
            disabled
          >
            <a-select-option value="0"> 6个月 </a-select-option>
            <a-select-option value="1"> 1年 </a-select-option>
            <a-select-option value="2"> 2年 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item style="display: flex; justify-content: center">
          <div style="display: flex; justify-content: space-around">
            <a-button
              type="primary"
              :size="size"
              style="background: #999; border-color: #999"
              @click="handleCancel"
            >
              取消
            </a-button>
          </div>
        </a-form-item>
      </a-form></a-modal>
  </div>
</template>

<script>
import BaseModal from '@/components/subscription/BaseModal'
import { getTemplateDetails_controller } from '@/static/controller/Subscription.controller'
import subscriptMixin from '@/mixins/subscriptMixin'
export default {
  extends: BaseModal,
  mixins: [subscriptMixin],
  data() {
    return {
      organSelect: false,
      id: null,
      data: []
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getDetail(this.id)
      }
    }
  },
  methods: {
    getDetail(id) {
      getTemplateDetails_controller({ id: id }).then(res => {
        this.data = res
      })
    }
  }
}
</script>

<style>
</style>
