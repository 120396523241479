/*
 * @Author: your name
 * @Date: 2021-05-30 14:07:53
 * @LastEditTime: 2021-05-31 15:55:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\ServiceMealMgr.controller.js
 */
let {
  addServiceMeal_service,
  queryServiceMeal_service,
  delServiceMeal_service,
  updateServiceMeal_service,
  getServiceMeal_service,
  updateStateServiceMeal_service
} = require('../service/ServiceMealMgr.service')

module.exports = {
  addServiceMeal_controller(val) {
    return addServiceMeal_service(val)
  },
  queryServiceMeal_controller(val) {
    return queryServiceMeal_service(val)
  },
  delServiceMeal_controller(val) {
    return delServiceMeal_service(val)
  },
  updateServiceMeal_controller(val) {
    return updateServiceMeal_service(val)
  },
  getServiceMeal_controller(val) {
    return getServiceMeal_service(val)
  },
  updateStateServiceMeal_controller(val) {
    return updateStateServiceMeal_service(val)
  }
}
