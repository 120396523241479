a-transfer
<!--
 * @Author: your name
 * @Date: 2021-03-03 15:58:16
 * @LastEditTime: 2021-06-21 18:11:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\views\sysset\addrole.vue
-->
<template>
  <div>
    <a-page-header title="角色维护" sub-title="添加角色、编辑、查看" @back="() => $router.go(-1)" />
    <a-form :form="form" :label-col="{ span: 2 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
      <a-form-item>
        <a-page-header sub-title="基础信息" />
      </a-form-item>
      <a-form-item label="角色编号">
        <a-input
          v-decorator="[
            'roleCode',
            {
              rules: [{ required: true, message: '请输入角色编号！' }],
              initialValue: initValue.roleCode,
            },
          ]"
          placeholder="请输入角色编号"
          style="width: 300px"
        />
      </a-form-item>
      <a-form-item label="角色名称">
        <a-input
          v-decorator="[
            'roleName',
            {
              rules: [{ required: true, message: '请输入角色名称！' }],
              initialValue: initValue.roleName,
            },
          ]"
          placeholder="请输入角色名称"
          style="width: 300px"
        />
      </a-form-item>
      <a-form-item label="角色描述">
        <a-input
          v-decorator="[
            'roleDes',
            {
              rules: [{ required: true, message: '请输入角色描述' }],
              initialValue: initValue.roleDes,
            },
          ]"
          placeholder="请输入角色描述"
          style="width: 300px"
        />
      </a-form-item>

      <a-form-item>
        <a-page-header sub-title="更多信息" />
      </a-form-item>

      <a-form-item label="分配模块">
        <!-- <a-checkbox-group
          v-decorator="[
            'applicationNos',
            { initialValue: initValue.applicationName },
          ]"
        >
          <a-row>
            <a-col
              v-for="item in dataSource"
              :key="item.applicationNo"
              :span="24"
            >
              <a-checkbox :value="item.applicationNo"
                >{{ item.applicationName }}
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group> -->

        <a-transfer
          v-if="treeInfo && transferDataSource.length > 0"
          class="tree-transfer"
          :data-source="transferDataSource"
          :show-select-all="false"
          :target-keys="targetKeys"
          :render="(item) => item.title"
          :list-style="{
            width: '300px',
            height: '300px',
          }"
          @change="onChange"
        >
          <template
            slot="children"
            slot-scope="{
              props: { direction, selectedKeys },
              on: { itemSelect },
            }"
          >
            <a-tree
              v-if="direction === 'left'"
              block-node
              checkable
              check-strictly
              default-expand-all
              :checked-keys="[...selectedKeys, ...targetKeys]"
              :tree-data="treeData"
              @check="
                (_, props) => {
                  onChecked(
                    _,
                    props,
                    [...selectedKeys, ...targetKeys],
                    itemSelect
                  );
                }
              "
              @select="
                (_, props) => {
                  onChecked(
                    _,
                    props,
                    [...selectedKeys, ...targetKeys],
                    itemSelect
                  );
                }
              "
            />
          </template>
        </a-transfer>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 2 }">
        <a-button type="primary" html-type="submit"> 保存 </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import {
  roleSave_controller,
  getTenantApplications_controller,
  getRole_controller,
  roleUpdate_controller,
  permissionQueryTree_controller
} from '../../static/controller/UserRole.controller'

/**
	 * 递归菜单
	 * @param {Array} info
	 */
function getMenuInfo(info) {
  if (info.length > 0) {
    info.forEach((item, index) => {
      info[index] = {
        key: item.code,
        title: item.showName,
        children: item.children.length > 0 ? item.children : []
      }
      if (item.children.length > 0) {
        getMenuInfo(item.children)
      }
    })
    return info
  }
}

const transferDataSource = []

function flatten(list = []) {
  list.forEach((item) => {
    transferDataSource.push(item)
    flatten(item.children)
  })
}

function isChecked(selectedKeys, eventKey) {
  return selectedKeys.indexOf(eventKey) !== -1
}

function handleTreeData(data, targetKeys = []) {
  data.forEach((item) => {
    item['disabled'] = targetKeys.includes(item.key)
    if (item.children) {
      handleTreeData(item.children, targetKeys)
    }
  })
  return data
}

export default {
  data() {
    return {
      transferDataSource: [],
      dataSource: [],
      initValue: {},
      targetKeys: [],
      treeInfo: []
    }
  },
  computed: {
    treeData() {
      return handleTreeData(this.treeInfo, this.targetKeys)
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'time_related_controls'
    })
  },
  mounted() {
    // this.initPermission()
    if (this.$route.query.id) {
      getRole_controller({
        code: this.$route.query.id
      }).then((res) => {
        this.targetKeys = res.permissionCodes
        this.initValue = {
          roleCode: res.code,
          roleName: res.name,
          roleDes: res.remark
        }
      })
    }
    permissionQueryTree_controller({}).then((res) => {
      let menu = getMenuInfo(res)
      this.treeInfo = menu
      flatten(JSON.parse(JSON.stringify(menu)))
      this.transferDataSource = transferDataSource
    })
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, fieldsValue) => {
        if (err) {
          return
        }
        if (this.targetKeys.length === 0) {
          this.$message.warning('请给角色分配模块！')
          return
        }

        if (this.$route.query.id) {
          // 表示编辑
          roleUpdate_controller({
            code: fieldsValue.roleCode,
            name: fieldsValue.roleName,
            remark: fieldsValue.roleDes,
            permissionCodes: this.targetKeys
          }).then((res) => {
            this.$message.success('更新角色成功！')
            this.$router.push({
              name: 'permissions',
              query: {
                type: 2
              }
            })
          })
        } else {
          // 表示新增
          roleSave_controller({
            code: fieldsValue.roleCode,
            name: fieldsValue.roleName,
            remark: fieldsValue.roleDes,
            permissionCodes: this.targetKeys
          }).then((res) => {
            this.$message.success('添加角色成功！')
            this.$router.push({
              name: 'permissions',
              query: {
                type: 2
              }
            })
          })
        }
      })
    },
    // 初始话权限列表
    initPermission() {
      getTenantApplications_controller().then((res) => {
        this.dataSource = res
      })
    },
    onChange(targetKeys) {
      console.log('Target Keys:', targetKeys)
      this.targetKeys = targetKeys
    },
    onChecked(_, e, checkedKeys, itemSelect) {
      const {
        eventKey
      } = e.node
      itemSelect(eventKey, !isChecked(checkedKeys, eventKey))
    }
  }
}
</script>
<style>
	.tree-transfer .ant-transfer-list:first-child {
		width: 50%;
		flex: none;
	}

	.ant-transfer-list-body {
		overflow-y: auto;
	}
</style>
