/*
 * @Author: your name
 * @Date: 2021-03-03 15:41:56
 * @LastEditTime: 2021-06-28 15:49:45
 * @LastEditors: Please set LastEditors
 * @Description: 角色管理
 * @FilePath: \ioac-tenant-html\src\static\api\RoleMgr.js
 */
let {
  uc
} = require('@/static/config/api_spec')

module.exports = {
  // 获取所有角色
  rolelist_api: {
    url: `${uc}/mgr/user/role/list`,
    method: 'GET'
  },
  // 新增角色
  roleSave_api: {
    url: `${uc}/mgr/user/role/save`,
    post: {
      code: '?',
      name: '?',
      remark: '?',
      permissionCodes: '?'
    }
  },
  // 新增角色
  roleUpdate_api: {
    url: `${uc}/mgr/user/role/update`,
    post: {
      code: '?',
      name: '?',
      remark: '?',
      permissionCodes: '?'
    }
  },
  roleSaveByApp_api: {
    url: `${uc}/mgr/user/role/saveByApp`,
    post: {
      code: '?',
      name: '?',
      remark: '?',
      appNos: '?'
    }
  },
  // 删除角色
  roleDelete_api: {
    url: `${uc}/mgr/user/role/delete`,
    method: 'GET',
    post: {
      code: '?'
    }
  },
  getRole_api: {
    url: `${uc}/mgr/user/role/get`,
    method: 'GET',
    post: {
      code: '?'
    }
  },
  // 根据模块修改角色
  updateByApp_api: {
    url: `${uc}/mgr/user/role/updateByApp`,
    post: {
      code: '?',
      name: '?',
      remark: '?',
      appNos: '?'
    }
  }

}
