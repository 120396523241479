import { render, staticRenderFns } from "./singleitem.vue?vue&type=template&id=74642666"
import script from "./singleitem.vue?vue&type=script&lang=js"
export * from "./singleitem.vue?vue&type=script&lang=js"
import style0 from "./singleitem.vue?vue&type=style&index=0&id=74642666&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports