<template>
  <div class="gutter-example">
    <a-row :gutter="16">
      <a-col class="gutter-row" :span="6">
        <div class="gutter-box">
          <div class="imgbox">
            <img
              v-show="onebool"
              src="https://hlkj-img.oss-cn-shanghai.aliyuncs.com/16324665589149303bcd6aea9a383b7bb1199b99a45bf空状态-暂无数据.png?p=d2g9NTA1LTM0Mg=="
              alt=""
              style="height: auto; width: auto"
            >
          </div>
          <div
            v-show="!onebool"
            id="myChartsage"
            ref="myChartsage"
            style="height: 320px; width: 100%"
          />
        </div>
      </a-col>
      <a-col class="gutter-row" :span="6">
        <div class="gutter-box">
          <div class="imgbox">
            <img
              v-show="twobool"
              src="https://hlkj-img.oss-cn-shanghai.aliyuncs.com/16324665589149303bcd6aea9a383b7bb1199b99a45bf空状态-暂无数据.png?p=d2g9NTA1LTM0Mg=="
              alt=""
              style="height: auto; width: auto"
            >
          </div>
          <div
            v-show="!twobool"
            id="myChartcrowd"
            ref="myChartcrowd"
            style="height: 320px; width: 100%"
          />
        </div>
      </a-col>
      <a-col class="gutter-row" :span="12">
        <div class="gutter-box">
          <div class="imgbox">
            <img
              v-show="threebool"
              src="https://hlkj-img.oss-cn-shanghai.aliyuncs.com/16324665589149303bcd6aea9a383b7bb1199b99a45bf空状态-暂无数据.png?p=d2g9NTA1LTM0Mg=="
              alt=""
              style="height: auto; width: auto"
            >
          </div>
          <div
            v-show="!threebool"
            id="myChartsequip"
            ref="myChartsequip"
            style="height: 320px; width: 100%"
          />
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
var echarts = require('echarts')
export default {
  filters: {},
  props: { threelist: Array, threeOnelist: Array },
  data() {
    return {
      agelist: {},
      equiplist: {},
      peopleliast: {},
      list: [],
      oneList: [],
      onebool: false,
      twobool: false,
      threebool: false
    }
  },
  computed: {},
  watch: {
    threelist(val, newval) {
      this.list = val

      this.getlist()
      // if (
      //   this.list[0].maleAgeRangeList.length === 0 &&
      //   this.list[0].womanAgeRangeList.length === 0
      // ) {
      //   this.onebool = true
      // } else {
      //   this.onebool = false
      this.myChartage()
      // }
      console.log(this.list[1])
      // if (
      //   this.list[1].womanDetectRankList.length === 0 &&
      //   this.list[1].maleDetectRankList.length === 0
      // ) {
      //   this.twobool = true
      // } else {
      // this.twobool = false
      this.myChartcrowd()
      // }
    },
    threeOnelist(val, newval) {
      this.oneList = val
      this.oneGetList()
      // if (
      //   this.oneList[0].inspectionBoxDetectList.length === 0 &&
      //   this.oneList[0].machineDetectList.length === 0 &&
      //   this.oneList[0].robotDetectList.length === 0
      // ) {
      //   this.threebool = true
      // } else {
      //   this.threebool = false
      this.myChartsequip()
      // }
    }
  },

  created() {},
  updated() {
    this.myCharts5()
  },
  mounted() {},
  methods: {
    getlist() {
      this.agelist = this.list[0]
      this.peopleliast = this.list[1]
    },
    oneGetList() {
      this.equiplist = this.oneList[0]
    },
    myChartage() {
      var chartDom = document.getElementById('myChartsage')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        title: {
          text: '注册用户年龄分布'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          top: '20px',
          left: '0',
          data: ['男性', '女性']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.agelist.ageRangeList
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '男性',
            type: 'bar',
            stack: '性别',
            emphasis: {
              focus: 'series'
            },
            data: this.agelist.maleAgeRangeList
          },
          {
            name: '女性',
            type: 'bar',
            stack: '性别',
            emphasis: {
              focus: 'series'
            },
            data: this.agelist.womanAgeRangeList
          }
        ]
      }
      myChart.clear()
      option && myChart.setOption(option)
    },
    myChartcrowd() {
      var chartDom = document.getElementById('myChartcrowd')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        title: {
          text: '活跃检测人群排行'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          top: '20px',
          left: '0',
          data: ['男性', '女性']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.peopleliast.ageRangeList
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '男性',
            type: 'bar',
            stack: '性别',
            emphasis: {
              focus: 'series'
            },
            data: this.peopleliast.maleDetectRankList
          },
          {
            name: '女性',
            type: 'bar',
            stack: '性别',
            emphasis: {
              focus: 'series'
            },
            data: this.peopleliast.womanDetectRankList
          }
        ]
      }
      myChart.clear()
      option && myChart.setOption(option)
    },
    myChartsequip() {
      var chartDom = document.getElementById('myChartsequip')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        title: {
          text: '设备使用情况'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['质检箱', '一体机', '机器人']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.equiplist.timeRange
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '质检箱',
            type: 'line',
            // stack: '总量',
            data: this.equiplist.inspectionBoxDetectList
          },
          {
            name: '一体机',
            type: 'line',
            // stack: '总量',
            data: this.equiplist.machineDetectList
          },
          {
            name: '机器人',
            type: 'line',
            // stack: '总量',
            data: this.equiplist.robotDetectList
          }
        ]
      }
      myChart.clear()
      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
.sp {
  font-size: 36px;
  color: #ff8847;
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box {
  background: #fff;
  padding: 5px 0;
  margin-top: 10px;
}
.imgbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
</style>
