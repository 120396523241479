/*
 * @Description:
 * @Author:
 * @Date: 2021-05-20 13:19:57
 * @LastEditTime: 2021-05-28 14:48:49
 * @LastEditors:
 */
const { xhr } = require('../lib/xhr')
const { robotQuery_api, elderDetail_api, elderCheck_api, monitoringQuery_api, eldermonitorQuery_api, elderstatistics_api, checkcount_api,
  categorycount_api,
  robotattention_api,
  homestatistics_api,
  robotlocate_api
} = require('../api/MonitoringApp.api')
module.exports = {
  robotQuery_controller(val) {
    return xhr(robotQuery_api, val)
  },
  elderDetail_controller(val) {
    return xhr(elderDetail_api, val)
  },
  elderCheck_controller(val) {
    return xhr(elderCheck_api, val)
  },
  monitoringQuery_controller(val) {
    return xhr(monitoringQuery_api, val)
  },
  eldermonitorQuery_controller(val) {
    return xhr(eldermonitorQuery_api, val)
  },
  elderstatistics_controller(val) {
    return xhr(elderstatistics_api, val)
  },
  checkcount_controller() {
    return xhr(checkcount_api)
  },
  categorycount_controller() {
    return xhr(categorycount_api)
  },
  robotattention_controller(val) {
    return xhr(robotattention_api, val)
  },
  homestatistics_controller(val) {
    return xhr(homestatistics_api, val)
  },
  robotlocate_controller(val) {
    return xhr(robotlocate_api, val)
  }
}
