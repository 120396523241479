<template>
  <div>
    <div
      class="m-box col m-mt-10 box"
      style="background: none; padding-bottom: 0"
    >
      <a-form
        class="ant-advanced-search-form"
        :form="form"
        @submit="handleSearch"
      >
        <a-row :gutter="24">
          <a-col :span="8">
            <div class="gutter-box">
              <a-form-item label="查询时间">
                <a-range-picker v-decorator="['date']" />
              </a-form-item>
            </div>
          </a-col>
          <a-col :span="6">
            <div class="gutter-box">
              <a-form-item>
                <a-button type="primary" html-type="submit"> 搜索 </a-button>
                <!-- <a-button
                  type="primary"
                  style="margin-left: 50px"
                  @click="up()"
                >
                  重置
                </a-button> -->
              </a-form-item>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="m-box box" style="background: none; padding-top: 0">
      <!-- 顶部 -->
      <Top :toplist.sync="toplist" />
      <!-- 中间 -->
      <Two :twolist.sync="twolist" :two-onelist.sync="twoOnelist" />
      <!-- 第三部分 -->
      <Three :threelist.sync="threelist" :three-onelist.sync="threeOnelist" />
      <!-- 最下边 -->
      <Bottom :bottomlist.sync="bottomlist" />
    </div>
  </div>
</template>
<script>
// var echarts = require('echarts')
import {
  // center_query_controller,
  ageGenderRank_controller,
  deviceTypeDetect_controller,
  genderDetectRank_controller,
  usage_controller,
  userDetectSos_controller,
  variousDetectSituation_controller
} from '../../static/controller/cost.controller'
import moment from 'moment'

export default {
  filters: {},
  components: {
    Top: () => import('../../components/echarts/top.vue'),
    Two: () => import('../../components/echarts/two.vue'),
    Three: () => import('../../components/echarts/three.vue'),
    Bottom: () => import('../../components/echarts/bottom.vue')
  },
  data() {
    return {
      form: this.$form.createForm(this, {
        name: 'evalutaion'
      }),
      bol: 456,
      beginDate: '',
      endDate: '',
      list: '',
      // 今日注册
      getEcharts1list: '',
      // 第四个整体检测趋势
      myEchartslist: '',
      // 检测次数排行
      myChartnumlist: '',
      // 异常检测项排行
      myChartabnormallist: '',
      // 注册用户年龄分布
      myChartagelist: '',
      // 活跃检测人群排行
      myChartcrowdlist: '',
      // 注册用户年龄分布
      myChartsequiplist: '',
      // 整体检测趋势
      myCharts5list: '',
      // 结果占比
      // myChartspie1list: '',
      // 总用户量
      myCharts2list: '',
      // 总检测量
      myCharts3list: '',
      // sos触发量
      myCharts4list: '',
      toplist: [],
      twolist: [],
      twoOnelist: [],
      threelist: [],
      threeOnelist: [],
      bottomlist: {}
    }
  },

  computed: {},

  created() {
    this.createBeginDate()
    this.getdate()
  },
  beforeMount() {
    this.twolist = []
    this.twoOnelist = []
    this.threelist = []
    this.threeOnelist = []
    this.toplist = []
    this.topGetElderList(this.beginDate, this.endDate)
    this.twoGetElderList(this.beginDate, this.endDate)
    this.threeGetElderList(this.beginDate, this.endDate)
    this.bottomGetElderList(this.beginDate, this.endDate)
  },
  mounted() {},
  methods: {
    moment,
    up() {
      this.twolist = []
      this.twoOnelist = []
      this.threelist = []
      this.threeOnelist = []
      this.toplist = []
      this.topGetElderList(this.beginDate, this.endDate)
      this.twoGetElderList(this.beginDate, this.endDate)
      this.threeGetElderList(this.beginDate, this.endDate)
      this.bottomGetElderList(this.beginDate, this.endDate)
    },
    getdate() {
      var date = new Date()
      var seperator1 = '-'
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      this.beginDate = year + seperator1 + month + seperator1 + strDate
      return this.beginDate
    },
    createBeginDate() {
      var date = new Date()
      date.setMonth(date.getMonth() - 6)
      date.toLocaleDateString()
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      this.endDate = y + '-' + m + '-' + d
      return this.endDate
    },
    topGetElderList(start, end) {
      userDetectSos_controller({
        beginDate: end,
        endDate: start
      }).then((res) => {
        // 第一部分
        this.toplist.push(res.equipDataCenterUserMgrModel)
        this.toplist.push(res.equipDataCenterUserMgrModel)
        this.toplist.push(res.equipDataCenterDetectMgrModel)
        this.toplist.push(res.equipDataCenterSosMgrModel)
        this.form.resetFields()
      })
    },
    twoGetElderList(start, end) {
      deviceTypeDetect_controller({
        beginDate: end,
        endDate: start
      }).then((res) => {
        this.twoOnelist.push(res)
      })
      genderDetectRank_controller({
        beginDate: end,
        endDate: start
      }).then((res) => {
        this.twolist.push(res.equipDataCenterDetectAnomaliesRankMgrModel)
        this.twolist.push(res.equipDataCenterDetectRankMgrModel)
      })
    },
    threeGetElderList(start, end) {
      ageGenderRank_controller({
        beginDate: end,
        endDate: start
      }).then((res) => {
        this.threelist.push(res.equipDataCenterRegisterUserAgeRangeMgrModel)
        this.threelist.push(res.equipDataCenterUserDetectRankMgrModel)
      })
      usage_controller({
        beginDate: end,
        endDate: start
      }).then((res) => {
        this.threeOnelist.push(res)
      })
    },
    bottomGetElderList(start, end) {
      variousDetectSituation_controller({
        beginDate: end,
        endDate: start
      }).then((res) => {
        this.bottomlist = res
      })
    },
    handleSearch(e) {
      e.preventDefault()
      this.form.validateFields((error, values) => {
        if (error) {
          return
        }
        if (!values.date) {
          this.up()
        } else {
          this.twolist = []
          this.twoOnelist = []
          this.threelist = []
          this.threeOnelist = []
          this.toplist = []
          let start = moment(values.date[0]._d).format('L').replaceAll('/', '-')
          let end = moment(values.date[1]._d).format('L').replaceAll('/', '-')
          console.log(start, end)
          this.topGetElderList(end, start)
          this.twoGetElderList(end, start)
          this.threeGetElderList(end, start)
          this.bottomGetElderList(end, start)
        }
      })
    }
  }
}
</script>

<style  scoped>
.sp {
  font-size: 36px;
  color: #ff8847;
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box {
  background: #fff;
  padding: 5px 0;
  margin-top: 10px;
}
</style>
