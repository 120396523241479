/*
 * @Author: your name
 * @Date: 2021-03-06 11:47:03
 * @LastEditTime: 2021-03-10 19:22:13
 * @LastEditors: Please set LastEditors
 * @Description: 楼栋管理
 * @FilePath: \ioac-tenant-html\src\static\api\FloorMgr.api.js
 */
let {
  ic
} = require('../config/api_spec')
module.exports = {
  floorTree_api: {
    url: `${ic}/mgr/ioac/floor/tree`,
    post: {
      organCode: '?'
    }
  },
  addFloor_api: {
    url: `${ic}/mgr/ioac/floor/add`,
    post: {
      id: '?',
      name: '?',
      orders: '?',
      organCode: '?',
      parentId: '?',
      tenantCode: '?',
      treePath: '?',
      type: '?' // 1:楼栋，2:单元，3：层，4：房间
    }
  },
  removeFloor_api: {
    url: `${ic}/mgr/ioac/floor/remove`,
    post: {
      id: '?'
    }
  },
  updateFloor_api: {
    url: `${ic}/mgr/ioac/floor/update`,
    post: {
      id: '?',
      name: '?',
      orders: '?',
      organCode: '?',
      parentId: '?',
      tenantCode: '?',
      treePath: '?',
      type: '?' // 1:楼栋，2:单元，3：层，4：房间
    }
  },
  // 查询当前楼层房间信息
  roomsByFloor_api: {
    url: `${ic}/mgr/ioac/floor/roomsByFloor`,
    post: {
      floorCode: '?',
      organCode: '?'
    }
  },
  amountByType_api: {
    url: `${ic}/mgr/ioac/floor/amountByType`,
    post: {
      organCode: '?',
      type: '?'
    }
  }
}
