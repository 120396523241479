/*
 * @Author: your name
 * @Date: 2021-06-02 13:19:11
 * @LastEditTime: 2021-06-02 13:19:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\AccountInfoMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')

const {
  addAccountInfo_api,
  getByCustomerNo_api,
  queryAccountInfo_api,
  queryList_api,
  depositAcct_api,
  updateState_api,
  butler_batchDelete_api,
  butler_export_api,
  butler_getInfoById_api,
  butler_importUser_api,
  butler_queryList_api,
  butler_save_api,
  butler_updatePassword_api,
  knowledgeBaseAdd_api,
  queryKnowledgeBase_api,
  AddEquipAnalysis_api,
  UpdateEquipAnalysis_api,
  equipAnalysisQueryAll_api,
  disableEquipAnalysisById_api,
  getEquipAnalysisById_api,
  removeEquipAnalysisById_api,
  BatchRemoveKnowledgeSubjectInfo_api,
  UpdateBaseInfoBySid_api,
  UpdateBaseKNameByKid_api,
  createKnowledgeSubjectInfo_api,
  queryBySubjectSid_api,
  queryKnowledgeSubjectInfoList_api,
  releaseKnowledgeSubjectInfoList_api,
  equipment_add_api,
  equipment_equip_api,
  equipment_batchequip_api,
  equipment_deleteEquipInfoById_api,
  equipment_editEquipInfo_api,
  equipment_getEquipInfoById_api,
  equipment_query_api,
  queryAllEquipTypeAndModel_api,
  type_add_api,
  deleteEquipTypeInfoById_api,
  editEquipTypeInfo_api,
  getEquipTypeInfoById_api,
  type_query_api,
  deleteOrganById_api,
  account_queryList_api,
  account_queryTree_api,
  account_save_api,
  account_updatePassword_api,
  account_updateState_api,
  enduser_query_api,
  enduser_queryList_api,
  enduser_update_api,
  center_query_api,
  equipment_batchDeleteEquipInfo_api,
  ageGenderRank_api,
  deviceTypeDetect_api,
  genderDetectRank_api,
  usage_api,
  userDetectSos_api,
  variousDetectSituation_api,
  enduserByType_api,
  unbindOrgan_api
} = require('../api/AccountInfoMgr.api')

module.exports = {
  addAccountInfo_service(val) {
    return xhr(addAccountInfo_api, val)
  },
  getByCustomerNo_service(val) {
    return xhr(getByCustomerNo_api, val)
  },
  queryAccountInfo_service(val) {
    return xhr(queryAccountInfo_api, val)
  },
  querylist_service(val) {
    return xhr(queryList_api, val)
  },
  depositAcct_service(val) {
    return xhr(depositAcct_api, val)
  },
  updateState_service(val) {
    return xhr(updateState_api, val)
  },

  // 管家
  butler_batchDelete_service(val) {
    return xhr(butler_batchDelete_api, val)
  },
  butler_export_service(val) {
    return xhr(butler_export_api, val)
  },
  butler_getInfoById_service(val) {
    return xhr(butler_getInfoById_api, val)
  },
  butler_importUser_service(val) {
    return xhr(butler_importUser_api, val)
  },
  butler_queryList_service(val) {
    return xhr(butler_queryList_api, val)
  },
  butler_save_service(val) {
    return xhr(butler_save_api, val)
  },
  butler_updatePassword_service(val) {
    return xhr(butler_updatePassword_api, val)
  },
  // 知识库
  knowledgeBaseAdd_service(val) {
    return xhr(knowledgeBaseAdd_api, val)
  },
  queryKnowledgeBase_service(val) {
    return xhr(queryKnowledgeBase_api, val)
  },
  // 检测分析
  AddEquipAnalysis_service(val) {
    return xhr(AddEquipAnalysis_api, val)
  },
  UpdateEquipAnalysis_service(val) {
    return xhr(UpdateEquipAnalysis_api, val)
  },
  equipAnalysisQueryAll_service(val) {
    return xhr(equipAnalysisQueryAll_api, val)
  },
  disableEquipAnalysisById_service(val) {
    return xhr(disableEquipAnalysisById_api, val)
  },
  getEquipAnalysisById_service(val) {
    return xhr(getEquipAnalysisById_api, val)
  },
  removeEquipAnalysisById_service(val) {
    return xhr(removeEquipAnalysisById_api, val)
  },

  // 知识库操作

  BatchRemoveKnowledgeSubjectInfo_service(val) {
    return xhr(BatchRemoveKnowledgeSubjectInfo_api, val)
  },
  UpdateBaseInfoBySid_service(val) {
    return xhr(UpdateBaseInfoBySid_api, val)
  },
  UpdateBaseKNameByKid_service(val) {
    return xhr(UpdateBaseKNameByKid_api, val)
  },
  createKnowledgeSubjectInfo_service(val) {
    return xhr(createKnowledgeSubjectInfo_api, val)
  },
  queryBySubjectSid_service(val) {
    return xhr(queryBySubjectSid_api, val)
  },
  queryKnowledgeSubjectInfoList_service(val) {
    return xhr(queryKnowledgeSubjectInfoList_api, val)
  },
  releaseKnowledgeSubjectInfoList_service(val) {
    return xhr(releaseKnowledgeSubjectInfoList_api, val)
  },
  // 设备信息管理
  equipment_add_service(val) {
    return xhr(equipment_add_api, val)
  },
  equipment_equip_service(val) {
    return xhr(equipment_equip_api, val)
  },
  equipment_batchequip_service(val) {
    return xhr(equipment_batchequip_api, val)
  },
  equipment_deleteEquipInfoById_service(val) {
    return xhr(equipment_deleteEquipInfoById_api, val)
  },
  equipment_editEquipInfo_service(val) {
    return xhr(equipment_editEquipInfo_api, val)
  },
  equipment_getEquipInfoById_service(val) {
    return xhr(equipment_getEquipInfoById_api, val)
  },
  equipment_query_service(val) {
    return xhr(equipment_query_api, val)
  },
  queryAllEquipTypeAndModel_service(val) {
    return xhr(queryAllEquipTypeAndModel_api, val)
  },
  equipment_batchDeleteEquipInfo_service(val) {
    return xhr(equipment_batchDeleteEquipInfo_api, val)
  },

  //  设备类型
  type_add_service(val) {
    return xhr(type_add_api, val)
  },

  deleteEquipTypeInfoById_service(val) {
    return xhr(deleteEquipTypeInfoById_api, val)
  },
  editEquipTypeInfo_service(val) {
    return xhr(editEquipTypeInfo_api, val)
  },
  getEquipTypeInfoById_service(val) {
    return xhr(getEquipTypeInfoById_api, val)
  },
  type_query_service(val) {
    return xhr(type_query_api, val)
  },

  //  机构管理  账户信息查询
  deleteOrganById_service(val) {
    return xhr(deleteOrganById_api, val)
  },
  account_queryList_service(val) {
    return xhr(account_queryList_api, val)
  },
  account_queryTree_service(val) {
    return xhr(account_queryTree_api, val)
  },
  account_save_service(val) {
    return xhr(account_save_api, val)
  },
  account_updatePassword_service(val) {
    return xhr(account_updatePassword_api, val)
  },
  account_updateState_service(val) {
    return xhr(account_updateState_api, val)
  },
  enduser_query_service(val) {
    return xhr(enduser_query_api, val)
  },
  enduser_queryList_service(val) {
    return xhr(enduser_queryList_api, val)
  },
  enduser_update_service(val) {
    return xhr(enduser_update_api, val)
  },
  center_query_service(val) {
    return xhr(center_query_api, val)
  },
  ageGenderRank_service(val) {
    return xhr(ageGenderRank_api, val)
  },
  deviceTypeDetect_service(val) {
    return xhr(deviceTypeDetect_api, val)
  },
  genderDetectRank_service(val) {
    return xhr(genderDetectRank_api, val)
  },
  usage_service(val) {
    return xhr(usage_api, val)
  },
  userDetectSos_service(val) {
    return xhr(userDetectSos_api, val)
  },
  variousDetectSituation_service(val) {
    return xhr(variousDetectSituation_api, val)
  },
  enduserByType_service(val) {
    return xhr(enduserByType_api, val)
  },
  // 设备解除机构绑定
  unbindOrgan_service(val) {
    return xhr(unbindOrgan_api, val)
  }
}
console.log(account_queryTree_api)
