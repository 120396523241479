var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-scroll"},[_c('div',{staticClass:"m-box col m-mt-10 box"},[_c('a-form',{staticClass:"fff",attrs:{"form":_vm.form,"label-col":{ span: 0 },"wrapper-col":{ span: 22 }},on:{"submit":_vm.handleSearch}},[_c('a-row',{attrs:{"type":"flex"}},[_c('a-col',{attrs:{"span":3}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'organCode',
                {
                  initialValue: [],
                  rules: [
                    {
                      type: 'array',
                      required: false,
                      message: '选择机构',
                    },
                  ],
                },
              ]),expression:"[\n                'organCode',\n                {\n                  initialValue: [],\n                  rules: [\n                    {\n                      type: 'array',\n                      required: false,\n                      message: '选择机构',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"所有机构","change-on-select":"","options":_vm.companyData,"field-names":{
                label: 'name',
                value: 'companyNo',
                children: 'children',
              }}})],1)],1),_c('a-col',{staticClass:"ant-form-item",attrs:{"span":2}},[_c('a-button',{staticClass:"m-mt-4",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("查询")])],1),_c('a-col',{staticClass:"ant-form-item text-right",attrs:{"span":19}},[_c('a-button',{staticClass:"m-mt-4 m-ml-10",attrs:{"type":"primary"},on:{"click":function($event){return _vm.add()}}},[_vm._v("新增机构")])],1)],1)],1)],1),_c('div',{staticClass:"m-box m-mt-10"},[_c('a-row',{attrs:{"type":"flex"}},[_c('a-col',{attrs:{"span":24}},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"pagination":false,"scroll":{ x: 1850 }},scopedSlots:_vm._u([{key:"photo",fn:function(record){return _c('div',{staticClass:"text-3d9"},[_c('img',{staticClass:"photo",attrs:{"src":record}})])}},{key:"status",fn:function(record){return _c('div',{staticClass:"text-3d9"},[_c('span',{style:(record.organStatus == '1'
                  ? 'color: #BEE6CF; padding-left: 15px'
                  : 'color: #FF1818; padding-left: 15px')},[_vm._v(_vm._s(record.organStatus === "1" ? "使用中" : "已禁用"))])])}},{key:"createdTime",fn:function(record){return _c('div',{staticClass:"text-3d9"},[_c('span',[_vm._v(_vm._s(record.substr(0, 10)))])])}},{key:"lastLoginTime",fn:function(record){return _c('div',{staticClass:"text-3d9"},[_c('span',[_vm._v(_vm._s(record.substr(0, 10)))])])}},{key:"use",fn:function(record){return _c('div',{staticClass:"text-3d9"},[_c('a-button',{staticStyle:{"background":"##1890ff","border-color":"##1890ff","margin-right":"10px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.password(record)}}},[_vm._v("密码")]),_c('a-button',{staticStyle:{"background":"##1890ff","border-color":"##1890ff"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.setItem(record)}}},[_vm._v("编辑")]),_c('a-button',{staticClass:"m-ml-10",style:(record.organStatus === '1'
                  ? 'background: #ff9d4d; border-color: #ff9d4d'
                  : 'background: rgb(56, 178, 107);border-color: rgb(56, 178, 107)'),attrs:{"type":"primary"},on:{"click":function($event){return _vm.state(record)}}},[_vm._v(_vm._s(record.organStatus === "1" ? "禁用" : "启用")+" ")]),_c('a-button',{staticClass:"m-ml-10",attrs:{"type":"danger"},on:{"click":function($event){return _vm.del(record)}}},[_vm._v("删除")]),_c('a-button',{staticClass:"m-ml-10",attrs:{"type":"primary"},on:{"click":function($event){return _vm.distribute(record)}}},[_vm._v("分配注册码")])],1)}}])},[_vm._v(" > ")]),_c('pagination',{staticClass:"paginationStyle",attrs:{"page":_vm.pageIndex,"limit":_vm.pageSize,"total":_vm.total},on:{"update:page":function($event){_vm.pageIndex=$event},"update:limit":function($event){_vm.pageSize=$event},"pagination":_vm.getPages}})],1)],1)],1),_c('a-modal',{attrs:{"visible":_vm.visible,"footer":null,"mask-closable":false},on:{"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.addForm,"label-col":{ span: 6 },"wrapper-col":{ span: 17 }},on:{"submit":_vm.handSave}},[_c('a-form-item',{attrs:{"label":"机构名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                {
                  required: true,
                  trigger: 'blur',
                  message: '请输入机构名称',
                },
                {
                  max: 20,
                  message: '机构名称不能超过20个字',
                }
              ],
              initialValue: _vm.addInfo.name,
            },
          ]),expression:"[\n            'name',\n            {\n              rules: [\n                {\n                  required: true,\n                  trigger: 'blur',\n                  message: '请输入机构名称',\n                },\n                {\n                  max: 20,\n                  message: '机构名称不能超过20个字',\n                }\n              ],\n              initialValue: addInfo.name,\n            },\n          ]"}],attrs:{"placeholder":"请输入姓名"}})],1),_c('a-form-item',{attrs:{"label":"机构logo"}},[_c('UploadImg',{attrs:{"url":_vm.frontUrl,"tip":"上传照片","width":280,"height":100},on:{"update:url":function($event){_vm.frontUrl=$event}}})],1),_c('a-form-item',{attrs:{"label":"用户登录账户"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'id',
            {
              rules: [
                {
                  required: true,
                  trigger: 'blur',
                  message: '请输入登录账户',
                },
              ],
              initialValue: _vm.addInfo.age,
            },
          ]),expression:"[\n            'id',\n            {\n              rules: [\n                {\n                  required: true,\n                  trigger: 'blur',\n                  message: '请输入登录账户',\n                },\n              ],\n              initialValue: addInfo.age,\n            },\n          ]"}],attrs:{"placeholder":"","max-length":11}})],1),_c('a-form-item',{attrs:{"label":"联系方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'phone',
            {
              rules: [
                {
                  required: true,
                  validator: _vm.validatorPhone,
                  trigger: 'blur',
                },
              ],
              initialValue: _vm.addInfo.phone,
            },
          ]),expression:"[\n            'phone',\n            {\n              rules: [\n                {\n                  required: true,\n                  validator: validatorPhone,\n                  trigger: 'blur',\n                },\n              ],\n              initialValue: addInfo.phone,\n            },\n          ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"所属机构"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'companyName',
            {
              rules: [
                {
                  required: true,
                  trigger: 'blur',
                },
              ],
              initialValue: _vm.companyName,
            },
          ]),expression:"[\n            'companyName',\n            {\n              rules: [\n                {\n                  required: true,\n                  trigger: 'blur',\n                },\n              ],\n              initialValue: companyName,\n            },\n          ]"}],attrs:{"disabled":"","placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"角色访问授权"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'permissions',
            {
              rules: [
                {
                  required: true,
                  trigger: 'blur',
                  message: '请选择角色授权',
                },
              ],
              initialValue: _vm.addInfo.permissions,
            },
          ]),expression:"[\n            'permissions',\n            {\n              rules: [\n                {\n                  required: true,\n                  trigger: 'blur',\n                  message: '请选择角色授权',\n                },\n              ],\n              initialValue: addInfo.permissions,\n            },\n          ]"}],attrs:{"placeholder":"","allow-clear":true}},_vm._l((_vm.rolelist),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","size":_vm.size}},[_vm._v(" 确认 ")]),_c('a-button',{staticStyle:{"background":"#999","border-color":"#999"},attrs:{"type":"primary","size":_vm.size},on:{"click":_vm.handleCancel}},[_vm._v(" 取消 ")])],1)])],1)],1),_c('a-modal',{attrs:{"visible":_vm.visible1,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.updateForm,"label-col":{ span: 6 },"wrapper-col":{ span: 17 }},on:{"submit":_vm.handSave1}},[_c('a-form-item',{attrs:{"label":"机构名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                {
                  required: true,
                  trigger: 'blur',
                  message: '请输入机构名称',
                },
                {
                  max: 20,
                  message: '机构名称不能超过20个字',
                }
              ],
              initialValue: _vm.info.organName,
            },
          ]),expression:"[\n            'name',\n            {\n              rules: [\n                {\n                  required: true,\n                  trigger: 'blur',\n                  message: '请输入机构名称',\n                },\n                {\n                  max: 20,\n                  message: '机构名称不能超过20个字',\n                }\n              ],\n              initialValue: info.organName,\n            },\n          ]"}],attrs:{"placeholder":"请输入姓名"}})],1),_c('a-form-item',{attrs:{"label":"机构logo"}},[_c('UploadImg',{attrs:{"url":_vm.info.logo,"tip":"上传照片","width":280,"height":100},on:{"update:url":function($event){return _vm.$set(_vm.info, "logo", $event)}}})],1),_c('a-form-item',{attrs:{"label":"登录账户"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'count',
            {
              rules: [
                {
                  required: true,
                  trigger: 'blur',
                  message: '请输入登录账户',
                },
              ],
              initialValue: _vm.info.name,
            },
          ]),expression:"[\n            'count',\n            {\n              rules: [\n                {\n                  required: true,\n                  trigger: 'blur',\n                  message: '请输入登录账户',\n                },\n              ],\n              initialValue: info.name,\n            },\n          ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"联系方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'phone',
            {
              rules: [
                {
                  required: true,
                  validator: _vm.validatorPhone,
                  trigger: 'blur',
                },
              ],
              initialValue: _vm.info.phone,
            },
          ]),expression:"[\n            'phone',\n            {\n              rules: [\n                {\n                  required: true,\n                  validator: validatorPhone,\n                  trigger: 'blur',\n                },\n              ],\n              initialValue: info.phone,\n            },\n          ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"所属机构"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'companyName',
            {
              rules: [
                {
                  required: true,
                  trigger: 'blur',
                },
              ],
              initialValue: _vm.companyName,
            },
          ]),expression:"[\n            'companyName',\n            {\n              rules: [\n                {\n                  required: true,\n                  trigger: 'blur',\n                },\n              ],\n              initialValue: companyName,\n            },\n          ]"}],attrs:{"disabled":"","placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"角色访问授权"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'permissions',
            {
              rules: [
                {
                  required: true,
                  trigger: 'blur',
                  message: '请选择角色授权',
                },
              ],
              initialValue: _vm.info.roleId,
            },
          ]),expression:"[\n            'permissions',\n            {\n              rules: [\n                {\n                  required: true,\n                  trigger: 'blur',\n                  message: '请选择角色授权',\n                },\n              ],\n              initialValue: info.roleId,\n            },\n          ]"}],attrs:{"placeholder":"","allow-clear":true}},_vm._l((_vm.rolelist),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","size":_vm.size}},[_vm._v(" 确认 ")]),_c('a-button',{staticStyle:{"background":"#999","border-color":"#999"},attrs:{"type":"primary","size":_vm.size},on:{"click":_vm.handleCancel}},[_vm._v(" 取消 ")])],1)])],1)],1),_c('a-modal',{attrs:{"visible":_vm.visible2,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.passForm,"label-col":{ span: 6 },"wrapper-col":{ span: 17 }},on:{"submit":_vm.handSave2}},[_c('a-form-item',{attrs:{"label":"用户账户"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                {
                  required: true,
                  trigger: 'blur',
                },
              ],
              initialValue: _vm.passinfo.name,
            },
          ]),expression:"[\n            'name',\n            {\n              rules: [\n                {\n                  required: true,\n                  trigger: 'blur',\n                },\n              ],\n              initialValue: passinfo.name,\n            },\n          ]"}],attrs:{"placeholder":"请输入姓名","disabled":""}})],1),_c('a-form-item',{attrs:{"label":"用户旧密码"}},[_c('a-input',{attrs:{"type":"password","disabled":""},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})],1),_c('a-form-item',{attrs:{"label":"新的登录密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: '请输入密码!',
                },
                {
                  validator: _vm.validateToNextPassword,
                },
              ],
              initialValue: _vm.passinfo.password,
            },
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入密码!',\n                },\n                {\n                  validator: validateToNextPassword,\n                },\n              ],\n              initialValue: passinfo.password,\n            },\n          ]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{attrs:{"label":"重复新的密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'confirm',
            {
              rules: [
                {
                  required: true,
                  message: '请再次输入密码!',
                },
                {
                  validator: _vm.compareToFirstPassword,
                },
              ],
              initialValue: _vm.passinfo.password,
            },
          ]),expression:"[\n            'confirm',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请再次输入密码!',\n                },\n                {\n                  validator: compareToFirstPassword,\n                },\n              ],\n              initialValue: passinfo.password,\n            },\n          ]"}],attrs:{"type":"password"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","size":_vm.size}},[_vm._v(" 保存 ")]),_c('a-button',{staticStyle:{"background":"#999","border-color":"#999"},attrs:{"type":"primary","size":_vm.size},on:{"click":_vm.handleCancel}},[_vm._v(" 取消 ")])],1)])],1)],1),_c('DistributeParams',{ref:"distribute",on:{"refresh":_vm.refresh}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }