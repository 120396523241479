/*
 * @Author: your name
 * @Date: 2021-03-03 10:41:41
 * @LastEditTime: 2021-04-27 10:37:36
 * @LastEditors:
 * @Description: 用户管理
 * @FilePath: \ioac-tenant-html\src\static\api\UserMgr.api.js
 */
let {
  uc
} = require('../config/api_spec')

module.exports = {
  // 用户列表查询
  userQuery_api: {
    url: `${uc}/mgr/user/user/query`,
    post: {
      roleCode: '?', // 角色编号
      mobile: '?', // 手机号
      nickName: '?', // 昵称
      page: '?',
      rows: '?'
    }
  },
  // 查看所有用户列表
  userallQuery_api: {
    url: `${uc}/mgr/user/user/queryAll`,
    post: {
      roleCode: '?', // 角色编号
      mobile: '?', // 手机号
      nickName: '?', // 昵称
      page: '?',
      rows: '?'
    }
  },
  // 更改用户状态 1：正常，2：冻结 ，3：删除
  changeState_api: {
    url: `${uc}/mgr/user/user/changeState`,
    post: {
      id: '?', // 用户id
      state: '?' // 1：正常，2：冻结 ，3：删除
    }
  },
  // 新增用户
  userSave_api: {
    url: `${uc}/mgr/user/user/save`,
    post: {
      userName: '?', // 用户名
      password: '?', // 密码
      realName: '?', // 用户昵称
      mobile: '?', // 手机
      headImg: '?', // 头像
      sourceOne: '?', // 1.自助注册,9.后台新增
      userTypeOne: '?', // 1.员工，2.客户
      roles: '?',
      remark: '?'
    }
  },
  // 用户信息
  getUserInfo_api: {
    url: `${uc}/mgr/user/user/get`,
    method: 'GET',
    post: {
      id: '?'
    }
  },
  // 修改用户信息
  updateUser_api: {
    url: `${uc}/mgr/user/user/update`,
    post: {
      id: '?',
      realName: '?',
      headImg: '?',
      remark: '?',
      roles: '?',
      mobile: '?',
      sourceOne: '?', // 1.自助注册,9.后台新增
      userTypeOne: '?' // 1.员工，2.客户
    }
  }
}
