/*
 * @Description:
 * @Author:
 * @Date: 2021-03-20 11:26:25
 * @LastEditTime: 2021-03-20 11:29:55
 * @LastEditors:
 */
const {
  xhr
} = require('../lib/xhr')
const {
  addbizCode_api,
  updatebizCode_api,
  querybizCode_api,
  removebizCode_api
} = require('../api/BizCodeMgr.api')

module.exports = {
  addbizCode_service(val) {
    return xhr(addbizCode_api, val)
  },
  updatebizCode_service(val) {
    return xhr(updatebizCode_api, val)
  },
  querybizCode_service(val) {
    return xhr(querybizCode_api, val)
  },
  removebizCode_service(val) {
    return xhr(removebizCode_api, val)
  }
}
