/*
 * @Description:
 * @Author:
 * @Date: 2021-03-19 14:27:40
 * @LastEditTime: 2021-04-12 10:37:04
 * @LastEditors:
 */
let {
  ic
} = require('../config/api_spec')
module.exports = {
  addfinance_api: {
    url: `${ic}/mgr/finance/order/add`,
    post: {
      acctType: '?',
      customerRegisterNo: '?',
      feeId: '?',
      inOut: '?',
      month: '?', // 交易方式
      orderType: '?',
      payTerm: '?',
      fund: '?',
      customerName: '?',
      remark: '?'
    }
  },

  updatefinance_api: {
    url: `${ic}/mgr/finance/order/update`,
    post: {
      fund: '?',
      acctType: '?',
      customerRegisterNo: '?',
      feeId: '?',
      inOut: '?',
      month: '?', // 交易方式
      orderType: '?',
      payTerm: '?',
      sn: '?',
      customerName: '?',
      remark: '?'

    }
  },
  removefinance_api: {
    url: `${ic}/mgr/finance/order/delete`,
    get: {
      sn: '?'
    }
  },
  detailfinance_api: {
    url: `${ic}/mgr/finance/order/get`,
    get: {
      sn: '?'
    }
  },
  queryfinance_api: {
    url: `${ic}/mgr/finance/order/query`,
    post: {
      page: '?',
      rows: '?',
      feeId: '?',
      orderType: '?',
      acctType: '?',
      customerRegisterNo: '?',
      customerName: '?',
      payTerm: '?'
    }
  }

}
