/*
 * @Author: your name
 * @Date: 2021-03-23 16:02:45
 * @LastEditTime: 2022-01-18 17:19:34
 * @LastEditors:
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\utils\init_title.js
 */

let init_router_beforeEach = (router) => {
  router.beforeEach((to, from, next) => {
    console.log(from)
    window.scrollTo(0, 0)
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
  })
}

module.exports = {
  init_router_beforeEach
}
