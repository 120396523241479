/*
 * @Author: your name
 * @Date: 2021-03-06 13:27:56
 * @LastEditTime: 2021-03-08 16:15:32
 * @LastEditors: Please set LastEditors
 * @Description: 机构管理
 * @FilePath: \ioac-tenant-html\src\static\api\OrganMgr.api.js
 */
let {
  ic
} = require('../config/api_spec')
module.exports = {
  organQuery_api: {
    url: `${ic}/mgr/ioac/organ/query`,
    post: {
      provinceCode: '?', // 省
      cityCode: '?', // 市
      hotelCode: '?', // 区
      name: '?', // 名称查询
      page: '?',
      rows: '?'
    }
  },
  organAdd_api: {
    url: `${ic}/mgr/ioac/organ/add`,
    post: {
      provinceCode: '?', // 省
      provinceName: '?',
      cityCode: '?', // 市
      cityName: '?',
      hotelCode: '?', // 区
      hotelName: '?',
      img: '?',
      name: '?', // 名称
      remark: '?',
      other: '?',
      organCode: '?', // 机构编号
      state: 1
    }
  },
  // 删除机构
  organRemove_api: {
    url: `${ic}/mgr/ioac/organ/remove`,
    method: 'GET',
    post: {
      organCode: '?'
    }
  },
  organUpdate_api: {
    url: `${ic}/mgr/ioac/organ/update`,
    post: {
      provinceCode: '?', // 省
      provinceName: '?',
      cityCode: '?', // 市
      cityName: '?',
      hotelCode: '?', // 区
      hotelName: '?',
      img: '?',
      name: '?', // 名称
      remark: '?',
      other: '?',
      organCode: '?', // 机构编号
      state: '?'
    }
  },
  // 机构列表查询
  queryByUser_api: {
    url: `${ic}/mgr/ioac/organ/queryByUser`,
    post: {

    }
  },
  // 机构详情
  getOrgan_api: {
    url: `${ic}/mgr/ioac/organ/get`,
    method: 'GET',
    post: {
      code: '?'
    }
  },
  // 获取机构选项
  getOrganOptions_api: {
    url: `${ic}/mgr/equip/organization/account/options`,
    method: 'GET'
  }
}
