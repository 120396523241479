<template>
  <div class="view-scroll">
    <div class="m-box col m-mt-10 box">
      <a-row type="flex">
        <a-col :span="3" class="ant-form-item">
          <a-button type="primary" class="m-mt-4" @click="add">
            新增套餐
          </a-button>
        </a-col>
      </a-row>
    </div>

    <div class="m-box m-mt-10">
      <a-table :columns="columns" :data-source="data" :pagination="false" row-key="id">
        <div slot="templateType" slot-scope="row">
          <span>{{
            row === "0" ? "通用套餐" : "机构专属套餐"
          }}</span>
        </div>
        <div slot="status" slot-scope="row">
          <span>{{
            row === "0" ? "已显示" : "已隐藏"
          }}</span>
        </div>
        <div slot="action" slot-scope="record" class="text-3d9">
          <a-button
            type="primary"
            class="m-ml-10"
            @click="detal(record)"
          >查看详情</a-button>
          <a-button
            type="danger"
            class="m-ml-10"
            @click="disabled(record)"
          >
            {{
              record.status === "0" ? "隐藏" : "显示"
            }}</a-button>
        </div>
      </a-table>

      <Pagination
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        :total="total"
        class="paginationStyle"
        @pagination="getPages"
      />
    </div>
    <!-- 新增套餐窗口 -->
    <AddTempForm ref="addTempForm" @refresh="getList" />
    <!-- 套餐详情窗口 -->
    <TempDetail ref="tempDetail" />
  </div>
</template>

<script>
import Base from '@/components/subscription/Base'
import AddTempForm from '@/components/subscription/STemplate/AddTempForm/Index'
import TempDetail from '@/components/subscription/STemplate/TempDetail'
import Pagination from '@/components/Pagination'
import {
  getSubscriptionTemplateLists_controller,
  updateTemplateStatus_controller,
  getTemplateMetaDict_controller
} from '../../static/controller/Subscription.controller'
import { mapActions } from 'vuex'

export default {
  components: {
    AddTempForm,
    Pagination,
    TempDetail
  },
  extends: Base,
  data() {
    return {
      columns: [
        {
          title: '套餐名称',
          dataIndex: 'templateName',
          key: 'description',
          ellipsis: true
        },
        {
          title: '套餐修订日期',
          dataIndex: 'createTime'
        },
        {
          title: '套餐修订人',
          dataIndex: 'createUser'
        },
        {
          title: '套餐类型',
          dataIndex: 'templateType',
          scopedSlots: {
            customRender: 'templateType'
          }
        },
        {
          title: '可订购机构',
          dataIndex: 'exclusiveOrgName'
        },
        {
          title: '套餐状态',
          dataIndex: 'status',
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  mounted() {
    // 请求画面数据
    this.getList()
    // 获取规则字典
    this.getTemplateMetaDict(getTemplateMetaDict_controller)
  },
  methods: {
    ...mapActions({
      getTemplateMetaDict: 'subscription/getTemplateMetaDict'
    }),

    /**
     * 新增套餐按钮
     */
    add() {
      this.$refs['addTempForm'].visible = true
    },

    /**
     * 套餐详情
     */
    detal(record) {
      this.$refs.tempDetail.id = record.id
      this.$refs.tempDetail.visible = true
    },

    /**
     * 隐藏/显示套餐
     */
    disabled(record) {
      updateTemplateStatus_controller({
        id: record.id
      }).then(res => {
        this.$message.success('操作成功')
        this.getList()
      })
    },

    /**
     * 请求画面数据
     */
    getList() {
      getSubscriptionTemplateLists_controller({
        page: this.pageIndex,
        rows: this.pageSize
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    }
  }
}
</script>
