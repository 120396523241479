
<template>
  <div class="view-scroll">
    <div class="m-box col m-mt-10 box">
      <a-form
        :form="form"
        :label-col="{ span: 0 }"
        class="fff"
        :wrapper-col="{ span: 22 }"
        @submit="handleSearch"
      >
        <a-row type="flex">
          <a-col :span="4">
            <a-form-item label="">
              <a-input v-decorator="['mobile']" placeholder="请输入查询内容" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-select
                v-decorator="['OneType']"
                placeholder="型号"
                :allow-clear="true"
              >
                <a-select-option value="1代"> 1代 </a-select-option>
                <a-select-option value="2代"> 2代 </a-select-option>
                <a-select-option value="3代"> 3代 </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-form-item label="">
              <a-select
                v-decorator="['OneType1']"
                placeholder="供应商"
                :allow-clear="true"
              >
                <a-select-option value="亿慈智能"> 亿慈智能 </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="3" class="ant-form-item">
            <a-button type="primary" class="m-mt-4" html-type="submit">
              查询
            </a-button>
          </a-col>
          <a-col :span="9" class="ant-form-item text-right">
            <a-button type="primary" class="m-mt-4" @click="add()">
              新增设备
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="m-box m-mt-10">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <div slot="action" slot-scope="record" class="text-3d9">
          <a-button
            type="primary"
            class="m-ml-10"
            @click="update(record)"
          >编辑</a-button>
          <a-button
            type="danger"
            class="m-ml-10"
            @click="del(record)"
          >删除</a-button>
        </div>
      </a-table>

      <pagination
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        :total="total"
        class="paginationStyle"
        @pagination="getPages"
      />
    </div>

    <a-modal
      title="编辑设备"
      :visible="visible"
      @ok="handleOkup"
      @cancel="handleCancel"
    >
      <a-form
        :form="upForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-item label="设备类型">
          <a-input
            v-decorator="['typeName', {
              initialValue: addInfo.typeName,
              rules: [
                {
                  required: true,
                  trigger: 'blur',
                  message: '请输入设备类型',
                },
              ]
            }]"
            placeholder="请输入设备类型"
          />
        </a-form-item>
        <a-form-item label="设备型号">
          <a-input
            v-decorator="['model', {
              initialValue: addInfo.model,
              rules: [
                {
                  required: true,
                  trigger: 'blur',
                  message: '请输入设备型号',
                },
              ]
            }]"
            placeholder="请输入设备型号"
          />
        </a-form-item>
        <a-form-item label="保修年限">
          <a-input
            v-decorator="[
              'guaranteeNum', {
                initialValue: addInfo.guaranteeNum,
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请输入保修年限',
                  },
                ]
              },
            ]"
            placeholder="请输入保修年限"
          />
        </a-form-item>
        <a-form-item label="供应商">
          <a-input
            v-decorator="['supplier',{
              initialValue: addInfo.supplier,
              rules: [
                {
                  required: true,
                  trigger: 'blur',
                  message: '请输入供应商',
                },
              ]
            }]"
            placeholder="请输入供应商"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 新增 -->
    <a-modal
      title="新增设备"
      :visible="visible1"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form
        :form="addForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-item label="设备类型">
          <a-input
            v-decorator="[
              'typeName',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请输入设备类型',
                  },
                ]
              }]"
            placeholder="请输入设备类型"
          />
        </a-form-item>
        <a-form-item label="设备型号">
          <a-input
            v-decorator="[
              'model',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请输入设备型号',
                  },
                ]
              }
            ]"
            placeholder="请输入设备型号"
          />
        </a-form-item>
        <a-form-item label="保修年限">
          <a-input
            v-decorator="[
              'guaranteeNum',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请输入保修年限',
                  },
                ]
              }
            ]"
            placeholder="请输入保修年限"
          />
        </a-form-item>
        <a-form-item label="供应商">
          <a-input
            v-decorator="['supplier', {
              rules: [
                {
                  required: true,
                  trigger: 'blur',
                  message: '请输入供应商',
                },
              ]
            }]"
            placeholder="请输入供应商"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// import File from '../../components/file.vue'
import {
  type_add_controller,
  deleteEquipTypeInfoById_controller,
  editEquipTypeInfo_controller,
  //   getEquipTypeInfoById_controller,
  type_query_controller,
  queryAllEquipTypeAndModel_controller
} from '../../static/controller/cost.controller'
const columns = [
  {
    title: '设备类型',
    dataIndex: 'typeName'
  },
  {
    title: '型号',
    dataIndex: 'model'
  },
  {
    title: '保修年数',
    dataIndex: 'guaranteeNum'
  },
  {
    title: '供应商',
    dataIndex: 'supplier'
  },
  {
    title: '创建时间',
    dataIndex: 'cdt'
  },
  {
    title: '操作',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const data = []
export default {
  filters: {},
  components: {
    pagination: () => import('../../components/Pagination')
  },
  data() {
    return {
      data,
      columns,
      selectedRowKeys: [],
      form: this.$form.createForm(this, {
        name: 'evalutaion'
      }),
      addForm: this.$form.createForm(this, {
        name: 'customer_list'
      }),
      upForm: this.$form.createForm(this, {
        name: 'customer_listup'
      }),
      assForm: this.$form.createForm(this, {
        name: 'customer_listass'
      }),
      equiForm: this.$form.createForm(this, {
        name: 'customer_listequi'
      }),
      visible: false,
      visible1: false,
      pageIndex: 1, // 当前页码
      pageSize: 10,
      pagination: {}, // tab分页
      total: 0,
      where: {},
      addInfo: {},
      ids: [],
      equis: [],
      newInfo: {
        typeId: '',
        imei: '',
        sn: '',
        mac: ''
      },
      info: {},
      typeId: '',
      success: false,
      equiList: ''
    }
  },

  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },

  created() {
    this.getElderList()
    queryAllEquipTypeAndModel_controller().then((res) => {
      this.equiList = res
    })
  },
  methods: {
    change(e) {
      this.$refs.file.typeId = e
    },
    getElderList() {
      type_query_controller({
        // ...this.where,
        model: this.where.OneType,
        typeName: this.where.mobile,
        supplier: this.where.OneType1,
        page: this.pageIndex,
        rows: this.pageSize
        // typeName:
      }).then((res) => {
        this.selectedRowKeys = []
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    },

    start() {
      this.loading = true
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },

    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      let ids = []
      let equis = []
      for (let x of selectedRowKeys) {
        for (let i in this.data) {
          console.log(x, i)
          if (x === +i) {
            ids.push(this.data[i].id)
            equis.push(this.data[i].typeName)
          }
        }
      }
      console.log(ids)
      this.ids = ids
      this.equis = equis.filter((item, index) => {
        return equis.indexOf(item, 0) === index
      })
      this.selectedRowKeys = selectedRowKeys
    },
    batchadd() {
      this.visible3 = true
    },
    handleSearch(e) {
      e.preventDefault()
      this.form.validateFields((error, values) => {
        console.log(error, values)
        this.pageIndex = 1
        this.where = values
        this.getElderList()
      })
    },

    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.getElderList()
    },

    add() {
      this.visible1 = true
    },
    update(e) {
      this.upForm.resetFields()
      this.addInfo = e
      this.upForm.setFieldsValue({
        OneType: e.typeName
      })
      this.visible = true
    },
    del(e) {
      deleteEquipTypeInfoById_controller({ id: e.id }).then((res) => {
        this.$message.success('删除成功')
        this.getElderList()
      })
    },
    // 新增
    handleOk(e) {
      e.preventDefault()
      this.addForm.validateFields((error, values) => {
        if (error) {
          return
        }
        type_add_controller({
          guaranteeNum: values.guaranteeNum,
          model: values.model,
          supplier: values.supplier,
          typeName: values.typeName
        }).then((res) => {
          this.visible1 = false
          this.$message.success('新增成功')
          this.getElderList()
          this.addForm.resetFields()
        })
      })
    },
    // 编辑
    handleOkup(e) {
      e.preventDefault()
      this.upForm.validateFields((error, values) => {
        if (error) {
          return
        }
        editEquipTypeInfo_controller({
          guaranteeNum: values.guaranteeNum,
          model: values.model,
          supplier: values.supplier,
          typeName: values.typeName,
          id: this.addInfo.id
        }).then((res) => {
          this.visible = false
          this.$message.success('修改成功')
          this.getElderList()
        })
      })
    },
    handleCancel(e) {
      // console.log(1123)
      this.visible1 = false
      this.visible = false
      this.addForm.resetFields()
    },

    updSuccess() {
      // console.log('chenggong')
      this.success = true
    }
  }
}
</script>
<style scoped lang="less">
.index-img {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 0;
  right: 0;
}

.box .ant-form-item {
  margin: 10px 0 !important;
}

.m-mt-4 {
  margin-top: 4px;
}
.ant-upload.ant-upload-select {
  width: 100% !important;
}
</style>
