/*
 * @Author: your name
 * @Date: 2021-03-09 09:59:31
 * @LastEditTime: 2021-06-30 16:54:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\api\HardwareMgr.api.js
 */

let {
  hc
} = require('../config/api_spec')
module.exports = {
  listDataForSbj_api: { // 查询
    url: `${hc}/mgr/hardware/data/listDataForSbj`,
    post: {

      deviceCode: '?',
      startDate: '?',
      endDate: '?'
    }
  }
}
