/*
 * @Author: your name
 * @Date: 2021-06-29 14:58:52
 * @LastEditTime: 2021-06-29 15:01:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\HardwareFloorMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')

const {
  queryBuildingsFloor_api,
  queryFloorByBuildingFloor_api
} = require('../api/HardwareFloorMgr.api')

module.exports = {
  queryBuildingsFloor_service(val) {
    return xhr(queryBuildingsFloor_api, val)
  },
  queryFloorByBuildingFloor_serveice(val) {
    return xhr(queryFloorByBuildingFloor_api, val)
  }
}
