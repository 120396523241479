/*
 * @Author: your name
 * @Date: 2021-04-08 16:35:50
 * @LastEditTime: 2021-04-14 15:26:00
 * @LastEditors: Please set LastEditors
 * @Description: 客户外出
 * @FilePath: \ioac-tenant-html\src\static\api\ApplyGoOutMgr.api.js
 */
let {
  ic
} = require('../config/api_spec')

module.exports = {
  queryGoOut_api: {
    url: `${ic}/mgr/customer/goOut/query`,
    post: {
      name: '?',
      startDate: '?',
      endDate: '?',
      page: '?',
      rows: '?'
    }
  },
  addGoOut_api: {
    url: `${ic}/mgr/customer/goOut/add`,
    post: {
      customerRegisterNo: '?',
      goOutDate: '?',
      goOutReason: '?',
      state: '?' // 1.外出、2.返回
    }
  },
  backGoOut_api: {
    url: `${ic}/mgr/customer/goOut/back`,
    post: {
      backDate: '?',
      id: '?',
      state: 2
    }
  }
}
