/*
 * @Author: 李会朋
 * @Date: 2021年5月19日17:24:19
 * @LastEditTime: 2021-03-01 14:52:31
 * @LastEditors: Please set LastEditors
 * @Description: 护理相关接口访问
 * @FilePath: \ioac-tenant-html\src\static\service\Nursing.service.js
 */
const {
  xhr
} = require('../lib/xhr')

const {
  addUser_api,
  userList_api,
  userList_api2,
  userDetail_api,
  updUser_api,
  teamList_api,
  addTeam_api,
  teamDetail_api,
  updTeam_api,
  scheduleList_api,
  addSchedule_api,
  updSchedule_api,
  scheduleDetail_api,
  addNursing_api,
  nursingList_api,
  nursingDetail_api,
  updNursing_api,
  workList_api,
  frequencyList_api,
  updFrequency_api,
  addFrequency_api,
  frequencyDetail_api,
  batchAddWork_api,
  addTemplate_api,
  updTemplate_api,
  templeatDetail_api,
  templeatList_api,
  delTemplate_api,
  planList_api,
  addPlan_api,
  delPlan_api,
  taskList_api,
  recordList_api,
  planTaskList_api,
  addTask_api,
  taskDetailList_api,
  updPlan_api,
  bedCard_api,
  delUser_api,
  delTeam_api,
  delSchedule_api,
  updBreakup_api,
  delNursing_api,
  userList_api3,
  taskList_api2
} = require('../api/Nursing.api')

module.exports = {
  // 添加护理人员
  addUser_service(val) {
    return xhr(addUser_api, val)
  },

  userList_service(val) {
    return xhr(userList_api, val)
  },

  userList_service2(val) {
    return xhr(userList_api2, val)
  },

  userDetail_service(val) {
    return xhr(userDetail_api, val)
  },

  updUser_service(val) {
    return xhr(updUser_api, val)
  },

  teamList_service(val) {
    return xhr(teamList_api, val)
  },

  addTeam_service(val) {
    return xhr(addTeam_api, val)
  },

  teamDetail_service(val) {
    return xhr(teamDetail_api, val)
  },

  updTeam_service(val) {
    return xhr(updTeam_api, val)
  },

  scheduleList_service(val) {
    return xhr(scheduleList_api, val)
  },

  addSchedule_service(val) {
    return xhr(addSchedule_api, val)
  },

  updSchedule_service(val) {
    return xhr(updSchedule_api, val)
  },

  scheduleDetail_service(val) {
    return xhr(scheduleDetail_api, val)
  },

  addNursing_service(val) {
    return xhr(addNursing_api, val)
  },

  nursingList_service(val) {
    return xhr(nursingList_api, val)
  },

  nursingDetail_service(val) {
    return xhr(nursingDetail_api, val)
  },

  updNursing_service(val) {
    return xhr(updNursing_api, val)
  },

  workList_service(val) {
    return xhr(workList_api, val)
  },

  frequencyList_service(val) {
    return xhr(frequencyList_api, val)
  },

  updFrequency_service(val) {
    return xhr(updFrequency_api, val)
  },

  addFrequency_service(val) {
    return xhr(addFrequency_api, val)
  },

  frequencyDetail_service(val) {
    return xhr(frequencyDetail_api, val)
  },

  batchAddWork_service(val) {
    return xhr(batchAddWork_api, val)
  },

  addTemplate_service(val) {
    return xhr(addTemplate_api, val)
  },

  updTemplate_service(val) {
    return xhr(updTemplate_api, val)
  },

  templeatDetail_service(val) {
    return xhr(templeatDetail_api, val)
  },

  templeatList_service(val) {
    return xhr(templeatList_api, val)
  },

  delTemplate_service(val) {
    return xhr(delTemplate_api, val)
  },

  planList_service(val) {
    return xhr(planList_api, val)
  },

  addPlan_service(val) {
    return xhr(addPlan_api, val)
  },

  delPlan_service(val) {
    return xhr(delPlan_api, val)
  },

  taskList_service(val) {
    return xhr(taskList_api, val)
  },

  recordList_service(val) {
    return xhr(recordList_api, val)
  },

  planTaskList_service(val) {
    return xhr(planTaskList_api, val)
  },

  addTask_service(val) {
    return xhr(addTask_api, val)
  },

  taskDetailList_service(val) {
    return xhr(taskDetailList_api, val)
  },

  updPlan_service(val) {
    return xhr(updPlan_api, val)
  },

  bedCard_service(val) {
    return xhr(bedCard_api, val)
  },

  delUser_service(val) {
    return xhr(delUser_api, val)
  },

  delTeam_service(val) {
    return xhr(delTeam_api, val)
  },

  delSchedule_service(val) {
    return xhr(delSchedule_api, val)
  },

  updBreakup_service(val) {
    return xhr(updBreakup_api, val)
  },

  delNursing_service(val) {
    return xhr(delNursing_api, val)
  },

  userList_service3(val) {
    return xhr(userList_api3, val)
  },

  taskList_service2(val) {
    return xhr(taskList_api2, val)
  }
}
