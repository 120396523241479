export default {
  methods: {
    /**
     * 判断是否显示无限制
     * @param string/number judgeVal
     * @param string/number displayVal
     */
    noLimitConvert(judgeVal, displayVal) {
      return judgeVal.toString() === '-1' ? '无限制' : displayVal
    }
  }
}
