/*
 * @Author: your name
 * @Date: 2021-03-01 19:12:38
 * @LastEditTime: 2021-03-01 19:17:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\UserPermissionCheck.service.js
 */
const {
  xhr
} = require('../lib/xhr')

const {
  getMenu_api,
  getMenu2Button_api,
  getUserPermCodes_api
} = require('../api/UserPermissionCheck.api')

module.exports = {
  // 获取图形验证码
  getMenu_service() {
    return xhr(getMenu_api, {})
  },
  getMenu2Button_service() {
    return xhr(getMenu2Button_api, {})
  },
  getUserPermCodes_service() {
    return xhr(getUserPermCodes_api, {})
  }
}
