<!--
 * @Author: your name
 * @Date: 2021-03-10 19:24:43
 * @LastEditTime: 2021-03-10 19:33:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\components\sysset\floorstatistics.vue
-->
<template>
  <a-row :gutter="16">
    <a-col :span="8">
      <a-card>
        <a-statistic
          title="床位数"
          :value="bedStatistics.unusecount"
          :value-style="{ color: '#3f8600' }"
          style="margin-right: 50px"
        >
          <template #prefix>
            <a-icon type="inbox" />
          </template>
          <template #suffix>
            <span> / {{ bedStatistics.count }}</span>
          </template>
        </a-statistic>
      </a-card>
    </a-col>
    <a-col :span="8">
      <a-card>
        <a-statistic
          title="房间数"
          :value="roomStatistics.unusecount"
          class="demo-class"
          :value-style="{ color: '#2db7f5' }"
        >
          <template #prefix>
            <a-icon type="home" />
          </template>
          <template #suffix>
            <span> / {{ roomStatistics.count }}</span>
          </template>
        </a-statistic>
      </a-card>
    </a-col>
    <a-col :span="8">
      <a-card>
        <a-statistic
          title="楼栋数"
          :value="floorStatistics.count"
          class="demo-class"
          :value-style="{ color: '#13c2c2' }"
        >
          <template #prefix>
            <a-icon type="bank" />
          </template>
        </a-statistic>
      </a-card>
    </a-col>
  </a-row>
</template>
<script>
import { amountByType_controller } from '../../static/controller/BedMgr.controller'
export default {
  props: {
    organCode: String
  },
  data() {
    return {
      bedStatistics: {},
      roomStatistics: {},
      floorStatistics: {}
    }
  },
  mounted() {
    amountByType_controller({
      organCode: this.organCode,
      type: 5
    }).then((res) => {
      console.log('床位数：', res)
      this.bedStatistics = res
    })
    amountByType_controller({
      organCode: this.organCode,
      type: 1
    }).then((res) => {
      console.log('楼栋：', res)
      this.floorStatistics = res
    })
    amountByType_controller({
      organCode: this.organCode,
      type: 4
    }).then((res) => {
      console.log('房间：', res)
      this.roomStatistics = res
    })
  }
}
</script>
