/*
 * @Author: your name
 * @Date: 2021-02-23 17:18:07
 * @LastEditTime: 2021-04-26 17:45:09
 * @LastEditors:
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\config\url.js
 */
// let url = process.env.NODE_ENV === 'production' ? `${window.location.origin}/` : `${window.location.origin}/`
// let url = process.env.NODE_BASE_API
const URL_INFO = {
  _mainUrl: 'http://47.117.118.24/', // 正式服务器
  _mainTestUrl: 'https://qilinyun.e-cares.cn/', // 测试环境
  _testUrl: 'https://qlyun.e-cares.cn/', // 正式服务器
  _betaUrl: 'https://api.pre.kukeduo.cn/', // 预发布服务器
  _localUrl: 'https://admin.robot-manage.yc-ai.com/', // 本地服务器
  // _localUrl: 'https://robot-manage.shenhua-ai.com/', // 本地服务器
  _staticUrl: '' // 静态资源地址
}
module.exports = {
  URL_INFO
}
