/*
 * @Description:
 * @Author:
 * @Date: 2021-03-04 20:06:47
 * @LastEditTime: 2021-04-26 14:51:47
 * @LastEditors:
 */

const {
  xhr
} = require('../lib/xhr')
const {
  getComeCustomerMgr_api,
  deleteComeCustomerMgr_api,
  addComeCustomerMgr_api,
  detailComeCustomerMgr_api,
  queryCheckInCustList_api,
  updateSimple_api,
  editComeCustomerMgr_api,
  confirmCheckIn_api,
  detailList_api,
  queryCheckInCustPage_api
} = require('../api/ComeCustomerMgr.api')

module.exports = {
  queryCheckInCustList_service(val) {
    return xhr(queryCheckInCustList_api, val)
  },
  getComeCustomerMgr_service(val) {
    return xhr(getComeCustomerMgr_api, val)
  },
  deleteComeCustomerMgr_service(val) {
    return xhr(deleteComeCustomerMgr_api, val)
  },
  addComeCustomerMgr_service(val) {
    return xhr(addComeCustomerMgr_api, val)
  },
  detailComeCustomerMgr_service(val) {
    return xhr(detailComeCustomerMgr_api, val)
  },
  updateSimpleMgr_service(val) {
    return xhr(updateSimple_api, val)
  },
  editComeCustomerMgr_service(val) {
    return xhr(editComeCustomerMgr_api, val)
  },
  // 确认入住
  confirmCheckIn_service(val) {
    return xhr(confirmCheckIn_api, val)
  },
  detailList_service(val) {
    return xhr(detailList_api, val)
  },
  queryCheckIn_service(val) {
    return xhr(queryCheckInCustPage_api, val)
  }
}
