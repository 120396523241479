<template>
  <div class="view-scroll">
    <div class="m-box col m-mt-10 box">
      <a-form
        :form="form"
        :label-col="{ span: 0 }"
        class="fff"
        :wrapper-col="{ span: 22 }"
        @submit="handleSearch"
      >
        <a-row type="flex">
          <a-col :span="4">
            <a-form-item label="">
              <a-input v-decorator="['organName']" placeholder="请输入机构名" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-input
                v-decorator="['templateName']"
                placeholder="请输入套餐名"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :span="3" class="ant-form-item">
            <a-button type="primary" class="m-mt-4" html-type="submit">
              查询
            </a-button>
          </a-col>
          <a-col v-if="adminRequired()" :span="3" class="ant-form-item">
            <a-button type="primary" class="m-mt-4" @click="bind">
              订阅绑定
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="m-box m-mt-10">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :scroll="{ x: 1600 }"
        row-key="id"
      >
        <div slot="templateType" slot-scope="row">
          <span>{{ row === "0" ? "通用套餐" : "机构专属套餐" }}</span>
        </div>
        <div slot="action" slot-scope="record" class="text-3d9">
          <a-button
            type="primary"
            class="m-ml-10"
            @click="detal(record)"
          >订阅详情</a-button>
        </div>
      </a-table>

      <Pagination
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        :total="total"
        class="paginationStyle"
        @pagination="getPages"
      />
    </div>

    <Bind ref="bind" />
    <Detail ref="detail" />
  </div>
</template>

<script>
import Base from '@/components/subscription/Base'
import Bind from '@/components/subscription/Bind/Index'
import Detail from '@/components/subscription/Bind/Detail'
import { getSubscriptionLists_controller } from '../../static/controller/Subscription.controller'
import Pagination from '@/components/Pagination'
export default {
  components: {
    Bind,
    Pagination,
    Detail
  },
  extends: Base,
  data() {
    return {
      bindVisible: false,
      form: this.$form.createForm(this, {
        name: 'evalutaion'
      }),
      columns: [
        {
          title: '订阅机构',
          dataIndex: 'organName'
        },
        {
          title: '订阅名称',
          dataIndex: 'name'
        },
        {
          title: '订阅生效日期',
          dataIndex: 'startDate'
        },
        {
          title: '订阅结束日期',
          dataIndex: 'endDate'
        },
        {
          title: '支付方式',
          dataIndex: 'payType'
        },
        {
          title: '订单编号',
          dataIndex: 'orderNo'
        },
        {
          title: '关联合同编号',
          dataIndex: 'contractNum'
        },
        {
          title: '订阅状态',
          dataIndex: 'status',
          customRender: (text, row, index) => {
            const start = new Date(row.startDate).getTime()
            const end = new Date(row.endDate).getTime()
            const now = new Date().getTime()
            if (start > now) {
              return '待生效'
            } else if (end < now) {
              return '已失效'
            } else {
              return '生效中'
            }
          }
        },
        {
          title: '订阅人',
          dataIndex: 'createUser'
        },
        {
          title: '修订人',
          dataIndex: 'updateUser'
        },
        {
          title: '修订日期',
          dataIndex: 'updateTime'
        },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    /**
     * 请求画面数据
     */
    getList() {
      getSubscriptionLists_controller({
        page: this.pageIndex,
        rows: this.pageSize,
        organName: this.form.getFieldValue('organName'),
        templateName: this.form.getFieldValue('templateName')
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    },
    bind() {
      this.$refs.bind.visible = true
    },

    /**
     * 点击查看详情按钮
     */
    detal(record) {
      this.$refs.detail.id = record.id
      this.$refs.detail.visible = true
    }
  }
}
</script>
