var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"formTitle"},[_vm._v("长者信息")]),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span:6 },"wrapper-col":{ span: 14 }}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['elder.custName',{
              rules:[{required: true, message: '姓名不能为空'}],
              initialValue: _vm.baseInfo.custName,
            }]),expression:"['elder.custName',{\n              rules:[{required: true, message: '姓名不能为空'}],\n              initialValue: baseInfo.custName,\n            }]"}]})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['elder.custSex',{
              rules:[{required: true, message: '请选择性别'}],
              initialValue: _vm.baseInfo.custSex,
            }]),expression:"['elder.custSex',{\n              rules:[{required: true, message: '请选择性别'}],\n              initialValue: baseInfo.custSex,\n            }]"}],staticClass:"ipt",attrs:{"placeholder":"性别"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" 男 ")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v(" 女 ")])],1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"年龄"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['elder.custAge',{
              rules:[{required:true, message:'请输入长者年龄！'},
                     {
                       required: true, pattern:new RegExp(/^\d{1,}$/), trigger: 'blur', message:'请输入正确的长者年龄！'
                     }
              ],
              initialValue:_vm.baseInfo.custAge
            }]),expression:"['elder.custAge',{\n              rules:[{required:true, message:'请输入长者年龄！'},\n                     {\n                       required: true, pattern:new RegExp(/^\\d{1,}$/), trigger: 'blur', message:'请输入正确的长者年龄！'\n                     }\n              ],\n              initialValue:baseInfo.custAge\n            }]"}],attrs:{"placeholder":"年龄"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['elder.linkTel',{
              rules:[{required: true, message: '请填写联系电话'},
                     {
                       required: true,
                       validator: _vm.validatorPhone,
                       trigger: 'blur',
                       message: '请输入正确的联系电话！',
                     }],
              initialValue: _vm.baseInfo.linkTel,

            }]),expression:"['elder.linkTel',{\n              rules:[{required: true, message: '请填写联系电话'},\n                     {\n                       required: true,\n                       validator: validatorPhone,\n                       trigger: 'blur',\n                       message: '请输入正确的联系电话！',\n                     }],\n              initialValue: baseInfo.linkTel,\n\n            }]"}],staticClass:"ipt"})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"户籍地区"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:(['elder.registArea',{
              rules:[{required: true, message: '请选择户籍地区'}],
              initialValue: _vm.baseInfo.registArea
            }]),expression:"['elder.registArea',{\n              rules:[{required: true, message: '请选择户籍地区'}],\n              initialValue: baseInfo.registArea\n            }]"}],attrs:{"options":_vm.regionList,"expand-trigger":"hover","placeholder":"请选择","field-names":_vm.fieldNames}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"户籍详细地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['elder.registAddr',{
              rules:[{required: true, message: '请填写户籍详细地址'}],
              initialValue: _vm.baseInfo.registAddr,
            }]),expression:"['elder.registAddr',{\n              rules:[{required: true, message: '请填写户籍详细地址'}],\n              initialValue: baseInfo.registAddr,\n            }]"}],staticStyle:{"width":"300px"},attrs:{"placeholder":"详细地址"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"居住地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['elder.addr',{rules:[{required: true, message: '请填写居住地址!'}],initialValue: _vm.baseInfo.addr}]),expression:"['elder.addr',{rules:[{required: true, message: '请填写居住地址!'}],initialValue: baseInfo.addr}]"}],staticStyle:{"width":"300px"},attrs:{"placeholder":"居住地址"}})],1)],1)],1),_c('p',{staticClass:"formTitle"},[_vm._v("家庭成员信息")]),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"称谓"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['fm.relType',{
              rules:[{required: true, message: '请填写称谓'}],
              initialValue: _vm.familyModels.relType,
            }]),expression:"['fm.relType',{\n              rules:[{required: true, message: '请填写称谓'}],\n              initialValue: familyModels.relType,\n            }]"}],attrs:{"placeholder":"称谓","max-length":_vm.maxFou}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['fm.name',{
              rules:[{required: true, message: '请填写姓名'}],
              initialValue: _vm.familyModels.name,
            }]),expression:"['fm.name',{\n              rules:[{required: true, message: '请填写姓名'}],\n              initialValue: familyModels.name,\n            }]"}],attrs:{"placeholder":"姓名"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['fm.sex',{
              rules:[{required: false, message: '请选择性别'}],
              initialValue: _vm.familyModels.sex,
            }]),expression:"['fm.sex',{\n              rules:[{required: false, message: '请选择性别'}],\n              initialValue: familyModels.sex,\n            }]"}],attrs:{"placeholder":"性别"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" 男 ")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v(" 女 ")])],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['fm.mobile',{
              rules: [
                { required: true, message: '请输入联系电话！' },
                {
                  required: true,
                  validator: _vm.validatorPhone,
                  trigger: 'blur',
                  message: '请输入正确的联系电话！',
                }
              ],
              initialValue: _vm.familyModels.mobile,
            }]),expression:"['fm.mobile',{\n              rules: [\n                { required: true, message: '请输入联系电话！' },\n                {\n                  required: true,\n                  validator: validatorPhone,\n                  trigger: 'blur',\n                  message: '请输入正确的联系电话！',\n                }\n              ],\n              initialValue: familyModels.mobile,\n            }]"}],attrs:{"placeholder":"联系电话"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"身份证"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['fm.idNo',{
              rules:[{required: true, message: '请填写身份证号'}, {
                required: true,
                validator: _vm.validatorCode,
                trigger: 'blur'
              }],
              initialValue: _vm.familyModels.idNo,
            }]),expression:"['fm.idNo',{\n              rules:[{required: true, message: '请填写身份证号'}, {\n                required: true,\n                validator: validatorCode,\n                trigger: 'blur'\n              }],\n              initialValue: familyModels.idNo,\n            }]"}],attrs:{"placeholder":"身份证"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"居住地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['fm.homeAddr',{
              rules:[{required: true, message: '请填写居住地址'}],
              initialValue: _vm.familyModels.homeAddr,
            }]),expression:"['fm.homeAddr',{\n              rules:[{required: true, message: '请填写居住地址'}],\n              initialValue: familyModels.homeAddr,\n            }]"}],attrs:{"placeholder":"居住地址"}})],1)],1)],1),_c('a-divider'),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"目前服用药物"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['elder.remark',{ initialValue: _vm.baseInfo.remark}]),expression:"['elder.remark',{ initialValue: baseInfo.remark}]"}],staticStyle:{"width":"400px"},attrs:{"placeholder":"输入备注","rows":4}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"申请入院理由"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['elder.consultContent',{ initialValue: _vm.baseInfo.consultContent}]),expression:"['elder.consultContent',{ initialValue: baseInfo.consultContent}]"}],staticStyle:{"width":"400px"},attrs:{"placeholder":"输入备注","rows":4}})],1)],1)],1)],1),_c('a-divider',{attrs:{"orientation":"center"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.nextStep}},[_vm._v("下一步")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }