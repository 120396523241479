/*
 * @Author:
 * @Date: 2021-03-01 19:14:04
 * @LastEditTime: 2021-03-26 13:23:35
 * @LastEditors:
 * @Description:入院登记
 */
let {
  getComeCustomerMgr_service,
  deleteComeCustomerMgr_service,
  addComeCustomerMgr_service,
  detailComeCustomerMgr_service,
  queryCheckInCustList_service,
  updateSimpleMgr_service,
  editComeCustomerMgr_service,
  confirmCheckIn_service,
  detailList_service,
  queryCheckIn_service
} = require('../service/ComeCustomerMgr.service')

module.exports = {
  getComeCustomerMgr_controller(val) {
    return getComeCustomerMgr_service(val)
  },
  deleteComeCustomerMgr_controller(val) {
    return deleteComeCustomerMgr_service(val)
  },
  addComeCustomerMgr_controller(val) {
    return addComeCustomerMgr_service(val)
  },
  detailComeCustomerMgr_controller(val) {
    return detailComeCustomerMgr_service(val)
  },
  queryCheckInCustList_controller(val) {
    return queryCheckInCustList_service(val)
  },
  updateSimpleMgr_controller(val) {
    return updateSimpleMgr_service(val)
  },
  editComeCustomerMgr_controller(val) {
    return editComeCustomerMgr_service(val)
  },
  confirmCheckIn_controller(val) {
    return confirmCheckIn_service(val)
  },
  detailList_controller(val) {
    return detailList_service(val)
  },
  queryCheckIn_controller(val) {
    return queryCheckIn_service(val)
  }

}
