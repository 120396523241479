/*
 * @Description:
 * @Author:
 * @Date: 2021-03-04 17:23:09
 * @LastEditTime: 2021-03-04 17:24:49
 * @LastEditors:
 */

const {
  xhr
} = require('../lib/xhr')
const {
  getBizTypCodeApp_api
} = require('../api/BizTypCodeApp.api')

module.exports = {
  getBizTypCodeApp_service(val) {
    return xhr(getBizTypCodeApp_api, val)
  }
}
