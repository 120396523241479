/*
 * @Author: your name
 * @Date: 2021-06-02 18:56:03
 * @LastEditTime: 2021-06-10 15:13:09
 * @LastEditors: Please set LastEditors
 * @Description: 账单信息管理
 * @FilePath: \ioac-tenant-html\src\static\api\AccountBillInfoMgr.api.js
 */
let {
  hsc
} = require('../config/api_spec')
module.exports = {
  queryAccountBillInfo_api: {
    url: `${hsc}/mgr/finance/accountBillInfo/query`,
    post: {
      customerNo: '?',
      name: '?',
      page: '?',
      rows: '?',
      type: '?'
    }
  }
}
