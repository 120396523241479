<!--
 * @Author: your name
 * @Date: 2021-03-05 16:37:56
 * @LastEditTime: 2021-04-23 10:17:03
 * @LastEditors: Please set LastEditors
 * @Description: 房间
 * @FilePath: \ioac-tenant-html\src\components\Room.vue
-->
<template>
  <a-modal
    :title="title"
    :visible="visible"
    :width="948"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="roombox">
      <div class="shadows rb">
        <div class="shadow6" />
        <div class="shadow5" />
        <div class="shadow4" />
        <div class="shadow1" />
        <div class="shadow2" />
        <div class="shadow3" />
      </div>
      <div class="room rb">
        <div class="at">
          <div class="at__front face" />
          <div class="at__back face" />
          <div class="at__right face" />
          <div class="at__left face" />
          <div class="at__top face">
            <div class="os os1" />
            <div class="os os2" />
            <div class="ws1 ws" />
            <div class="ws2 ws" />
            <div class="ws3 ws" />
            <div class="ws4 ws" />
            <div class="ws5 ws" />
            <div class="ws6 ws" style="display: none" />
            <div class="ws7 ws" />
          </div>
          <div class="at__bottom face" />
        </div>
        <div class="ab">
          <div class="ab__front face" />
          <div class="ab__back face" />
          <div class="ab__right face" />
          <div class="ab__left face" />
          <div class="ab__top face" />
          <div class="ab__bottom face" />
        </div>
        <div class="bb">
          <div class="bb__front face">
            <div class="os os1" />
            <div class="os os2" />
          </div>
          <div class="bb__back face" />
          <div class="bb__right face" />
          <div class="bb__left face" />
          <div class="bb__top face" />
          <div class="bb__bottom face" />
        </div>
        <div class="bt">
          <div class="bt__front face" />
          <div class="bt__back face" />
          <div class="bt__right face" />
          <div class="bt__left face" />
          <div class="bt__top face" />
          <div class="bt__bottom face" />
        </div>
        <div class="bbl">
          <div class="bbl__front face" />
          <div class="bbl__back face" />
          <div class="bbl__right face" />
          <div class="bbl__left face" />
          <div class="bbl__top face" />
          <div class="bbl__bottom face" />
        </div>
        <div class="bbr">
          <div class="bbr__front face" />
          <div class="bbr__back face" />
          <div class="bbr__right face" />
          <div class="bbr__left face" />
          <div class="bbr__top face" />
          <div class="bbr__bottom face" />
        </div>
        <div class="bl">
          <div class="bl__front face" />
          <div class="bl__back face" />
          <div class="bl__right face">
            <div class="os0" />
            <div class="os os1" style="display: none" />
            <div class="os os2" style="display: none" />
            <div class="os os3" style="display: none" />
            <div class="os os4" style="display: none" />
            <div class="os os5" style="display: none" />
            <div class="os os6" style="display: none" />
          </div>
          <div class="bl__left face">
            <div class="os os1" />
          </div>
          <div class="bl__top face" />
          <div class="bl__bottom face" />
        </div>
        <div class="ventana">
          <div class="ventana__back face">
            <div class="lg lg1" />
            <div class="lg lg2" />
            <div class="vs vs1" />
            <div class="vs vs2" style="display: none" />
            <div class="vs vs3" />
            <div class="vs vs4" />
          </div>
          <div class="ventana-l">
            <div class="ventana-l__front face" />
            <div class="ventana-l__back face" />
            <div class="ventana-l__right face" />
            <div class="ventana-l__left face" />
            <div class="ventana-l__top face" />
            <div class="ventana-l__bottom face" />
          </div>
          <div class="ventana-c">
            <div class="ventana-c__front face" />
            <div class="ventana-c__back face" />
            <div class="ventana-c__right face" />
            <div class="ventana-c__left face" />
            <div class="ventana-c__top face" />
            <div class="ventana-c__bottom face" />
          </div>
          <div class="ventana-r">
            <div class="ventana-r__front face" />
            <div class="ventana-r__back face" />
            <div class="ventana-r__right face" />
            <div class="ventana-r__left face" />
            <div class="ventana-r__top face" />
            <div class="ventana-r__bottom face" />
          </div>
          <div class="ventana-t">
            <div class="ventana-t__front face" />
            <div class="ventana-t__back face" />
            <div class="ventana-t__right face" />
            <div class="ventana-t__left face" />
            <div class="ventana-t__top face" />
            <div class="ventana-t__bottom face" />
          </div>
          <div class="ventana-b">
            <div class="ventana-b__front face" />
            <div class="ventana-b__back face" />
            <div class="ventana-b__right face" />
            <div class="ventana-b__left face" />
            <div class="ventana-b__top face" />
            <div class="ventana-b__bottom face" />
          </div>
        </div>
        <div class="bedSort">
          <div>
            <template v-for="(item, index) in bedList">
              <Bed v-if="index <= 2" :key="item.id" :bed-info="item" />
            </template>
          </div>
          <div style="position: absolute; left: 210px">
            <template v-for="(item, index) in bedList">
              <OtherBed v-if="index > 2" :key="item.id" :bed-info="item" />
            </template>
          </div>
        </div>
        <div id="bocina" class="bocina" style="display: none">
          <div class="fs" />
          <div class="bocina-l bocina-a">
            <div class="bocina-l__front face" />
            <div class="bocina-l__back face" />
            <div class="bocina-l__right face" />
            <div class="bocina-l__left face" />
            <div class="bocina-l__top face" />
            <div class="oocina-l__bottom face" />
          </div>
          <div class="bocina-stereo">
            <div class="bocina-stereo__front face" />
            <div class="bocina-stereo__back face" />
            <div class="bocina-stereo__right face" />
            <div class="bocina-stereo__left face" />
            <div class="bocina-stereo__top face" />
            <div class="bocina-stereo__bottom face" />
          </div>
          <div class="bocina-r bocina-a">
            <div class="bocina-r__front face" />
            <div class="bocina-r__back face" />
            <div class="bocina-r__right face" />
            <div class="bocina-r__left face" />
            <div class="bocina-r__top face" />
            <div class="oocina-r__bottom face" />
          </div>
        </div>
        <div class="libros" style="display: none">
          <div class="libro libro--azul">
            <div class="libro__front face" />
            <div class="libro__back face" />
            <div class="libro__right face" />
            <div class="libro__left face" />
            <div class="libro__top face" />
            <div class="libro__bottom face" />
          </div>
          <div class="libro libro--verde">
            <div class="libro__front face" />
            <div class="libro__back face" />
            <div class="libro__right face" />
            <div class="libro__left face" />
            <div class="libro__top face" />
            <div class="libro__bottom face" />
          </div>
          <div class="libro libro--amarillo">
            <div class="libro__front face" />
            <div class="libro__back face" />
            <div class="libro__right face" />
            <div class="libro__left face" />
            <div class="libro__top face" />
            <div class="libro__bottom face" />
          </div>
          <div class="libro libro--azul">
            <div class="libro__front face" />
            <div class="libro__back face" />
            <div class="libro__right face" />
            <div class="libro__left face" />
            <div class="libro__top face" />
            <div class="libro__bottom face" />
          </div>
          <div class="libro libro--azul">
            <div class="libro__front face" />
            <div class="libro__back face" />
            <div class="libro__right face" />
            <div class="libro__left face" />
            <div class="libro__top face" />
            <div class="libro__bottom face" />
          </div>
          <div class="libro libro--verde">
            <div class="libro__front face" />
            <div class="libro__back face" />
            <div class="libro__right face" />
            <div class="libro__left face" />
            <div class="libro__top face" />
            <div class="libro__bottom face" />
          </div>
          <div class="libro libro--blanco">
            <div class="libro__front face" />
            <div class="libro__back face" />
            <div class="libro__right face" />
            <div class="libro__left face" />
            <div class="libro__top face" />
            <div class="libro__bottom face" />
          </div>
          <div class="libro libro--azul">
            <div class="libro__front face" />
            <div class="libro__back face" />
            <div class="libro__right face" />
            <div class="libro__left face" />
            <div class="libro__top face" />
            <div class="libro__bottom face" />
          </div>
          <div class="libro libro--azul">
            <div class="libro__front face" />
            <div class="libro__back face" />
            <div class="libro__right face" />
            <div class="libro__left face" />
            <div class="libro__top face" />
            <div class="libro__bottom face" />
          </div>
          <div class="libro libro--azul">
            <div class="libro__front face" />
            <div class="libro__back face" />
            <div class="libro__right face" />
            <div class="libro__left face" />
            <div class="libro__top face" />
            <div class="libro__bottom face" />
          </div>
          <div class="libro libro--amarillo">
            <div class="libro__front face" />
            <div class="libro__back face" />
            <div class="libro__right face" />
            <div class="libro__left face" />
            <div class="libro__top face" />
            <div class="libro__bottom face" />
          </div>
          <div class="libro libro--azul">
            <div class="libro__front face" />
            <div class="libro__back face" />
            <div class="libro__right face" />
            <div class="libro__left face" />
            <div class="libro__top face" />
            <div class="libro__bottom face" />
          </div>
          <div class="libro libro--verde">
            <div class="libro__front face" />
            <div class="libro__back face" />
            <div class="libro__right face" />
            <div class="libro__left face" />
            <div class="libro__top face" />
            <div class="libro__bottom face" />
          </div>
        </div>
        <div class="sillon" style="display: none">
          <div class="fs" />
          <div class="sillon-l">
            <div class="sillon-l__front face" />
            <div class="sillon-l__back face" />
            <div class="sillon-l__right face" />
            <div class="sillon-l__left face" />
            <div class="sillon-l__top face" />
            <div class="sillon-l__bottom face" />
          </div>
          <div class="sillon-r">
            <div class="sillon-r__front face" />
            <div class="sillon-r__back face" />
            <div class="sillon-r__right face" />
            <div class="sillon-r__left face" />
            <div class="sillon-r__top face" />
            <div class="sillon-r__bottom face" />
          </div>
          <div class="sillon-c">
            <div class="sillon-c__front face" />
            <div class="sillon-c__back face" />
            <div class="sillon-c__right face" />
            <div class="sillon-c__left face" />
            <div class="sillon-c__top face" />
            <div class="sillon-c__bottom face" />
          </div>
          <div class="sillon-c2">
            <div class="sillon-c2__front face" />
            <div class="sillon-c2__back face" />
            <div class="sillon-c2__right face" />
            <div class="sillon-c2__left face" />
            <div class="sillon-c2__top face" />
            <div class="sillon-c2__bottom face" />
          </div>
          <div class="sillon-b">
            <div class="sillon-b__front face" />
            <div class="sillon-b__back face" />
            <div class="sillon-b__right face" />
            <div class="sillon-b__left face" />
            <div class="sillon-b__top face" />
            <div class="sillon-b__bottom face" />
          </div>
          <div class="sillon-b2">
            <div class="sillon-b2__front face" />
            <div class="sillon-b2__back face" />
            <div class="sillon-b2__right face" />
            <div class="sillon-b2__left face" />
            <div class="sillon-b2__top face" />
            <div class="sillon-b2__bottom face" />
          </div>
        </div>
        <div class="libreros" style="display: none">
          <div class="librero">
            <div class="fs" />
            <div class="librero-t">
              <div class="librero-t__front face" />
              <div class="librero-t__back face" />
              <div class="librero-t__right face" />
              <div class="librero-t__left face" />
              <div class="librero-t__top face" />
              <div class="librero-t__bottom face" />
            </div>
            <div class="librero-b">
              <div class="librero-b__front face" />
              <div class="librero-b__back face" />
              <div class="librero-b__right face" />
              <div class="librero-b__left face" />
              <div class="librero-b__top face" />
              <div class="librero-b__bottom face" />
            </div>
            <div class="librero-m1">
              <div class="librero-m1__front face" />
              <div class="librero-m1__back face" />
              <div class="librero-m1__right face" />
              <div class="librero-m1__left face" />
              <div class="librero-m1__top face" />
              <div class="librero-m1__bottom face" />
            </div>
            <div class="librero-m2">
              <div class="librero-m2__front face" />
              <div class="librero-m2__back face" />
              <div class="librero-m2__right face" />
              <div class="librero-m2__left face" />
              <div class="librero-m2__top face" />
              <div class="librero-m2__bottom face" />
            </div>
            <div class="librero-m3">
              <div class="librero-m3__front face" />
              <div class="librero-m3__back face" />
              <div class="librero-m3__right face" />
              <div class="librero-m3__left face" />
              <div class="librero-m3__top face" />
              <div class="librero-m3__bottom face" />
            </div>
            <div class="librero-l">
              <div class="librero-l__front face" />
              <div class="librero-l__back face" />
              <div class="librero-l__right face" />
              <div class="librero-l__left face" />
              <div class="librero-l__top face" />
              <div class="librero-l__bottom face" />
            </div>
            <div class="librero-l1">
              <div class="librero-l1__front face" />
              <div class="librero-l1__back face" />
              <div class="librero-l1__right face" />
              <div class="librero-l1__left face" />
              <div class="librero-l1__top face" />
              <div class="librero-l1__bottom face" />
            </div>
            <div class="librero-l2">
              <div class="librero-l2__front face" />
              <div class="librero-l2__back face" />
              <div class="librero-l2__right face" />
              <div class="librero-l2__left face" />
              <div class="librero-l2__top face" />
              <div class="librero-l2__bottom face" />
            </div>
            <div class="librero-l3">
              <div class="librero-l3__front face" />
              <div class="librero-l3__back face" />
              <div class="librero-l3__right face" />
              <div class="librero-l3__left face" />
              <div class="librero-l3__top face" />
              <div class="librero-l3__bottom face" />
            </div>
            <div class="librero-r">
              <div class="librero-r__front face" />
              <div class="librero-r__back face" />
              <div class="librero-r__right face" />
              <div class="librero-r__left face" />
              <div class="librero-r__top face" />
              <div class="librero-r__bottom face" />
            </div>
          </div>
          <div id="librero" class="librero">
            <div class="fs" />
            <div class="librero-base" />
            <div class="librero-t">
              <div class="librero-t__front face" />
              <div class="librero-t__back face" />
              <div class="librero-t__right face" />
              <div class="librero-t__left face" />
              <div class="librero-t__top face" />
              <div class="librero-t__bottom face" />
            </div>
            <div class="librero-b">
              <div class="librero-b__front face" />
              <div class="librero-b__back face" />
              <div class="librero-b__right face" />
              <div class="librero-b__left face" />
              <div class="librero-b__top face" />
              <div class="librero-b__bottom face" />
            </div>
            <div class="librero-m1">
              <div class="librero-m1__front face" />
              <div class="librero-m1__back face" />
              <div class="librero-m1__right face" />
              <div class="librero-m1__left face" />
              <div class="librero-m1__top face" />
              <div class="librero-m1__bottom face" />
            </div>
            <div class="librero-m2">
              <div class="librero-m2__front face" />
              <div class="librero-m2__back face" />
              <div class="librero-m2__right face" />
              <div class="librero-m2__left face" />
              <div class="librero-m2__top face" />
              <div class="librero-m2__bottom face" />
            </div>
            <div class="librero-m3">
              <div class="librero-m3__front face" />
              <div class="librero-m3__back face" />
              <div class="librero-m3__right face" />
              <div class="librero-m3__left face" />
              <div class="librero-m3__top face" />
              <div class="librero-m3__bottom face" />
            </div>
            <div class="librero-l">
              <div class="librero-l__front face" />
              <div class="librero-l__back face" />
              <div class="librero-l__right face" />
              <div class="librero-l__left face" />
              <div class="librero-l__top face" />
              <div class="librero-l__bottom face" />
            </div>
            <div class="librero-r1">
              <div class="librero-r1__front face" />
              <div class="librero-r1__back face" />
              <div class="librero-r1__right face flex">
                <div class="manija">
                  <div class="manija__front face" />
                  <div class="manija__back face" />
                  <div class="manija__right face" />
                  <div class="manija__left face" />
                  <div class="manija__top face" />
                  <div class="manija__bottom face" />
                </div>
              </div>
              <div class="librero-r1__left face" />
              <div class="librero-r1__top face" />
              <div class="librero-r1__bottom face" />
            </div>
            <div class="librero-r2">
              <div class="librero-r2__front face" />
              <div class="librero-r2__back face" />
              <div class="librero-r2__right face flex">
                <div class="manija">
                  <div class="manija__front face" />
                  <div class="manija__back face" />
                  <div class="manija__right face" />
                  <div class="manija__left face" />
                  <div class="manija__top face" />
                  <div class="manija__bottom face" />
                </div>
              </div>
              <div class="librero-r2__left face" />
              <div class="librero-r2__top face" />
              <div class="librero-r2__bottom face" />
            </div>
            <div class="librero-r3">
              <div class="librero-r3__front face" />
              <div class="librero-r3__back face" />
              <div class="librero-r3__right face flex">
                <div class="manija">
                  <div class="manija__front face" />
                  <div class="manija__back face" />
                  <div class="manija__right face" />
                  <div class="manija__left face" />
                  <div class="manija__top face" />
                  <div class="manija__bottom face" />
                </div>
              </div>
              <div class="librero-r3__left face" />
              <div class="librero-r3__top face" />
              <div class="librero-r3__bottom face" />
            </div>
            <div class="librero-r">
              <div class="librero-r__front face" />
              <div class="librero-r__back face" />
              <div class="librero-r__right face" />
              <div class="librero-r__left face" />
              <div class="librero-r__top face" />
              <div class="librero-r__bottom face" />
            </div>
          </div>
        </div>
        <div class="arbustos" style="display: none">
          <div class="fs" />
          <div class="arbusto flex face">
            <div class="arbusto-el flex face">
              <div class="arbusto-el__front face" />
              <div class="arbusto-el__back face" />
              <div class="arbusto-el__right face" />
              <div class="arbusto-el__left face" />
              <div class="arbusto-el__top face" />
              <div class="arbusto-el__bottom face" />
            </div>
            <div class="arbusto-el flex face">
              <div class="arbusto-el__front face" />
              <div class="arbusto-el__back face" />
              <div class="arbusto-el__right face" />
              <div class="arbusto-el__left face" />
              <div class="arbusto-el__top face" />
              <div class="arbusto-el__bottom face" />
            </div>
            <div class="arbusto-el flex face">
              <div class="arbusto-el__front face" />
              <div class="arbusto-el__back face" />
              <div class="arbusto-el__right face" />
              <div class="arbusto-el__left face" />
              <div class="arbusto-el__top face" />
              <div class="arbusto-el__bottom face" />
            </div>
            <div class="arbusto-el flex face">
              <div class="arbusto-el__front face" />
              <div class="arbusto-el__back face" />
              <div class="arbusto-el__right face" />
              <div class="arbusto-el__left face" />
              <div class="arbusto-el__top face" />
              <div class="arbusto-el__bottom face" />
            </div>
          </div>
          <div class="arbusto flex face">
            <div class="arbusto-el flex face">
              <div class="arbusto-el__front face" />
              <div class="arbusto-el__back face" />
              <div class="arbusto-el__right face" />
              <div class="arbusto-el__left face" />
              <div class="arbusto-el__top face" />
              <div class="arbusto-el__bottom face" />
            </div>
            <div class="arbusto-el flex face">
              <div class="arbusto-el__front face" />
              <div class="arbusto-el__back face" />
              <div class="arbusto-el__right face" />
              <div class="arbusto-el__left face" />
              <div class="arbusto-el__top face" />
              <div class="arbusto-el__bottom face" />
            </div>
            <div class="arbusto-el flex face">
              <div class="arbusto-el__front face" />
              <div class="arbusto-el__back face" />
              <div class="arbusto-el__right face" />
              <div class="arbusto-el__left face" />
              <div class="arbusto-el__top face" />
              <div class="arbusto-el__bottom face" />
            </div>
            <div class="arbusto-el flex face">
              <div class="arbusto-el__front face" />
              <div class="arbusto-el__back face" />
              <div class="arbusto-el__right face" />
              <div class="arbusto-el__left face" />
              <div class="arbusto-el__top face" />
              <div class="arbusto-el__bottom face" />
            </div>
          </div>
          <div class="arbusto2 flex face">
            <div class="arbusto2-el flex face">
              <div class="arbusto2-el__front face" />
              <div class="arbusto2-el__back face" />
              <div class="arbusto2-el__right face" />
              <div class="arbusto2-el__left face" />
              <div class="arbusto2-el__top face" />
              <div class="arbusto2-el__bottom face" />
            </div>
            <div class="arbusto2-el flex face">
              <div class="arbusto2-el__front face" />
              <div class="arbusto2-el__back face" />
              <div class="arbusto2-el__right face" />
              <div class="arbusto2-el__left face" />
              <div class="arbusto2-el__top face" />
              <div class="arbusto2-el__bottom face" />
            </div>
            <div class="arbusto2-el flex face">
              <div class="arbusto2-el__front face" />
              <div class="arbusto2-el__back face" />
              <div class="arbusto2-el__right face" />
              <div class="arbusto2-el__left face" />
              <div class="arbusto2-el__top face" />
              <div class="arbusto2-el__bottom face" />
            </div>
            <div class="arbusto2-el flex face">
              <div class="arbusto2-el__front face" />
              <div class="arbusto2-el__back face" />
              <div class="arbusto2-el__right face" />
              <div class="arbusto2-el__left face" />
              <div class="arbusto2-el__top face" />
              <div class="arbusto2-el__bottom face" />
            </div>
          </div>
        </div>
        <div class="maceta">
          <div class="fs" />
          <div class="maceta-base">
            <div class="maceta-hoja" />
            <div class="maceta-hoja" />
            <div class="maceta-hoja" />
            <div class="maceta-hoja" />
            <div class="maceta-hoja" />
            <div class="maceta-hoja" />
            <div class="maceta-hoja" />
            <div class="maceta-hoja" />
            <div class="maceta-hoja" />
            <div class="maceta-hoja" />
            <div class="maceta-hoja" />
            <div class="maceta-hoja" />
            <div class="maceta-hoja" />
            <div class="maceta-hoja" />
            <div class="maceta-hoja" />
          </div>
          <div class="maceta-l">
            <div class="maceta-l__front face" />
            <div class="maceta-l__back face" />
            <div class="maceta-l__right face" />
            <div class="maceta-l__left face" />
            <div class="maceta-l__top face" />
            <div class="maceta-l__bottom face" />
          </div>
          <div class="maceta-r">
            <div class="maceta-r__front face" />
            <div class="maceta-r__back face" />
            <div class="maceta-r__right face" />
            <div class="maceta-r__left face" />
            <div class="maceta-r__top face" />
            <div class="maceta-r__bottom face" />
          </div>
          <div class="maceta-t">
            <div class="maceta-t__front face" />
            <div class="maceta-t__back face" />
            <div class="maceta-t__right face" />
            <div class="maceta-t__left face" />
            <div class="maceta-t__top face" />
            <div class="maceta-t__bottom face" />
          </div>
          <div class="maceta-b">
            <div class="maceta-b__front face" />
            <div class="maceta-b__back face" />
            <div class="maceta-b__right face" />
            <div class="maceta-b__left face" />
            <div class="maceta-b__top face" />
            <div class="maceta-b__bottom face" />
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { hospitalBedQuery_controller } from '../static/controller/BedMgr.controller'
export default {
  components: {
    Bed: () => import('../components/RoomLayout/Bed'),
    OtherBed: () => import('../components/RoomLayout/OtherBed')
  },
  props: {
    visible: Boolean,
    initValue: Object
  },
  data() {
    return {
      title: '',
      bedList: []
    }
  },
  mounted() {
    this.title = this.initValue.name
    hospitalBedQuery_controller({
      floorCode: this.initValue.id,
      organCode: this.initValue.organCode
    }).then((res) => {
      this.bedList = res
    })
  },
  methods: {
    handleCancel() {
      this.$emit('update:visible', false)
    },
    handleOk() {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style lang="less" >
.roombox {
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
    transform-style: preserve-3d;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    outline: none;
    appearance: none;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 600px;
  background-color: var(--bg1);
  overflow: hidden;
  --bg1: #8dd9e7;
  --bg2: #86c6d3;
  --bg3: #5c8f9b;
  --floor1: #ebe4d1;
  --floor2: #c9c5b3;
  --floor3: #bab6a6;
  --floor4: #a49e82;
  --wall1: #e3eaf0;
  --wall2: #ccd8dd;
  --wall2b: #c0ccd0;
  --wall2c: #b1bec2;
  --wall3: #9aadb5;
  --wood1: #feefcd;
  --wood2: #e8cfa9;
  --wood3: #cdac8c;
  --wood3b: #bfa183;
  --wood4: #a48669;
  --wood5: #996951;
  --white1: #edede4;
  --white2: #d9dfd4;
  --white3: #c3c9be;
  --white4: #aeb4a9;
  --blue1: #4f8fc0;
  --blue2: #4383b4;
  --blue3: #3473a3;
  --blue4: #27618c;
  --black1: #474747;
  --black2: #393939;
  --black3: #222222;
  --black4: #1a1a1a;
  --green1: #89b863;
  --green2: #6faa40;
  --green3: #59a11f;
  --yellow1: #e9e35a;
  --yellow2: #dbd54d;
  --yellow3: #d5ce32;

  .face {
    position: absolute;
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /***************/
  /***************/
  .room,
  .shadows {
    position: absolute;
    width: 30em;
    height: 45em;
    transform: perspective(10000px) rotateX(60deg) rotateZ(55deg)
      translateZ(-8em);
    transition: transform 100ms linear;
  }
  .bedSort {
    display: flex;
    flex-direction: column;
  }
  .shadow1,
  .shadow2,
  .shadow3,
  .shadow4,
  .shadow5,
  .shadow6 {
    position: absolute;
    top: 0em;
    left: 0em;
    background-color: var(--bg3);
    filter: blur(0.25em);
  }
  .shadow1 {
    width: 30.25em;
    height: 37.25em;
  }
  .shadow2 {
    top: 37em;
    left: 15em;
    width: 15.25em;
    height: 8.25em;
  }
  .shadow3,
  .shadow4 {
    width: 12.25em;
    height: 37.25em;
    left: -12em;
    background-color: transparent;
    background-image: linear-gradient(to left, var(--bg3), var(--bg2));
  }
  .shadow4 {
    width: 25em;
    height: 1.25em;
    top: 37em;
    left: -10em;
    filter: blur(1em);
    opacity: 0.75;
  }
  .shadow5 {
    top: -2em;
    left: 27em;
    width: 5em;
    height: 5em;
    background-color: transparent;
    background-image: radial-gradient(
      circle,
      var(--bg3),
      var(--bg2) 80%,
      transparent
    );
    border-radius: 50%;
    filter: blur(1em);
    opacity: 0.5;
  }
  .shadow6 {
    top: 45em;
    left: 15em;
    width: 15.25em;
    height: 1.25em;
    background-color: transparent;
    background-image: linear-gradient(
      to bottom,
      var(--bg3),
      var(--bg2) 80%,
      transparent
    );
    transform-origin: bottom right;
    filter: blur(1em);
    opacity: 0.75;
  }

  .os {
    position: absolute;
    filter: blur(0.2em);
    background-image: linear-gradient(to bottom, var(--floor2), transparent);
  }

  .fs {
    position: absolute;
    width: 115%;
    height: 110%;
    background-image: linear-gradient(
      to bottom,
      var(--floor3),
      var(--floor4),
      var(--floor4)
    );
    filter: blur(0.175em);
  }

  .lg {
    position: absolute;
    width: 5em;
    height: 150%;
    transform: rotateZ(20deg);
    background-image: linear-gradient(
      to right,
      transparent,
      var(--white2),
      transparent
    );
    opacity: 0.35;
    filter: blur(0.5em);
  }

  /***************/
  /***************/
  .at {
    position: absolute;
    top: 0em;
    left: 0em;
    width: 30em;
    height: 37em;
  }
  .at__front {
    width: 30em;
    height: 0.6em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(36.4em);
  }
  .at__back {
    width: 30em;
    height: 0.6em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-30em)
      translateY(-0.6em);
  }
  .at__right {
    width: 37em;
    height: 0.6em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(30em) translateX(-37em)
      translateY(-0.6em);
  }
  .at__left {
    width: 37em;
    height: 0.6em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.6em);
  }
  .at__top {
    width: 30em;
    height: 37em;
    transform-origin: top left;
    transform: translateZ(0.6em);
  }
  .at__bottom {
    width: 30em;
    height: 37em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-30em);
  }
  .at__top {
    overflow: hidden;
  }
  .at__top .os1 {
    width: 6em;
    height: 100%;
    background-image: linear-gradient(to right, var(--floor3), transparent);
  }
  .at__top .os2 {
    width: 100%;
    height: 6em;
  }
  .at__top .ws {
    position: absolute;
    filter: blur(0.45em);
    background-color: var(--floor2);
    transform: skewX(-5deg);
  }
  .at__top .ws1,
  .at__top .ws2,
  .at__top .ws3 {
    left: 1.5em;
    top: 1.5em;
    width: 5em;
    height: 20em;
  }
  .at__top .ws2 {
    left: 27em;
  }
  .at__top .ws3 {
    left: 52%;
    width: 0.5em;
  }
  .at__top .ws4,
  .at__top .ws5 {
    left: 1.5em;
    top: 1.5em;
    width: 28em;
    height: 6em;
  }
  .at__top .ws5 {
    top: 15.75em;
  }
  .at__top .ws6 {
    left: 9em;
    top: 1.5em;
    width: 14em;
    height: 10em;
  }
  .at__top .ws7 {
    right: 2em;
    top: 1.5em;
    width: 3em;
    height: 6em;
  }

  .ab {
    position: absolute;
    top: 37em;
    right: 0em;
    width: 15em;
    height: 8em;
  }
  .ab__front {
    width: 15em;
    height: 0.6em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(7.4em);
  }
  .ab__back {
    width: 15em;
    height: 0.6em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-15em)
      translateY(-0.6em);
  }
  .ab__right {
    width: 8em;
    height: 0.6em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(15em) translateX(-8em)
      translateY(-0.6em);
  }
  .ab__left {
    width: 8em;
    height: 0.6em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.6em);
  }
  .ab__top {
    width: 15em;
    height: 8em;
    transform-origin: top left;
    transform: translateZ(0.6em);
  }
  .ab__bottom {
    width: 15em;
    height: 8em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-15em);
  }

  .at__front,
  .ab__front {
    background-color: var(--wall3);
  }
  .at__back,
  .ab__back {
    background-color: var(--wall2);
  }
  .at__right,
  .ab__right {
    background-color: var(--wall2);
  }
  .at__left,
  .ab__left {
    background-color: var(--wall2c);
  }
  .at__top,
  .ab__top {
    background-color: var(--floor1);
  }
  .at__bottom,
  .ab__bottom {
    background-color: var(--wall3);
  }

  /********/
  .bb,
  .bbl,
  .bbr,
  .bt {
    position: absolute;
    top: 0;
    left: 1.5em;
    width: 28.5em;
    height: 1.5em;
    transform: translateZ(0.6em);
  }
  .bb__front,
  .bbl__front,
  .bbr__front,
  .bt__front {
    width: 28.5em;
    height: 3em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(-1.5em);
  }
  .bb__back,
  .bbl__back,
  .bbr__back,
  .bt__back {
    width: 28.5em;
    height: 3em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-28.5em)
      translateY(-3em);
  }
  .bb__right,
  .bbl__right,
  .bbr__right,
  .bt__right {
    width: 1.5em;
    height: 3em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(28.5em)
      translateX(-1.5em) translateY(-3em);
  }
  .bb__left,
  .bbl__left,
  .bbr__left,
  .bt__left {
    width: 1.5em;
    height: 3em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-3em);
  }
  .bb__top,
  .bbl__top,
  .bbr__top,
  .bt__top {
    width: 28.5em;
    height: 1.5em;
    transform-origin: top left;
    transform: translateZ(3em);
  }
  .bb__bottom,
  .bbl__bottom,
  .bbr__bottom,
  .bt__bottom {
    width: 28.5em;
    height: 1.5em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-28.5em);
  }
  .bb__front,
  .bbl__front,
  .bbr__front,
  .bt__front {
    background-color: var(--wall2b);
  }
  .bb__front .os1,
  .bbl__front .os1,
  .bbr__front .os1,
  .bt__front .os1 {
    width: 100%;
    height: 100%;
    bottom: 0;
    background-image: linear-gradient(to top, var(--wall2c), transparent);
  }
  .bb__front .os2,
  .bbl__front .os2,
  .bbr__front .os2,
  .bt__front .os2 {
    width: 100%;
    height: 1em;
    bottom: 0;
    background-image: linear-gradient(to top, var(--wall3), transparent);
  }
  .bb__back .os1,
  .bbl__back .os1,
  .bbr__back .os1,
  .bt__back .os1 {
    width: 100%;
    height: 6em;
    bottom: 0;
    background-image: linear-gradient(to top, var(--wall2b), transparent);
  }

  .bt {
    transform: translateZ(12.6em);
  }

  .bbl,
  .bbr {
    position: absolute;
    top: 0;
    left: 1.5em;
    width: 3em;
    height: 1.5em;
    transform: translateZ(3.6em);
  }
  .bbl__front,
  .bbr__front {
    width: 3em;
    height: 9em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(-7.5em);
  }
  .bbl__back,
  .bbr__back {
    width: 3em;
    height: 9em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-3em) translateY(-9em);
  }
  .bbl__right,
  .bbr__right {
    width: 1.5em;
    height: 9em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(3em) translateX(-1.5em)
      translateY(-9em);
  }
  .bbl__left,
  .bbr__left {
    width: 1.5em;
    height: 9em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-9em);
  }
  .bbl__top,
  .bbr__top {
    width: 3em;
    height: 1.5em;
    transform-origin: top left;
    transform: translateZ(9em);
  }
  .bbl__bottom,
  .bbr__bottom {
    width: 3em;
    height: 1.5em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-3em);
  }

  .bbr {
    left: 27em;
  }

  .bl {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.5em;
    height: 37em;
    transform: translateZ(0.6em);
  }
  .bl__front {
    width: 1.5em;
    height: 15em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(22em);
  }
  .bl__back {
    width: 1.5em;
    height: 15em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-1.5em)
      translateY(-15em);
  }
  .bl__right {
    width: 37em;
    height: 15em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(1.5em)
      translateX(-37em) translateY(-15em);
  }
  .bl__left {
    width: 37em;
    height: 15em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-15em);
  }
  .bl__top {
    width: 1.5em;
    height: 37em;
    transform-origin: top left;
    transform: translateZ(15em);
  }
  .bl__bottom {
    width: 1.5em;
    height: 37em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-1.5em);
  }
  .bl__front {
    background-color: var(--wall3);
  }
  .bl__right {
    overflow: hidden;
  }
  .bl__right .os0 {
    position: absolute;
    width: 50%;
    height: 200%;
    bottom: -74%;
    left: 72%;
    transform: rotateZ(55deg);
    background-image: linear-gradient(to top, var(--wall2), var(--wall2b));
  }
  .bl__right .os1 {
    width: 100%;
    height: 10em;
    bottom: 0;
    background-image: linear-gradient(
      to top,
      var(--wall2b),
      var(--wall2b),
      transparent
    );
  }
  .bl__right .os2 {
    width: 100%;
    height: 1em;
    bottom: 0;
    background-image: linear-gradient(to top, var(--wall3), var(--wall2b));
  }
  .bl__right .os3 {
    width: 10.25em;
    height: 4.25em;
    bottom: 0;
    left: 1.75em;
    background-image: linear-gradient(to top, var(--wall3), var(--wall2c));
  }
  .bl__right .os4 {
    width: 8.25em;
    height: 5em;
    top: 2.5em;
    left: 2.75em;
    background-image: linear-gradient(to top, var(--wall3), var(--wall2c));
  }
  .bl__right .os5 {
    width: 4.5em;
    height: 4.5em;
    bottom: 0;
    left: 12em;
    background-image: linear-gradient(to top, var(--wall3), var(--wall2c));
  }
  .bl__right .os6 {
    width: 19.5em;
    height: 12.35em;
    bottom: 0;
    right: 1.25em;
    background-image: linear-gradient(to top, var(--wall2c), var(--wall2c));
  }
  .bl__left .os1 {
    width: 100%;
    height: 6em;
    bottom: 0;
    background-image: linear-gradient(to top, var(--wall2c), transparent);
  }

  .bb__back,
  .bt__back,
  .bbl__back,
  .bbr__back,
  .bl__back {
    background-color: var(--wall2);
  }
  .bb__right,
  .bt__right,
  .bbl__right,
  .bbr__right,
  .bl__right {
    background-color: var(--wall2);
  }
  .bb__left,
  .bt__left,
  .bbl__left,
  .bbr__left,
  .bl__left {
    background-color: var(--wall2b);
  }
  .bb__top,
  .bt__top,
  .bbl__top,
  .bbr__top,
  .bl__top {
    background-color: var(--wall1);
  }
  .bb__bottom,
  .bt__bottom,
  .bbl__bottom,
  .bbr__bottom,
  .bl__bottom {
    background-color: var(--wall3);
  }

  .bb__top {
    background-color: var(--wall2);
  }

  .ventana {
    position: absolute;
    top: 0;
    left: 4.5em;
    width: 22.5em;
    height: 1.5em;
    transform: translateZ(3.6em);
  }
  .ventana__front {
    width: 22.5em;
    height: 9em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(-7.5em);
  }
  .ventana__back {
    width: 22.5em;
    height: 9em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-22.5em)
      translateY(-9em);
  }
  .ventana__right {
    width: 1.5em;
    height: 9em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(22.5em)
      translateX(-1.5em) translateY(-9em);
  }
  .ventana__left {
    width: 1.5em;
    height: 9em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-9em);
  }
  .ventana__top {
    width: 22.5em;
    height: 1.5em;
    transform-origin: top left;
    transform: translateZ(9em);
  }
  .ventana__bottom {
    width: 22.5em;
    height: 1.5em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-22.5em);
  }
  .ventana__back {
    top: 0.75em;
    background-image: radial-gradient(var(--bg2), var(--bg3));
    opacity: 0.5;
    overflow: hidden;
  }
  .ventana__back .lg1 {
    left: 60%;
  }
  .ventana__back .lg2 {
    width: 15em;
    left: -20%;
    border-radius: 50%;
  }
  .ventana__back .vs {
    position: absolute;
    opacity: 0.65;
    filter: blur(0.25em);
  }
  .ventana__back .vs1 {
    width: 4em;
    height: 100%;
    right: 0;
    background-image: linear-gradient(to right, var(--wood1), var(--wood3));
  }
  .ventana__back .vs2 {
    width: 14em;
    height: 2em;
    right: 4.5em;
    bottom: 0;
    background-image: linear-gradient(to right, var(--blue1), var(--blue1));
  }
  .ventana__back .vs3 {
    width: 6em;
    height: 6em;
    right: 4.5em;
    top: -2em;
    border-radius: 50%;
    background-image: radial-gradient(circle, var(--bg1), var(--bg1));
    filter: blur(0.35em);
  }
  .ventana__back .vs4 {
    width: 2.5em;
    height: 2em;
    bottom: 0;
    background-image: radial-gradient(circle, var(--white1), var(--white1));
    opacity: 0.5;
  }
  .ventana-l,
  .ventana-c,
  .ventana-r {
    position: absolute;
    top: 0.5em;
    width: 0.5em;
    height: 0.5em;
  }
  .ventana-l__front,
  .ventana-c__front,
  .ventana-r__front {
    width: 0.5em;
    height: 9em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(-8.5em);
  }
  .ventana-l__back,
  .ventana-c__back,
  .ventana-r__back {
    width: 0.5em;
    height: 9em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-0.5em)
      translateY(-9em);
  }
  .ventana-l__right,
  .ventana-c__right,
  .ventana-r__right {
    width: 0.5em;
    height: 9em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.5em)
      translateX(-0.5em) translateY(-9em);
  }
  .ventana-l__left,
  .ventana-c__left,
  .ventana-r__left {
    width: 0.5em;
    height: 9em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-9em);
  }
  .ventana-l__top,
  .ventana-c__top,
  .ventana-r__top {
    width: 0.5em;
    height: 0.5em;
    transform-origin: top left;
    transform: translateZ(9em);
  }
  .ventana-l__bottom,
  .ventana-c__bottom,
  .ventana-r__bottom {
    width: 0.5em;
    height: 0.5em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-0.5em);
  }
  .ventana-l__front,
  .ventana-c__front,
  .ventana-r__front {
    background-color: var(--white1);
  }
  .ventana-l__back,
  .ventana-c__back,
  .ventana-r__back {
    background-color: var(--white1);
  }
  .ventana-l__right,
  .ventana-c__right,
  .ventana-r__right {
    background-color: var(--white2);
  }
  .ventana-l__left,
  .ventana-c__left,
  .ventana-r__left {
    background-color: var(--white2);
  }
  .ventana-l__top,
  .ventana-c__top,
  .ventana-r__top {
    background-color: var(--white1);
  }
  .ventana-l__bottom,
  .ventana-c__bottom,
  .ventana-r__bottom {
    background-color: var(--white1);
  }
  .ventana-l {
    left: 0;
  }
  .ventana-c {
    left: 50%;
  }
  .ventana-r {
    right: 0;
  }
  .ventana-b,
  .ventana-t {
    position: absolute;
    left: 0.5em;
    top: 0.5em;
    width: 22em;
    height: 0.5em;
  }
  .ventana-b__front,
  .ventana-t__front {
    width: 22em;
    height: 0.5em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(0em);
  }
  .ventana-b__back,
  .ventana-t__back {
    width: 22em;
    height: 0.5em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-22em)
      translateY(-0.5em);
  }
  .ventana-b__right,
  .ventana-t__right {
    width: 0.5em;
    height: 0.5em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(22em)
      translateX(-0.5em) translateY(-0.5em);
  }
  .ventana-b__left,
  .ventana-t__left {
    width: 0.5em;
    height: 0.5em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.5em);
  }
  .ventana-b__top,
  .ventana-t__top {
    width: 22em;
    height: 0.5em;
    transform-origin: top left;
    transform: translateZ(0.5em);
  }
  .ventana-b__bottom,
  .ventana-t__bottom {
    width: 22em;
    height: 0.5em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-22em);
  }
  .ventana-b__front,
  .ventana-t__front {
    background-color: var(--white1);
  }
  .ventana-b__back,
  .ventana-t__back {
    background-color: var(--white1);
  }
  .ventana-b__right,
  .ventana-t__right {
    background-color: var(--white2);
  }
  .ventana-b__left,
  .ventana-t__left {
    background-color: var(--white2);
  }
  .ventana-b__top,
  .ventana-t__top {
    background-color: var(--white2);
  }
  .ventana-b__bottom,
  .ventana-t__bottom {
    background-color: var(--white1);
  }
  .ventana-t {
    transform: translateZ(8.5em);
  }

  /***************/
  .cama {
    position: relative;
    top: 0;
    left: 1.75em;
    width: 15em;
    height: 10em;
    transform: translateZ(0.6em);
  }
  .cama .fs {
    width: 102%;
    height: 102%;
    background-image: linear-gradient(to bottom, var(--floor2), var(--floor2));
  }
  .cama-al {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.75em;
    height: 10em;
  }
  .cama-al__front {
    width: 0.75em;
    height: 4em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(6em);
  }
  .cama-al__back {
    width: 0.75em;
    height: 4em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-0.75em)
      translateY(-4em);
  }
  .cama-al__right {
    width: 10em;
    height: 4em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.75em)
      translateX(-10em) translateY(-4em);
  }
  .cama-al__left {
    width: 10em;
    height: 4em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-4em);
  }
  .cama-al__top {
    width: 0.75em;
    height: 10em;
    transform-origin: top left;
    transform: translateZ(4em);
  }
  .cama-al__bottom {
    width: 0.75em;
    height: 10em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-0.75em);
  }
  .cama-ac {
    position: absolute;
    top: 0.5em;
    left: 0.75em;
    width: 13.5em;
    height: 9em;
    transform: translateZ(0.5em);
  }
  .cama-ac__front {
    width: 13.5em;
    height: 1em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(8em);
  }
  .cama-ac__back {
    width: 13.5em;
    height: 1em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-13.5em)
      translateY(-1em);
  }
  .cama-ac__right {
    width: 9em;
    height: 1em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(13.5em)
      translateX(-9em) translateY(-1em);
  }
  .cama-ac__left {
    width: 9em;
    height: 1em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-1em);
  }
  .cama-ac__top {
    width: 13.5em;
    height: 9em;
    transform-origin: top left;
    transform: translateZ(1em);
  }
  .cama-ac__bottom {
    width: 13.5em;
    height: 9em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-13.5em);
  }
  .cama-ar {
    position: absolute;
    top: 0;
    right: 0;
    width: 0.75em;
    height: 10em;
  }
  .cama-ar__front {
    width: 0.75em;
    height: 2.5em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(7.5em);
  }
  .cama-ar__back {
    width: 0.75em;
    height: 2.5em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-0.75em)
      translateY(-2.5em);
  }
  .cama-ar__right {
    width: 10em;
    height: 2.5em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.75em)
      translateX(-10em) translateY(-2.5em);
  }
  .cama-ar__left {
    width: 10em;
    height: 2.5em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-2.5em);
  }
  .cama-ar__top {
    width: 0.75em;
    height: 10em;
    transform-origin: top left;
    transform: translateZ(2.5em);
  }
  .cama-ar__bottom {
    width: 0.75em;
    height: 10em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-0.75em);
  }
  .cama-al__front,
  .cama-ac__front,
  .cama-ar__front {
    background-color: var(--wood3);
  }
  .cama-al__back,
  .cama-ac__back,
  .cama-ar__back {
    background-color: var(--wood2);
  }
  .cama-al__right,
  .cama-ac__right,
  .cama-ar__right {
    background-color: var(--wood2);
  }
  .cama-al__left,
  .cama-ac__left,
  .cama-ar__left {
    background-color: var(--wood2);
  }
  .cama-al__top,
  .cama-ac__top,
  .cama-ar__top {
    background-color: var(--wood1);
  }
  .cama-al__bottom,
  .cama-ac__bottom,
  .cama-ar__bottom {
    background-color: var(--wood3);
  }
  .cama-ac__top {
    background-image: linear-gradient(to top, var(--wood4), var(--wood2));
  }
  .cama-al__right {
    background-image: linear-gradient(
      to bottom,
      var(--wood2) 60%,
      var(--wood3)
    );
  }
  .cama-bc {
    position: absolute;
    top: 0.6em;
    left: 0.9em;
    width: 13.2em;
    height: 8.8em;
    transform: translateZ(1.6em);
  }
  .cama-bc__front {
    width: 13.2em;
    height: 1em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(7.8em);
  }
  .cama-bc__back {
    width: 13.2em;
    height: 1em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-13.2em)
      translateY(-1em);
  }
  .cama-bc__right {
    width: 8.8em;
    height: 1em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(13.2em)
      translateX(-8.8em) translateY(-1em);
  }
  .cama-bc__left {
    width: 8.8em;
    height: 1em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-1em);
  }
  .cama-bc__top {
    width: 13.2em;
    height: 8.8em;
    transform-origin: top left;
    transform: translateZ(1em);
  }
  .cama-bc__bottom {
    width: 13.2em;
    height: 8.8em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-13.2em);
  }
  .cama-bc__front {
    background-image: linear-gradient(
      to right,
      var(--white3) 40%,
      var(--bg3) 40%
    );
  }
  .cama-bc__back {
    background-image: linear-gradient(
      to left,
      var(--white2) 40%,
      var(--bg2) 40%
    );
  }
  .cama-bc__right {
    background-color: var(--white2);
  }
  .cama-bc__left {
    background-color: var(--white1);
  }
  .cama-bc__top {
    background-image: linear-gradient(
      to right,
      var(--white1) 40%,
      var(--bg1) 40%
    );
  }
  .cama-bc__bottom {
    background-color: var(--white3);
  }
  .cama-almos {
    position: absolute;
    top: 0.5em;
    left: 1em;
    height: 9em;
    width: 3em;
    transform: translateZ(2.6em);
    padding: 0.25em;
  }
  .cama-almo {
    position: absolute;
    width: 2.5em;
    height: 3.5em;
  }
  .cama-almo__front {
    width: 2.5em;
    height: 0.35em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(3.65em);
  }
  .cama-almo__back {
    width: 2.5em;
    height: 0.35em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-2.5em)
      translateY(-0.35em);
  }
  .cama-almo__right {
    width: 4em;
    height: 0.35em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(2.5em) translateX(-4em)
      translateY(-0.35em);
  }
  .cama-almo__left {
    width: 4em;
    height: 0.35em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.35em);
  }
  .cama-almo__top {
    width: 2.5em;
    height: 4em;
    transform-origin: top left;
    transform: translateZ(0.35em);
  }
  .cama-almo__bottom {
    width: 2.5em;
    height: 4em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-2.5em);
  }
  .cama-almo:nth-of-type(2) {
    bottom: 0.75em;
    transform: rotateZ(-10deg);
  }
  .cama-almo__front {
    background-color: var(--white3);
  }
  .cama-almo__back {
    background-color: var(--white2);
  }
  .cama-almo__right {
    background-color: var(--white2);
  }
  .cama-almo__left {
    background-color: var(--white1);
  }
  .cama-almo__top {
    background-color: var(--white1);
  }
  .cama-almo__bottom {
    background-color: var(--white3);
  }
  .cama-almo__sh {
    position: absolute;
    top: -5%;
    width: 110%;
    height: 120%;
    background-color: var(--wall2c);
    filter: blur(0.125em);
  }

  /***************/
  .repisa {
    position: absolute;
    left: 1.5em;
    top: 26em;
    width: 2.5em;
    height: 8em;
    transform: translateZ(10em);
    cursor: pointer;
  }
  .repisa-base {
    position: absolute;
    left: 0.1em;
    width: 3em;
    height: 8em;
    background-color: var(--white4);
    transform-origin: bottom left;
    transform: rotateY(-90deg);
  }
  .repisa-b,
  .repisa-t {
    position: absolute;
    width: 4em;
    height: 8em;
  }
  .repisa-b__front,
  .repisa-t__front {
    width: 2.5em;
    height: 0.25em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(7.75em);
  }
  .repisa-b__back,
  .repisa-t__back {
    width: 2.5em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-2.5em)
      translateY(-0.25em);
  }
  .repisa-b__right,
  .repisa-t__right {
    width: 8em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(2.5em) translateX(-8em)
      translateY(-0.25em);
  }
  .repisa-b__left,
  .repisa-t__left {
    width: 8em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.25em);
  }
  .repisa-b__top,
  .repisa-t__top {
    width: 2.5em;
    height: 8em;
    transform-origin: top left;
    transform: translateZ(0.25em);
  }
  .repisa-b__bottom,
  .repisa-t__bottom {
    width: 2.5em;
    height: 8em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-2.5em);
  }
  .repisa-b__front,
  .repisa-t__front {
    background-color: var(--white3);
  }
  .repisa-b__back,
  .repisa-t__back {
    background-color: var(--white1);
  }
  .repisa-b__right,
  .repisa-t__right {
    background-color: var(--white2);
  }
  .repisa-b__left,
  .repisa-t__left {
    background-color: var(--white2);
  }
  .repisa-b__top,
  .repisa-t__top {
    background-color: var(--white1);
  }
  .repisa-b__bottom,
  .repisa-t__bottom {
    background-color: var(--white4);
  }
  .repisa-t {
    transform: translateZ(3em);
  }
  .repisa-l,
  .repisa-c,
  .repisa-r {
    position: absolute;
    width: 4em;
    height: 0.25em;
    transform: translateZ(0.25em);
  }
  .repisa-l__front,
  .repisa-c__front,
  .repisa-r__front {
    width: 2.5em;
    height: 2.75em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(-2.5em);
  }
  .repisa-l__back,
  .repisa-c__back,
  .repisa-r__back {
    width: 2.5em;
    height: 2.75em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-2.5em)
      translateY(-2.75em);
  }
  .repisa-l__right,
  .repisa-c__right,
  .repisa-r__right {
    width: 0.25em;
    height: 2.75em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(2.5em)
      translateX(-0.25em) translateY(-2.75em);
  }
  .repisa-l__left,
  .repisa-c__left,
  .repisa-r__left {
    width: 0.25em;
    height: 2.75em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-2.75em);
  }
  .repisa-l__top,
  .repisa-c__top,
  .repisa-r__top {
    width: 2.5em;
    height: 0.25em;
    transform-origin: top left;
    transform: translateZ(2.75em);
  }
  .repisa-l__bottom,
  .repisa-c__bottom,
  .repisa-r__bottom {
    width: 2.5em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-2.5em);
  }
  .repisa-l__front,
  .repisa-c__front,
  .repisa-r__front {
    background-color: var(--white3);
  }
  .repisa-l__back,
  .repisa-c__back,
  .repisa-r__back {
    background-color: var(--white1);
  }
  .repisa-l__right,
  .repisa-c__right,
  .repisa-r__right {
    background-color: var(--white2);
  }
  .repisa-l__left,
  .repisa-c__left,
  .repisa-r__left {
    background-color: var(--white2);
  }
  .repisa-l__top,
  .repisa-c__top,
  .repisa-r__top {
    background-color: var(--white2);
  }
  .repisa-l__bottom,
  .repisa-c__bottom,
  .repisa-r__bottom {
    background-color: var(--white4);
  }
  .repisa-l {
    bottom: 0;
  }
  .repisa-l__back {
    background-color: var(--white3);
  }
  .repisa-c {
    bottom: calc(50% - 0.125em);
  }
  .repisa-c__back {
    background-color: var(--white3);
  }
  .repisa-c__right {
    background-color: var(--white3);
  }
  .repisa-puertas {
    width: 100%;
    height: 100%;
  }
  .repisa-puerta {
    position: absolute;
    width: 0.25em;
    height: 3.9em;
    left: 100%;
    transition: transform 0.3s linear;
    transform-origin: bottom left;
  }
  .repisa-puerta__front {
    width: 0.25em;
    height: 3.25em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(0.65em);
  }
  .repisa-puerta__back {
    width: 0.25em;
    height: 3.25em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-0.25em)
      translateY(-3.25em);
  }
  .repisa-puerta__right {
    width: 3.9em;
    height: 3.25em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.25em)
      translateX(-3.9em) translateY(-3.25em);
  }
  .repisa-puerta__left {
    width: 3.9em;
    height: 3.25em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-3.25em);
  }
  .repisa-puerta__top {
    width: 0.25em;
    height: 3.9em;
    transform-origin: top left;
    transform: translateZ(3.25em);
  }
  .repisa-puerta__bottom {
    width: 0.25em;
    height: 3.9em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-0.25em);
  }
  .repisa-puerta__front {
    background-color: var(--white3);
  }
  .repisa-puerta__back {
    background-color: var(--white1);
  }
  .repisa-puerta__right {
    background-color: var(--white2);
  }
  .repisa-puerta__left {
    background-color: var(--white3);
  }
  .repisa-puerta__top {
    background-color: var(--white1);
  }
  .repisa-puerta__bottom {
    background-color: var(--white4);
  }
  .repisa-puerta:nth-of-type(2) {
    bottom: 0;
  }

  .is-repisa-open {
    transform: rotateZ(40deg);
    transform-origin: bottom left;
    transition: transform 0.5s linear;
  }

  /***************/
  .buro {
    position: relative;
    left: 1.5em;
    /* top: 20.5em; */
    width: 3em;
    height: 4em;
    transform: translateZ(0.6em);
    cursor: pointer;
  }
  .buro-b,
  .buro-c,
  .buro-t {
    position: absolute;
    width: 3em;
    height: 5em;
  }
  .buro-b__front,
  .buro-c__front,
  .buro-t__front {
    width: 3em;
    height: 0.25em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(3.75em);
  }
  .buro-b__back,
  .buro-c__back,
  .buro-t__back {
    width: 3em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-3em)
      translateY(-0.25em);
  }
  .buro-b__right,
  .buro-c__right,
  .buro-t__right {
    width: 4em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(3em) translateX(-4em)
      translateY(-0.25em);
  }
  .buro-b__left,
  .buro-c__left,
  .buro-t__left {
    width: 4em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.25em);
  }
  .buro-b__top,
  .buro-c__top,
  .buro-t__top {
    width: 3em;
    height: 4em;
    transform-origin: top left;
    transform: translateZ(0.25em);
  }
  .buro-b__bottom,
  .buro-c__bottom,
  .buro-t__bottom {
    width: 3em;
    height: 4em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-3em);
  }
  .buro-b__front,
  .buro-c__front,
  .buro-t__front {
    background-color: var(--wood3);
  }
  .buro-b__back,
  .buro-c__back,
  .buro-t__back {
    background-color: var(--wood1);
  }
  .buro-b__right,
  .buro-c__right,
  .buro-t__right {
    background-color: var(--wood2);
  }
  .buro-b__left,
  .buro-c__left,
  .buro-t__left {
    background-color: var(--wood2);
  }
  .buro-b__top,
  .buro-c__top,
  .buro-t__top {
    background-color: var(--wood2);
  }
  .buro-b__bottom,
  .buro-c__bottom,
  .buro-t__bottom {
    background-color: var(--wood4);
  }
  .buro-b__top {
    background-color: var(--wood3b);
  }
  .buro-t {
    transform: translateZ(4em);
  }
  .buro-t__top {
    background-color: var(--wood1);
  }
  .buro-c {
    transform: translateZ(2em);
  }
  .buro-c__top {
    background-color: var(--wood3b);
  }
  .buro-c__right {
    background-color: var(--wood3b);
  }
  .buro-l,
  .buro-r {
    position: absolute;
    width: 0.25em;
    height: 5em;
  }
  .buro-l__front,
  .buro-r__front {
    width: 3em;
    height: 4.25em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(-4em);
  }
  .buro-l__back,
  .buro-r__back {
    width: 3em;
    height: 4.25em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-3em)
      translateY(-4.25em);
  }
  .buro-l__right,
  .buro-r__right {
    width: 0.25em;
    height: 4.25em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(3em)
      translateX(-0.25em) translateY(-4.25em);
  }
  .buro-l__left,
  .buro-r__left {
    width: 0.25em;
    height: 4.25em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-4.25em);
  }
  .buro-l__top,
  .buro-r__top {
    width: 3em;
    height: 0.25em;
    transform-origin: top left;
    transform: translateZ(4.25em);
  }
  .buro-l__bottom,
  .buro-r__bottom {
    width: 3em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-3em);
  }
  .buro-l__front,
  .buro-r__front {
    background-color: var(--wood4);
  }
  .buro-l__back,
  .buro-r__back {
    background-color: var(--wood1);
  }
  .buro-l__right,
  .buro-r__right {
    background-color: var(--wood2);
  }
  .buro-l__left,
  .buro-r__left {
    background-color: var(--wood2);
  }
  .buro-l__top,
  .buro-r__top {
    background-color: var(--wood1);
  }
  .buro-l__bottom,
  .buro-r__bottom {
    background-color: var(--wood4);
  }
  .buro-l {
    top: 100%;
  }
  .buro-l__back {
    background-color: var(--wood3);
  }
  .buro-l__front {
    background-color: var(--wood3);
  }
  .buro-puerta {
    position: absolute;
    width: 0.25em;
    height: 4.25em;
    left: 100%;
    transform: translateZ(0.125em);
  }
  .buro-puerta__front {
    width: 0.25em;
    height: 2em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(2.25em);
  }
  .buro-puerta__back {
    width: 0.25em;
    height: 2em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-0.25em)
      translateY(-2em);
  }
  .buro-puerta__right {
    width: 4.25em;
    height: 2em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.25em)
      translateX(-4.25em) translateY(-2em);
  }
  .buro-puerta__left {
    width: 4.25em;
    height: 2em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-2em);
  }
  .buro-puerta__top {
    width: 0.25em;
    height: 4.25em;
    transform-origin: top left;
    transform: translateZ(2em);
  }
  .buro-puerta__bottom {
    width: 0.25em;
    height: 4.25em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-0.25em);
  }
  .buro-puerta__front {
    background-color: var(--wood3);
  }
  .buro-puerta__back {
    background-color: var(--wood1);
  }
  .buro-puerta__right {
    background-color: var(--wood2);
  }
  .buro-puerta__left {
    background-color: var(--wood3);
  }
  .buro-puerta__top {
    background-color: var(--wood1);
  }
  .buro-puerta__bottom {
    background-color: var(--wood4);
  }
  .buro-puerta:nth-of-type(2) {
    transform: translateZ(2.25em);
  }

  /***************/
  .bocina {
    position: relative;
    left: 2.75em;
    /* top: 21em; */
    width: 0.5em;
    height: 3em;
    transform: translateZ(5em);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }
  .bocina-l,
  .bocina-r {
    width: 0.5em;
    height: 0.5em;
  }
  .bocina-l__front,
  .bocina-r__front {
    width: 0.5em;
    height: 1.75em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(-1.15em);
  }
  .bocina-l__back,
  .bocina-r__back {
    width: 0.5em;
    height: 1.75em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-0.5em)
      translateY(-1.75em);
  }
  .bocina-l__right,
  .bocina-r__right {
    width: 0.6em;
    height: 1.75em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.5em)
      translateX(-0.6em) translateY(-1.75em);
  }
  .bocina-l__left,
  .bocina-r__left {
    width: 0.6em;
    height: 1.75em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-1.75em);
  }
  .bocina-l__top,
  .bocina-r__top {
    width: 0.5em;
    height: 0.6em;
    transform-origin: top left;
    transform: translateZ(1.75em);
  }
  .bocina-l__bottom,
  .bocina-r__bottom {
    width: 0.5em;
    height: 0.6em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-0.5em);
  }
  .bocina-l__front,
  .bocina-r__front {
    background-color: var(--black3);
  }
  .bocina-l__back,
  .bocina-r__back {
    background-color: var(--black1);
  }
  .bocina-l__right,
  .bocina-r__right {
    background-color: var(--black2);
  }
  .bocina-l__left,
  .bocina-r__left {
    background-color: var(--black2);
  }
  .bocina-l__top,
  .bocina-r__top {
    background-color: var(--black2);
  }
  .bocina-l__bottom,
  .bocina-r__bottom {
    background-color: var(--black3);
  }
  .bocina-stereo {
    width: 0.5em;
    height: 1.5em;
  }
  .bocina-stereo__front {
    width: 0.5em;
    height: 0.75em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(0.75em);
  }
  .bocina-stereo__back {
    width: 0.5em;
    height: 0.75em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-0.5em)
      translateY(-0.75em);
  }
  .bocina-stereo__right {
    width: 1.5em;
    height: 0.75em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.5em)
      translateX(-1.5em) translateY(-0.75em);
  }
  .bocina-stereo__left {
    width: 1.5em;
    height: 0.75em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.75em);
  }
  .bocina-stereo__top {
    width: 0.5em;
    height: 1.5em;
    transform-origin: top left;
    transform: translateZ(0.75em);
  }
  .bocina-stereo__bottom {
    width: 0.5em;
    height: 1.5em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-0.5em);
  }
  .bocina-stereo__front {
    background-color: var(--black3);
  }
  .bocina-stereo__back {
    background-color: var(--black1);
  }
  .bocina-stereo__right {
    background-color: var(--black2);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bocina-stereo__right::before {
    content: "";
    position: absolute;
    width: 0.25em;
    height: 0.25em;
    border-radius: 50%;
    background-color: var(--white1);
  }
  .bocina-stereo__left {
    background-color: var(--black2);
  }
  .bocina-stereo__top {
    background-color: var(--black2);
  }
  .bocina-stereo__bottom {
    background-color: var(--black3);
  }

  .is-bocina-active {
    animation: bocina 0.5s ease infinite;
  }

  @keyframes bocina {
    0%,
    10%,
    50% {
      transform: translateZ(0);
    }
    40%,
    60%,
    90%,
    100% {
      transform: translateZ(-0.05em);
    }
  }
  .is-librero-open {
    transform: rotateZ(30deg);
    transform-origin: bottom left;
    transition: transform 0.5s linear;
  }

  .libro {
    position: absolute;
    width: 2em;
    height: 0.5em;
    cursor: pointer;
    transition: left 1s;
  }
  .libro__front {
    width: 2em;
    height: 2em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(-1.5em);
  }
  .libro__back {
    width: 2em;
    height: 2em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-2em) translateY(-2em);
  }
  .libro__right {
    width: 0.5em;
    height: 2em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(2em) translateX(-0.5em)
      translateY(-2em);
  }
  .libro__left {
    width: 0.5em;
    height: 2em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-2em);
  }
  .libro__top {
    width: 2em;
    height: 0.5em;
    transform-origin: top left;
    transform: translateZ(2em);
  }
  .libro__bottom {
    width: 2em;
    height: 0.5em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-2em);
  }
  .libro:nth-of-type(1) {
    left: 1.9em;
    top: 10em;
    transform: translateZ(6.9em);
  }
  .libro:nth-of-type(2) {
    left: 2em;
    top: 9.45em;
    transform: translateZ(6.8em);
  }
  .libro:nth-of-type(3) {
    left: 2em;
    top: 8.9em;
    transform: translateZ(6.8em);
  }
  .libro:nth-of-type(4) {
    left: 2em;
    top: 8em;
    transform: translateZ(6.9em) rotateX(-8deg);
  }
  .libro:nth-of-type(5) {
    left: 1.9em;
    top: 6.5em;
    transform: translateZ(6.9em) rotateX(-8deg);
  }
  .libro:nth-of-type(6) {
    left: 2em;
    top: 5.9em;
    transform: translateZ(6.9em) rotateX(-10deg);
  }
  .libro:nth-of-type(7) {
    left: 2.1em;
    top: 5.3em;
    transform: translateZ(6.9em) rotateX(-12deg);
  }
  .libro:nth-of-type(8) {
    left: 2em;
    top: 2em;
    transform: translateZ(7em) rotateX(-90deg);
  }
  .libro:nth-of-type(9) {
    left: 2.1em;
    top: 10em;
    transform: translateZ(3.9em);
  }
  .libro:nth-of-type(10) {
    left: 2em;
    top: 9.45em;
    transform: translateZ(3.8em);
  }
  .libro:nth-of-type(11) {
    left: 1.9em;
    top: 8.9em;
    transform: translateZ(3.8em);
  }
  .libro:nth-of-type(12) {
    left: 2em;
    top: 8em;
    transform: translateZ(3.9em) rotateX(-8deg);
  }
  .libro:nth-of-type(13) {
    left: 2.05em;
    top: 7.5em;
    transform: translateZ(3.9em) rotateX(-8deg);
  }
  .libro:hover {
    left: 4em;
    transition: left 1s;
  }
  .libro--azul .libro__front {
    background-color: var(--blue3);
  }
  .libro--azul .libro__back {
    background-color: var(--blue3);
  }
  .libro--azul .libro__right {
    background-color: var(--blue2);
  }
  .libro--azul .libro__left {
    background-color: var(--white3);
  }
  .libro--azul .libro__top {
    background-color: var(--white2);
  }
  .libro--azul .libro__bottom {
    background-color: var(--white2);
  }
  .libro--verde .libro__front {
    background-color: var(--green3);
  }
  .libro--verde .libro__back {
    background-color: var(--green3);
  }
  .libro--verde .libro__right {
    background-color: var(--green2);
  }
  .libro--verde .libro__left {
    background-color: var(--white3);
  }
  .libro--verde .libro__top {
    background-color: var(--white2);
  }
  .libro--verde .libro__bottom {
    background-color: var(--white2);
  }
  .libro--amarillo .libro__front {
    background-color: var(--bg3);
  }
  .libro--amarillo .libro__back {
    background-color: var(--bg3);
  }
  .libro--amarillo .libro__right {
    background-color: var(--bg2);
  }
  .libro--amarillo .libro__left {
    background-color: var(--white3);
  }
  .libro--amarillo .libro__top {
    background-color: var(--white2);
  }
  .libro--amarillo .libro__bottom {
    background-color: var(--white2);
  }
  .libro--blanco .libro__front {
    background-color: var(--white3);
  }
  .libro--blanco .libro__back {
    background-color: var(--white3);
  }
  .libro--blanco .libro__right {
    background-color: var(--white3);
  }
  .libro--blanco .libro__left {
    background-color: var(--white3);
  }
  .libro--blanco .libro__top {
    background-color: var(--white2);
  }
  .libro--blanco .libro__bottom {
    background-color: var(--white2);
  }

  /***************/
  .librero {
    position: absolute;
    left: 1.5em;
    top: 11em;
    width: 3em;
    height: 9em;
    transform: translateZ(0.6em);
    cursor: pointer;
  }
  .librero:nth-of-type(1) {
    top: 1.5em;
  }
  .librero-base {
    position: absolute;
    left: 0.1em;
    width: 12em;
    height: 9em;
    background-color: var(--wood4);
    transform-origin: bottom left;
    transform: rotateY(-90deg);
  }
  .librero-b,
  .librero-m1,
  .librero-m2,
  .librero-m3,
  .librero-t {
    position: absolute;
    width: 3em;
    height: 9em;
  }
  .librero-b__front,
  .librero-m1__front,
  .librero-m2__front,
  .librero-m3__front,
  .librero-t__front {
    width: 3em;
    height: 0.25em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(8.75em);
  }
  .librero-b__back,
  .librero-m1__back,
  .librero-m2__back,
  .librero-m3__back,
  .librero-t__back {
    width: 3em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-3em)
      translateY(-0.25em);
  }
  .librero-b__right,
  .librero-m1__right,
  .librero-m2__right,
  .librero-m3__right,
  .librero-t__right {
    width: 9em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(3em) translateX(-9em)
      translateY(-0.25em);
  }
  .librero-b__left,
  .librero-m1__left,
  .librero-m2__left,
  .librero-m3__left,
  .librero-t__left {
    width: 9em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.25em);
  }
  .librero-b__top,
  .librero-m1__top,
  .librero-m2__top,
  .librero-m3__top,
  .librero-t__top {
    width: 3em;
    height: 9em;
    transform-origin: top left;
    transform: translateZ(0.25em);
  }
  .librero-b__bottom,
  .librero-m1__bottom,
  .librero-m2__bottom,
  .librero-m3__bottom,
  .librero-t__bottom {
    width: 3em;
    height: 9em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-3em);
  }
  .librero-b__front,
  .librero-m1__front,
  .librero-m2__front,
  .librero-m3__front,
  .librero-t__front {
    background-color: var(--wood3);
  }
  .librero-b__back,
  .librero-m1__back,
  .librero-m2__back,
  .librero-m3__back,
  .librero-t__back {
    background-color: var(--wood1);
  }
  .librero-b__right,
  .librero-m1__right,
  .librero-m2__right,
  .librero-m3__right,
  .librero-t__right {
    background-color: var(--wood2);
  }
  .librero-b__left,
  .librero-m1__left,
  .librero-m2__left,
  .librero-m3__left,
  .librero-t__left {
    background-color: var(--wood2);
  }
  .librero-b__top,
  .librero-m1__top,
  .librero-m2__top,
  .librero-m3__top,
  .librero-t__top {
    background-color: var(--wood3);
  }
  .librero-b__bottom,
  .librero-m1__bottom,
  .librero-m2__bottom,
  .librero-m3__bottom,
  .librero-t__bottom {
    background-color: var(--wood4);
  }
  .librero-m1 {
    transform: translateZ(3em);
  }
  .librero-m2 {
    transform: translateZ(6em);
  }
  .librero-m3 {
    transform: translateZ(9em);
  }
  .librero-t {
    transform: translateZ(12em);
  }
  .librero-t__top {
    background-image: linear-gradient(
      60deg,
      var(--wood1) 36%,
      var(--wood2) 40%
    );
  }
  .librero-l,
  .librero-r {
    position: absolute;
    width: 0.25em;
    height: 5em;
  }
  .librero-l__front,
  .librero-r__front {
    width: 3em;
    height: 12.25em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(-12em);
  }
  .librero-l__back,
  .librero-r__back {
    width: 3em;
    height: 12.25em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-3em)
      translateY(-12.25em);
  }
  .librero-l__right,
  .librero-r__right {
    width: 0.25em;
    height: 12.25em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(3em)
      translateX(-0.25em) translateY(-12.25em);
  }
  .librero-l__left,
  .librero-r__left {
    width: 0.25em;
    height: 12.25em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-12.25em);
  }
  .librero-l__top,
  .librero-r__top {
    width: 3em;
    height: 0.25em;
    transform-origin: top left;
    transform: translateZ(12.25em);
  }
  .librero-l__bottom,
  .librero-r__bottom {
    width: 3em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-3em);
  }
  .librero-l__front,
  .librero-r__front {
    background-color: var(--wood3b);
  }
  .librero-l__back,
  .librero-r__back {
    background-color: var(--wood1);
  }
  .librero-l__right,
  .librero-r__right {
    background-color: var(--wood2);
  }
  .librero-l__left,
  .librero-r__left {
    background-color: var(--wood2);
  }
  .librero-l__top,
  .librero-r__top {
    background-color: var(--wood1);
  }
  .librero-l__bottom,
  .librero-r__bottom {
    background-color: var(--wood4);
  }
  .librero-r__top {
    background-color: var(--wood2);
  }
  .librero-l {
    top: 100%;
  }
  .librero-l__back {
    background-color: var(--wood3);
  }
  .librero-l__front {
    background-color: var(--wood3);
  }
  .librero-l1,
  .librero-l2,
  .librero-l3,
  .librero-l4 {
    top: calc(50% - 0.125em);
    position: absolute;
    width: 0.25em;
    height: 5em;
    transform: translateZ(3em);
  }
  .librero-l1__front,
  .librero-l2__front,
  .librero-l3__front,
  .librero-l4__front {
    width: 3em;
    height: 3em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(-2.75em);
  }
  .librero-l1__back,
  .librero-l2__back,
  .librero-l3__back,
  .librero-l4__back {
    width: 3em;
    height: 3em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-3em) translateY(-3em);
  }
  .librero-l1__right,
  .librero-l2__right,
  .librero-l3__right,
  .librero-l4__right {
    width: 0.25em;
    height: 3em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(3em)
      translateX(-0.25em) translateY(-3em);
  }
  .librero-l1__left,
  .librero-l2__left,
  .librero-l3__left,
  .librero-l4__left {
    width: 0.25em;
    height: 3em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-3em);
  }
  .librero-l1__top,
  .librero-l2__top,
  .librero-l3__top,
  .librero-l4__top {
    width: 3em;
    height: 0.25em;
    transform-origin: top left;
    transform: translateZ(3em);
  }
  .librero-l1__bottom,
  .librero-l2__bottom,
  .librero-l3__bottom,
  .librero-l4__bottom {
    width: 3em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-3em);
  }
  .librero-l1__front,
  .librero-l2__front,
  .librero-l3__front,
  .librero-l4__front {
    background-color: var(--wood3b);
  }
  .librero-l1__back,
  .librero-l2__back,
  .librero-l3__back,
  .librero-l4__back {
    background-color: var(--wood3b);
  }
  .librero-l1__right,
  .librero-l2__right,
  .librero-l3__right,
  .librero-l4__right {
    background-color: var(--wood2);
  }
  .librero-l1__left,
  .librero-l2__left,
  .librero-l3__left,
  .librero-l4__left {
    background-color: var(--wood2);
  }
  .librero-l1__top,
  .librero-l2__top,
  .librero-l3__top,
  .librero-l4__top {
    background-color: var(--wood1);
  }
  .librero-l1__bottom,
  .librero-l2__bottom,
  .librero-l3__bottom,
  .librero-l4__bottom {
    background-color: var(--wood4);
  }
  .librero-l2 {
    transform: translateZ(6em);
    top: calc(33% - 0.125em);
  }
  .librero-l3 {
    transform: translateZ(6em);
    top: calc(66% - 0.125em);
  }
  .librero-l4 {
    transform: translateZ(9em);
    top: calc(50% - 0.125em);
  }
  .librero-r1,
  .librero-r2,
  .librero-r3 {
    position: absolute;
    left: 3em;
    width: 0.25em;
    height: 3em;
  }
  .librero-r1__front,
  .librero-r2__front,
  .librero-r3__front {
    width: 0.25em;
    height: 12.25em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(-9.35em);
  }
  .librero-r1__back,
  .librero-r2__back,
  .librero-r3__back {
    width: 0.25em;
    height: 12.25em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-0.25em)
      translateY(-12.25em);
  }
  .librero-r1__right,
  .librero-r2__right,
  .librero-r3__right {
    width: 2.9em;
    height: 12.25em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.25em)
      translateX(-2.9em) translateY(-12.25em);
  }
  .librero-r1__left,
  .librero-r2__left,
  .librero-r3__left {
    width: 2.9em;
    height: 12.25em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-12.25em);
  }
  .librero-r1__top,
  .librero-r2__top,
  .librero-r3__top {
    width: 0.25em;
    height: 2.9em;
    transform-origin: top left;
    transform: translateZ(12.25em);
  }
  .librero-r1__bottom,
  .librero-r2__bottom,
  .librero-r3__bottom {
    width: 0.25em;
    height: 2.9em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-0.25em);
  }
  .librero-r1__front,
  .librero-r2__front,
  .librero-r3__front {
    background-color: var(--wood3b);
  }
  .librero-r1__back,
  .librero-r2__back,
  .librero-r3__back {
    background-color: var(--wood3b);
  }
  .librero-r1__right,
  .librero-r2__right,
  .librero-r3__right {
    background-color: var(--wood2);
  }
  .librero-r1__left,
  .librero-r2__left,
  .librero-r3__left {
    background-color: var(--wood2);
  }
  .librero-r1__top,
  .librero-r2__top,
  .librero-r3__top {
    background-color: var(--wood1);
  }
  .librero-r1__bottom,
  .librero-r2__bottom,
  .librero-r3__bottom {
    background-color: var(--wood4);
  }
  .librero-r1 {
    transition: transform 0.5s linear;
    transform-origin: bottom left;
  }
  .librero-r2 {
    top: calc(33% + 0.075em);
  }
  .librero-r3 {
    bottom: calc(0% - 0.125em);
  }
  .librero:nth-of-type(2) .librero-t .librero-t__top {
    background-image: linear-gradient(
      55deg,
      var(--wood1) 40%,
      var(--wood1) 40%
    );
  }
  .librero:nth-of-type(2) .librero-r .librero-r__top {
    background-color: var(--wood1);
  }

  .sillon {
    position: absolute;
    left: 9em;
    top: 2em;
    width: 14em;
    height: 4em;
    transform: translateZ(0.6em);
  }
  .sillon .fs {
    width: 100%;
  }
  .sillon-l,
  .sillon-c,
  .sillon-c2,
  .sillon-r {
    position: absolute;
    width: 1em;
    height: 4em;
  }
  .sillon-l__front,
  .sillon-c__front,
  .sillon-c2__front,
  .sillon-r__front {
    width: 1em;
    height: 3em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(1em);
  }
  .sillon-l__back,
  .sillon-c__back,
  .sillon-c2__back,
  .sillon-r__back {
    width: 1em;
    height: 3em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-1em) translateY(-3em);
  }
  .sillon-l__right,
  .sillon-c__right,
  .sillon-c2__right,
  .sillon-r__right {
    width: 4em;
    height: 3em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(1em) translateX(-4em)
      translateY(-3em);
  }
  .sillon-l__left,
  .sillon-c__left,
  .sillon-c2__left,
  .sillon-r__left {
    width: 4em;
    height: 3em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-3em);
  }
  .sillon-l__top,
  .sillon-c__top,
  .sillon-c2__top,
  .sillon-r__top {
    width: 1em;
    height: 4em;
    transform-origin: top left;
    transform: translateZ(3em);
  }
  .sillon-l__bottom,
  .sillon-c__bottom,
  .sillon-c2__bottom,
  .sillon-r__bottom {
    width: 1em;
    height: 4em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-1em);
  }
  .sillon-l__front,
  .sillon-c__front,
  .sillon-c2__front,
  .sillon-r__front {
    background-color: var(--blue3);
  }
  .sillon-l__back,
  .sillon-c__back,
  .sillon-c2__back,
  .sillon-r__back {
    background-color: var(--blue3);
  }
  .sillon-l__right,
  .sillon-c__right,
  .sillon-c2__right,
  .sillon-r__right {
    background-color: var(--blue2);
  }
  .sillon-l__left,
  .sillon-c__left,
  .sillon-c2__left,
  .sillon-r__left {
    background-color: var(--blue3);
  }
  .sillon-l__top,
  .sillon-c__top,
  .sillon-c2__top,
  .sillon-r__top {
    background-color: var(--blue1);
  }
  .sillon-l__bottom,
  .sillon-c__bottom,
  .sillon-c2__bottom,
  .sillon-r__bottom {
    background-color: var(--blue3);
  }
  .sillon-r {
    right: 0;
  }
  .sillon-c,
  .sillon-c2 {
    left: 1em;
  }
  .sillon-c__front,
  .sillon-c2__front {
    width: 5.95em;
    height: 2em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(1.9em);
  }
  .sillon-c__back,
  .sillon-c2__back {
    width: 5.95em;
    height: 2em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-5.95em)
      translateY(-2em);
  }
  .sillon-c__right,
  .sillon-c2__right {
    width: 3.9em;
    height: 2em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(5.95em)
      translateX(-3.9em) translateY(-2em);
  }
  .sillon-c__left,
  .sillon-c2__left {
    width: 3.9em;
    height: 2em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-2em);
  }
  .sillon-c__top,
  .sillon-c2__top {
    width: 5.95em;
    height: 3.9em;
    transform-origin: top left;
    transform: translateZ(2em);
  }
  .sillon-c__bottom,
  .sillon-c2__bottom {
    width: 5.95em;
    height: 3.9em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-5.95em);
  }
  .sillon-c2 {
    left: 7em;
  }
  .sillon-b,
  .sillon-b2 {
    position: absolute;
    width: 5.95em;
    height: 1em;
    left: 1em;
    transform: rotateX(10deg);
  }
  .sillon-b__front,
  .sillon-b2__front {
    width: 5.95em;
    height: 4.5em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(-3.5em);
  }
  .sillon-b__back,
  .sillon-b2__back {
    width: 5.95em;
    height: 4.5em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-5.95em)
      translateY(-4.5em);
  }
  .sillon-b__right,
  .sillon-b2__right {
    width: 1em;
    height: 4.5em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(5.95em)
      translateX(-1em) translateY(-4.5em);
  }
  .sillon-b__left,
  .sillon-b2__left {
    width: 1em;
    height: 4.5em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-4.5em);
  }
  .sillon-b__top,
  .sillon-b2__top {
    width: 5.95em;
    height: 1em;
    transform-origin: top left;
    transform: translateZ(4.5em);
  }
  .sillon-b__bottom,
  .sillon-b2__bottom {
    width: 5.95em;
    height: 1em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-5.95em);
  }
  .sillon-b__front,
  .sillon-b2__front {
    background-color: var(--blue3);
  }
  .sillon-b__back,
  .sillon-b2__back {
    background-color: var(--blue3);
  }
  .sillon-b__right,
  .sillon-b2__right {
    background-color: var(--blue2);
  }
  .sillon-b__left,
  .sillon-b2__left {
    background-color: var(--blue2);
  }
  .sillon-b__top,
  .sillon-b2__top {
    background-color: var(--blue1);
  }
  .sillon-b__bottom,
  .sillon-b2__bottom {
    background-color: var(--blue3);
  }
  .sillon-b2 {
    left: 7em;
  }

  .arbustos {
    transform: rotateZ(25deg);
    width: 1em;
    height: 3.5em;
    position: absolute;
    left: 22em;
    top: 40em;
    transform: rotateZ(26deg) translateZ(0.6em);
  }
  .arbustos .fs {
    left: -0.5em;
    top: -0.75em;
    opacity: 0.5;
  }

  .arbusto,
  .arbusto2 {
    position: absolute;
    transform: translateZ(0.6em) rotateX(90deg) rotateZ(22.5deg);
  }
  .arbusto:nth-of-type(2),
  .arbusto2:nth-of-type(2) {
    top: 2.5em;
  }
  .arbusto-el,
  .arbusto2-el {
    width: 1em;
    height: 1em;
  }
  .arbusto-el__front,
  .arbusto2-el__front {
    width: 0.425em;
    height: 0.75em;
    transform: rotateX(-90deg) translateZ(0.5em);
  }
  .arbusto-el__back,
  .arbusto2-el__back {
    width: 0.425em;
    height: 0.75em;
    transform: rotateX(90deg) translateZ(0.5em);
  }
  .arbusto-el__top,
  .arbusto2-el__top {
    width: 0.425em;
    height: 1em;
    transform: rotateY(0deg) translateZ(0.375em);
  }
  .arbusto-el__bottom,
  .arbusto2-el__bottom {
    width: 0.425em;
    height: 1em;
    transform: rotateY(180deg) translateZ(0.375em);
  }
  .arbusto-el__right,
  .arbusto2-el__right {
    width: 0.75em;
    height: 1em;
    transform: rotateY(90deg) translateZ(0.1960784314em);
  }
  .arbusto-el__left,
  .arbusto2-el__left {
    width: 0.75em;
    height: 1em;
    transform: rotateY(90deg) translateZ(-0.1960784314em);
  }
  .arbusto-el:nth-of-type(2),
  .arbusto2-el:nth-of-type(2) {
    transform: rotateZ(45deg);
  }
  .arbusto-el:nth-of-type(3),
  .arbusto2-el:nth-of-type(3) {
    transform: rotateZ(90deg);
  }
  .arbusto-el:nth-of-type(4),
  .arbusto2-el:nth-of-type(4) {
    transform: rotateZ(135deg);
  }
  .arbusto-el__front,
  .arbusto2-el__front {
    background-color: var(--black3);
  }
  .arbusto-el__back,
  .arbusto2-el__back {
    background-color: var(--black2);
  }
  .arbusto-el__right,
  .arbusto2-el__right {
    background-color: var(--black3);
  }
  .arbusto-el__left,
  .arbusto2-el__left {
    background-color: var(--black2);
  }
  .arbusto-el__top,
  .arbusto2-el__top {
    background-color: var(--black1);
  }
  .arbusto-el__bottom,
  .arbusto2-el__bottom {
    background-color: var(--black3);
  }

  .arbusto2 {
    top: 1.25em;
  }
  .arbusto2-el {
    width: 0.25em;
    height: 0.25em;
  }
  .arbusto2-el__front {
    width: 0.10625em;
    height: 2.5em;
    transform: rotateX(-90deg) translateZ(0.125em);
  }
  .arbusto2-el__back {
    width: 0.10625em;
    height: 2.5em;
    transform: rotateX(90deg) translateZ(0.125em);
  }
  .arbusto2-el__top {
    width: 0.10625em;
    height: 0.25em;
    transform: rotateY(0deg) translateZ(1.25em);
  }
  .arbusto2-el__bottom {
    width: 0.10625em;
    height: 0.25em;
    transform: rotateY(180deg) translateZ(1.25em);
  }
  .arbusto2-el__right {
    width: 2.5em;
    height: 0.25em;
    transform: rotateY(90deg) translateZ(0.0490196078em);
  }
  .arbusto2-el__left {
    width: 2.5em;
    height: 0.25em;
    transform: rotateY(90deg) translateZ(-0.0490196078em);
  }
  .arbusto2-el:nth-of-type(2) {
    transform: rotateZ(45deg);
  }
  .arbusto2-el:nth-of-type(3) {
    transform: rotateZ(90deg);
  }
  .arbusto2-el:nth-of-type(4) {
    transform: rotateZ(135deg);
  }
  .arbusto2-el__front {
    background-color: var(--floor3);
  }
  .arbusto2-el__back {
    background-color: var(--floor2);
  }
  .arbusto2-el__right {
    background-color: var(--floor3);
  }
  .arbusto2-el__left {
    background-color: var(--floor2);
  }
  .arbusto2-el__top {
    background-color: var(--floor1);
  }
  .arbusto2-el__bottom {
    background-color: var(--floor3);
  }

  .maceta {
    position: absolute;
    right: 2em;
    top: 2em;
    width: 3em;
    height: 3em;
    transform: translateZ(0.61em);
  }
  .maceta-base {
    position: absolute;
    left: 0.5em;
    top: 0.5em;
    width: 2em;
    height: 2em;
    transform: translateZ(1.5em);
    background-color: var(--white1);
  }
  .maceta-hoja {
    position: absolute;
    width: 0.5em;
    height: 1.35em;
    border-radius: 50%;
    transform: translateZ(0.85em) rotateX(90deg) rotateY(-25deg);
    background-color: var(--green1);
  }
  .maceta-hoja:nth-of-type(1) {
    left: 0.1em;
    top: 0.1em;
    transform: translateZ(0.8em) rotateX(90deg) rotateY(-65deg);
  }
  .maceta-hoja:nth-of-type(2) {
    left: 0.5em;
    top: 0.2em;
    background-color: var(--green2);
  }
  .maceta-hoja:nth-of-type(3) {
    left: 0.95em;
    top: 0em;
    transform: translateZ(0.75em) rotateX(90deg) rotateY(-30deg);
    background-color: var(--green3);
  }
  .maceta-hoja:nth-of-type(4) {
    left: 1.25em;
    top: 0.4em;
    background-color: var(--green2);
  }
  .maceta-hoja:nth-of-type(5) {
    left: 1.5em;
    top: 0.6em;
  }
  .maceta-hoja:nth-of-type(6) {
    left: 0.35em;
    top: 0.56em;
    transform: translateZ(0.65em) rotateX(90deg) rotateY(-55deg);
  }
  .maceta-hoja:nth-of-type(7) {
    left: 0.55em;
    top: 1.46em;
  }
  .maceta-hoja:nth-of-type(8) {
    left: 0.935em;
    top: 1.2em;
    transform: translateZ(0.75em) rotateX(90deg) rotateY(-40deg);
  }
  .maceta-hoja:nth-of-type(9) {
    left: 1em;
    top: 1.2em;
    background-color: var(--green3);
  }
  .maceta-hoja:nth-of-type(10) {
    left: 1.5em;
    top: 0em;
    transform: translateZ(0.85em) rotateX(90deg) rotateY(-65deg);
    background-color: var(--green3);
  }
  .maceta-hoja:nth-of-type(11) {
    left: 0.1em;
    top: 0.1em;
    transform: translateZ(0.5em) rotateX(90deg) rotateY(-85deg);
  }
  .maceta-hoja:nth-of-type(12) {
    left: 0.5em;
    top: 0.2em;
    background-color: var(--green2);
  }
  .maceta-hoja:nth-of-type(13) {
    left: 0.95em;
    top: 0em;
    transform: translateZ(0.75em) rotateX(90deg) rotateY(90deg);
    background-color: var(--green3);
  }
  .maceta-hoja:nth-of-type(14) {
    left: 1.25em;
    top: 0.4em;
    transform: translateZ(0.5em) rotateX(90deg) rotateY(90deg);
  }
  .maceta-hoja:nth-of-type(15) {
    left: 1.35em;
    top: 0.6em;
    transform: translateZ(0.75em) rotateX(90deg) rotateY(-100deg);
    background-color: var(--green2);
  }
  .maceta-l,
  .maceta-r {
    position: absolute;
    width: 0.5em;
    height: 3em;
  }
  .maceta-l__front,
  .maceta-r__front {
    width: 0.5em;
    height: 2em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(1em);
  }
  .maceta-l__back,
  .maceta-r__back {
    width: 0.5em;
    height: 2em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-0.5em)
      translateY(-2em);
  }
  .maceta-l__right,
  .maceta-r__right {
    width: 3em;
    height: 2em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.5em) translateX(-3em)
      translateY(-2em);
  }
  .maceta-l__left,
  .maceta-r__left {
    width: 3em;
    height: 2em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-2em);
  }
  .maceta-l__top,
  .maceta-r__top {
    width: 0.5em;
    height: 3em;
    transform-origin: top left;
    transform: translateZ(2em);
  }
  .maceta-l__bottom,
  .maceta-r__bottom {
    width: 0.5em;
    height: 3em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-0.5em);
  }
  .maceta-l__front,
  .maceta-r__front {
    background-color: var(--white3);
  }
  .maceta-l__back,
  .maceta-r__back {
    background-color: var(--white3);
  }
  .maceta-l__right,
  .maceta-r__right {
    background-color: var(--white2);
  }
  .maceta-l__left,
  .maceta-r__left {
    background-color: var(--white3);
  }
  .maceta-l__top,
  .maceta-r__top {
    background-color: var(--white1);
  }
  .maceta-l__bottom,
  .maceta-r__bottom {
    background-color: var(--white3);
  }
  .maceta-r {
    right: 0;
  }
  .maceta-b,
  .maceta-t {
    position: absolute;
    left: 0.5em;
    width: 2em;
    height: 0.5em;
  }
  .maceta-b__front,
  .maceta-t__front {
    width: 2em;
    height: 2em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(-1.5em);
  }
  .maceta-b__back,
  .maceta-t__back {
    width: 2em;
    height: 2em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-2em) translateY(-2em);
  }
  .maceta-b__right,
  .maceta-t__right {
    width: 0.5em;
    height: 2em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(2em) translateX(-0.5em)
      translateY(-2em);
  }
  .maceta-b__left,
  .maceta-t__left {
    width: 0.5em;
    height: 2em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-2em);
  }
  .maceta-b__top,
  .maceta-t__top {
    width: 2em;
    height: 0.5em;
    transform-origin: top left;
    transform: translateZ(2em);
  }
  .maceta-b__bottom,
  .maceta-t__bottom {
    width: 2em;
    height: 0.5em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-2em);
  }
  .maceta-b__front,
  .maceta-t__front {
    background-color: var(--white3);
  }
  .maceta-b__back,
  .maceta-t__back {
    background-color: var(--white3);
  }
  .maceta-b__right,
  .maceta-t__right {
    background-color: var(--white2);
  }
  .maceta-b__left,
  .maceta-t__left {
    background-color: var(--white3);
  }
  .maceta-b__top,
  .maceta-t__top {
    background-color: var(--white1);
  }
  .maceta-b__bottom,
  .maceta-t__bottom {
    background-color: var(--white3);
  }
  .maceta-b {
    bottom: 0;
  }

  .manija {
    position: absolute;
    width: 1em;
    height: 0.25em;
  }
  .manija__front {
    width: 1em;
    height: 0.25em;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ(0em);
  }
  .manija__back {
    width: 1em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-1em)
      translateY(-0.25em);
  }
  .manija__right {
    width: 0.25em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ(1em)
      translateX(-0.25em) translateY(-0.25em);
  }
  .manija__left {
    width: 0.25em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.25em);
  }
  .manija__top {
    width: 1em;
    height: 0.25em;
    transform-origin: top left;
    transform: translateZ(0.25em);
  }
  .manija__bottom {
    width: 1em;
    height: 0.25em;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-1em);
  }
  .manija__front {
    background-color: var(--white3);
  }
  .manija__back {
    background-color: var(--white1);
  }
  .manija__right {
    background-color: var(--white1);
  }
  .manija__left {
    background-color: var(--white3);
  }
  .manija__top {
    background-color: var(--white2);
  }
  .manija__bottom {
    background-color: var(--white3);
  }
}
</style>
