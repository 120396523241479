/*
 * @Author: your name
 * @Date: 2021-06-10 18:41:30
 * @LastEditTime: 2021-06-11 14:06:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\api\VisitRecordMgr.api.js
 */
let {
  hsc
} = require('../config/api_spec')
module.exports = {
  addVisitRecord_api: {
    url: `${hsc}/mgr/home2shop/visitRecord/add`,
    post: {
      taskId: '?',
      visitComment: '?',
      visitDate: '?',
      visitType: '?',
      visitUserId: '?',
      visitUserName: '?'
    }
  },
  // mgr/home2shop/visitRecord/query
  queryVisitRecord_api: {
    url: `${hsc}/mgr/home2shop/visitRecord/query`,
    post: {
      'page': '?',
      'rows': '?',
      'visitUserName': '?',
      'name': '?'
    }
  }
}
