/*
 * @Author: your name
 * @Date: 2021-06-02 18:59:06
 * @LastEditTime: 2021-06-02 18:59:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\record.controller.js
 */
let {
  queryAccountBillInfo_service
} = require('../service/AccountBillInfoMgr.service')

module.exports = {
  queryAccountBillInfo_controller(val) {
    return queryAccountBillInfo_service(val)
  }
}
