/*
 * @Author: your name
 * @Date: 2021-03-03 19:25:31
 * @LastEditTime: 2021-03-03 19:26:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\TenantApp.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  getTenantApplications_api
} = require('../api/TenantApp.api')

module.exports = {
  getTenantApplications_service() {
    return xhr(getTenantApplications_api, {})
  }
}
