<!--
 * @Description:
 * @Author:
 * @Date: 2021-05-18 09:48:32
 * @LastEditTime: 2021-05-29 12:19:39
 * @LastEditors:
-->
<template>
  <div class="personal">
    <a-row :gutter="24" type="flex" justify="space-between">
      <a-col :span="12">
        <a-card title="个人信息" class="margin_10" style="min-height:190px">
          <a-card-meta>
            <a-avatar
              slot="avatar"
              :src="dataobj.img"
              icon="user"
            />
            <div slot="title">
              <a-row type="flex" justify="space-between">
                <a-col class="textl">
                  <p style="margin-bottom:15px">{{ dataobj.deviceBindCustName }}<a-divider type="vertical" /> {{ dataobj.birthday }}岁 <a-divider type="vertical" /> {{ dataobj.sex===true?'男':'女' }}<a-divider type="vertical" /> 护理等级：{{ getLabel(levelList,dataobj.nurseType) }}</p>
                  <p>登记时间：{{ dataobj.comeDate }}</p>
                </a-col>
              </a-row>
            </div>
          </a-card-meta>
        </a-card>

        <a-card title="检测日志" style="min-height:400px">
          <a-table
            :columns="columns"
            :data-source="data"
            :pagination="false"
          >
            <span slot="dataType" slot-scope="record">{{ getLabel(indicatorList,record.dataType) }}</span>
            <span slot="dataType2" slot-scope="text,record">{{ getContents(text,record) }}</span>
            <span slot="tip" slot-scope="text,record" :class="{'red':record.checkData.state==='2'}">{{ text }}</span>
          </a-table>
          <pagination
            :page.sync="pageIndex"
            :limit.sync="pageSize"
            :total="total"
            class="paginationStyle"
            @pagination="getPages"
          />
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card title="最近异常检测记录" class="cardr">
          <a-table
            :columns="columns"
            :data-source="columndata"
            :pagination="pagination"
            @change="changeTable"
          >
            <span slot="dataType" slot-scope="record">{{ getLabel(indicatorList,record.dataType) }}</span>
            <span slot="dataType2" slot-scope="text,record">{{ getContents(text,record) }}</span>
            <span slot="tip" slot-scope="text,record" :class="{'red':record.checkData.state==='2'}">{{ text }}</span>

          </a-table>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { elderDetail_controller, elderCheck_controller } from '../../static/controller/MonitoringApp.controller'
import { levelList, indicatorList } from '../../static/enum/index'
const columns = [
  {
    title: '检测项目',
    scopedSlots: { customRender: 'dataType' }
  },
  {
    title: '检测值',
    dataIndex: 'dataType',
    scopedSlots: { customRender: 'dataType2' }
  },
  {
    title: '检测结果',
    dataIndex: 'checkData.tip',
    scopedSlots: { customRender: 'tip' }
  },
  {
    title: '检测时间',
    dataIndex: 'checkData.monitoringTime',
    key: 'checkData.monitoringTime'
  }
]
export default {
  components: {
    pagination: () => import('../../components/Pagination')
  },
  data() {
    return {
      columns,
      data: [],
      pageIndex: 1, // 当前页码
      pageSize: 10,
      total: 0,
      columndata: [],
      dataobj: {},
      levelList,
      indicatorList,
      winHeight: window.innerHeight - 300,
      pagination: { total: 0 },
      current: 1
    }
  },
  created() {
    this.initData()
    this.initData2()
    this.initData3()
  },
  methods: {
    initData() {
      elderDetail_controller({
        customerNo: this.$route.params.id
      }).then((res) => {
        console.log(res)
        this.dataobj = res
      })
    },
    initData2() {
      elderCheck_controller({
        customerNo: this.$route.params.id,
        page: this.pageIndex,
        rows: this.pageSize
      }).then((res) => {
        console.log(res)
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    },
    initData3() {
      elderCheck_controller({
        customerNo: this.$route.params.id,
        page: this.current,
        rows: 10,
        type: 1
      }).then((res) => {
        this.columndata = res.records
        this.pagination.current = res.current
        this.pagination.total = res.total
      })
    },
    changeTable(page) {
      this.current = page
      this.initData3()
    },
    getLabel(list, value) {
      let label = ''
      list.map((item) => {
        if (item.value === value) {
          label = item.label
        }
      })
      return label
    },
    getContents(type, data) {
      console.log(type, data)
      let content = ''
      switch (type) {
        case 'blood_pressure':
          content = data.checkData.systolicBloodPressure + ' / ' + data.checkData.diastolicBloodPressure
          break
        case 'blood_oxygen':
          content = data.checkData.spo2 + ' / ' + data.checkData.pulseRate
          break
        case 'blood_sugar':
          content = data.checkData.glu
          break
        case 'cholesterol':
          content = data.checkData.cholesterol
          break
        case 'uricacid':
          content = data.checkData.uricAcid
          break
        // case 'whr':
        //   content = data.checkData.waistline + '/' + data.checkData.hip + '/' + data.checkData.whr
        //   break
        case 'temperature':
          content = data.checkData.temp
          break
      }
      return content
    },
    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.initData2()
    }
  }

}
</script>

<style scope lang="less">
.personal{
    .ant-avatar-image {
        height: 60px;
        width: 60px;
        margin-right: 10px;
    }
    .ant-card-meta-title{
        font-size: 14px;
    }
    .cardr{
        min-height: 600px;
    }
}

</style>
