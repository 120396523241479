/*
 * @Author:
 * @Date: 2021-03-01 12:44:42
 * @LastEditTime: 2021-04-14 19:38:26
 * @LastEditors:
 * @Description:地区查询

 */
const {
  xhr
} = require('../lib/xhr')
const {
  getRegionInfo_api,
  getqueryLowerAll_api
} = require('../api/RegionInfo.api')

module.exports = {
  getRegionInfo_service(val) {
    return xhr(getRegionInfo_api, val)
  },
  getqueryLowerAll_service(val) {
    return xhr(getqueryLowerAll_api, val)
  }
}
