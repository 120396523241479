/*
 * @Description:
 * @Author:
 * @Date: 2021-03-09 19:08:13
 * @LastEditTime: 2021-03-24 14:12:45
 * @LastEditors:
 */

let {
  getBedMgr_service
} = require('../service/HospitalBedMgr.service')

module.exports = {
  queryBedMgr_controller(val) {
    return getBedMgr_service(val)
  }
}

