<template>
  <div class="view-scroll">
    <div class="m-box col m-mt-10 box">
      <a-row type="flex" justify="space-between">
        <a-col :span="5">
          <a-button type="primary" @click="add()">添加建议</a-button>
        </a-col>
        <a-col :span="7">
          <a-input-search
            v-model="phone"
            placeholder="请输入查询姓名或者检测水平"
            style="width: 100%"
            @search="onSearch"
          />
        </a-col>
      </a-row>
    </div>

    <div class="m-box m-mt-10">
      <a-table :columns="columns" :data-source="data" :pagination="false">
        <div slot="name" slot-scope="record" class="text-3d9">
          <span class="type">{{ record }}</span>
        </div>
        <div slot="status" slot-scope="record" class="text-3d9">
          <span
            :style="
              record.state == '1'
                ? 'color: #BEE6CF; padding-left: 15px'
                : 'color: #FF1818; padding-left: 15px'
            "
          >{{ record.state === "1" ? "已启用" : "禁用" }}</span>
        </div>
        <div slot="use" slot-scope="record" class="text-3d9">
          <a-button
            type="primary"
            style="background: ##1890ff; border-color: ##1890ff"
            @click="setItem(record)"
          >编辑</a-button>
          <a-button
            type="danger"
            class="m-ml-10"
            @click="del(record)"
          >删除</a-button>
          <a-button
            type="primary"
            class="m-ml-10"
            :style="record.state === '1'
              ? 'background: #ff9d4d; border-color: #ff9d4d'
              : 'background: rgb(56, 178, 107);border-color: rgb(56, 178, 107)'
            "
            @click="state(record, index)"
          >{{ record.state === "1" ? '禁用':'启用' }}</a-button>
        </div>
      </a-table>

      <pagination
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        :total="total"
        class="paginationStyle"
        @pagination="getPages"
      />
    </div>
    <!-- 添加 -->
    <a-modal :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <a-col :span="24" style="margin-bottom: 20px">添加建议</a-col>
      <a-textarea
        v-model="newName"
        placeholder="填写建议"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
      <span style="line-height:30px margin-bottom:10px">检测项目</span>
      <a-select
        :size="size"
        style="width: 100%"
        :default-value="checkname"
        @change="handleChangeF"
      >
        <a-select-option v-for="(item, index) in list" :key="index">
          {{ item.name }}
        </a-select-option>
      </a-select>
      <span style="line-height:30px margin-bottom:10px">检测水平</span>
      <a-select
        :size="size"
        :default-value="checkLevelname"
        style="width: 100%"
        @change="handleChange"
      >
        <a-select-option v-for="(v, i) in arry" :key="i">
          {{ v }}
        </a-select-option>
      </a-select>
    </a-modal>

    <!-- 修改 -->
    <a-modal :visible="visible1" @ok="handleOk1" @cancel="handleCancel">
      <a-col :span="24" style="margin-bottom: 20px">修改建议</a-col>
      <a-textarea
        v-model="addInfo.suggestContent"
        placeholder="填写建议"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
      <span style="line-height:30px margin-bottom:10px">检测项目</span>
      <a-select
        v-model="addInfo.checkName"
        :size="size"
        :default-value="addInfo.checkName"
        style="width: 100%"
        @change="handleChangeF1"
      >
        <a-select-option v-for="(item, index) in list" :key="index">
          {{ item.name }}
        </a-select-option>
      </a-select>
      <span style="line-height:30px margin-bottom:10px">检测水平</span>
      <a-select
        :size="size"
        :default-value="addInfo.checkLevelName"
        :value="addInfo.checkLevelName"
        style="width: 100%"
        @change="handleChange1"
      >
        <a-select-option v-for="(v, i) in arry" :key="i">
          {{ v }}
        </a-select-option>
      </a-select>
    </a-modal>
  </div>
</template>

<script>
import {
  AddEquipAnalysis_controller,
  UpdateEquipAnalysis_controller,
  equipAnalysisQueryAll_controller,
  disableEquipAnalysisById_controller,
  removeEquipAnalysisById_controller
} from '../../static/controller/cost.controller'
const columns = [
  {
    title: '建议内容',
    dataIndex: 'suggestContent',
    scopedSlots: {
      customRender: 'name'
    }
  },
  {
    title: '检测项目',
    dataIndex: 'checkName'
  },
  {
    title: '检测水平',
    dataIndex: 'checkLevelName'
  },
  {
    title: '更新时间',
    dataIndex: 'edt'
  },
  {
    title: '状态',
    // dataIndex: 'status',
    scopedSlots: {
      customRender: 'status'
    }
  },
  {
    title: '操作',
    scopedSlots: {
      customRender: 'use'
    }
  }
]

const data = []
const arry = []
export default {
  filters: {},
  components: {
    pagination: () => import('../../components/Pagination')
  },
  data() {
    return {
      data,
      columns,
      arry,
      selectedRowKeys: [],
      form: this.$form.createForm(this, {
        name: 'evalutaion'
      }),
      addForm: this.$form.createForm(this, {
        name: 'customer_list'
      }),
      visible: false,
      pageIndex: 1, // 当前页码
      pageSize: 10,
      pagination: {}, // tab分页
      total: 0,
      where: {},
      //   修改内容
      addInfo: {},
      //   新增知识库名称
      newName: '',
      ids: [],
      index: false,
      size: 'default',
      checkCode: '',
      checkname: '',
      checkLevelCode: '',
      checkLevelname: '',
      visible1: false,
      list: [
        {
          name: '血压',
          arr: ['低血压水平', '正常', '正常高值', '1级高血压水平', '2级高血压水平', '3级高血压水平', '单纯收缩期高血压']
        },
        {
          name: '总胆固醇',
          arr: ['偏低', '正常', '偏高', '明显偏高']
        },
        {
          name: '心电',
          arr: ['心动过缓', '正常', '心动过速']
        },
        {
          name: '体温',
          arr: ['正常', '低热', '高热']
        },
        {
          name: '血糖',
          arr: ['低血糖', '正常', '空腹血糖受损', '糖耐量异常', '糖代谢异常', '高血糖']
        },
        {
          name: '血氧',
          arr: ['正常', '轻度偏低', '偏低', '明显偏低']
        },
        {
          name: '尿酸',
          arr: ['偏低', '正常', '偏高']
        },
        {
          name: '腰臀比',
          arr: ['正常', '偏高']
        }
      ],
      phone: ''
    }
  },

  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },

  mounted() {
    console.log(equipAnalysisQueryAll_controller)
    this.getElderList()
  },
  methods: {
    getElderList() {
      equipAnalysisQueryAll_controller({
        // ...this.where,
        page: this.pageIndex,
        rows: this.pageSize
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    },

    start() {
      this.loading = true
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      let ids = []
      for (let x of selectedRowKeys) {
        for (let i in this.data) {
          console.log(x, i)
          if (x === +i) {
            ids.push(this.data[i].id)
          }
        }
      }
      console.log(ids)
      this.ids = ids
      this.selectedRowKeys = selectedRowKeys
    },
    handleChange(e) {
      console.log(e)
      this.checkLevelCode = e
      this.checkLevelname = this.arry[e]
    },
    handleChangeF(e) {
      this.checkCode = e
      this.checkname = this.list[e].name
      this.arry = this.list[e].arr
    },
    handleChange1(e) {
      this.addInfo.checkLevelCode = e
      this.addInfo.checkLevelName = this.arry[e]
    },
    handleChangeF1(e) {
      this.addInfo.checkCode = e
      this.addInfo.checkName = this.list[e].name
      this.arry = this.list[e].arr
    },
    // handleSearch(e) {
    //   e.preventDefault()
    //   this.form.validateFields((error, values) => {
    //     console.log(error, values)
    //     this.pageIndex = 1
    //     this.where = values
    //     this.getElderList()
    //   })
    // },
    del(e) {
      removeEquipAnalysisById_controller({ id: e.id }).then((res) => {
        this.$message.success('删除成功')
        this.getElderList()
      })
    },
    state(e, index) {
      console.log(index, e)
      if (e.state === '0') {
        disableEquipAnalysisById_controller({
          id: e.id,
          state: '1'
        }).then((res) => {
          this.$message.success('修改成功')
          this.getElderList()
        })
      } else {
        disableEquipAnalysisById_controller({
          id: e.id,
          state: '0'
        }).then((res) => {
          this.$message.success('修改成功')
          this.getElderList()
        })
      }
    },
    onSearch() {
      equipAnalysisQueryAll_controller({
        keyWord: this.phone,
        page: this.pageIndex,
        rows: this.pageSize
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
        this.phone = ''
      })
    },
    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.getElderList()
    },

    setItem(e) {
      this.addInfo = e
      this.where = e
      this.visible1 = true
      this.arry = this.list[e.checkCode].arr
    },
    add() {
      this.visible = true
    },
    handleOk(e) {
      AddEquipAnalysis_controller({
        checkCode: this.checkCode,
        checkLevelCode: this.checkLevelCode,
        checkLevelName: this.checkLevelname,
        checkName: this.checkname,
        suggestContent: this.newName
      }).then((res) => {
        this.visible = false
        this.$message.success('新增成功')
        this.checkCode = ''
        this.checkLevelCode = ''
        this.newName = ''
        this.getElderList()
      })
    },
    handleOk1(e) {
      UpdateEquipAnalysis_controller({
        checkCode: this.addInfo.checkCode,
        checkLevelCode: this.addInfo.checkLevelCode,
        checkLevelName: this.addInfo.checkLevelName,
        checkName: this.addInfo.checkName,
        suggestContent: this.addInfo.suggestContent,
        id: this.addInfo.id
      }).then((res) => {
        this.visible1 = false
        this.$message.success('修改成功')
        this.getElderList()
      })
    },
    handleCancel(e) {
      this.visible = false
      this.visible1 = false
      // this.addInfo = this.where
    },

    updSuccess() {
      console.log('chenggong')
      this.getElderList()
    }
  }
}
</script>
<style scoped lang="less">
.index-img {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 0;
  right: 0;
}

.box .ant-form-item {
  margin: 10px 0 !important;
}

.ant-row {
  display: flex;
  align-items: center;
}

.m-mt-4 {
  margin-top: 4px;
}

.photo {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.type {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  color: #000;
  opacity: 0.65;
}
</style>
