var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"分配注册码","visible":_vm.visible,"footer":null,"mask-closable":false},on:{"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 17 }},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"注册码数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'count',
            {
              rules: [
                {
                  required: true,
                  message: '注册码数量不能为空!',
                },
                {
                  pattern: /^[1-9]\d*$/,
                  message: '请输入正整数!',
                }
              ],
              validateTrigger: ['blur'],
            }
          ]),expression:"[\n            'count',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '注册码数量不能为空!',\n                },\n                {\n                  pattern: /^[1-9]\\d*$/,\n                  message: '请输入正整数!',\n                }\n              ],\n              validateTrigger: ['blur'],\n            }\n          ]"}],attrs:{"id":"count","min":1,"max":100,"step":5}})],1),_c('a-form-item',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","size":_vm.size}},[_vm._v(" 确认 ")]),_c('a-button',{staticStyle:{"background":"#999","border-color":"#999"},attrs:{"type":"primary","size":_vm.size},on:{"click":_vm.handleCancel}},[_vm._v(" 取消 ")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }