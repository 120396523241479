/*
 * @Author: 刘崇杰
 * @Date: 2021-03-01 12:44:42
 * @LastEditTime: 2021-03-01 14:52:31
 * @LastEditors: Please set LastEditors
 * @Description: 验证码接口访问
 * @FilePath: \ioac-tenant-html\src\static\service\Captcha.service.js
 */
const {
  xhr
} = require('../lib/xhr')

const {
  getCaptcha_api,
  checkCaptcha_api,
  queryList_api
} = require('../api/Captcha.api')

module.exports = {
  // 获取图形验证码
  getCaptcha_service(val) {
    return xhr(getCaptcha_api, val)
  },
  // 检查图形验证码
  checkCaptcha_service(val) {
    return xhr(checkCaptcha_api, val)
  },

  // 反馈中心
  queryList_service(val) {
    return xhr(queryList_api, val)
  }

}
