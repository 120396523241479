/*
 * @Description:
 * @Author:
 * @Date: 2021-04-15 15:08:36
 * @LastEditTime: 2021-04-15 15:09:51
 * @LastEditors:
 */
const {
  xhr
} = require('../lib/xhr')
const {
  getByType_api
} = require('../api/OrganTemplateMgr.api')

module.exports = {
  getByType_service(val) {
    return xhr(getByType_api, val)
  }
}
