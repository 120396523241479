<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      visible: false,
      size: 'large'
    }
  },
  computed: {
    ...mapState({
      organOptions: (state) => state.organ.organOptions
    })
  },
  methods: {
    handleCancel() {
      this.visible = false
    }
  }
}
</script>

<style>

</style>
