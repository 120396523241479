/*
 * @Description:
 * @Author:
 * @Date: 2021-04-15 15:10:43
 * @LastEditTime: 2021-04-15 15:11:49
 * @LastEditors:
 */
const {
  getByType_service
} = require('../service/OrganTemplateMgr.service')
module.exports = {
  getByType_controller(val) {
    return getByType_service(val)
  }
}
