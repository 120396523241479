/*
 * @Description:
 * @Author:
 * @Date: 2021-03-20 10:19:12
 * @LastEditTime: 2021-03-31 14:53:49
 * @LastEditors:
 */

let {
  addfeeItem_service,
  updatefeeItem_service,
  queryfeeItem_service,
  detailfeeItem_service,
  removefeeItem_service,
  updateStatefeeItem_service,
  feeItemlist_service
} = require('../service/FinanceFeeItemMgr.service')

module.exports = {
  addfeeItem_controller(val) {
    return addfeeItem_service(val)
  },
  updatefeeItem_controller(val) {
    return updatefeeItem_service(val)
  },
  queryfeeItem_controller(val) {
    return queryfeeItem_service(val)
  },
  detailfeeItem_controller(val) {
    return detailfeeItem_service(val)
  },
  removefeeItem_controller(val) {
    return removefeeItem_service(val)
  },
  updateStatefeeItem_controller(val) {
    return updateStatefeeItem_service(val)
  },
  feeItemlist_controller(val) {
    return feeItemlist_service(val)
  }
}
