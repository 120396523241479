/*
 * @Author: your name
 * @Date: 2021-05-26 10:36:22
 * @LastEditTime: 2021-06-02 19:43:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\CustomerRegisterMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  register_api,
  queryPageRegister_api,
  registerGetById_api,
  registerUpdate_api,
  registerRemoveById_api,
  queryCheckInCustList_api
} = require('../api/CustomerRegisterMgr.api')

module.exports = {
  register_service(val) {
    return xhr(register_api, val)
  },
  queryPageRegister_service(val) {
    return xhr(queryPageRegister_api, val)
  },
  registerGetById_service(val) {
    return xhr(registerGetById_api, val)
  },
  registerUpdate_service(val) {
    return xhr(registerUpdate_api, val)
  },
  registerRemoveById_service(val) {
    return xhr(registerRemoveById_api, val)
  },
  queryCheckInCustList_service(val) {
    return xhr(queryCheckInCustList_api, val)
  }
}
