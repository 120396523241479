<!--
 * @Description:
 * @Author:
 * @Date: 2021-03-25 10:16:56
 * @LastEditTime: 2021-04-29 17:51:04
 * @LastEditors:
-->
<template>
  <div>
    <div style="padding:10px 24px">
      <a-descriptions
        :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
      >
        <a-descriptions-item label="姓名">
          {{ elderIfo.name }}
        </a-descriptions-item>
        <a-descriptions-item label="性别">
          {{ elderIfo.sex==='0'?'未知':elderIfo.sex==='1'?'男':'女' }}

        </a-descriptions-item>
        <a-descriptions-item label="出生年月">
          {{ elderIfo.birthday }}
        </a-descriptions-item>
        <a-descriptions-item label="身份证号">
          {{ elderIfo.idNo }}
        </a-descriptions-item>
        <a-descriptions-item label="民族">
          {{ codeInfo.nationName }}
        </a-descriptions-item>
        <a-descriptions-item label="婚姻状态">
          {{ codeInfo.maritalState }}
        </a-descriptions-item>
        <a-descriptions-item label="文化状态">
          {{ codeInfo.educationDegree }}
        </a-descriptions-item>
        <a-descriptions-item label="原职业">
          {{ codeInfo.oldJob }}
        </a-descriptions-item>
        <a-descriptions-item label="户籍详细地址">
          {{ elderIfo.adminProvinceName+elderIfo.adminCityName+elderIfo.adminHotelName+elderIfo.homeAddr }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions
        :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
      >
        <a-descriptions-item label="目前服用药物">
          {{ remark }}
        </a-descriptions-item>
        <a-descriptions-item label="申请入院理由">
          {{ comeReason }}
        </a-descriptions-item>
      </a-descriptions>
      <a-divider />
      <a-descriptions
        :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
      >
        <a-descriptions-item label="担保人姓名">
          {{ datas.name }}
        </a-descriptions-item>
        <a-descriptions-item label="担保人联系电话">
          {{ datas.mobile }}
        </a-descriptions-item>
      </a-descriptions>

    </div>
    <div style="padding:10px 24px">
      <p class="formTitle">家庭成员信息</p>
      <a-table :columns="columns" :data-source="data" :pagination="false">
        <a slot="sex" slot-scope="text"><span>{{ text==='0'?'未知':text==='1'?'男':'女' }}</span></a>
        <span slot="areanames" slot-scope="text">
          {{ text.join("/") }}
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { detailComeCustomerMgr_controller } from '../../static/controller/ComeCustomerMgr.controller'
import { getLabel } from '../../static/enum/index'
const columns = [
  {
    title: '称谓',
    dataIndex: 'relType'
  },
  {
    title: '姓名',
    className: 'name',
    dataIndex: 'name'
  },
  {
    title: '性别',
    dataIndex: 'sex',
    scopedSlots: { customRender: 'sex' }
  },
  {
    title: '身份证号',
    dataIndex: 'idNo'
  },
  {
    title: '手机号',
    dataIndex: 'mobile'
  },
  {
    title: '户籍地区',
    dataIndex: 'registerAreaNames',
    scopedSlots: { customRender: 'areanames' }
  },
  {
    title: '户籍详细地址',
    key: 'homeAddr',
    dataIndex: 'homeAddr'
  }

]
export default {
  data() {
    return {
      elderIfo: {},
      familyList: [],
      comeReason: '',
      remark: '',
      columns,
      data: [],
      nation: [],
      marital_state: [],
      education_degree: [],
      job: [],
      nationName: '',
      codeInfo: {},
      datas: {}// 担保人信息
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      detailComeCustomerMgr_controller({ id: this.$route.params.id }).then(async(res) => {
        console.log(res)
        this.elderIfo = res.customerModel
        this.data = res.comeCustomerFamilyModelList
        this.comeReason = res.comeReason
        this.remark = res.remark
        let familyList = res.comeCustomerFamilyModelList
        if (familyList.length === 1) {
          this.datas = familyList[0]
        } else {
          familyList.map((item) => {
            if (item.type === '1') {
              this.datas = item
            }
          })
        }
        let marital_state = await getLabel('marital_state', String(this.elderIfo.maritalState))
        let old_job = await getLabel('job', String(this.elderIfo.oldJob))
        let education_degree = await getLabel('education_degree', String(this.elderIfo.educationDegree))
        let nationName = await getLabel('nation', String(this.elderIfo.nation))
        this.codeInfo = {
          nationName: nationName,
          maritalState: marital_state,
          educationDegree: education_degree,
          oldJob: old_job
        }
      })
    }
  }

}
</script>

<style>
.ant-descriptions-row > td{
    padding-bottom: 35px;
}

</style>
