/*
 * @Author: your name
 * @Date: 2021-06-22 14:37:31
 * @LastEditTime: 2021-06-22 14:54:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\api\TenantCompanyMgr.api.js
 */
let {
  uc
} = require('../config/api_spec')

module.exports = {
  // 获取当前租户模块(应用)列表
  getCompany_api: {
    url: `${uc}/mgr/t/company/get`,
    get: {
      companyNo: '?'
    }
  }
}
