/*
 * @Description: 入院咨询
 * @Author:
 * @Date: 2021-03-03 10:36:52
 * @LastEditTime: 2021-03-31 18:48:58
 * @LastEditors:
 */

module.exports = {
  getCustomerBookingMg_api: {
    url: 'ioac-center/mgr/customer/booking/list',
    post: {
      page: 1,
      rows: 10,
      custName: '',
      state: ''
    }
  },
  deleteCustomerBookingMg_api: {
    url: 'ioac-center/mgr/customer/booking/remove',
    post: {
      id: '?'
    }
  },
  confirmCustomerBookingMg_api: {
    url: 'ioac-center/mgr/customer/booking/confirmCheckIn',
    post: {
      id: '?'
    }
  },
  addCustomerBookingMg_api: {
    url: 'ioac-center/mgr/customer/booking/add',
    post: {
      addr: '', // 居住地地址
      bathway: '',
      bookingHospitalBedNo: '',
      cognitionlevel: '',
      custName: '',
      custAge: '',
      custSex: '',
      consultContent: '?',
      eatway: '',
      linkTel: '',
      moveway: '',
      remark: '', // 服药情况
      toiletway: '',
      registArea: '?', // 户籍地区
      registAddr: '?', // 户籍详细地址
      customerFamilyModels: [], // 家庭情况
      custBirthday: '?'
    }
  },
  editCustomerBookingMg_api: {
    url: 'ioac-center/mgr/customer/booking/update',
    post: {
      id: '?',
      addr: '', // 居住地地址
      bathway: '',
      bookingHospitalBedNo: '',
      cognitionlevel: '',
      custName: '',
      custAge: '',
      custSex: '',
      consultContent: '?',
      eatway: '',
      linkTel: '',
      moveway: '',
      remark: '', // 服药情况
      toiletway: '',
      registArea: '?', // 户籍地区
      registAddr: '?', // 户籍详细地址
      customerFamilyModels: [], // 家庭情况
      custBirthday: '?'
    }
  },
  addFloorMgr_api: {
    url: 'ioac-center/mgr/ioac/floor/queryHasBed',
    post: {

    }
  },
  detailCustomerBookingMg_api: {
    url: 'ioac-center/mgr/customer/booking/getDetailById',
    post: {
      id: '?'
    }
  }
}
