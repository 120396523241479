/*
 * @Description:入院登记
 * @Author:
 * @Date: 2021-03-04 20:03:13
 * @LastEditTime: 2021-06-15 16:56:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-03-13 16:49:17
 * @LastEditors:
 */
module.exports = {
  queryCheckInCustList_api: {
    url: 'ioac-center/mgr/customer/come/queryCheckInCustList',
    post: {
      nameIdNo: '?',
      comeWay: '?'
    }
  },
  getComeCustomerMgr_api: {
    url: 'ioac-center/mgr/customer/come/list',
    post: {
      page: 1,
      rows: 10,
      nameIdenti: '?',
      state: '?',
      nurseType: '?'
    }
  },
  deleteComeCustomerMgr_api: {
    url: 'ioac-center/mgr/customer/come/remove',
    post: {
      id: '?'
    }
  },
  confirmCustomerBookingMg_api: {
    url: 'ioac-center/mgr/customer/booking/confirmCheckIn',
    post: {
      id: '?'
    }
  },
  // addComeCustomerMgr_api: {
  //   url: 'ioac-center/mgr/customer/come/add',
  //   post: {
  //     comeCustomerFamilyModels: '?',
  //     customerModel: '?',
  //     comeReason: '?', // 入院理由
  //     remark: '?', //
  //     comeWay: '?',
  //     customerBookingId: '?'
  //   }
  // },
  addComeCustomerMgr_api: {
    url: 'seniors-center/mgr/customer/come/add',
    post: {
      homeCustomerFamilyModels: '?',
      homeCustomerModel: '?',
      comeReason: '?', // 入院理由
      remark: '?', //
      comeWay: '?',
      customerBookingId: '?'
    }
  },
  editComeCustomerMgr_api: {
    url: 'seniors-center/mgr/customer/come/update',
    post: {
      id: '?',
      homeCustomerFamilyModels: '?',
      homeCustomerModel: '?',
      comeReason: '?', // 入院理由
      remark: '?'
    }
  },
  detailComeCustomerMgr_api: {
    url: 'seniors-center/mgr/customer/come/getDetailInfoById',
    get: {
      id: '?'
    }
  },
  // editComeCustomerMgr_api: {
  //   url: 'ioac-center/mgr/customer/come/update',
  //   post: {
  //     id: '?',
  //     comeCustomerFamilyModels: '?',
  //     customerModel: '?',
  //     comeReason: '?', // 入院理由
  //     remark: '?'
  //   }
  // },
  updateSimple_api: {
    url: 'ioac-center/mgr/customer/come/updateSimple',
    post: {
      id: '?',
      hospitalBedNo: '?'
    }
  },
  // 确认居住
  confirmCheckIn_api: {
    url: 'ioac-center/mgr/customer/booking/confirmCheckIn',
    post: {
      id: '?'
    }
  },
  // 列表详情
  detailList_api: {
    url: 'ioac-center/mgr/customer/come/getRegisterDetailById',
    get: {
      id: '?'
    }
  },
  // 查询已入住的客户信息(分页)
  queryCheckInCustPage_api: {
    url: 'ioac-center/mgr/customer/come/queryCheckInCustPage',
    post: {
      page: '?',
      rows: '?',
      nameIdNo: '?',
      nurseType: '?',
      organTrier: '?' // 合同类型
    }
  }

}
