/*
 * @Author: your name
 * @Date: 2021-06-07 18:57:28
 * @LastEditTime: 2021-06-08 19:52:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\evaluationform.controller.js
 */
let {
  getCustomerEvaluationInfo_service,
  batchSubmitCustomer_service,
  evaluation_service
} = require('../service/CustomerEvaluationMgr.service')

module.exports = {
  getCustomerEvaluationInfo_controller(val) {
    return getCustomerEvaluationInfo_service(val)
  },
  batchSubmitCustomer_controller(val) {
    return batchSubmitCustomer_service(val)
  },
  evaluation_controller(val) {
    return evaluation_service(val)
  }
}
