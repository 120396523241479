/*
 * @Description: 入院咨询
 * @Author:
 * @Date: 2021-03-03 11:07:27
 * @LastEditTime: 2021-03-18 15:36:47
 * @LastEditors:
 */
let {
  getCustomerBookingMg_service,
  deleteCustomerBookingMg_serveice,
  confirmCustomerBookingMg_service,
  addCustomerBookingMg_service,
  addFloorMgr_service,
  detailCustomerBookingMg_service,
  editCustomerBookingMg_service
} = require('../service/CustomerBookingMg.service')

module.exports = {
  getCustomerBookingMg_controller(val) {
    return getCustomerBookingMg_service(val)
  },
  deleteCustomerBookingMg_controller(val) {
    return deleteCustomerBookingMg_serveice(val)
  },
  confirmCustomerBookingMg_controller(val) {
    return confirmCustomerBookingMg_service(val)
  },
  addCustomerBookingMg_controller(val) {
    return addCustomerBookingMg_service(val)
  },
  detailCusBookingMg_controller(val) {
    return detailCustomerBookingMg_service(val)
  },
  // 房间号
  addFloorMgr_controller(val) {
    return addFloorMgr_service(val)
  },

  editCustomerBookingMg_controller(val) {
    return editCustomerBookingMg_service(val)
  }

}
