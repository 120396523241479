<template>
  <!-- 档案 -->
  <div class="view-scroll">
    <a-page-header
      title="健康档案"
      sub-title="查看"
      @back="() => $router.go(-1)"
    />
    <div class="m-box m-mt-10">
      <a-row style="margin: 10px 0">
        <a-col :span="24">
          <span
            style="
              border-bottom: 1px solid #027aff;
              line-height: 20px;
              padding: 10px 0;
            "
          >
            个人档案
          </span>
        </a-col>
      </a-row>
      <a-row type="flex">
        <a-col :span="7" style="margin-right: 20px; height: 100%">
          <div
            class="m-box m-mt-10"
            style="
              border: 1px solid #ccc;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <img
              :src="data.avatar"
              alt=""
              style="border-radius: 50%; width: 150px; height: 150px"
            >
            <div>
              <h3>姓名:{{ data.name }}</h3>
              <p>性别：{{ data.sex === 1 ? "男" : "女" }}</p>
              <p>联系方式：{{ data.phone }}</p>
            </div>
          </div>
          <div
            class="m-box m-mt-10"
            style="border: 1px solid #ccc; border-radius: 6px"
          >
            <p>身高：{{ data.height }}cm</p>
            <p>体重：{{ data.weight }}kg</p>
            <p>出生日期：{{ data.birth }}</p>
            <p>身份证号：{{ data.idcard }}</p>
            <p>住址：</p>
            <p>注册时间：{{ data.createTime }}</p>
          </div>
          <div
            class="m-box m-mt-10"
            style="border: 1px solid #ccc; border-radius: 6px"
          >
            <p style="margin: 0; font-size: 16px; font-weight: 600">家族病史</p>
            <span style="margin-bottom: 5px">{{
              (data.familyHistory ? data.familyHistory.join(",") : "无") || "无"
            }}</span>
            <p style="margin: 0; font-size: 16px; font-weight: 600">既往病史</p>
            <span style="margin-bottom: 5px">{{
              (data.pastMedicalHistory
                ? data.pastMedicalHistory.join(",")
                : "无") || "无"
            }}</span>
            <p style="margin: 0; font-size: 16px; font-weight: 600">过敏史</p>
            <span style="margin-bottom: 5px">{{
              (data.allergyHistory ? data.allergyHistory.join(",") : "无") ||
                "无"
            }}</span>
            <p style="margin: 0; font-size: 16px; font-weight: 600">其他</p>
            <span>{{ data.remark || "无" }}</span>
          </div>
        </a-col>
        <a-col
          :span="16"
          style="
            border: 1px solid #ccc;
            border-radius: 6px;
            margin-top: 10px;
            height: 100%;
          "
        >
          <a-tabs @change="changeTab">
            <a-tab-pane key="血压" tab="血压" />
            <a-tab-pane key="血糖" tab="血糖" />
            <a-tab-pane key="尿酸" tab="尿酸" />
            <a-tab-pane key="总胆固醇" tab="总胆固醇" />
            <a-tab-pane key="血氧" tab="血氧" />
            <a-tab-pane key="心电" tab="心电" />
            <a-tab-pane key="体温" tab="体温" />
            <a-tab-pane key="体脂" tab="体脂" />
            <a-range-picker
              slot="tabBarExtraContent"
              :format="dateFormat"
              style="width: 300px"
              @change="changeTime"
            />
            <!--  :value="[
                moment(beginDate, dateFormat),
                moment(endDate, dateFormat),
              ]" -->
          </a-tabs>

          <a-row type="flex" style="width: 96%; margin: 0 auto">
            <a-col :span="5" class="mr">
              <div
                class="m-box m-mt-10"
                style="
                  border: 1px solid #ccc;
                  border-radius: 6px;
                  height: 200px;
                "
              >
                <h2>共检测：{{ eCharts.totalDetect }}次</h2>
                <p>平均值：{{ eCharts.avgVal }}</p>
                <p>最高值：{{ eCharts.maxVal }}</p>
                <p>最低值：{{ eCharts.minVal }}</p>
              </div>
            </a-col>
            <a-col :span="18">
              <a-row
                class="m-box m-mt-10"
                style="
                  border: 1px solid #ccc;
                  border-radius: 6px;
                  padding: 0;
                  height: 200px;
                  overflow: hidden;
                "
              >
                <a-col :span="9" style="border-right: 2px solid #ccc">
                  <span class="sp">异常比率</span>
                  <div
                    id="myChartspie"
                    ref="myChartspie"
                    style="height: 200px"
                  />
                </a-col>
                <a-col :span="15">
                  <div
                    id="myChartspie1"
                    ref="myChartspie1"
                    style="height: 200px"
                  />
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row
            type="flex"
            style="width: 100%; margin: 0 auto; height: 100%; margin-top: 50px"
          >
            <a-col :span="24">
              <div
                class="m-box m-mt-50"
                style="
                  border: 1px solid #ccc;
                  border-radius: 6px;
                  margin-bottom: 30px;
                  position: relative;
                "
              >
                <span class="sp-all">整体检测趋势</span>
                <div id="myCharts" ref="myCharts" style="height: 350px" />
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
var echarts = require('echarts')
import {
  enduser_query_controller,
  enduserByType_controller
} from '../../static/controller/cost.controller'
import moment from 'moment'
export default {
  filters: {},
  components: {},
  data() {
    return {
      dateFormat: 'YYYY-MM-DD',
      data: {},
      beginDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      name: '血压',
      eCharts: {},
      colorlist: [
        '#57BA1C',
        '#FFE350',
        '#FFAF4C',
        '#FFAF4C',
        '#FF5050',
        '#FFAF4C',
        '#FF5050'
      ],
      colorlist_one: ['#57BA1C', '#FF5050']
    }
  },

  computed: {},

  created() {
    this.getElderList()
    this.$nextTick(() => {
      this.getType()
    })
  },
  mounted() {},
  methods: {
    moment,
    getType() {
      enduserByType_controller({
        id: this.$route.query.id,
        name: this.name,
        beginDate: '',
        endDate: ''
      }).then((res) => {
        this.eCharts = res
        let arr = res.rawDataLineChart.date
        arr.forEach((res) => {
          res = res.substring(0, 16)
          return {
            ...res
          }
        })
        res.rawDataLineChart.date = arr
        console.log(res.rawDataLineChart)
        this.myChartspie(res.abnormalRatioPieChart)
        this.myEcharts(res.rawDataLineChart)
        this.myChartspie1(res.resultClassifyPieChart)
      })
    },
    getElderList() {
      enduser_query_controller({
        id: this.$route.query.id
      }).then((res) => {
        this.data = res
      })
    },
    myChartspie(info) {
      let name = []
      let total = info[0].value + info[1].value
      name[0] = (info[0].value / total) * 100
      name[1] = (info[1].value / total) * 100

      // var myChartspie = echarts.init(document.getElementById('myChartspie'))
      var chartDom = document.getElementById('myChartspie')
      var myChart = echarts.init(chartDom)
      var option
      if (info[0].value === 0 && info[1].value === 0) {
        option = {
          title: {
            text: '暂无数据',
            x: 'center',
            y: 'center',
            textStyle: {
              color: '#65ABE7',
              fontWeight: 'normal',
              fontSize: 16
            }
          }
        }
        option && myChart.setOption(option, true)
      } else {
        option = {
          title: {
            text: '',
            x: 'center',
            y: 'center',
            textStyle: {
              color: '#65ABE7',
              fontWeight: 'normal',
              fontSize: 16
            }
          },
          color: this.colorlist_one,
          series: [
            {
              // name: '异常比率',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '12'
                }
              },
              labelLine: {
                show: false
              },
              data: [
                {
                  value: info[0].value,
                  name: info[0].name + ' ' + name[0].toFixed(0) + '%'
                },
                {
                  value: info[1].value,
                  name: info[1].name + ' ' + name[1].toFixed(0) + '%'
                }
              ]
            }
          ]
        }
        option && myChart.setOption(option, true)
      }
    },
    myChartspie1(info) {
      console.log(info)
      // var myChartspie = echarts.init(document.getElementById('myChartspie'))
      var chartDom = document.getElementById('myChartspie1')
      var myChart = echarts.init(chartDom)
      var option
      if (info.length === 0) {
        option = {
          title: {
            text: '暂无数据',
            x: 'center',
            y: 'center',
            textStyle: {
              color: '#65ABE7',
              fontWeight: 'normal',
              fontSize: 16
            }
          }
        }
        option && myChart.setOption(option, true)
      } else {
        option = {
          tooltip: {
            trigger: 'item'
          },
          title: {
            text: '',
            x: 'center',
            y: 'center',
            textStyle: {
              color: '#65ABE7',
              fontWeight: 'normal',
              fontSize: 16
            }
          },
          color: this.colorlist,
          series: [
            {
              name: '异常比率',
              type: 'pie',
              radius: '50%',
              data: info || 0,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        option && myChart.setOption(option, true)
      }
    },
    myEcharts(info) {
      var chartDom = document.getElementById('myCharts')
      var myChart = echarts.init(chartDom)
      var option
      if (info.date.length === 0) {
        option = {
          title: {
            text: '暂无数据',
            x: 'center',
            y: 'center',
            textStyle: {
              color: '#65ABE7',
              fontWeight: 'normal',
              fontSize: 16
            }
          }
        }
        option && myChart.setOption(option, true)
      } else {
        option = {
          tooltip: {
            trigger: 'axis'
          },
          title: {
            text: '',
            x: 'center',
            y: 'center',
            textStyle: {
              color: '#65ABE7',
              fontWeight: 'normal',
              fontSize: 16
            }
          },
          legend: {
            // data: ['高血压', '较低血压', '视频广告', '直接访问', '搜索引擎']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: info.date
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              // name: '直接访问',
              type: 'line',
              // stack: '总量',
              data: info.series1
            },
            {
              // name: '搜索引擎',
              type: 'line',
              // stack: '总量',
              data: info.series2
            }
          ]
        }
        option && myChart.setOption(option, true)
      }
    },

    changeTab(e) {
      this.name = e
      this.getType()
    },

    changeTime(e) {
      this.beginDate = e[0].format(this.dateFormat)
      this.endDate = e[1].format(this.dateFormat)
      enduserByType_controller({
        id: this.$route.query.id,
        name: this.name,
        beginDate: this.beginDate,
        endDate: this.endDate
      }).then((res) => {
        this.eCharts = res
        this.myChartspie(res.abnormalRatioPieChart)
        this.myEcharts(res.rawDataLineChart)
        this.myChartspie1(res.resultClassifyPieChart)
      })
    }
  }
}
</script>
<style scoped lang="less">
.index-img {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 0;
  right: 0;
}

.box .ant-form-item {
  margin: 10px 0 !important;
}

.ant-row {
  display: flex;
  align-items: center;
}

.m-mt-4 {
  margin-top: 4px;
}

.photo {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.sp {
  position: absolute;
  font-size: 20px;
  left: 10px;
}

.mr {
  margin-right: 4%;
}
.sp-all {
  position: absolute;
  top: 5px;
  font-size: 20px;
  left: 10px;
}
</style>
