<!--
 * @Description: 分页组件
 * @Author:
 * @Date: 2021-02-24 14:42:30
 * @LastEditTime: 2021-04-26 14:15:02
 * @LastEditors:
-->

<template>
  <div class="pagination-container">
    <a-pagination
      v-model="currentPage"
      show-quick-jumper
      :show-total="(total) => `共 ${total} 条`"
      :page-size-options="pageSizes"
      :total="total"
      show-size-changer
      :page-size="pageSize"
      @showSizeChange="handleSizeChange"
      @change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 10
    },
    pageSizes: {
      type: Array,
      default() {
        return ['10', '20', '30', '40']
      }
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)// 修改并响应更新
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(current, size) {
      this.$emit('pagination', { page: current, limit: size })
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
    }
  }
}
</script>

<style scoped>
.pagination-container {
  background: #fff;
  padding: 32px 16px;
}
</style>

