let {
  getSubscriptionTemplateLists_service,
  updateTemplateStatus_service,
  getTemplateMetaDict_service,
  createTemplate_service,
  getTemplateDetails_service,
  getTemplateOptions_service,
  bindTemplate_service,
  getSubscriptionLists_service,
  getSubscriptionDetail_service,
  getSubscriptionDetailLists_service,
  checkTemplateNameDuplicate_service
} = require('../service/Subscription.service')

module.exports = {
  getSubscriptionTemplateLists_controller(val) {
    return getSubscriptionTemplateLists_service(val)
  },
  updateTemplateStatus_controller(val) {
    return updateTemplateStatus_service(val)
  },
  getTemplateMetaDict_controller() {
    return getTemplateMetaDict_service()
  },
  createTemplate_controller(val) {
    return createTemplate_service(val)
  },
  getTemplateDetails_controller(val) {
    return getTemplateDetails_service(val)
  },
  getTemplateOptions_controller(val) {
    return getTemplateOptions_service(val)
  },
  bindTemplate_controller(val) {
    return bindTemplate_service(val)
  },
  getSubscriptionLists_controller(val) {
    return getSubscriptionLists_service(val)
  },
  getSubscriptionDetail_controller(val) {
    return getSubscriptionDetail_service(val)
  },
  getSubscriptionDetailLists_controller(val) {
    return getSubscriptionDetailLists_service(val)
  },
  checkTemplateNameDuplicate_controller(val) {
    return checkTemplateNameDuplicate_service(val)
  }
}
