/*
 * @Description:
 * @Author:
 * @Date: 2021-06-02 14:24:59
 * @LastEditTime: 2021-06-07 11:37:49
 * @LastEditors:
 */

let {
  hc
} = require('../config/api_spec')

module.exports = {
  // 报警设置
  queryForCamera_api: {
    url: `${hc}/mgr/hardware/camera/queryForCamera`,
    method: 'post',
    post: {
      bindObjType: '?',
      code: '?',
      page: '?',
      rows: '?',
      ifCapture: 'true'
    }
  },
  // 报警设置
  accessToken_api: {
    url: `${hc}/mgr/hardware/camera/accessToken`,
    method: 'post',
    post: {
    }
  },
  // 抓拍图
  cameraCapture_api: {
    url: `${hc}/mgr/hardware/camera/capture`,
    method: 'post',
    post: {
      accessToken: '?',
      channelNo: '?',
      deviceSerial: '?'
    }
  },
  // 云控制台
  ptzControl_api: {
    url: `${hc}/mgr/hardware/camera/ptzControl`,
    method: 'post',
    post: {
      accessToken: '?',
      channelNo: '1',
      deviceSerial: '?',
      direction: '?',
      speed: '1'
    }
  },
  // 获取直播地址
  getLiveAddress_api: {
    url: `${hc}/mgr/hardware/camera/getLiveAddress`,
    method: 'post',
    post: {
      accessToken: '?',
      channelNo: '1',
      deviceSerial: '?',
      expireTime: '?',
      gbchannel: '?',
      protocol: '?',
      quality: '?',
      startTime: '?',
      stopTime: '?',
      supportH265: '?',
      type: '?'
    }
  }

}
