<!--
 * @Description:基础信息
 * @Author:
 * @Date: 2021-03-04 15:09:51
 * @LastEditTime: 2021-04-21 14:54:31
 * @LastEditors:
-->
<template>
  <div>
    <p class="formTitle">长者信息</p>
    <a-form :form="form" :label-col="{ span:6 }" :wrapper-col="{ span: 14 }">
      <a-row :gutter="24">
        <a-col :span="8">
          <a-form-item label="姓名">
            <a-input
              v-decorator="['elder.custName',{
                rules:[{required: true, message: '姓名不能为空'}],
                initialValue: baseInfo.custName,
              }]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="性别">
            <a-select
              v-decorator="['elder.custSex',{
                rules:[{required: true, message: '请选择性别'}],
                initialValue: baseInfo.custSex,
              }]"
              placeholder="性别"
              class="ipt"
            >
              <a-select-option value="1">
                男
              </a-select-option>
              <a-select-option value="2">
                女
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="年龄">
            <!-- <a-date-picker
              v-decorator="[
                'elder.custBirthday',
                {
                  rules: [{ required: true, message: '请选择出生年月!' }],
                  initialValue: baseInfo.custBirthday
                },
              ]"
              placeholder="请选择出生年月"
              value-format="YYYY-MM-DD"
              format="YYYY-MM-DD"
            /> -->
            <a-input
              v-decorator="['elder.custAge',{
                rules:[{required:true, message:'请输入长者年龄！'},
                       {
                         required: true, pattern:new RegExp(/^\d{1,}$/), trigger: 'blur', message:'请输入正确的长者年龄！'
                       }
                ],
                initialValue:baseInfo.custAge
              }]"
              placeholder="年龄"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="8">
          <a-form-item label="联系电话">
            <a-input
              v-decorator="['elder.linkTel',{
                rules:[{required: true, message: '请填写联系电话'},
                       {
                         required: true,
                         validator: validatorPhone,
                         trigger: 'blur',
                         message: '请输入正确的联系电话！',
                       }],
                initialValue: baseInfo.linkTel,

              }]"
              class="ipt"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="户籍地区">
            <a-cascader
              v-decorator="['elder.registArea',{
                rules:[{required: true, message: '请选择户籍地区'}],
                initialValue: baseInfo.registArea
              }]"
              :options="regionList"
              expand-trigger="hover"
              placeholder="请选择"
              :field-names="fieldNames"
            />

          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="户籍详细地址">
            <a-input
              v-decorator="['elder.registAddr',{
                rules:[{required: true, message: '请填写户籍详细地址'}],
                initialValue: baseInfo.registAddr,
              }]"
              placeholder="详细地址"
              style="width:300px"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-item label="居住地址">
            <a-input v-decorator="['elder.addr',{rules:[{required: true, message: '请填写居住地址!'}],initialValue: baseInfo.addr}]" placeholder="居住地址" style="width:300px" />
          </a-form-item>
        </a-col>
      </a-row>
      <p class="formTitle">家庭成员信息</p>
      <a-row :gutter="24">
        <a-col :span="8">
          <a-form-item label="称谓">
            <a-input
              v-decorator="['fm.relType',{
                rules:[{required: true, message: '请填写称谓'}],
                initialValue: familyModels.relType,
              }]"
              placeholder="称谓"
              :max-length="maxFou"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="姓名">
            <a-input
              v-decorator="['fm.name',{
                rules:[{required: true, message: '请填写姓名'}],
                initialValue: familyModels.name,
              }]"
              placeholder="姓名"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="性别">
            <a-select
              v-decorator="['fm.sex',{
                rules:[{required: false, message: '请选择性别'}],
                initialValue: familyModels.sex,
              }]"
              placeholder="性别"
            >
              <a-select-option value="1">
                男
              </a-select-option>
              <a-select-option value="2">
                女
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

      </a-row>
      <a-row :gutter="24">
        <a-col :span="8">
          <a-form-item label="联系电话">
            <a-input
              v-decorator="['fm.mobile',{
                rules: [
                  { required: true, message: '请输入联系电话！' },
                  {
                    required: true,
                    validator: validatorPhone,
                    trigger: 'blur',
                    message: '请输入正确的联系电话！',
                  }
                ],
                initialValue: familyModels.mobile,
              }]"
              placeholder="联系电话"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="身份证">
            <a-input
              v-decorator="['fm.idNo',{
                rules:[{required: true, message: '请填写身份证号'}, {
                  required: true,
                  validator: validatorCode,
                  trigger: 'blur'
                }],
                initialValue: familyModels.idNo,
              }]"
              placeholder="身份证"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="居住地址">
            <a-input
              v-decorator="['fm.homeAddr',{
                rules:[{required: true, message: '请填写居住地址'}],
                initialValue: familyModels.homeAddr,
              }]"
              placeholder="居住地址"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-divider />
      <a-row :gutter="24">
        <a-col :span="8">
          <a-form-item label="目前服用药物">
            <a-textarea v-decorator="['elder.remark',{ initialValue: baseInfo.remark}]" placeholder="输入备注" :rows="4" style="width:400px" />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="申请入院理由">
            <a-textarea v-decorator="['elder.consultContent',{ initialValue: baseInfo.consultContent}]" placeholder="输入备注" :rows="4" style="width:400px" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-divider orientation="center">
      <a-button type="primary" @click="nextStep">下一步</a-button>
    </a-divider>
  </div>
</template>

<script>
import {
  getRegionInfo_controller
} from '../../static/controller/RegionInfo.controller'
import {
  detailCusBookingMg_controller
} from '../../static/controller/CustomerBookingMg.controller'
import { validateReg } from '../../static/utils/regular'
export default {
  data() {
    return {
      regionList: [],
      baseInfo: {},
      familyModels: {},
      form: this.$form.createForm(this, { name: 'base' }),
      maxFou: 4,
      fieldNames: {
        value: 'code',
        label: 'name',
        children: 'children'
      }
    }
  },
  mounted() {
    this.getRegion()
    if (this.$route.params.id) {
      this.initData()
    }
  },
  methods: {
    // 初始化基本信息
    initData() {
      detailCusBookingMg_controller({ id: this.$route.params.id })
        .then((res) => {
          console.log(res)
          let registArea = res.registArea ? res.registArea.split(',') : []
          res.registArea = registArea
          this.baseInfo = res
          this.familyModels = res.customerFamilyModels[0]
        })
    },
    nextStep() {
      this.form.validateFields((err, values) => {
        console.log(values)
        if (!err) {
          console.log('Received values of form: ', values)
          this.$emit('nextChange', values, 2)
        }
      })
    },
    onChange(value) {
      this.getRegion()
    },
    getRegion() {
      getRegionInfo_controller({ level: 3 }).then((res) => {
        console.log('获取：：', res)
        this.regionList = res
      })
    },
    validatorPhone(rule, value, callback) {
      console.log(value)
      let val = validateReg('phone', value)
      if (!val.validate) {
        callback(new Error('手机号格式不正确!'))
      } else {
        callback()
      }
    },
    validatorCode(rule, value, callback) {
      console.log(value)
      let val = validateReg('idCrad', value)
      if (!val.validate) {
        callback(new Error('身份证不正确!'))
      } else {
        callback()
      }
    }

  }
}
</script>

<style>

</style>
