var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-scroll"},[_c('div',{staticClass:"m-box col m-mt-10 box"},[_c('a-form',{staticClass:"fff",attrs:{"form":_vm.form,"label-col":{ span: 0 },"wrapper-col":{ span: 22 }},on:{"submit":_vm.handleSearch}},[_c('a-row',{attrs:{"type":"flex"}},[_c('a-col',{attrs:{"span":3}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['distribute']),expression:"['distribute']"}],attrs:{"placeholder":"分配状态","allow-clear":""}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v(" 已分配 ")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" 未分配 ")])],1)],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'organCode',
                {
                  initialValue: [],
                  rules: [
                    {
                      type: 'array',
                      required: false,
                      message: '选择机构',
                    },
                  ],
                },
              ]),expression:"[\n                'organCode',\n                {\n                  initialValue: [],\n                  rules: [\n                    {\n                      type: 'array',\n                      required: false,\n                      message: '选择机构',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"所有机构","change-on-select":"","options":_vm.companyData,"field-names":{
                label: 'name',
                value: 'companyNo',
                children: 'children',
              }}})],1)],1),_c('a-col',{staticClass:"ant-form-item",attrs:{"span":1}},[_c('a-button',{staticClass:"m-mt-4",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("查询")])],1),_c('a-col',{staticClass:"ant-form-item text-right",attrs:{"span":16}},[_c('a-button',{staticClass:"m-mt-4 m-ml-10",attrs:{"type":"primary"},on:{"click":function($event){return _vm.exportExcel()}}},[_vm._v("导出注册码")]),_c('a-button',{staticClass:"m-mt-4 m-ml-10",attrs:{"type":"primary"},on:{"click":function($event){return _vm.generateParams()}}},[_vm._v("生成注册码")])],1)],1),_c('a-row',[_c('a-col',{staticClass:"ant-form-item",attrs:{"span":3}},[_c('a-popconfirm',{attrs:{"title":"作废后将不能重新启用，确定作废已选中的注册码吗?","ok-text":"确认","cancel-text":"取消"},on:{"confirm":function($event){return _vm.makeRegistrationCodeInvalid()}}},[_c('a-button',{staticClass:"m-mt-4",attrs:{"type":"danger","danger":""}},[_vm._v("作废")])],1)],1)],1)],1)],1),_c('div',{staticClass:"m-box m-mt-10"},[_c('a-table',{attrs:{"row-selection":_vm.rowSelection,"columns":_vm.columns,"data-source":_vm.data,"pagination":false,"scroll":{ x: 1200 },"row-key":"registrationCode"}}),_c('pagination',{staticClass:"paginationStyle",attrs:{"page":_vm.pageIndex,"limit":_vm.pageSize,"total":_vm.total},on:{"update:page":function($event){_vm.pageIndex=$event},"update:limit":function($event){_vm.pageSize=$event},"pagination":_vm.getPages}})],1),_c('GenerateParams',{ref:"generateParams",on:{"refresh":_vm.getList}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }