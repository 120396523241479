<!--
 * @Author: your name
 * @Date: 2021-03-06 15:43:09
 * @LastEditTime: 2021-04-16 11:38:09
 * @LastEditors:
 * @Description: 地区选择
 * @FilePath: \ioac-tenant-html\src\components\RegionSelect.vue
-->
<template>
  <a-cascader
    :options="options"
    :show-search="{ filter }"
    placeholder="请选择地区名称/选择地区"
    :default-value="initValue ? initValue : []"
    @change="selectArea"
  />
</template>

<script>
import { getRegionInfo_controller } from '../static/controller/OrganMgr.controller'

function getRegionList(res) {
  return res.map((info) => {
    if (info.children && info.children.length > 0) {
      return {
        value: info.code,
        label: info.fullName,
        children: getRegionList(info.children)
      }
    } else {
      return { value: info.code, label: info.fullName }
    }
  })
}

let list = []
function initData(res, id) {
  res.forEach((info) => {
    if (id.includes(info.code)) {
      list.push({
        value: info.code,
        label: info.fullName
      })
    }
    if (info.children && info.children.length > 0) {
      initData(info.children, id)
    }
  })
}

export default {
  props: {
    area: {
      default: () => {
        return null
      },
      type: Object
    },
    initValue: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  data() {
    return {
      options: []
    }
  },
  mounted() {
    getRegionInfo_controller().then((res) => {
      this.options = getRegionList(res)
      // 初始化省市区
      if (this.initValue.length > 0) {
        list = []
        initData(res, this.initValue[0])
        let params = {
          value: this.initValue[0],
          data: list,
          label: list.map((res) => {
            return res.label
          })
        }
        console.log('初始化：：', list)
        this.$emit('update:area', { ...params })
      }
    })
  },
  methods: {
    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      )
    },
    selectArea(value, selectedOptions) {
      if (selectedOptions.length > 1) {
        let params = {
          value: value,
          data: selectedOptions,
          label: selectedOptions.map((res) => {
            return res.label
          })
        }
        this.$emit('update:area', params)
      } else {
        this.$emit('update:area', {
          value: [
            selectedOptions[0].value,
            selectedOptions[0].value,
            selectedOptions[0].value
          ],
          data: selectedOptions,
          label: [
            selectedOptions[0].label,
            selectedOptions[0].label,
            selectedOptions[0].label
          ]
        })
      }
    }
  }
}
</script>
