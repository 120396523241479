/*
 * @Description:
 * @Author:
 * @Date: 2021-03-04 17:21:04
 * @LastEditTime: 2021-03-20 09:55:05
 * @LastEditors:
 */
module.exports = {
  getBizTypCodeApp_api: {
    url: 'basic-center/app/base/bizCode/queryCode',
    post: {
      typeCodes: '?'
    }
  }
}
