/*
 * @Author: your name
 * @Date: 2021-03-03 10:52:46
 * @LastEditTime: 2021-06-28 15:53:09
 * @LastEditors: Please set LastEditors
 * @Description: 用户管理
 * @FilePath: \ioac-tenant-html\src\static\controller\UserRole.controller.js
 */

const {
  userQuery_service,
  userallQuery_service,
  changeState_service,
  userSave_service,
  getUserInfo_service,
  updateUser_service
} = require('../service/UserMgr.service')

const {
  rolelist_service,
  roleSave_service,
  roleSaveByApp_service,
  roleDelete_service,
  getRole_service,
  updateByApp_service,
  roleUpdate_service
} = require('../service/RoleMgr.service')

const {
  permissionQueryTree_service
} = require('../service/PermissionMgr.service')

const {
  getTenantApplications_service
} = require('../service/TenantApp.service')

module.exports = {
  userQuery_controller(val) {
    return userQuery_service(val)
  },
  userallQuery_controller(val) {
    return userallQuery_service(val)
  },
  changeState_controller(val) {
    return changeState_service(val)
  },
  rolelist_controller() {
    return rolelist_service()
  },
  roleSave_controller(val) {
    return roleSave_service(val)
  },
  roleUpdate_controller(val) {
    return roleUpdate_service(val)
  },
  permissionQueryTree_controller() {
    return permissionQueryTree_service()
  },
  // 获取当前租户模块(应用)列表
  getTenantApplications_controller() {
    return getTenantApplications_service()
  },
  // 根据模块新增角色
  roleSaveByApp_controller(val) {
    return roleSaveByApp_service(val)
  },
  // 删除角色
  roleDelete_controller(val) {
    return roleDelete_service(val)
  },
  // 新增角色
  userSave_controller(val) {
    return userSave_service(val)
  },
  // 返回用户信息
  getUserInfo_controller(val) {
    return getUserInfo_service(val)
  },
  // 返回用户角色
  getRole_controller(val) {
    return getRole_service(val)
  },
  // 修改角色
  updateByApp_controller(val) {
    return updateByApp_service(val)
  },
  // 修改用户
  updateUser_controller(val) {
    return updateUser_service(val)
  }
}
