/*
 * @Description:
 * @Author:
 * @Date: 2021-02-23 17:30:24
 * @LastEditTime: 2021-05-25 17:39:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-09 14:22:56
 * @LastEditors: Please set LastEditors
 */
const {
  POST_URL
} = require('../config')
const axios = require('axios')
const store = require('../../store').default
const ant = require('ant-design-vue')
const router = require('../../router')

const {
  makeUrl
} = require('./makeurl')

const {
  getStorageValue
} = require('../utils/localstorage')

/**
 * 网络请求
 * @param {String} url请求地址
 * @param {Boolean} loading是否需要loading，默认false
 * 加异常处理
 */
const xhr = (url, val) => {
  const mixedRequest = makeUrl(url, val)
  const _baseUrl = `${POST_URL}${mixedRequest.url}` // 拼接请求地址

  const _storage_token = getStorageValue('token')

  const _token = _storage_token || `` // 获取到的token
  console.log(mixedRequest.loading)
  if (mixedRequest.loading === false) {
    store.commit('loadState', true)
  } else {
    store.commit('loadState', false)
  }
  return new Promise((resolve, reject) => {
    const headers = {
      token: _token
    }
    if (mixedRequest.isFileUpload) { // 表示文件上传
      headers['Content-Type'] = 'multipart/form-data'
      mixedRequest.postData = mixedRequest.postData.fileF
    }

    let param = {
      method: mixedRequest.method || 'post',
      url: _baseUrl,
      headers: headers
    }

    if (param.method.toLowerCase() === 'post') {
      param.data = mixedRequest.postData
    } else {
      param.params = mixedRequest.postData
    }

    axios({
      ...param
    }).then((res) => {
      store.commit('loadState', false)
      if (res.data.code === '0') {
        resolve(res.data.data)
      } else if (res.data.code === '10003') {
        resolve(res.data.code)
      } else {
        ant.message.error(res.data.msg)
        switch (parseInt(res.data.code)) {
          case -3:
            router.default.push({
              name: 'Login'
            })
            break

          default:
            break
        }
        reject(res.data.data)
      }
    }).catch(function(res) { // 请求异常处理
      store.commit('loadState', false)
      console.error('错误信息:', res, '接口路径：', _baseUrl, '请求参数：', mixedRequest.postData, res
        .response.status)
    })
  })
}

const refreshxhr = (url, val) => {
  const mixedRequest = makeUrl(url, val)
  const _baseUrl = `${POST_URL}${mixedRequest.url}` // 拼接请求地址

  const _storage_token = getStorageValue('token')

  const _token = _storage_token || `` // 获取到的token
  store.commit('loadState', false) // 刷新不显示loading
  return new Promise((resolve, reject) => {
    const headers = {
      token: _token
    }
    if (mixedRequest.isFileUpload) { // 表示文件上传
      headers['Content-Type'] = 'multipart/form-data'
      mixedRequest.postData = mixedRequest.postData.fileF
    }

    let param = {
      method: mixedRequest.method || 'post',
      url: _baseUrl,
      headers: headers
    }

    if (param.method === 'post') {
      param.data = mixedRequest.postData
    } else {
      param.params = mixedRequest.postData
    }

    axios({
      ...param
    }).then((res) => {
      store.commit('loadState', false)
      if (res.data.code === '0') {
        resolve(res.data.data)
      } else if (res.data.code === '10003') {
        resolve(res.data.code)
      } else {
        ant.message.error(res.data.msg)
        switch (parseInt(res.data.code)) {
          case -3:
            router.default.push({
              name: 'Login'
            })
            break

          default:
            break
        }
        reject(res.data.data)
      }
    }).catch(function(res) { // 请求异常处理
      store.commit('loadState', false)
      console.error('错误信息:', res, '接口路径：', _baseUrl, '请求参数：', mixedRequest.postData, res
        .response.status)
    })
  })
}

/**
 * 自定义传值
 * @param {*} url 请求地址
 * @param {*} val 传递参数
 */
const xhr_custom = (url, val) => {
  const _baseUrl = `${url}` // 拼接请求地址

  const _storage_token = getStorageValue('token')

  const _token = _storage_token || `` // 获取到的token
  store.commit('loadState', true)
  return new Promise((resolve, reject) => {
    let param = {
      method: val.method || 'post',
      url: _baseUrl
    }
    if (val.upload) { // 表示文件上传
      param.headers = {
        'Content-Type': 'multipart/form-data'
      }
    }

    if (_token) {
      param.headers = {
        token: _token
      }
    }

    if (param.method === 'post') {
      param.data = val.data
    } else {
      param.params = val.data
    }
    axios({
      ...param
    }).then((res) => {
      store.commit('loadState', false)
      if (res.data.code === '0') {
        resolve(res.data.data)
      } else {
        if (res.status === 200) {
          resolve(res.data)
        } else {
          ant.message.error(res.data.msg)
          reject(res.data.data)
        }
      }
    }).catch(function(res) { // 请求异常处理
      store.commit('loadState', false)
      console.error('错误信息:', res, '接口路径：', _baseUrl, '请求参数：', val.data, res.response.status)
    })
  })
}

module.exports = {
  xhr,
  xhr_custom,
  refreshxhr
}
