/*
 * @Author: your name
 * @Date: 2021-06-02 13:07:26
 * @LastEditTime: 2021-06-10 14:02:02
 * @LastEditors: Please set LastEditors
 * @Description: 注册码
 */
let {
  uc
} = require('@/static/config/api_spec')

module.exports = {
  registrationCodeList_api: {
    url: `${uc}/mgr/equip/registration/code/queryList`,
    post: {
      distribute: '',
      organCode: '',
      page: 0,
      rows: 0,
      number: 0,
      size: 0
    }
  },
  generateRegistrationCode_api: {
    url: `${uc}/mgr/equip/registration/code/create`,
    post: {
      count: 0
    }
  },
  makeRegistrationCodeInvalid_api: {
    url: `${uc}/mgr/equip/registration/code/makeInvalid`,
    post: {
      registrationCodeList: []
    }
  },
  distributeRegistrationCode_api: {
    url: `${uc}/mgr/equip/registration/code/distribute`,
    post: {
      organCode: '',
      count: 0
    }
  },
  export_api: {
    url: `${uc}/mgr/equip/registration/code/export`,
    post: {
      organCode: '?',
      distribute: '?'
    }
  }
}
