<!--
 * @Author: your name
 * @Date: 2021-03-03 09:48:34
 * @LastEditTime: 2021-06-21 16:42:17
 * @LastEditors: Please set LastEditors
 * @Description: 角色管理
 * @FilePath: \ioac-tenant-html\src\components\sysset\rolemgr.vue
-->
<template>
  <div id="components-form-demo-advanced-search">
    <a-row :style="{ marginBottom: '12px' }">
      <a-col :span="12">
        <a-alert
          message="温馨提示：编辑角色，拥有该角色包含权限的账号会同步更改，请谨慎编辑！"
          banner
          closable
        />
      </a-col>
    </a-row>
    <a-form class="ant-advanced-search-form">
      <a-row :gutter="24">
        <a-row type="flex" justify="space-between">
          <a-col>
            <router-link :to="{ name: 'addrole' }">
              <a-button
                type="primary"
                html-type="submit"
                icon="contacts"
              >新建角色
              </a-button>
            </router-link>
          </a-col>
        </a-row>
      </a-row></a-form>
    <div class="search-result-list">
      <a-table :columns="columns" :data-source="data" bordered>
        <a slot="index" slot-scope="text, record, index">{{
          (pagination.current - 1) * 20 + index + 1
        }}</a>
        <span slot="realName" slot-scope="text">
          <a-tag color="#2db7f5">{{ text }}</a-tag>
        </span>
        <span slot="mobile" slot-scope="text">
          {{ text ? text : "---" }}
        </span>
        <span slot="userState" slot-scope="text">
          <a-tag :color="text == 1 ? 'cyan' : 'red'">{{
            text == 1 ? "正常" : text == 2 ? "停用" : "删除"
          }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <!-- <a-button type="link"> 查看 </a-button> -->
          <!-- <a-divider type="vertical" /> -->
          <router-link :to="{ name: 'addrole', params: { id: record.code } }">
            <a-button type="link"> 编辑 </a-button>
          </router-link>
          <a-divider type="vertical" />
          <a-popconfirm
            title="是否确认删除该账号?"
            ok-text="确认"
            cancel-text="取消"
            @confirm="confirm(record)"
          >
            <a-button type="link"> 删除 </a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
import {
  rolelist_controller,
  roleDelete_controller
} from '../../static/controller/UserRole.controller'

const columns = [
  {
    title: '角色编号',
    dataIndex: 'code',
    key: 'code',
    width: 120
  },
  {
    title: '角色名称',
    dataIndex: 'name',
    key: 'name',
    width: 220
  },
  {
    title: '描述',
    dataIndex: 'remark',
    key: 'remark'
  },
  {
    title: '操作',
    key: 'action',
    width: 260,
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  data() {
    return {
      expand: false,
      pagination: {}, // tab分页
      data: [],
      columns
    }
  },
  mounted() {
    this.initRolelist()
  },
  methods: {
    initRolelist() {
      rolelist_controller().then((res) => {
        this.data = res
        this.pagination = {
          total: res.length,
          current: 1
        }
      })
    },
    confirm(e) {
      roleDelete_controller({
        code: e.code
      }).then((res) => {
        this.$message.success('删除成功！')
        this.initRolelist()
      })
    }
  }
}
</script>
<style scoped>
</style>
