<template>
  <div class="view-scroll">
    <div class="m-box col m-mt-10 box">
      <a-form
        :form="form"
        :label-col="{ span: 0 }"
        class="fff"
        :wrapper-col="{ span: 22 }"
        @submit="handleSearch"
      >
        <a-row type="flex">
          <a-col :span="4">
            <a-form-item label="">
              <a-input
                v-decorator="['mobile']"
                placeholder="请输入查询姓名或手机号"
              />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-select v-decorator="['OneType']" placeholder="标记状态" :allow-clear="true">
                <a-select-option value="1"> 有效 </a-select-option>
                <a-select-option value="0"> 无效 </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="3" class="ant-form-item">
            <a-button type="primary" class="m-mt-4" html-type="submit">
              查询
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="m-box m-mt-10">
      <a-table :columns="columns" :data-source="data" :pagination="false">
        <div slot="photo" slot-scope="record" class="text-3d9">
          <img :src="record" class="photo">
        </div>

        <div slot="action" slot-scope="record" class="text-3d9">
          <a-button
            type="primary"
            :style="
              record.state === 1
                ? 'background: #1890ff; border-color: #1890ff'
                : 'background: #999; border-color: #999'
            "
            @click="setItem(record,1)"
          >有效</a-button>
          <a-button
            type="primary"
            class="m-ml-10"
            :style="
              record.state === 0
                ? 'background: #1890ff; border-color: #1890ff'
                : 'background: #999; border-color: #999'
            "
            @click="setItem(record,0)"
          >无效</a-button>
        </div>
      </a-table>

      <pagination
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        :total="total"
        class="paginationStyle"
        @pagination="getPages"
      />
    </div>
  </div>
</template>

<script>
import {
  querylist_controller,
  updateState_controller
} from '../../static/controller/cost.controller'
const columns = [
  {
    title: '姓名',
    dataIndex: 'name'
  },
  {
    title: '头像',
    dataIndex: 'avatar',
    scopedSlots: {
      customRender: 'photo'
    }
  },
  {
    title: '联系方式',
    dataIndex: 'phone'
  },
  {
    title: '问题内容',
    dataIndex: 'issueContent'
  },
  {
    title: '标记状态',
    // dataIndex: 'state',
    scopedSlots: {
      customRender: 'action'
    }
  },
  {
    title: '反馈时间',
    dataIndex: 'feebackTime'
  }
]

const data = []
export default {
  filters: {},
  components: {
    pagination: () => import('../../components/Pagination')
  },
  data() {
    return {
      data,
      columns,
      selectedRowKeys: [],
      form: this.$form.createForm(this, {
        name: 'evalutaion'
      }),
      addForm: this.$form.createForm(this, {
        name: 'customer_list'
      }),
      visible: false,
      pageIndex: 1, // 当前页码
      pageSize: 10,
      pagination: {}, // tab分页
      total: 0,
      where: {},
      addInfo: {},
      ids: []
    }
  },

  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  created() {
    this.getElderList()
  },

  mounted() {},
  methods: {
    getElderList() {
      querylist_controller({
        name: this.where.mobile,
        state: this.where.OneType,
        page: this.pageIndex,
        rows: this.pageSize
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    },

    start() {
      this.loading = true
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },

    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      let ids = []
      for (let x of selectedRowKeys) {
        for (let i in this.data) {
          console.log(x, i)
          if (x === +i) {
            ids.push(this.data[i].id)
          }
        }
      }
      console.log(ids)
      this.ids = ids
      this.selectedRowKeys = selectedRowKeys
    },

    handleSearch(e) {
      e.preventDefault()
      this.form.validateFields((error, values) => {
        console.log(error, values)
        this.pageIndex = 1
        this.where = values
        this.getElderList()
      })
    },

    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.getElderList()
    },

    setItem(e, state) {
      if (e.state === state) {
        return
      }
      console.log(e, state)
      this.addInfo = e
      this.visible = true
      updateState_controller({
        // ...this.where,
        id: e.id,
        page: this.pageIndex,
        rows: this.pageSize,
        state: state
      }).then(res => {
        this.getElderList()
      })
    },

    handleCancel(e) {
      this.visible = false
    },

    updSuccess() {
      console.log('chenggong')
      this.getElderList()
    }
  }
}
</script>
<style scoped lang="less">
.index-img {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 0;
  right: 0;
}

.box .ant-form-item {
  margin: 10px 0 !important;
}

.ant-row {
  display: flex;
  align-items: center;
}

.m-mt-4 {
  margin-top: 4px;
}

.photo {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
</style>
