/**
 * 设置localStorage
 * @param {String} key 键
 * @param {Object} value 值
 */
let setStorage = (key, value) => {
  if (!key) new Error('缺少 key')
  else if (!value) new Error('缺少 value')
  else localStorage.setItem(key, JSON.stringify(value))
}

/**
 * 获取localStorage值对象
 * @param {String} key 键
 */
let getStorageInfo = key => {
  if (!key) new Error('缺少 key')
  else return JSON.parse(localStorage.getItem(key))
}

/**
 * 获取localStorage值对象
 * @param {String} key 键
 */
let getStorageValue = key => {
  if (!key) new Error('缺少 key')
  else return localStorage.getItem(key)
}

let setStorageValue = (key, value) => {
  if (!key) new Error('缺少 key')
  else return localStorage.setItem(key, value)
}

/**
 * 删除localStorage
 * @param {String} key
 */
let removeStorage = key => {
  if (!key) new Error('缺少 key')
  else localStorage.removeItem(key)
}

/**
 * 清除localStorage
 */
let clearStorage = () => {
  localStorage.clear()
}

module.exports = {
  setStorage,
  getStorageInfo,
  getStorageValue,
  setStorageValue,
  removeStorage,
  clearStorage
}
