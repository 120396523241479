/*
 * @Description:
 * @Author:
 * @Date: 2021-03-20 09:55:30
 * @LastEditTime: 2021-03-20 12:59:07
 * @LastEditors:
 */
let {
  bc
} = require('../config/api_spec')

module.exports = {

  addbizCode_api: {
    url: `${bc}/mgr/base/bizCode/save`,
    post: {
      typeCode: '?',
      vname: '?',
      vval: '?'
    }
  },
  updatebizCode_api: {
    url: `${bc}/mgr/base/bizCode/update`,
    post: {
      id: '?',
      typeCode: '?',
      vname: '?',
      vval: '?'
    }
  },
  querybizCode_api: {
    url: `${bc}/mgr/base/bizCode/query`,
    post: {
      page: '?',
      rows: '?',
      vname: '?',
      typeCodes: '?'
    }
  },
  removebizCode_api: {
    url: `${bc}/mgr/base/bizCode/delete`,
    get: {
      id: '?'
    }
  }

}
