/*
 * @Description:
 * @Author:
 * @Date: 2021-08-17 14:01:09
 * @LastEditTime: 2022-01-18 16:25:46
 * @LastEditors:
 */

const regular = require('../config/regular')

module.exports = {
  /**
   * 正则规则验证
   * @param {string} reg  rule属性值
   * @param {string} val  需要验证的值
   */
  validateReg(reg, val) {
    // 当存在该正则规则
    if (Object.prototype.hasOwnProperty.call(regular, reg)) {
      if (regular[reg].test.test(val)) {
        return {
          validate: true,
          msg: '验证通过'
        }
      } else {
        return {
          validate: false,
          msg: regular[reg].msg
        }
      }
    } else {
      return {
        validate: false,
        msg: '不支持验证类型！'
      }
    }
  }
}
