<template>
  <a-layout>
    <a-affix :offset-top="0">
      <a-layout-header class="header" style="padding: 0 20px">
        <HomeHeader :collapsed.sync="collapsed" @setting="setting" />
      </a-layout-header>
    </a-affix>
    <a-layout>
      <a-layout-sider
        v-model="collapsed"
        width="200"
        :style="{ background: sysConfig.style === 2 ? '' : '#fff' }"
      >
        <LeftSider :collapsed.sync="collapsed" :sys-style.sync="sysConfig" />
      </a-layout-sider>
      <a-layout style="padding: 0 24px 24px">
        <a-breadcrumb style="margin: 16px 0 0 0; font-size: 16px">
          <a-breadcrumb-item>首页</a-breadcrumb-item>
          <a-breadcrumb-item
            v-for="(item, index) in breadList"
            :key="index"
            style="color: #1890ff"
          >{{ item.meta.title }}</a-breadcrumb-item>
        </a-breadcrumb>
        <a-layout-content>
          <a-spin :spinning="$store.state.loadding">
            <!-- padding: '24px', -->
            <div
              :style="{
                marginBottom: '10px',
                minHeight: '700px',
              }"
            >
              <router-view />
            </div>
          </a-spin>
          <div style="height: 20px" />
        </a-layout-content>
        <a-layout-footer
          style="text-align: center"
        >Copyright © 2021-2022. 亿慈（上海）智能科技有限公司, Inc.All Rights
          Reserved. 沪ICP备2022002877号-1</a-layout-footer>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
export default {
  name: 'Home',
  components: {
    HomeHeader: () => import('../components/home/header'),
    LeftSider: () => import('../components/home/leftsider')
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入之前
    next((vm) => {
      vm.getBreadList(to)
    })
  },
  data() {
    return {
      collapsed: false,
      breadList: [],
      sysConfig: {
        // 整体风格
        style: 1,
        // 导航模式
        mode: 1,
        // 固定header
        fixedHeader: true,
        // 固定侧边栏
        fixedSidebar: true
      } // 系统设置
    }
  },
  watch: {
    // 监听路由
    $route(val) {
      // 调用获取路由数组方法
      console.log(val)
      this.getBreadList(val)
    }
  },
  methods: {
    getBreadList(val) {
      if (val.matched) {
        let matched = val.matched.filter(
          (item) => item.meta && item.meta.title
        )
        this.breadList = matched
        console.log()
      }
    },
    setting(e) {
      console.log('设置新值：', e)
      this.sysConfig = e
    }
  }
}
</script>

<style lang="less">
.header {
  background-color: #fff;
  box-shadow: 5px 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 2;
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.user_info {
  display: flex;
  flex-direction: column;
  line-height: 20px;
  justify-content: center;
  height: 100%;
  text-align: right;
  margin-right: 10px;

  .name {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.65);
  }

  .tip {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.ant-layout-sider-trigger {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  color: #002140;
}
</style>
