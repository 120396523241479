<!--
 * @Description:
 * @Author:
 * @Date: 2021-02-23 17:30:24
 * @LastEditTime: 2021-06-29 10:44:27
 * @LastEditors: Please set LastEditors
-->
<template>
  <div>
    <AnimateBG />
    <div class="login_box">
      <img src="../../assets/login_logo.png" alt="" srcset="" class="logo">
      <a-form layout="vertical" :form="formData" :style="{ width: '100%' }">
        <a-form-item name="name">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: '请输入用户名!' }],
              },
            ]"
            size="large"
            placeholder="请输入用户名"
          >
            <a-icon slot="prefix" type="user" />
          </a-input>
        </a-form-item>
        <a-form-item name="password">
          <a-input
            v-decorator="[
              'password',
              {
                rules: [{ required: true, message: '请输入密码!' }],
              },
            ]"
            size="large"
            type="password"
            placeholder="请输入密码"
          >
            <a-icon slot="prefix" type="lock" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-popover title="登录验证" trigger="click">
            <template v-if="isVerification" slot="content">
              <a-spin :spinning="spinning" :delay="500">
                <a-row type="flex" justify="center" style="position: relative">
                  <div class="imgCaptchaBox" @click="verificationCaptcha">
                    <img :src="imgCaptcha" alt="" srcset="">
                    <div
                      v-for="(item, index) in wordList"
                      :key="index"
                      class="captchaItem"
                      :style="{ top: item.y + 'px', left: item.x + 'px' }"
                    >
                      {{ index + 1 }}
                    </div>
                  </div>
                  <a-icon
                    type="redo"
                    class="refreshCaptcha"
                    @click="refreshCaptcha"
                  />
                </a-row>
                <a-row type="flex" justify="center" style="margin-top: 10px">
                  <a-button type="dashed">
                    请按顺序点击【{{ word }}】
                  </a-button>
                </a-row>
              </a-spin>
            </template>
            <template v-else slot="content">
              <a-alert
                type="error"
                message="请输入有效信息，进行验证！"
                banner
              />
            </template>
            <a-button
              size="large"
              type="primary"
              block
              html-type="submit"
              @click="login"
            >登录</a-button>
          </a-popover>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import AnimateBG from '../../components/AnimateBg'
import {
  getCaptcha_controller,
  checkCaptcha_controller,
  loginByName2Password2CodeNoComNo_controller
} from '../../static/controller/Login.controller'

import { getMenu_controller } from '../../static/controller/Home.controller'
import { setRouter } from '../../static/utils/auth/router'

const {
  setStorageValue,
  clearStorage
} = require('../../static/utils/localstorage')

export default {
  components: {
    AnimateBG
  },
  data() {
    return {
      formData: this.$form.createForm(this, {
        name: 'coordinated'
      }),
      imgCaptcha: '', // base64 图片验证码
      word: '', // 需要点击的词汇
      spinning: false, // 加载遮罩
      wordList: [], // 验证码坐标
      secretKey: '',
      isVerification: false
    }
  },
  mounted() {
    if (this.$route.query.url) {
      let tips = ''
      if (this.$route.query.url === 'jj') {
        tips = '居家养老服务平台'
      }
      if (this.$route.query.url === 'jg') {
        tips = '机构养老服务平台'
      }
      if (this.$route.query.url === 'mz') {
        tips = '民政养老监管系统'
      }
      if (this.$route.query.url === 'sq') {
        tips = '社区养老管理平台'
      }
      if (this.$route.query.url === 'gf') {
        tips = '官方运营管理平台'
      }
      setStorageValue('tips', tips)
      console.log(tips)
    }
  },
  methods: {
    login() {
      // 先进行前端验证
      this.formData.validateFields((err, values) => {
        if (!err) {
          clearStorage()
          // 加载验证码
          this.isVerification = true
          this.spinning = true
          // this.initCaptcha()
          // 验证通过
          loginByName2Password2CodeNoComNo_controller({
            captchaVerification: '123456', // 图形验证码KEY
            password: values.password, // 密码
            userName: values.name // 用户名
          }).then(async(res) => {
            // setTimeout(() => {

            let thatMenu = await getMenu_controller()
            // 把当前菜单权限加入路由
            setRouter(this.$router, thatMenu)
            sessionStorage.setItem('roles', JSON.stringify(res.roles))
            this.$router.push({
              name: 'Home'
            })
            // }, 1000)
          })
        } else {
          this.isVerification = false
        }
      })
    },
    // 刷新验证码
    refreshCaptcha() {
      this.spinning = true
      this.initCaptcha()
    },
    // 点击验证码
    verificationCaptcha(e) {
      if (this.word.split('、').length === this.wordList.length + 1) {
        // 开始验证
        this.spinning = true
        checkCaptcha_controller({
          originalImageBase64: this.imgCaptcha,
          secretKey: this.secretKey,
          pointList: this.wordList
        }).then((res) => {
          this.spinning = false
          console.log('是否验证通过：：', res)
          // 如果验证通过
          if (res.verification) {
            // 进行登录
          } else {
            // 刷新验证码
            this.initCaptcha()
          }
        })
      }
      if (this.word.split('、').length > this.wordList.length) {
        // 加入验证
        this.wordList.push({
          x: e.offsetX - 10,
          y: e.offsetY - 5
        })
      }
    },
    // 初始化验证码
    initCaptcha() {
      getCaptcha_controller().then((res) => {
        this.imgCaptcha = res.originalImageBase64
        this.word = res.word
        this.spinning = false
        this.secretKey = res.secretKey
        this.wordList = []
      })
    }
  }
}
</script>
<style lang="less" scoped>
.login_box {
  position: relative;
  z-index: 2;
  width: 270px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .ant-form-item {
    margin-bottom: 10px;
  }
}

.logo {
  margin-bottom: 30px;
  height: 50px;
  width: auto;
}

.refreshCaptcha {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  color: #f8f8f8;
}

.imgCaptchaBox {
  position: relative;
  cursor: pointer;

  .captchaItem {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    text-align: center;
    line-height: 20px;
    background-color: #1abd6c;
    color: #ffffff;
    cursor: pointer;
  }
}
</style>
