/*
 * @Description:
 * @Author:
 * @Date: 2021-03-20 11:30:51
 * @LastEditTime: 2021-03-20 11:35:31
 * @LastEditors:
 */
let {
  addbizCode_service,
  updatebizCode_service,
  querybizCode_service,
  removebizCode_service
} = require('../service/BizCodeMgr.service')

module.exports = {
  addbizCode_controller(val) {
    return addbizCode_service(val)
  },
  updatebizCode_controller(val) {
    return updatebizCode_service(val)
  },
  querybizCode_controller(val) {
    return querybizCode_service(val)
  },
  removebizCode_controller(val) {
    return removebizCode_service(val)
  }
}
