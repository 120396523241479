<template>
  <div class="view-scroll">
    <div class="m-box col m-mt-10 box">
      <a-form
        :form="form"
        :label-col="{ span: 0 }"
        class="fff"
        :wrapper-col="{ span: 22 }"
        @submit="handleSearch"
      >
        <a-row type="flex">
          <a-col :span="3">
            <a-form-item label="">
              <a-cascader
                v-decorator="[
                  'organCode',
                  {
                    initialValue: [],
                    rules: [
                      {
                        type: 'array',
                        required: false,
                        message: '选择机构',
                      },
                    ],
                  },
                ]"
                placeholder="所有机构"
                change-on-select
                :options="companyData"
                :field-names="{
                  label: 'name',
                  value: 'companyNo',
                  children: 'children',
                }"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :span="3">
            <a-form-item label="">
              <a-input v-decorator="['mobile']" placeholder="搜索用户姓名/电话号码" allow-clear>
                <a-icon slot="prefix" type="search" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="2" class="ant-form-item">
            <a-button type="primary" class="m-mt-4" html-type="submit">查询</a-button>
          </a-col>
          <a-col :span="16" class="ant-form-item text-right">
            <a-button type="primary" class="m-mt-4 m-ml-10" @click="approvalMulti()">批量确认</a-button>
            <a-button type="primary" class="m-mt-4 m-ml-10" @click="exportExcel()">导出</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="m-box m-mt-10">
      <a-table
        :row-selection="rowSelection"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :scroll="{ x: 2450 }"
        row-key="id"
      >
        <div slot="drugPic" slot-scope="row" class="text-3d9">
          <img :src="row" class="drugPic" :width="48" :height="48" preview="0" @error="errorImg">
        </div>
        <div slot="action" slot-scope="record" class="text-3d9">
          <a-button
            :disabled="record.orderStatus != 0 && record.orderStatus != 2"
            type="primary"
            @click="approval(record)"
          >确认</a-button>
          <a-button
            :disabled="record.orderStatus != 0 && record.orderStatus != 2"
            type="primary"
            class="m-ml-10"
            @click="modify(record)"
          >修改</a-button>
          <a-button
            :disabled="record.orderStatus != 0 && record.orderStatus != 2"
            type="danger"
            class="m-ml-10"
            @click="del(record)"
          >删除</a-button>
        </div>
      </a-table>
      <pagination
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        :total="total"
        class="paginationStyle"
        @pagination="getPages"
      />

      <!-- 编辑 -->
      <a-modal
        title="修改"
        :visible="visibleModify"
        :footer="null"
        @cancel="handleCancel"
      >
        <a-form
          :form="modifyForm"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 17 }"
          @submit="handleModify"
        >
          <a-form-item hidden label="id">
            <a-input
              v-decorator="['id', {initialValue: infoModify.id}]"
              name="id"
              hidden
              disabled="disabled"
            />
          </a-form-item>
          <a-form-item label="用户姓名">
            <a-input
              v-decorator="['userName', {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请输入用户姓名'
                  },
                ],
                initialValue: infoModify.userName}]"
              disabled="disabled"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="机构名称">
            <a-input
              v-decorator="['organName', {initialValue: infoModify.organName}]"
              disabled="disabled"
            />
          </a-form-item>
          <a-form-item label="电话号">
            <a-input
              v-decorator="[
                'phone',
                {
                  rules: [
                    {
                      required: true,
                      validator: validatorPhone,
                      trigger: 'blur',
                    },
                  ],
                  initialValue: infoModify.phone,
                },
              ]"
              placeholder="请输入电话号"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="药品名称">
            <a-input
              v-decorator="[
                'drugName',
                {
                  rules: [
                    {
                      required: true,
                      trigger: 'blur',
                      message: '请输入药品名称!'
                    },
                  ],
                  initialValue: infoModify.drugName,
                },
              ]"
              placeholder="请输入药品名称"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="药品厂家">
            <a-input
              v-decorator="[
                'drugCompany',
                {
                  rules: [
                    {
                      required: true,
                      trigger: 'blur',
                      message: '请输入药品厂家!'
                    },
                  ],
                  initialValue: infoModify.drugCompany,
                },
              ]"
              placeholder="请输入药品厂家"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="同意不同厂家">
            <a-radio-group v-decorator="['agree', {initialValue: infoModify.agree}]">
              <a-radio :value="0">
                是
              </a-radio>
              <a-radio :value="1">
                否
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="药品数">
            <a-input-number
              id="count"
              v-decorator="[
                'count',
                {
                  rules: [
                    {
                      required: true,
                      message: '药品数量不能为空!',
                      message: '请输入药品数量!'
                    },
                    {
                      pattern: /^[1-9]\d*$/,
                      message: '请输入正整数!',
                    }
                  ],
                  validateTrigger: ['blur'],
                  initialValue: infoModify.count,
                }
              ]"
              :min="1"
              :max="2000"
              :step="1"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="送货地">
            <a-input
              v-decorator="[
                'address',
                {
                  rules: [
                    {
                      required: true,
                      trigger: 'blur',
                      message: '请输入送货地!'
                    },
                  ],
                  initialValue: infoModify.address,
                },
              ]"
              placeholder="请输入送货地"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="照片">
            <a-row type="flex">
              <img :src="infoModify.pic1" :width="80" :height="80">
              <img :src="infoModify.pic2" :width="80" :height="80">
              <img :src="infoModify.pic3" :width="80" :height="80">
              <!-- <UploadImg
                tip="上传照片"
                :width="80"
                :height="80"
                style="height:100px"
              /> -->
            </a-row>
          </a-form-item>
          <a-form-item label="备注">
            <a-textarea
              v-decorator="[
                'memo',
                {
                  initialValue: infoModify.memo,
                },
              ]"
              auto-size
              allow-clear
            />
          </a-form-item>
          <a-form-item style="display: flex; justify-content: center">
            <div style="display: flex; justify-content: space-around">
              <a-button type="primary" html-type="submit" size="large">
                确认
              </a-button>
              <a-button
                type="primary"
                style="background: #999; border-color: #999"
                size="large"
                @click="handleCancel"
              >
                取消
              </a-button>
            </div>
          </a-form-item>
        </a-form>
      </a-modal><!-- 修改end -->
    </div>
    <GenerateParams ref="generateParams" @refresh="getList" />
  </div>
</template>

<script>
import Base from '@/components/subscription/Base'

import {
  getDrugOrderLists_controller,
  approval_controller,
  approvalMulti_controller,
  modify_controller,
  delete_controller,
  export_controller
} from '@/static/controller/DrugBooking.controller'

import { utils, writeFile } from 'xlsx'
import companyOptionsMixin from '@/mixins/companyOptionsMixin'
import organTreeSelectMixin from '@/mixins/organTreeSelectMixin'
import { validateReg } from '@/static/utils/regular'
export default {
  components: {
    GenerateParams: () => import('@/components/RegistrationCode/GenerateParams'),
    Pagination: () => import('@/components/Pagination')
    // UploadImg: () => import('@/components/UploadImg')
  },
  extends: Base,
  mixins: [companyOptionsMixin, organTreeSelectMixin],
  data() {
    return {
      showImg: false,
      imgSrc: '',
      visibleModify: false,
      modifyForm: this.$form.createForm(this, {
        name: 'modify_form'
      }),
      infoModify: {},
      pageIndex: 1, // 当前页码
      pageSize: 10,
      pagination: {}, // tab分页
      total: 0,
      selectedRowKeys: [],
      where: {
        mobile: '',
        organCode: ''
      },
      bindVisible: false,
      form: this.$form.createForm(this, {
        name: 'evalutaion'
      }),
      columns: [
        {
          title: '用户姓名',
          dataIndex: 'userName',
          width: 180
        },
        {
          title: '机构名称',
          dataIndex: 'organName',
          width: 240
        },
        {
          title: '电话号码',
          dataIndex: 'phone',
          width: 120
        },
        {
          title: '药品名称',
          dataIndex: 'drugName',
          width: 180
        },
        {
          title: '药品厂家',
          dataIndex: 'drugCompany',
          width: 240
        },

        {
          title: '同意不同厂家',
          width: 100,
          align: 'center',
          dataIndex: 'agree',
          customRender: (text, row, index) => {
            if (row.agree === 0) {
              return '是'
            } else {
              return '否'
            }
          }
        },
        {
          title: '药品数量',
          dataIndex: 'count',
          align: 'center',
          width: 80
        },
        {
          title: '送货地址',
          width: 240,
          dataIndex: 'address'
        },
        {
          title: '照片',
          width: 240,
          dataIndex: 'pic1',
          align: 'center',
          scopedSlots: { customRender: 'drugPic' }
        },
        {
          title: '备注',
          width: 240,
          dataIndex: 'memo'
        },
        {
          title: '提交时间',
          width: 180,
          align: 'center',
          dataIndex: 'createTime'
        },
        {
          title: '订单状态',
          width: 100,
          align: 'center',
          dataIndex: 'orderStatus',
          customRender: (text, row, index) => {
            switch (row.orderStatus) {
              case '0':
                return <font color='red'>未确认</font>
              case '1':
                return <font color='green'>已确认</font>
              case '2':
                return <font color='orange'>已修改</font>
              case '3':
                return <font color='orange'>已删除</font>
            }
          }
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
        },
        getCheckboxProps: record => ({
          props: {
            disabled: (record.orderStatus !== '0' && record.orderStatus !== '2'),
            // Column configuration not to be checked
            name: 'drubOrderId'
          }
        })
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.pageIndex = 1
      this.setWhere()
      this.getList()
    },

    /**
     * 请求画面数据
     */
    getList() {
      getDrugOrderLists_controller({
        page: this.pageIndex,
        rows: this.pageSize,
        number: this.pageIndex,
        size: this.pageSize,
        mobile: this.where.mobile,
        organCode: this.where.organCode
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
        this.$previewRefresh()
      })
    },
    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.getList()
    },
    exportExcel() {
      this.setWhere()
      export_controller({
        mobile: this.where.mobile,
        organCode: this.where.organCode
      }).then((res) => {
        res = res.map((r) => {
          // 同意字典转换
          const agreeName = r.agree === 0 ? '是' : '否'
          // 状态字典转换
          const orderStatusName = r.orderStatus === '0' ? '未确认' : r.orderStatus === '1' ? '已确认' : r.orderStatus === '2' ? '已修改' : r.orderStatus === '3' ? '已删除' : ''
          return {
            '用户姓名': r.userName,
            '机构名称': r.organName,
            '电话号码': r.phone,
            '药品名称': r.drugName,
            '药品厂家': r.drugCompany,
            '同意不同厂家': agreeName,
            '药品数量': r.count,
            '送货地址': r.address,
            '照片1': r.pic1,
            '照片2': r.pic2,
            '照片3': r.pic3,
            '备注': r.memo,
            '提交时间': r.createTime,
            '订单状态': orderStatusName
          }
        })
        const book = utils.book_new()
        const sheet = utils.json_to_sheet(res)
        utils.book_append_sheet(book, sheet)
        writeFile(book, '药物订单列表.xlsx')
      })
    },
    /** 确定 */
    approval(record) {
      approval_controller({
        drugOrderId: record.id
      }).then((res) => {
        this.getList()
      }).catch((error) => {
        console.log(error)
      })
    },
    /** 批量确定 */
    approvalMulti() {
      approvalMulti_controller({
        drugOrderIdList: this.selectedRowKeys
      }).then(res => {
        this.getList()
      }).finally(() => {
        this.selectedRowKeys = []
      })
    },
    handleModify(record) {
      record.preventDefault()
      this.modifyForm.validateFields((error, values) => {
        if (error) {
          return
        }
        modify_controller({
          id: values.id,
          phone: values.phone,
          drugName: values.drugName,
          drugCompany: values.drugCompany,
          agree: values.agree,
          count: values.count,
          address: values.address,
          memo: values.memo
        }).then((res) => {
          this.visibleModify = false
          this.$message.success('修改成功')
          this.pageIndex = 1
          this.getList()
        })
      })
    },
    /** 修改 */
    modify(record) {
      this.modifyForm.resetFields()
      this.visibleModify = true
      this.infoModify = record
    },
    handleCancel(e) {
      this.visibleModify = false
      this.modifyForm.resetFields()
      this.infoModify = {}
    },
    /** 删除 */
    del(record) {
      delete_controller({
        drugOrderId: record.id
      }).then((res) => {
        this.getList()
      })
    },
    /**
     * 将画面输入的检索条件设置到变量中
     */
    setWhere() {
      this.where.mobile = this.form.getFieldValue('mobile') ?? ''
      this.where.organCode = this.getSearchOrganCode(this.form.getFieldValue('organCode'))
    },
    errorImg() {},
    validatorPhone(rule, value, callback) {
      let val = validateReg('phone', value)
      if (!val.validate) {
        callback(new Error('手机号格式不正确'))
      } else {
        callback()
      }
    }
  }
}
</script>
