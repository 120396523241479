/*
 * @Author: your name
 * @Date: 2021-04-08 16:38:09
 * @LastEditTime: 2021-04-09 17:48:26
 * @LastEditors: Please set LastEditors
 * @Description: 客户外出
 * @FilePath: \ioac-tenant-html\src\static\service\ApplyGoOutMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')

const {
  queryGoOut_api,
  addGoOut_api,
  backGoOut_api
} = require('../api/ApplyGoOutMgr.api')

module.exports = {
  queryGoOut_service: (val) => {
    return xhr(queryGoOut_api, val)
  },
  addGoOut_service: (val) => {
    return xhr(addGoOut_api, val)
  },
  backGoOut_service: (val) => {
    return xhr(backGoOut_api, val)
  }
}
