/*
 * @Description:
 * @Author:
 * @Date: 2021-03-11 16:07:49
 * @LastEditTime: 2021-04-26 14:49:57
 * @LastEditors:
 */

let {
  addContract_service,
  detailContract_service,
  updateContract_service,
  contractQuery_service,
  contractHtml_service,
  getByRegisterId_service,
  contractcustQuery_service,
  getSimplecust_service
} = require('../service/ContractCustomer.service')

module.exports = {
  addContract_controller(val) {
    return addContract_service(val)
  },
  detailContract_controller(val) {
    return detailContract_service(val)
  },
  updateContract_controller(val) {
    return updateContract_service(val)
  },
  contractQuery_controller(val) {
    return contractQuery_service(val)
  },
  contractHtml_controller(val) {
    return contractHtml_service(val)
  },
  getByRegisterId_controller(val) {
    return getByRegisterId_service(val)
  },
  contractcustQuery_controller(val) {
    return contractcustQuery_service(val)
  },
  getSimplecust_controller(val) {
    return getSimplecust_service(val)
  }
}

