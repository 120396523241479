/*
 * @Description:
 * @Author:
 * @Date: 2021-03-18 09:18:27
 * @LastEditTime: 2021-03-29 11:55:32
 * @LastEditors:
 */

const {
  xhr
} = require('../lib/xhr')
const {
  hardware_query_api,
  hardware_add_api,
  hardware_get_api,
  hardware_update_api,
  hardware_delete_api,
  bedCardcountMgr_api,
  hardware_bindObj_api,
  hardware_list_api,
  updatecardStatus_api,
  replaceubedCard_api,
  updateBedCardPush_api,
  getBedCardPushInfo_api,
  queryBedCardPushInfo_api,
  riskList_api,
  querypushLog_api,
  getInfoMgrList_api,
  delInfoMgr_api,
  getData_api,
  updSetting_api,
  getSetting_api
} = require('../api/HardwareMgr.api')

module.exports = {
  queryHardwareMgr_service(val) {
    return xhr(hardware_query_api, val)
  },
  addHardwareMgr_serveice(val) {
    return xhr(hardware_add_api, val)
  },
  getHardwareMgr_service(val) {
    return xhr(hardware_get_api, val)
  },
  updateHardwareMgr_service(val) {
    return xhr(hardware_update_api, val)
  },
  deleteHardwareMgr_service(val) {
    return xhr(hardware_delete_api, val)
  },
  bedCardcountMgr_service(val) {
    return xhr(bedCardcountMgr_api, val)
  },
  hardware_bindObj_service(val) {
    return xhr(hardware_bindObj_api, val)
  },
  hardware_list_service(val) {
    return xhr(hardware_list_api, val)
  },
  updatecardStatus_service(val) {
    return xhr(updatecardStatus_api, val)
  },
  replaceubedCard_service(val) {
    return xhr(replaceubedCard_api, val)
  },
  updateBedCardPush_service(val) {
    return xhr(updateBedCardPush_api, val)
  },
  getBedCardPushInfo_service(val) {
    return xhr(getBedCardPushInfo_api, val)
  },
  queryBedCardPushInfo_service(val) {
    return xhr(queryBedCardPushInfo_api, val)
  },
  riskList_service(val) {
    return xhr(riskList_api, val)
  },
  querypushLog_service(val) {
    return xhr(querypushLog_api, val)
  },
  getInfoMgrList_service(val) {
    return xhr(getInfoMgrList_api, val)
  },

  delInfoMgr_service(val) {
    return xhr(delInfoMgr_api, val)
  },

  getData_service(val) {
    return xhr(getData_api, val)
  },

  updSetting_service(val) {
    return xhr(updSetting_api, val)
  },

  getSetting_service(val) {
    return xhr(getSetting_api, val)
  }
}
