export default {
  computed: {
    loginUserAuth() {
      return JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    adminRequired() {
      const roles = this.loginUserAuth.roles
      return roles.includes('EqR01')
    }
  }
}
