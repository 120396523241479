/*
 * @Author: your name
 * @Date: 2021-06-02 18:57:11
 * @LastEditTime: 2021-06-02 18:57:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\AccountBillInfoMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')

const {
  queryAccountBillInfo_api
} = require('../api/AccountBillInfoMgr.api')

module.exports = {
  queryAccountBillInfo_service(val) {
    return xhr(queryAccountBillInfo_api, val)
  }
}
