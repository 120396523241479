import { render, staticRenderFns } from "./addrole.vue?vue&type=template&id=64b61c4e"
import script from "./addrole.vue?vue&type=script&lang=js"
export * from "./addrole.vue?vue&type=script&lang=js"
import style0 from "./addrole.vue?vue&type=style&index=0&id=64b61c4e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports