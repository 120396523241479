<!--
 * @Author: your name
 * @Date: 2021-03-09 13:26:46
 * @LastEditTime: 2021-06-03 10:03:38
 * @LastEditors:
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\components\home\leftsider.vue
-->
<template>
  <a-affix :offset-top="64">
    <!-- <img src="../../assets/logo.png" alt="" srcset="" style="height: 55px" /> -->
    <!-- <div
      class="logo"
      :style="
        sysStyle.style === 2
          ? ''
          : 'border-right: 1px solid #e8e8e8;border-bottom: 1px solid #e8e8e8;'
      "
    > -->
    <!-- <img src="../../assets/logo.png" alt="" srcset="" style="height: 55px" /> -->
    <!-- <div v-if="!collapsed" class="logo-full" />
      <div v-else class="logo-icon" />
    </div> -->
    <GeminiScrollbar :style="{ height: 'calc(100vh - 64px)' }" autoshow>
      <a-menu
        mode="inline"
        :theme="sysStyle.style === 2 ? 'dark' : 'light'"
        :default-selected-keys="['1']"
        :selected-keys="selectedKeys"
        :open-keys="openKeys"
        :sub-menu-close-delay="0.5"
        :sub-menu-open-delay="0.3"
        @click="selectedMenu"
      >
        <template v-for="item in menuList">
          <a-menu-item v-if="item.children.length == 0" :key="item.code">
            <router-link :to="item.url">
              <a-icon v-if="item.icon" :type="item.icon" />
              <span>{{ item.showName }}</span>
            </router-link>
          </a-menu-item>
          <a-sub-menu v-else :key="item.code" @titleClick="openSubMenu">
            <span
              slot="title"
            ><a-icon v-if="item.icon" :type="item.icon" />
              <span>{{ item.showName }}</span></span>
            <a-menu-item v-for="info in item.children" :key="info.code">
              <router-link :to="{ path: info.url }">
                {{ info.showName }}
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </template>
      </a-menu>
    </GeminiScrollbar>
  </a-affix>
</template>
<script>
import { getMenu_controller } from '../../static/controller/Home.controller'
export default {
  props: ['collapsed', 'sysStyle'],
  data() {
    return {
      menuList: [],
      selectedKeys: [],
      openKeys: []
    }
  },
  mounted() {
    console.log('进入：')
    getMenu_controller().then((res) => {
      // 获取当前用户的菜单权限
      this.menuList = res
      console.log('当前菜单：', res)
      // 初始化菜单
      res.forEach((item) => {
        if (item.url === this.$route.path) {
          this.selectedKeys = [item.code]
        } else {
          item.children.forEach((info) => {
            if (info.url === this.$route.path) {
              this.openKeys = [item.code]
              this.selectedKeys = [info.code]
            }
          })
        }
      })
    })
  },
  methods: {
    openSubMenu(res) {
      if (!this.openKeys.includes(res.key)) {
        this.openKeys = [res.key]
      } else {
        this.openKeys = []
      }
    },
    selectedMenu(res) {
      this.selectedKeys = [res.key]
    },
    toggleCollapsed(res) {
      console.log('点击：', res)
    }
  }
}
</script>
<style lang="less" scoped>
</style>
