/*
 * @Description:
 * @Author:
 * @Date: 2021-03-11 10:14:33
 * @LastEditTime: 2021-03-11 10:23:12
 * @LastEditors:
 */

let {
  ic
} = require('../config/api_spec')
module.exports = {
  getBedMgr_api: {
    url: `${ic}/mgr/ioac/hospitalBed/queryUnusedList`,
    post: {
      floorCode: '?'
    }
  },
  addHospitalBed_api: {
    url: `${ic}/mgr/ioac/hospitalBed/add`,
    post: {
      code: '?',
      floorCode: '?',
      name: '?',
      orders: '?',
      organCode: '?',
      remark: '?',
      type: '?'
    }
  },
  hospitalBedQuery_api: {
    url: `${ic}/mgr/ioac/hospitalBed/query`,
    post: {
      code: '?',
      dept: '?',
      floorCode: '?',
      name: '?',
      organCode: '?',
      tenantCode: '?',
      type: '?'
    }
  },
  updateHospitalBed_api: {
    url: `${ic}/mgr/ioac/hospitalBed/update`,
    post: {
      id: '?',
      code: '?',
      dept: '?',
      floorCode: '?',
      name: '?',
      organCode: '?',
      tenantCode: '?',
      type: '?'
    }
  },
  removeHospitalBed_api: {
    url: `${ic}/mgr/ioac/hospitalBed/remove`,
    post: {
      id: '?'
    }
  }
}
