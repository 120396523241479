// 处理传参
function makeUrl(api, data) {
  data = data || {}
  let search = ''
  let url = api.url
  const re = {}
  const getData = {}
  url = api.url.replace(/{{(\w+)}}/ig, function(all, param) {
    return data[param] || ''
  })
  re.method = 'get'
  re.loading = api.loading || false // 默认弹出
  re.encrypt = api.encrypt || true // 默认需要解密
  re.is_mock = api.is_mock || false // 是否模拟请求
  re.isFileUpload = api.isFileUpload || false // 是否模拟请求
  if (re.is_mock) re.mock_data = api.mock_data || {} // 模拟的数据

  if (api.urlEncodeCharset) {
    re.urlEncodeCharset = api.urlEncodeCharset
  }
  if (api.get) {
    for (const param in api.get) {
      const k = param
      const v = api.get[param]
      const _value = getValue(v, k, data)
      getData[k] = _value
      search += ((search.length ? '&' : '') + [k, getData[k]].join('='))
    }
  }
  // 计算已经存在的searchString
  if (url.indexOf('?') > -1) {
    const _existedSearchStr = url.substring(url.indexOf('?') + 1)
    re.originUrl = url.substring(0, url.indexOf('?'))
    for (let i = 0, a = _existedSearchStr.split('&'); i < a.length; i++) {
      const o = a[i].split('=')
      getData[o[0]] = o[1] || ''
    }
  } else {
    re.originUrl = api.url
  }
  re.getData = getData
  if (search.length) {
    if (url.indexOf('?') > -1) {
      if (url.indexOf('?') === url.length - 1) {
        url += search
      } else {
        url += '&' + search
      }
    } else {
      url += '?' + search
    }
  }
  re.url = url
  const postData = {}
  if (api.post) {
    for (const param in api.post) {
      const _value = getValue(api.post[param], param, data)

      if (api.post[param] === '?' && _value === null && _value === '') {
        //
      } else {
        postData[param] = _value
      }
    }
    re.method = 'post'
    re.postData = postData
  }
  if (api.isOnlyData === 1) {
    re.postData = {
      data: JSON.stringify(postData)
    }
  }

  function getValue(_v, _k, _d) {
    let v1
    if (_v === '?') {
      if (_d[_k] === 0) {
        v1 = 0
      } else if (typeof _d[_k] === 'boolean') {
        v1 = _d[_k]
      } else {
        v1 = _d[_k] || ''
      }
    } else if (typeof _v === 'function') {
      v1 = _v(_d)
    } else if (_v === '??') {
      v1 = detailGP(_k)
    } else {
      if (_d[_k] === 0) {
        v1 = 0
      } else {
        v1 = _d[_k] || _v
      }
    }
    return v1
  }

  // 处理值为+号的
  function detailGP(GPkey) {
    const oJson = {}
    let _k; let _v; let v1
    const _data = data[GPkey]
    const _body = api[GPkey] || {}
    for (const param in _body) {
      _k = param
      _v = _body[param]
      if (_v === '?') {
        if (_data[_k] === 0) {
          v1 = 0
        } else {
          v1 = _data[_k] || ''
        }
      } else {
        if (_data[_k] === 0) {
          v1 = 0
        } else {
          v1 = _data[_k] || _v
        }
      }
      oJson[param] = v1
    }
    return oJson
  }

  if (api.method) {
    re.method = api.method
  }
  return re
}

// 把参数2,3...的所有不为undefined的key复制到参数1上
function merge() {
  if (arguments.length > 0) {
    const re = arguments[0]
    for (let i = 1; i < arguments.length; i++) {
      const o = arguments[i]
      for (const p in o) {
        if (o[p] !== undefined) {
          re[p] = o[p]
        }
      }
    }
    return re
  }
  return undefined
}

module.exports = {
  makeUrl: makeUrl,
  merge: merge
}
