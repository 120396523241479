<template>
  <div>
    <!-- <a-button type="primary" class="m-mt-4" @click="visible = true"> 导入 </a-button> -->
    <a-upload
      list-type="text"
      name="file"
      :remove="handleRemove"
      :before-upload="beforeUpload"
      :custom-request="upRequest"
      :show-upload-list="false"
      accept=".xls,.xlsx"
    >
      <a-button type="primary" class="m-mt-4" style="width:100%;!important">
        选择文件 支持excel文件
      </a-button>
    </a-upload>
    <!-- <a-button type="primary" class="m-mt-4" @click="download"> 导出 </a-button> -->
  </div>
</template>

<script>
// import {
// addExcel_controller
// } from '../static/controller/elder.controller'
// import {
// getAliyunOssSign_controller
// } from '../static/controller/UploadImg.controller'
import { xhr_custom } from '../static/lib/xhr'
export default {
  filters: {},
  components: {},
  props: {
    // typeId: {
    //   type: String
    // },
    type: {
      type: Boolean
    }
  },
  data() {
    return {
      fileList: [],
      visible: false,
      typeId: ''
    }
  },

  mounted() {
    console.log()
  },

  methods: {
    // handleOk() {
    //   console.log(this.fileList)
    //
    // },

    handleCancel() {
      this.fileList = []
      this.visible = false
    },

    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = [...this.fileList]
      newFileList.splice(index, 1)
      this.fileList = newFileList
      console.log(this.fileList)
    },

    beforeUpload(file) {
      this.fileList = [...this.fileList, file]
      console.log(this.fileList)
    },

    upRequest(e) {
      for (let e of this.fileList) {
        let formData = new FormData()
        formData.append('file', e)
        formData.append('typeId', this.typeId)
        formData.append(
          'organCode',
          JSON.parse(localStorage.getItem('user')).organCode
        )
        formData.append(
          'tenantCode',
          JSON.parse(localStorage.getItem('user')).tenantCode
        )
        formData.append('userId', JSON.parse(localStorage.getItem('user')).id)
        xhr_custom('/equip-manage/mgr/equip/info/batchAdd', {
          method: 'post',
          upload: true,
          data: formData
        })
          .then((res) => {
            console.log(res)
            this.$emit('updsuccess')
            this.fileList = []
          })
          .catch(() => {
            this.loading = false
          })
      }
    },

    download() {
      const url = `${
        window.location.origin
      }/equip-manage/mgr/equip/service/butler/export?id=${
        JSON.parse(localStorage.getItem('user')).organCode
      }`
      console.log(url)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', 'excel.xlsx')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      xhr_custom('/equip-manage/mgr/equip/service/butler/export', {
        method: 'get',
        responseType: 'blob'
      })
        .then((res) => {
          const data = res
          console.log(data)
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
          )
          console.log(url)
          // const link = document.createElement('a')
          // link.style.display = 'none'
          // link.href = url
          // link.setAttribute('download', 'excel.xlsx')
          // document.body.appendChild(link)
          // link.click()
          // document.body.removeChild(link)
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped="scoped">
.box {
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  padding: 0px 10px;
  margin-right: 10px;
  cursor: pointer;
  height: 33px;
  line-height: 33px;
}

.box:hover {
  border: 1px solid #247fff;
  color: #247fff;
}
</style>
