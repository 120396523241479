/*
 * @Author: your name
 * @Date: 2021-03-06 14:43:28
 * @LastEditTime: 2021-03-08 16:13:06
 * @LastEditors: Please set LastEditors
 * @Description: 机构管理
 * @FilePath: \ioac-tenant-html\src\static\service\OrganMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  organQuery_api,
  organAdd_api,
  organRemove_api,
  organUpdate_api,
  queryByUser_api,
  getOrgan_api,
  getOrganOptions_api
} = require('../api/OrganMgr.api')

module.exports = {
  organQuery_service(val) {
    return xhr(organQuery_api, val)
  },
  organAdd_service(val) {
    return xhr(organAdd_api, val)
  },
  organRemove_service(val) {
    return xhr(organRemove_api, val)
  },
  organUpdate_service(val) {
    return xhr(organUpdate_api, val)
  },
  queryByUser_service() {
    return xhr(queryByUser_api, {})
  },
  getOrgan_service(val) {
    return xhr(getOrgan_api, val)
  },
  getOrganOptions_service(val) {
    return xhr(getOrganOptions_api, val)
  }
}
