/*
 * @Author: your name
 * @Date: 2021-03-08 15:51:05
 * @LastEditTime: 2021-04-23 11:13:00
 * @LastEditors:
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\BedMgr.controller.js
 */
const {
  queryByUser_service
} = require('../service/OrganMgr.service')

const {
  floorTree_service,
  addFloor_service,
  removeFloor_service,
  updateFloor_service,
  roomsByFloor_service,
  amountByType_service
} = require('../service/FloorMgr.service')

const {
  twoListByOneType_service
} = require('../service/FinanceFeeItemMgr.service')

const {
  addHospitalBed_service,
  hospitalBedQuery_service,
  updateHospitalBed_service,
  removeHospitalBed_service
} = require('../service/HospitalBedMgr.service')

module.exports = {
  queryByUser_controller() {
    return queryByUser_service()
  },
  floorTree_controller(val) {
    return floorTree_service(val)
  },
  addFloor_controller(val) {
    return addFloor_service(val)
  },
  removeFloor_controller(val) {
    return removeFloor_service(val)
  },
  updateFloor_controller(val) {
    return updateFloor_service(val)
  },
  twoListByOneType_controller(val) {
    return twoListByOneType_service(val)
  },
  addHospitalBed_controller(val) {
    return addHospitalBed_service(val)
  },
  roomsByFloor_controller(val) {
    return roomsByFloor_service(val)
  },
  hospitalBedQuery_controller(val) {
    return hospitalBedQuery_service(val)
  },
  updateHospitalBed_controller(val) {
    return updateHospitalBed_service(val)
  },
  removeHospitalBed_controller(val) {
    return removeHospitalBed_service(val)
  },
  amountByType_controller(val) {
    return amountByType_service(val)
  }
}
