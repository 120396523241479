/*
 * @Author: your name
 * @Date: 2021-06-01 13:56:37
 * @LastEditTime: 2021-06-02 10:53:02
 * @LastEditors: Please set LastEditors
 * @Description: 长者套餐管理
 * @FilePath: \ioac-tenant-html\src\static\api\live.CustomerMealMgr.api.js
 */
let {
  hsc
} = require('../config/api_spec')

module.exports = {
  addCustomerMeal_api: {
    url: `${hsc}/mgr/home2shop/customerMeal/add`,
    post: {
      customerNo: '?',
      endTime: '?',
      mealId: '?'
    }
  },
  // customerMeal/addBatch
  addBatchCustomerMeal_api: {
    url: `${hsc}/mgr/home2shop/customerMeal/addBatch`,
    post: {
      customerNo: '?',
      mealInfos: '?'
    }
  },
  deleteCustomerMeal_api: {
    url: `${hsc}/mgr/home2shop/customerMeal/delete`,
    post: {
      id: '?'
    }
  },
  getCustomerMeal_api: {
    url: `${hsc}/mgr/home2shop/customerMeal/get`,
    post: {
      id: '?'
    }
  },
  getAvailableMealByCustomer_api: {
    url: `${hsc}/mgr/home2shop/customerMeal/getAvailableMealByCustomer`,
    post: {
      customerNo: '?'
    }
  },
  queryCustomerMeal_api: {
    url: `${hsc}/mgr/home2shop/customerMeal/query`,
    post: {
      customerNo: '?',
      mealId: '?',
      page: '?',
      rows: '?'
    }
  },
  queryListCustomerMeal_api: {
    url: `${hsc}/mgr/home2shop/customerMeal/querylist`,
    post: {
      customerNo: '?',
      mealId: '?',
      nonceStr: '?',
      page: '?',
      rows: '?',
      rowsState: '?'
    }
  },
  updateCustomerMeal_api: {
    url: `${hsc}/mgr/home2shop/customerMeal/update`,
    post: {
      customerNo: '?',
      id: '?',
      mealId: '?'
    }
  }
}
