/*
 * @Author: your name
 * @Date: 2021-05-27 16:52:43
 * @LastEditTime: 2021-05-31 16:25:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\api\ServiceStaffInfoMgr.api.js
 */
let {
  hsc
} = require('../config/api_spec')

module.exports = {
  addServiceMeal_api: {
    url: `${hsc}/mgr/home2shop/serviceMeal/add`,
    post: {
      chargePeriod: '?',
      mealComment: '?',
      mealId: '?',
      mealName: '?',
      mealNo: '?',
      mealPrice: '?',
      mealType: '?',
      state: '?',
      mealServiceItemList: '?'
    }
  },
  queryServiceMeal_api: {
    url: `${hsc}/mgr/home2shop/serviceMeal/query`,
    post: {
      mealName: '?',
      page: '?',
      rows: '?'
    }
  },
  delServiceMeal_api: {
    url: `${hsc}/mgr/home2shop/serviceMeal/del`,
    post: {
      mealId: '?'
    }
  },
  //   mgr/home2shop/serviceMeal/update
  updateServiceMeal_api: {
    url: `${hsc}/mgr/home2shop/serviceMeal/update`,
    post: {
      chargePeriod: '?',
      mealComment: '?',
      mealId: '?',
      mealName: '?',
      mealNo: '?',
      mealPrice: '?',
      mealType: '?',
      state: '?',
      mealServiceItemList: '?'
    }
  },
  //   mgr/home2shop/serviceMeal/get
  getServiceMeal_api: {
    url: `${hsc}/mgr/home2shop/serviceMeal/get`,
    get: {
      mealId: '?'
    }
  },
  // mgr/home2shop/serviceMeal/updateState
  updateStateServiceMeal_api: {
    url: `${hsc}/mgr/home2shop/serviceMeal/updateState`,
    post: {
      mealId: '?',
      state: '?'
    }
  }
}
