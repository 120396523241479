/*
 * @Description:
 * @Author:
 * @Date: 2021-03-24 17:34:54
 * @LastEditTime: 2021-03-29 17:18:41
 * @LastEditors:
 */

let {
  queryChange_service,
  getChange_service,
  updateChange_service,
  addChange_service,
  getCustomerChange_service
} = require('../service/ChangeWorkMgr.service')

module.exports = {
  queryChangeMgr_controller(val) {
    return queryChange_service(val)
  },
  getChangeMgr_controller(val) {
    return getChange_service(val)
  },
  addChangeMgr_controller(val) {
    return addChange_service(val)
  },
  updateChangeMgr_controller(val) {
    return updateChange_service(val)
  },
  getCustomerChange_controller(val) {
    return getCustomerChange_service(val)
  }
}

