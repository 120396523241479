<!--
 * @Description:
 * @Author:
 * @Date: 2021-04-22 15:47:59
 * @LastEditTime: 2021-04-29 17:55:15
 * @LastEditors:
-->
<template>
  <div class="clearfix">
    <a-upload :file-list="fileList" list-type="text" name="file" :remove="handleRemove" :before-upload="beforeUpload" :custom-request="upRequest">
      <a-button> <a-icon type="upload" />上传文件</a-button>
    </a-upload>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
      uploading: false
    }
  },
  methods: {
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = [...this.fileList]
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file]
    },
    upRequest(e) {
      this.$emit('update:files', e.file)
    }
  }
}
</script>

<style>

</style>
