/*
 * @Description:
 * @Author:
 * @Date: 2021-03-19 09:30:59
 * @LastEditTime: 2021-06-18 11:17:25
 * @LastEditors:
 */
const { getBizTypCodeApp_controller } = require('../../static/controller/BizTypCodeApp.controller')
// 退住类型
export const leaveOut = [
  { label: '正常出院', value: '1' },
  { label: '劝退', value: '2' },
  { label: '死亡', value: '3' },
  { label: '试住到期', value: '4' },
  { label: '转院', value: '5' }
]

export const unitList = [
  { label: '元/天', value: '1' },
  { label: '元/月', value: '2' },
  { label: '元/次', value: '3' }
]

export const indicatorList = [
  { label: '血压', value: 'blood_pressure' },
  { label: '血糖', value: 'blood_sugar' },
  { label: '血氧', value: 'blood_oxygen' },
  // { label: '心电', value: 'blood_ecg' },
  // { label: '体脂', value: '' },
  { label: '体温', value: 'temperature' },
  { label: '胆固醇', value: 'cholesterol' },
  { label: '尿酸', value: 'uricacid' }
  // { label: '腰臀比', value: 'whr' }
]

export const levelList = [
  { label: '一级(中度)', value: '1' },
  { label: '二级（轻度）', value: '2' },
  { label: '三级（正常）', value: '3' },
  { label: '专护（重度）', value: '4' }
]

export const ageList = [
  { label: '60岁以下', value: '1' },
  { label: '60~69岁', value: '2' },
  { label: '70~79岁', value: '3' },
  { label: '80~89岁', value: '4' },
  { label: '90岁以上', value: '5' }
]
export const personType = [
  { label: '低保户', value: '1' },
  { label: '五保户', value: '2' }
]

export function indicatorLabel(value) {
  let label = ''
  indicatorList.map((item) => {
    if (item.value === value) {
      label = item.label
    }
  })
  return label
}

export function getLabel(label, value) {
  return new Promise((resolve, reject) => {
    getBizTypCodeApp_controller({ typeCodes: label }).then((res) => {
      let data = res.records
      data.map((item) => {
        if (item.vval === value) {
          return resolve(item.vname)
        } else {
          reject()
        }
      })
    })
  })
}
