/*
 * @Author: 刘崇杰
 * @Date: 2021-03-01 12:47:45
 * @LastEditTime: 2021-03-09 09:43:21
 * @LastEditors: Please set LastEditors
 * @Description: 登录页面
 * @FilePath: \ioac-tenant-html\src\static\controller\Captcha.controller.js
 */

const {
  getCaptcha_service,
  checkCaptcha_service
} = require('../service/Captcha.service')

const {
  loginByName2Password2Code_service,
  loginByName2Password2CodeNoComNo_service
} = require('../service/UserLogin2NamePass.service')

const {
  setStorageValue,
  setStorage
} = require('../utils/localstorage')

module.exports = {
  // 界面获取图形验证码
  getCaptcha_controller(val) {
    return new Promise((resolve) => {
      getCaptcha_service(val).then(res => {
        // 返回图像
        resolve({
          secretKey: res.secretKey,
          originalImageBase64: `data:image/jpeg;base64,${res.originalImageBase64}`,
          word: res.wordList.join('、')
        })
      })
    })
  },
  // 检查图形验证码
  checkCaptcha_controller(val) {
    return new Promise((resolve) => {
      checkCaptcha_service(val).then(res => {
        resolve({
          verification: true
        })
      }).catch(res => {
        resolve({
          verification: false
        })
      })
    })
  },
  // 用户名密码登录
  loginByName2Password2Code_controller(val) {
    return loginByName2Password2Code_service(val)
  },
  // 用户名密码登录
  loginByName2Password2CodeNoComNo_controller(val) {
    return new Promise((resolve) => {
      loginByName2Password2CodeNoComNo_service(val).then(res => {
        setStorageValue('token', res.token)
        setStorage('user', res)
        resolve(res)
      })
    })
  }

}
