<!--
 * @Description:
 * @Author:
 * @Date: 2021-03-19 15:27:04
 * @LastEditTime: 2021-04-13 11:15:21
 * @LastEditors:
-->
<template>
  <div id="components-form-demo-advanced-search">
    <a-form
      class="ant-advanced-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="8">
          <div class="gutter-box">
            <a-form-item label="姓名">
              <a-input
                v-decorator="['customerName']"
                placeholder="请输入长者姓名"
              />
            </a-form-item>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="gutter-box">
            <a-form-item label="费用项目">
              <a-cascader
                v-decorator="['feeList']"
                :options="projectList"
                style="width: 300px"
                placeholder="请选择项目！"
                :field-names="fieldNames"
                @change="onitemchange"
              />
            </a-form-item>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="gutter-box">
            <a-form-item label="交易方式">
              <a-select
                v-decorator="[
                  'payTerm'
                ]"
                style="width: 300px"
              >
                <a-select-option v-for="(item,index) in payList" :key="index" :value="item.vval">
                  {{ item.vname }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>
        </a-col>
      </a-row>
      <a-row type="flex" justify="space-between">
        <a-col>
          <router-link :to="{ path: '/finance/addbill/1'}">
            <a-button
              type="primary"
              icon="plus"
            >新增
            </a-button>
          </router-link>
        </a-col>
        <a-col>
          <a-button type="primary" html-type="submit"> 搜索 </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
            重置
          </a-button>
        </a-col>
      </a-row>
    </a-form>
    <div class="search-result-list">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="false"
        bordered
      >
        <span slot="payTerm" slot-scope="text">
          <span v-if="text==='1'">支付宝</span>
          <span v-if="text==='2'">微信</span>
          <span v-if="text==='3'">银行卡</span>
          <span v-if="text==='4'">现金</span>
        </span>
        <span slot="action" slot-scope="text, record">
          <router-link :to="{ path: '/finance/addbill/1', query: { id: record.sn }}">
            <a-button type="link"> 编辑 </a-button>
          </router-link>
          <a-divider type="vertical" />
          <a-popconfirm
            title="是否确认删除该条数据吗?"
            ok-text="确认"
            cancel-text="取消"
            @confirm="confirm(record.sn)"
          >
            <a-button type="link"> 删除 </a-button>
          </a-popconfirm>
        </span>
      </a-table>
      <pagination
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        :total="total"
        class="paginationStyle"
        @pagination="getPages"
      />
    </div>
  </div>
</template>
<script>
import { queryFinance_controller, removeFinance_controller, getFeeTypeItemList_controller } from '../../static/controller/FinanceOrderMgr.controller'
import { getBizTypCodeApp_controller } from '../../static/controller/BizTypCodeApp.controller'
const columns = [
  {
    title: '账单编号',
    dataIndex: 'sn',
    key: 'sn'
  },
  {
    title: '客户登记编号',
    dataIndex: 'customerRegisterNo',
    key: 'customerRegisterNo'
  },
  {
    title: '长者姓名',
    dataIndex: 'customerName',
    key: 'customerName'
  },
  {
    title: '费用项目',
    dataIndex: 'feeName',
    key: 'feeName'
  },
  {
    title: '缴费日期',
    dataIndex: 'date',
    key: 'date'
  },

  {
    title: '项目金额',
    key: 'fund',
    dataIndex: 'fund'
  },
  {
    title: '交易方式',
    key: 'payTerm',
    dataIndex: 'payTerm',
    scopedSlots: { customRender: 'payTerm' }
  },
  {
    title: '操作',
    key: 'action',
    width: 250,
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  components: {
    pagination: () => import('../../components/Pagination')
  },
  data() {
    return {
      expand: false,
      form: this.$form.createForm(this, { name: 'advanced_search' }),
      pagination: {}, // tab分页
      data: [],
      pageIndex: 1, // 当前页码
      pageSize: 10,
      total: 0,
      columns,
      roleList: [],
      where: {},
      payList: [],
      feeId: '',
      projectList: [],
      fieldNames: {
        value: 'id',
        label: 'name',
        children: 'items'
      }
    }
  },
  mounted() {
    this.getBizTypCodeApp()
    this.initData()
    this.getFeeTypeItemList()
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.form.validateFields((error, values) => {
        console.log('error', error)
        this.pageIndex = 1
        this.where = {
          customerName: values.customerName,
          feeId: this.feeId,
          payTerm: values.payTerm
        }
        this.initData()
      })
    },
    initData() {
      queryFinance_controller({
        ...this.where,
        page: this.pageIndex, // 页码
        rows: this.pageSize, // 每页记录数
        orderType: 1,
        acctType: 2
      }).then((res) => {
        this.data = res ? res.records : []
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    },
    handleReset() {
      this.form.resetFields()
      this.feeId = ''
    },
    // 删除
    confirm(id) {
      removeFinance_controller({
        sn: id
      }).then((res) => {
        this.$message.success('删除成功！')
        this.initData()
      })
    },
    onitemchange(value) {
      this.feeId = value.length > 1 ? value[1] : ''
    },
    // 交易方式
    getBizTypCodeApp() {
      getBizTypCodeApp_controller({ typeCodes: 'pay_term' }).then((res) => {
        this.payList = res.records
      })
    },
    // 项目选择
    getFeeTypeItemList() {
      getFeeTypeItemList_controller().then((res) => {
        this.projectList = res
      })
    },
    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.initData()
    }
  }
}
</script>
<style>
.ant-advanced-search-form {
  padding: 24px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  /* border-radius: 6px; */
}

</style>
