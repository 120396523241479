<!--
 * @Description:
 * @Author:
 * @Date: 2021-03-09 14:57:00
 * @LastEditTime: 2021-05-17 15:24:53
 * @LastEditors:
-->
<template>
  <div>
    <p class="formTitle">基本情况填写</p>
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
      <a-row :gutter="14">
        <a-col :span="8">
          <a-form-item label="进食" prop="eatway">
            <a-select
              v-decorator="[
                'eatway',
                {
                  rules: [{ required: true, message: '请选择进食方式' }],
                  initialValue: serviceInfo.eatway,
                },
              ]"
              placeholder="请选择"
              mode="tags"
            >
              <a-select-option
                v-for="(item, index) in serviceList"
                :key="index"
                :value="item.vname"
              >
                {{ item.vname }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="洗澡">
            <a-select
              v-decorator="[
                'bathway',
                {
                  rules: [{ required: true, message: '请选择洗澡方式' }],
                  initialValue: serviceInfo.bathway,
                },
              ]"
              placeholder="请选择"
              mode="tags"
            >
              <a-select-option
                v-for="(item, index) in serviceList"
                :key="index"
                :value="item.vname"
              >
                {{ item.vname }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="如厕">
            <a-select
              v-decorator="[
                'toiletway',
                {
                  rules: [{ required: true, message: '请选择如厕方式' }],
                  initialValue: serviceInfo.toiletway,
                },
              ]"
              placeholder="请选择"
              mode="tags"
            >
              <a-select-option
                v-for="(item, index) in serviceList"
                :key="index"
                :value="item.vname"
              >
                {{ item.vname }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="8">
          <a-form-item label="移动">
            <a-select
              v-decorator="[
                'moveway',
                {
                  rules: [{ required: true, message: '请选择移动方式' }],
                  initialValue: serviceInfo.toiletway,
                },
              ]"
              placeholder="请选择"
              mode="tags"
            >
              <a-select-option
                v-for="(item, index) in serviceList"
                :key="index"
                :value="item.vname"
              >
                {{ item.vname }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="认知">
            <a-select
              v-decorator="[
                'cognitionlevel',
                {
                  rules: [{ required: true, message: '请选择认知方式' }],
                  initialValue: serviceInfo.cognitionlevel,
                },
              ]"
              placeholder="请选择"
              mode="tags"
            >
              <a-select-option
                v-for="(item, index) in serviceList"
                :key="index"
                :value="item.vname"
              >
                {{ item.vname }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="楼层">
            <a-cascader
              v-decorator="[
                'HospitalBedNo',
                { initialValue: floorTreeList},
              ]"
              :options="floorList"
              expand-trigger="hover"
              :field-names="fieldNames"
              placeholder="楼栋号、单元号、房间号"
              @change="changeBed"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-item label="预约床位号">
            <a-select
              v-decorator="[
                'bookingHospitalBedNo',
                { initialValue: serviceInfo.bookingHospitalBedNo },
              ]"
              placeholder="请选择"
            >
              <a-select-option
                v-for="(item, index) in bedList"
                :key="index"
                :value="item.code"
              >
                {{ item.code }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-divider orientation="center" style="margin-top: 40px">
      <a-button
        type="default"
        style="margin-right: 80px"
        @click="preStep"
      >上一步</a-button>
      <a-button type="primary" @click="handleSubmit">确定</a-button>
    </a-divider>

  </div>

</template>
<script>
import { getBizTypCodeApp_controller } from '../../static/controller/BizTypCodeApp.controller'
import { addFloorMgr_controller, detailCusBookingMg_controller } from '../../static/controller/CustomerBookingMg.controller'
import { queryBedMgr_controller } from '../../static/controller/HospitalBedMgr.controller'
export default {
  data() {
    return {
      serviceList: [],
      floorList: [],
      bedList: [],
      serviceInfo: {},
      form: this.$form.createForm(this, { name: 'validate_other' }),
      fieldNames: {
        value: 'id',
        label: 'name',
        children: 'children'
      },
      floorTreeList: [],
      bedId: ''

    }
  },
  created() {
    this.getBizTypCodeApp('self_ability')
    this.addFloorMgr()
    if (this.$route.params.id) {
      this.initData()
    }
  },
  methods: {
    // 初始化基本信息
    initData() {
      detailCusBookingMg_controller({ id: this.$route.params.id })
        .then((res) => {
          console.log(res)
          let data = res
          data.eatway ? data.eatway = data.eatway.split(',') : []
          data.bathway ? data.bathway = data.bathway.split(',') : []
          data.toiletway ? data.toiletway = data.toiletway.split(',') : []
          data.moveway ? data.moveway = data.moveway.split(',') : []
          data.cognitionlevel ? data.cognitionlevel = data.cognitionlevel.split(',') : []
          this.serviceInfo = data
          this.floorTreeList = this.serviceInfo.hospitalBedInfoModel.floorTreeList || []
          let floorList = [...this.floorTreeList]
          let lastfloor = floorList.pop()
          this.querybedMrg(lastfloor)
        })
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          this.$emit('sendForm', values)
        }
      })
    },
    preStep() {
      this.$emit('prechange', 1)
    },
    getBizTypCodeApp(data) {
      getBizTypCodeApp_controller({ typeCodes: data }).then((res) => {
        this.serviceList = res.records
      })
    },
    addFloorMgr() {
      addFloorMgr_controller().then((res) => {
        this.floorList = res
      })
    },
    changeBed(value) {
      this.bedId = value.pop()
      this.querybedMrg(this.bedId)
      this.form.setFieldsValue({
        bookingHospitalBedNo: ''
      })
    },
    querybedMrg(data) {
      queryBedMgr_controller({ floorCode: data }).then((res) => {
        this.bedList = res
      })
    }
  }
}
</script>
