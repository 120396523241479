<template>
  <div class="view-scroll">
    <div class="m-box col m-mt-10 box">
      <a-form
        :form="form"
        :label-col="{ span: 0 }"
        class="fff"
        :wrapper-col="{ span: 22 }"
        @submit="handleSearch"
      >
        <a-row type="flex">
          <a-col :span="4">
            <a-form-item label="">
              <a-input
                v-decorator="['mobile']"
                placeholder="请输入查询姓名或手机号"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :span="3" class="ant-form-item">
            <a-button type="primary" class="m-mt-4" html-type="submit">
              查询
            </a-button>
          </a-col>
          <a-col :span="17" class="ant-form-item text-right">
            <div style="display: inline-block; margin-right: 10px">
              <Flie @success="updSuccess1" />
            </div>
            <a-button type="primary" class="m-mt-4 m-ml-10" @click="add()">
              新增
            </a-button>
            <!-- <a-button type="primary" class="m-mt-4 m-ml-10"> 导出 </a-button> -->
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="m-box m-mt-10">
      <a-table :columns="columns" :data-source="data" :pagination="false" :scroll="{ x: 1200 }">
        <div slot="photo" slot-scope="row" class="text-3d9">
          <img :src="row" class="photo">
        </div>
        <span slot="sex" slot-scope="row">{{ row === 1 ? "男" : "女" }}</span>
        <div slot="action" slot-scope="record" class="text-3d9">
          <a-button
            type="primary"
            style="background: #ff9d4d; border-color: #ff9d4d"
            @click="setPass(record)"
          >密码</a-button>
          <a-button
            type="primary"
            class="m-ml-10"
            @click="setItem(record)"
          >编辑</a-button>
          <a-button
            type="danger"
            class="m-ml-10"
            @click="del(record)"
          >删除</a-button>
        </div>
      </a-table>

      <pagination
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        :total="total"
        class="paginationStyle"
        @pagination="getPages"
      />
    </div>

    <!-- 密码 -->
    <a-modal :visible="visiblepassword" :footer="null" @cancel="handleCancel">
      <a-form
        :form="passForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
        @submit="handSave2"
      >
        <a-form-item label="用户账户">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur'
                  },
                  {
                    max: 20,
                    message: '姓名不能超过20个字',
                  }
                ],
                initialValue: passinfo.name,
              },
            ]"
            placeholder="请输入姓名"
            :max-length="maxlength"
            disabled
          />
        </a-form-item>
        <a-form-item label="用户旧密码">
          <a-input
            v-model="number"
            type="password"
            disabled
          />
        </a-form-item>
        <a-form-item label="新的登录密码">
          <a-input
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入密码!',
                  },
                  {
                    validator: validateToNextPassword,
                  },
                ],
                initialValue: passinfo.password,
              },
            ]"
            :max-length="maxlength"
            type="password"
            allow-clear
          />
        </a-form-item>
        <a-form-item label="重复新的密码">
          <a-input
            v-decorator="[
              'confirm',
              {
                rules: [
                  {
                    required: true,
                    message: '请再次输入密码!',
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
                initialValue: passinfo.password,
              },
            ]"
            type="password"
            allow-clear
            @blur="handleConfirmBlur"
          />
        </a-form-item>
        <a-form-item style="display: flex; justify-content: center">
          <div style="display: flex; justify-content: space-around">
            <a-button type="primary" html-type="submit" :size="size">
              保存
            </a-button>
            <a-button
              type="primary"
              :size="size"
              style="background: #999; border-color: #999"
              @click="handleCancel"
            >
              取消
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 新增 -->
    <a-modal
      title="新增管家"
      :visible="visible"
      :footer="null"
      :mask-closable="false"
      @cancel="handleCancel"
    >
      <a-form
        :form="addForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
        @submit="handSave"
      >
        <a-form-item label="姓名">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请输入姓名'
                  },
                  {
                    max: 20,
                    message: '姓名不能超过20个字',
                  }
                ],
                initialValue: addInfo.name,
              },
            ]"
            placeholder="请输入姓名"
            allow-clear
          />
        </a-form-item>
        <a-form-item label="头像">
          <UploadImg
            :url.sync="frontUrl"
            tip="上传照片"
            :width="280"
            :height="100"
          />
        </a-form-item>
        <a-form-item label="联系方式">
          <a-input
            v-decorator="[
              'phone',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请输入联系方式'
                  },
                ],
                initialValue: addInfo.age,
              },
            ]"
            placeholder="请输入联系方式"
            allow-clear
          />
        </a-form-item>
        <a-form-item label="身份证号">
          <a-input
            v-decorator="[
              'idCard',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    validator: validatorIdCard,
                  },
                ],
                initialValue: addInfo.idNo,
              },
            ]"
            placeholder="请输入身份证号"
            allow-clear
          />
        </a-form-item>
        <a-form-item label="性别">
          <a-select
            v-decorator="[
              'sex',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请选择性别'
                  },
                ],
                initialValue: addInfo.sex,
              },
            ]"
            placeholder="请选择"
            allow-clear
          >
            <a-select-option value="1"> 男 </a-select-option>
            <a-select-option value="2"> 女 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="所属机构">
          <a-cascader
            v-decorator="[
              'equip',
              {
                initialValue: addInfo.equip,
                rules: [
                  {
                    type: 'array',
                    required: true,
                    message: '选择机构',
                  },
                ],
              },
            ]"
            placeholder="选择机构"
            change-on-select
            :options="gData"
            :field-names="{
              label: 'name',
              value: 'companyNo',
              children: 'children',
            }"
            allow-clear
          />
        </a-form-item>
        <a-form-item style="display: flex; justify-content: center">
          <div style="display: flex; justify-content: space-around">
            <a-button type="primary" html-type="submit" :size="size">
              确认
            </a-button>
            <a-button
              type="primary"
              :size="size"
              style="background: #999; border-color: #999"
              @click="handleCancel"
            >
              取消
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 编辑 -->
    <a-modal
      title="编辑信息"
      :visible="visible1"
      :footer="null"
      @cancel="handleCancel"
    >
      <a-form
        :form="updateFrom"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
        @submit="handleSearchUpate"
      >
        <a-form-item label="姓名">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请输入姓名'
                  },
                  {
                    max: 20,
                    message: '姓名不能超过20个字',
                  }
                ],
                initialValue: upinfo.name,
              },
            ]"
            placeholder="请输入姓名"
            allow-clear
          />
        </a-form-item>
        <a-form-item label="头像">
          <UploadImg
            :url.sync="updateUrl"
            tip="上传照片"
            :width="280"
            :height="100"
            style="height:100px"
          />
        </a-form-item>
        <a-form-item label="联系方式">
          <a-input
            v-decorator="[
              'phone',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请输入联系方式'
                  },
                ],
                initialValue: upinfo.phone,
              },
            ]"
            placeholder="请输入联系方式"
            allow-clear
          />
        </a-form-item>
        <a-form-item label="身份证号">
          <a-input
            v-decorator="[
              'idCard',
              {
                rules: [
                  {
                    required: true,
                    trigger: 'blur',
                    message: '请输入身份证号'
                  },
                ],
                initialValue: upinfo.idcard,
              },
            ]"
            placeholder="请输入身份证号"
            allow-clear
          />
        </a-form-item>
        <a-form-item label="性别">
          <a-select
            v-decorator="[
              'sex',
              {
                rules: [
                  {
                    type: 'number',
                    required: true,
                    trigger: 'blur',
                    message: '请选择性别'
                  },
                ],
                initialValue: upinfo ? upinfo.sex : '',
              },
            ]"
            placeholder="请选择"
            allow-clear
          >
            <a-select-option :value="1"> 男 </a-select-option>
            <a-select-option :value="2"> 女 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="所属机构">
          <a-cascader
            v-decorator="[
              'equip',
              {
                initialValue: upinfo.organIds,
                rules: [
                  {
                    type: 'array',
                    required: true,
                    message: '选择机构',
                  },
                ],
              },
            ]"
            placeholder="选择机构"
            change-on-select
            :options="gData"
            :field-names="{
              label: 'name',
              value: 'companyNo',
              children: 'children',
            }"
            allow-clear
          />
        </a-form-item>
        <a-form-item label="注册码">
          <a-input
            v-decorator="[
              'registrationCode',
              {
                initialValue: upinfo ? upinfo.registrationCode : '',
              }
            ]"
            disabled
          />
        </a-form-item>
        <a-form-item style="display: flex; justify-content: center">
          <div style="display: flex; justify-content: space-around">
            <a-button type="primary" html-type="submit" :size="size">
              确认
            </a-button>
            <a-button
              type="primary"
              :size="size"
              style="background: #999; border-color: #999"
              @click="handleCancel"
            >
              取消
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { validateReg } from '../../static/utils/regular'
import {
  butler_batchDelete_controller,
  // butler_export_controller,
  // butler_getInfoById_controller,
  // butler_importUser_controller,
  account_queryTree_controller,
  butler_save_controller,
  butler_queryList_controller,
  butler_updatePassword_controller
} from '../../static/controller/cost.controller'
const residences = [
  {
    value: 'zhejiang',
    label: 'Zhejiang',
    children: [
      {
        value: 'hangzhou',
        label: 'Hangzhou',
        children: [
          {
            value: 'xihu',
            label: 'West Lake'
          }
        ]
      }
    ]
  },
  {
    value: 'jiangsu',
    label: 'Jiangsu',
    children: [
      {
        value: 'nanjing',
        label: 'Nanjing',
        children: [
          {
            value: 'zhonghuamen',
            label: 'Zhong Hua Men'
          }
        ]
      }
    ]
  }
]
const columns = [
  {
    title: '姓名',
    dataIndex: 'name'
  },
  {
    title: '头像',
    dataIndex: 'avatar',
    width: 100,
    align: 'center',
    scopedSlots: {
      customRender: 'photo'
    }
  },
  {
    title: '联系方式',
    width: 120,
    align: 'center',
    dataIndex: 'phone'
  },
  {
    title: '身份证号',
    width: 175,
    align: 'center',
    dataIndex: 'idcard'
  },
  {
    title: '性别',
    width: 60,
    align: 'center',
    dataIndex: 'sex',
    scopedSlots: {
      customRender: 'sex'
    }
  },
  {
    title: '年龄',
    width: 60,
    align: 'center',
    dataIndex: 'age'
  },
  {
    title: '所属机构',
    dataIndex: 'organName'
  },
  {
    title: '操作',
    align: 'center',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const data = []
export default {
  filters: {},
  components: {
    pagination: () => import('../../components/Pagination'),
    UploadImg: () => import('../../components/UploadImg'),
    Flie: () => import('../../components/file.vue')
  },
  data() {
    return {
      maxlength: 6,
      number: '123123',
      residences,
      data,
      columns,
      selectedRowKeys: [],
      form: this.$form.createForm(this, {
        name: 'evalutaion'
      }),
      addForm: this.$form.createForm(this, {
        name: 'customer_list'
      }),
      updateFrom: this.$form.createForm(this, {
        name: 'customer_list2'
      }),
      passForm: this.$form.createForm(this, {
        name: 'customer_list1'
      }),
      visible: false,
      pageIndex: 1, // 当前页码
      pageSize: 10,
      pagination: {}, // tab分页
      total: 0,
      where: {},
      addWhere: {},
      addInfo: {},
      ids: [],
      size: 'large',
      visiblepassword: false,
      visible1: false,
      frontUrl: '',
      upinfo: {},
      passinfo: {},
      // newImg:'',
      gData: [],
      equiplist: ['HOL1', 'CRI1432877673957036032'],
      updateUrl: ''
    }
  },

  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },

  mounted() {
    this.getElderList()
    this.getTreeList()
  },
  methods: {
    getTreeList() {
      account_queryTree_controller({}).then((res) => {
        this.gData.push(res)
      })
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.passForm
      if (value && value !== form.getFieldValue('password')) {
        callback('密码不一致!')
      } else {
        callback()
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.passForm
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    updSuccess1(e) {
      if (e) {
        this.$message.error(e)
      } else {
        this.$message.success('导入成功')
      }
    },
    getElderList() {
      butler_queryList_controller({
        name: this.where.mobile,
        page: this.pageIndex,
        rows: this.pageSize,
        number: this.pageIndex,
        size: this.pageSize
      }).then((res) => {
        this.data = res.content
        this.pageIndex = res.pageable.pageNumber + 1
        this.pageSize = res.pageable.pageSize
        this.total = res.totalElements
      })
    },

    start() {
      this.loading = true
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },

    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      let ids = []
      for (let x of selectedRowKeys) {
        for (let i in this.data) {
          console.log(x, i)
          if (x === +i) {
            ids.push(this.data[i].id)
          }
        }
      }
      console.log(ids)
      this.ids = ids
      this.selectedRowKeys = selectedRowKeys
    },

    handleSearch(e) {
      e.preventDefault()
      this.form.validateFields((error, values) => {
        console.log(error, values)
        this.pageIndex = 1
        this.where = values
        this.getElderList()
      })
    },
    handleSearchUpate(e) {
      e.preventDefault()
      this.updateFrom.validateFields((error, values) => {
        if (!error) {
          this.pageIndex = 1
          let orangid = ''
          if (values.equip.length === 1) {
            orangid = values.equip[0]
          } else {
            orangid = values.equip[1]
          }
          butler_save_controller({
            // age: ,
            avatar: this.updateUrl,
            id: this.upinfo.id,
            idcard: values.idCard,
            name: values.name,
            organId: orangid,
            organName: '?',
            phone: values.phone,
            sex: values.sex
          }).then((res) => {
            this.$message.success('修改成功')
            this.getElderList()
            this.visible1 = false
          })
        }
      })
    },
    handSave(e) {
      e.preventDefault()
      this.addForm.validateFields(async(error, values) => {
        if (error) {
          return
        }
        // console.log(error, values)
        this.pageIndex = 1
        this.addWhere = values
        let orangid = ''
        if (this.addWhere.equip.length === 1) {
          orangid = this.addWhere.equip[0]
        } else {
          orangid = this.addWhere.equip[1]
        }
        // console.log(this.addWhere.idCard)
        butler_save_controller({
          // age: ,
          avatar: this.frontUrl,
          // id: '?',
          idcard: this.addWhere.idCard,
          name: this.addWhere.name,
          organId: orangid,
          organName: '?',
          phone: this.addWhere.phone,
          sex: this.addWhere.sex
        }).then((res) => {
          this.$message.success('添加成功')
          this.addForm.resetFields()
          this.getElderList()
          this.visible = false
        })
      })
    },
    handSave2(e) {
      e.preventDefault()
      this.passForm.validateFields(async(error, values) => {
        if (error) {
          return
        }
        console.log(error, values)
        this.pageIndex = 1
        butler_updatePassword_controller({
          id: this.passinfo.id,
          password: values.confirm
        }).then((res) => {
          console.log(res)
          this.$message.success('设置成功')
          this.getElderList()
          this.visiblepassword = false
          this.passForm.resetFields()
        })
      })
    },
    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.getElderList()
    },

    setItem(e) {
      this.updateFrom.resetFields()
      this.upinfo = e
      this.updateUrl = e.avatar
      // let newid = this.upinfo.organId.split(',')
      // this.upinfo.organId = newid
      console.log(this.upinfo)
      this.visible1 = true
    },
    del(e) {
      butler_batchDelete_controller({ id: String(e.id) }).then((res) => {
        this.$message.success('删除成功')
        this.getElderList()
      })
    },
    add() {
      this.visible = true
    },
    setPass(e) {
      this.visiblepassword = true
      this.passinfo = e
    },
    // handleOk(e) {
    //   e.preventDefault()
    //   this.addForm.validateFields((error, values) => {
    //     if (error) {
    //       return
    //     }
    // updElderList_controller({
    //   ...values,
    //   id: this.addInfo.id
    // }).then((res) => {
    //   this.visible = false
    //   this.$message.success('修改成功')
    //   this.getElderList()
    // })
    // })
    // },

    handleCancel(e) {
      this.visible = false
      this.visiblepassword = false
      this.visible1 = false
      this.passForm.resetFields()
      this.addForm.resetFields()
    },
    // handleCancelpass(e) {
    //   this.visiblepassword = false

    // },
    updSuccess() {
      console.log('chenggong')
      this.getElderList()
    },
    validatorIdCard(rule, value, callback) {
      let val = validateReg('idCrad', value)
      if (!val.validate) {
        callback(new Error('身份证号码格式不正确!'))
      } else {
        callback()
      }
    }
  }
}
</script>
<style scoped lang="less">
.index-img {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 0;
  right: 0;
}

.box .ant-form-item {
  margin: 10px 0 !important;
}

.m-mt-4 {
  margin-top: 4px;
}

.photo {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
</style>
