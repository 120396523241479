import moment from 'moment'

// 非空验证
export const createRequired = ({ msg, trigger, validator, min }) => {
  return { required: true, message: msg, trigger: trigger || 'blur', validator, min }
}
// 传入0默认为空验证
export const createNonZero = (msg) => {
  return (rule, value, callback) => {
    if (Number.isNaN(value)) {
      callback(new Error(msg))
    } else if (value === '0') {
      callback(new Error(msg))
    }
    callback()
  }
}
// 身份证验证
const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
export const validateIdCard = (rule, value, callback) => {
  if (!reg.test(value)) {
    callback(new Error('请输入正确的身份证号'))
    return
  }
  let date = ''
  if (value.length === 15) {
    date = `19${value.slice(6, 12)}`
  } else {
    date = value.slice(6, 14)
  }
  if (!moment(date, 'YYYYMMDD').isValid()) {
    callback(new Error('请输入正确的身份证号'))
    return
  }
  const year = moment(date, 'YYYYMMDD').year()
  if (year > moment().year() || year < 1900) {
    callback(new Error('请输入正确的身份证号'))
    return
  }
  callback()
}
// 手机号验证
export const validatePhone = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else if (!/^1[3456789]\d{9}$/.test(value)) {
    callback(new Error('请输入正确的手机号'))
  } else {
    callback()
  }
}
// 6位正则验证
export const validzipCode = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入邮编'))
  } else if (!/\d{6}$/.test(value)) {
    callback(new Error('请输入正确的邮编'))
  } else {
    callback()
  }
}

