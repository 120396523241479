/*
 * @Author: your name
 * @Date: 2021-03-01 12:01:38
 * @LastEditTime: 2021-03-24 18:23:01
 * @LastEditors:
 * @Description: 餐饮相关
 * @FilePath: \ioac-tenant-html\src\static\api\Captcha_api.js
 */
module.exports = {
  getFoodMgr_api: {
    url: 'ioac-center/mgr/restaurant/food/list',
    post: {
      name: '?',
      page: '?',
      rows: '?',
      state: '?'
    }
  },
  detailFoodMgr_api: {
    url: 'ioac-center/mgr/restaurant/food/get',
    get: {
      id: '?'
    }
  },
  deleteFoodMgr_api: {
    url: 'ioac-center/mgr/restaurant/food/remove',
    post: {
      id: '?'
    }
  },
  addFoodMgr_api: {
    url: 'ioac-center/mgr/restaurant/food/add',
    post: {
      name: '?',
      remark: '?',
      price: '?',
      tenantCode: '1',
      tags: '凉菜',
      food: '?',
      organCode: '',
      imgs: '',
      texture: '?'
    }
  },
  editFoodMgr_api: {
    url: 'ioac-center/mgr/restaurant/food/update',
    post: {
      id: '?',
      name: '?',
      remark: '?',
      price: '?',
      tenantCode: '1',
      tags: '凉菜',
      food: '?',
      texture: '?',
      organCode: '',
      state: '',
      imgs: ''

    }
  }

}
