<template>
  <div class="gutter-example">
    <a-row :gutter="16">
      <a-col class="gutter-row" :span="6">
        <div class="gutter-box">
          <p style="font-size: 18px; margin: 0; padding-left: 10px">今日注册</p>
          <span class="sp" style="padding-left: 10px">{{
            dayList.todayRegisterCount
          }}</span>
          <div
            id="myCharts1"
            ref="myCharts1"
            style="height: 200px; width: 100%"
          />
        </div>
      </a-col>
      <a-col class="gutter-row" :span="6">
        <div class="gutter-box">
          <p style="font-size: 18px; margin: 0; padding-left: 10px">总用户量</p>
          <span class="sp" style="padding-left: 10px">{{
            numList.userTotalCount
          }}</span>
          <div
            id="myCharts2"
            ref="myCharts2"
            style="height: 200px; width: 100%"
          />
        </div>
      </a-col>
      <a-col class="gutter-row" :span="6">
        <div class="gutter-box">
          <p style="font-size: 18px; margin: 0; padding-left: 10px">总检测量</p>
          <span class="sp" style="padding-left: 10px">{{
            detectionList.detectTotalCount
          }}</span>
          <div
            id="myCharts3"
            ref="myCharts3"
            style="height: 200px; width: 100%"
          />
        </div>
      </a-col>
      <a-col class="gutter-row" :span="6">
        <div class="gutter-box">
          <p style="font-size: 18px; margin: 0; padding-left: 10px">
            SOS触发总量
          </p>
          <span class="sp" style="padding-left: 10px">{{
            sosList.sosTotalCount
          }}</span>
          <div
            id="myCharts4"
            ref="myCharts4"
            style="height: 200px; width: 100%"
          />
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
// import $ from 'jquery'
var echarts = require('echarts')
export default {
  filters: {},
  props: {
    toplist: Array
  },
  data() {
    return {
      list: '',
      dayList: {},
      numList: {},
      sosList: {},
      detectionList: {}
    }
  },
  computed: {},
  watch: {
    toplist(val, newval) {
      this.list = newval
      this.getlist()
    }
  },

  updated() {
    this.getEcharts1()
    this.getEcharts2()
    this.getEcharts3()
    this.getEcharts4()
  },
  mounted() {},
  methods: {
    getlist() {
      this.dayList = this.list[0]
      this.numList = this.list[1]
      this.detectionList = this.list[2]
      this.sosList = this.list[3]
      console.log(this.dayList.dayList)
    },
    getEcharts1() {
      var chartDom = document.getElementById('myCharts1')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.dayList.dayList
        },
        yAxis: {
          type: 'value',
          splitNumber: 1
        },
        series: [
          {
            data: this.dayList.everyDayUserList,
            type: 'line',
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#ff8847' // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#FFF2EB' //   0% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            },
            color: '#ff8847', // 线条颜色
            smooth: true // 线条平滑
          }
        ]
      }
      myChart.clear()
      option && myChart.setOption(option)
    },
    getEcharts2() {
      var chartDom = document.getElementById('myCharts2')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.numList.monthList
        },
        yAxis: {
          type: 'value',
          splitNumber: 1
        },
        series: [
          {
            data: this.numList.everyMonthUserList,
            type: 'line',
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#ff8847' // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#FFF2EB' //   0% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            },
            color: '#ff8847', // 线条颜色
            smooth: true // 线条平滑
          }
        ]
      }
      myChart.clear()
      option && myChart.setOption(option)
    },
    getEcharts3() {
      var chartDom = document.getElementById('myCharts3')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.detectionList.monthList
        },
        yAxis: {
          type: 'value',
          splitNumber: 1
        },
        series: [
          {
            data: this.detectionList.everyMonthDetectList,
            type: 'line',
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#ff8847' // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#FFF2EB' //   0% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            },
            color: '#ff8847', // 线条颜色
            smooth: true // 线条平滑
          }
        ]
        // }
      }
      myChart.clear()
      option && myChart.setOption(option)
    },
    getEcharts4() {
      var chartDom = document.getElementById('myCharts4')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.sosList.monthList
        },
        yAxis: {
          type: 'value',
          splitNumber: 1
        },
        series: [
          {
            data: this.sosList.everyMonthSosList,
            type: 'line',
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#ff8847' // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#FFF2EB' //   0% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            },
            color: '#ff8847', // 线条颜色
            smooth: true // 线条平滑
          }
        ]
      }

      myChart.clear()
      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
.sp {
  font-size: 36px;
  color: #ff8847;
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box {
  background: #fff;
  padding: 5px 0;
  margin-top: 10px;
}
</style>
