<template>
  <div class="view-scroll">
    <a-page-header
      title="知识库添加"
      sub-title="添加建议、编辑、查看"
      @back="gohome()"
    />
    <div class="m-box col m-mt-10 box">
      <a-row type="flex" justify="space-between" style="margin-bottom: 30px">
        <a-col :span="8">
          <a-button
            type="primary"
            style="margin-right: 30px; background: #1890ff"
            :size="size"
            @click="release()"
          >发布</a-button>
          <a-dropdown>
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item key="1">
                <a-upload
                  list-type="text"
                  name="file"
                  :remove="handleRemove"
                  :before-upload="beforeUpload"
                  :custom-request="upRequest"
                  :show-upload-list="false"
                >
                  批量导入
                </a-upload>
              </a-menu-item>
              <a-menu-item key="2"> 导出 </a-menu-item>
              <!-- <a-menu-item key="3"> 删除 </a-menu-item> -->
            </a-menu>
            <a-button
              style="
                margin-left: 8px;
                background: #d8d8d8;
                border-color: #d8d8d8;
                color: #000;
              "
              :size="size"
              type="primary"
              :trigger="['click']"
            >
              批量操作 <a-icon type="down" style="color: #000" />
            </a-button>
          </a-dropdown>
          <a-select
            v-model="luck"
            style="width: 120px; margin-left: 40px"
            @change="handleChange"
          >
            <a-select-option v-for="val in baseBody" :key="val.kid">
              {{ val.kName }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="7">
          <a-input-search
            v-model="onsearch"
            placeholder="搜索主题丶问题丶答案"
            style="width: 400px"
            @search="onSearch"
          />
        </a-col>
      </a-row>
      <div v-for="(val, index) in anslist" :key="index" class="m-box_box">
        <a-icon
          type="down"
          style="color: #000; margin-right: 10px; margin-top: 10px"
        />
        <div style="width: 100%">
          <a-row class="margin">
            <a-col :span="12">
              <a-input
                v-model="val.name"
                placeholder="输入主题"
                style="width: 400px"
                :size="size"
              /></a-col>
          </a-row>
          <a-row class="margin">
            <a-col :span="12" style="display: flex">
              <span style="margin-right: 45px; font-weight: 600">添加问题</span>
              <div style="display: flex; flex-direction: column">
                <a-textarea
                  v-for="(a, i) in val.wenti"
                  :key="i"
                  v-model="val.wenti[i]"
                  placeholder="输入问题"
                  style="width: 400px; margin-bottom: 5px"
                  auto-size
                  :max-length="maxlength"
                  @pressEnter="enter(1, i)"
                />
              </div>
            </a-col>
            <a-col :span="12" style="display: flex">
              <span style="margin-right: 45px; font-weight: 600">添加答案</span>
              <div style="display: flex; flex-direction: column">
                <a-textarea
                  v-for="(c, i) in val.daan"
                  :key="i"
                  v-model="val.daan[i]"
                  placeholder="输入答案"
                  style="width: 400px"
                  auto-size
                  :max-length="maxlengthAns"
                  @pressEnter="enter(2, i)"
                />
              </div>
            </a-col>
          </a-row>
          <a-row class="margin">
            <a-col :span="6">
              <a-button
                type="primary"
                style="margin-right: 30px"
                @click="addsave(val)"
              >
                保存
              </a-button>
              <a-button style="background: #d8d8d8" @click="calcel(val)">
                取消
              </a-button>
            </a-col>
          </a-row>
        </div>
      </div>
      <!-- 模板 -->
      <div v-for="(item, index) in list" :key="index + 20" class="m-box_box">
        <a-icon
          type="down"
          style="color: #000; margin-right: 10px; margin-top: 10px"
          @click="max(index)"
        />
        <div style="width: 100%">
          <a-row class="margin" type="flex">
            <a-col flex="auto">
              <span
                v-if="!item.edit"
                style="font-size: 24px; font-weight: 600"
              >{{ item.sName }}</span>
              <a-textarea
                v-else
                v-model="item.sName"
                style="width: 400px"
                :allow-clear="true"
                :max-length="maxlength"
              />
              <a-icon type="edit" @click="edit(item, index)" />
            </a-col>
            <a-col flex="50px">
              <a-icon
                type="delete"
                style="font-szie: 22px; cursor: pointer"
                @click="del(item)"
              />
            </a-col>
          </a-row>
          <a-row class="margin">
            <a-col :span="12" style="display: flex; box-sizing: border-box">
              <span
                style="
                  box-sizing:border-box
                  padding-right: 20px;
                  font-weight: 600;
                  line-height: 40px;
                  width: 50px;
                "
              >问题</span>
              <a-list
                style="width: 90%"
                size="small"
                :bordered="false"
                :data-source="
                  item.bool
                    ? item.questionLists
                    : item.questionLists.slice(0, 1)
                "
              >
                <a-list-item
                  slot="renderItem"
                  slot-scope="v, r"
                  style="border: none"
                >
                  <span
                    v-if="!item.edit"
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 8;
                      -webkit-box-orient: vertical;
                    "
                  >{{ r + 1 + "丶" }}{{ v.question }}</span>
                  <a-textarea
                    v-else
                    v-model="v.question"
                    :allow-clear="true"
                    style="width: 400px"
                    :max-length="maxlength"
                    @pressEnter="change(item.questionLists, index, 1)"
                  />
                  <a-icon
                    v-if="item.edit"
                    type="delete"
                    style="margin-left: 20px"
                    @click="quesDel(item, index, r, 1)"
                  />
                </a-list-item>
              </a-list>
            </a-col>
            <a-col :span="12" style="display: flex">
              <span
                style="
                  box-sizing:border-box
                  padding-right: 20px;
                  font-weight: 600;
                  line-height: 40px;
                  width: 50px;
                  height:40px
                "
              >答案</span>
              <a-list
                style="width: 90%"
                size="small"
                :bordered="false"
                :data-source="
                  item.bool ? item.answerLists : item.answerLists.slice(0, 1)
                "
              >
                <a-list-item
                  slot="renderItem"
                  slot-scope="v, r"
                  style="border: none"
                >
                  <span
                    v-if="!item.edit"
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 15;
                      -webkit-box-orient: vertical;
                    "
                  >{{ r + 1 + "丶" }} {{ v.answer }}</span>
                  <a-textarea
                    v-else
                    v-model="v.answer"
                    style="width: 400px"
                    :allow-clear="true"
                    :max-length="maxlengthAns"
                    @pressEnter="change(item.answerLists, index, 0, 1)"
                  />
                  <a-icon
                    v-if="item.edit"
                    style="margin-left: 20px"
                    type="delete"
                    @click="quesDel(item, index, r)"
                  />
                </a-list-item>
              </a-list>
            </a-col>
          </a-row>
          <a-row v-if="item.edit" class="margin">
            <a-col :span="6">
              <a-button
                type="primary"
                style="margin-right: 30px"
                @click="save(item)"
              >
                保存
              </a-button>
              <a-button style="background: #d8d8d8" @click="unEdit(index)">
                取消
              </a-button>
            </a-col>
          </a-row>
        </div>
      </div>
      <pagination
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        :total="total"
        class="paginationStyle"
        @pagination="getPages"
      />
    </div>
  </div>
</template>

<script>
// import { message } from 'ant-design-vue'
import {
  BatchRemoveKnowledgeSubjectInfo_controller,
  UpdateBaseInfoBySid_controller,
  // UpdateBaseKNameByKid_controller,
  createKnowledgeSubjectInfo_controller,
  // queryBySubjectSid_controller,
  queryKnowledgeSubjectInfoList_controller,
  releaseKnowledgeSubjectInfoList_controller
} from '../../static/controller/cost.controller'
import { xhr_custom } from '../../static/lib/xhr'
const data = []
const anslist = [
  {
    name: '',
    wenti: [''],
    daan: ['']
  }
]
export default {
  filters: {},
  components: {
    pagination: () => import('../../components/Pagination')
  },
  data() {
    return {
      maxlength: 128,
      maxlengthAns: 1000,
      anslist,
      data,
      list: [],
      selectedRowKeys: [],
      visible: false,
      pageIndex: 1, // 当前页码
      pageSize: 10,
      pagination: {}, // tab分页
      total: 0,
      where: {},
      addInfo: {},
      //   新增知识库名称
      newName: '',
      ids: [],
      knowId: '',
      size: 'large',
      //   消息展示值
      messageIndex: [false],
      wenti: '',
      daan: '',
      baseBody: [],
      luck: '',
      newdata: [],
      fileList: [],
      saveQues: {
        qid: '',
        sid: '',
        question: ''
      },
      saveAns: {
        qid: '',
        sid: '',
        question: ''
      },
      onsearch: ''
    }
  },
  created() {
    if (this.$route.params.id) {
      this.knowId = this.$route.params.id
      localStorage.setItem('knowId', this.$route.params.id)
    } else {
      this.knowId = localStorage.getItem('knowId')
    }
    this.getElderList()
  },
  methods: {
    release() {
      releaseKnowledgeSubjectInfoList_controller({ kid: this.knowId }).then(
        (res) => {
          this.$message.success('发布成功')
          this.$router.go(-1)
        }
      )
    },
    quesDel(val, index, r, type) {
      console.log(val, index, r)
      if (type) {
        if (val.questionLists.length === 1) {
          this.$message.error('至少有一个问题')
        } else {
          val.questionLists.splice(r, 1)
        }
      } else {
        if (val.answerLists.length === 1) {
          this.$message.error('至少有一个答案')
        } else {
          val.answerLists.splice(r, 1)
        }
      }
    },
    onSearch() {
      // queryKnowledgeSubjectInfoList_controller({
      //   kid: this.knowId,
      //   page: this.pageIndex,
      //   rows: this.pageSize,
      //   param: this.onsearch
      // }).then((res) => {
      //   this.baseBody = res.baseBody
      //   this.luck = res.baseBody.filter((res) => {
      //     if (res.kid === this.knowId) {
      //       return res.kName
      //     }
      //   })[0].kName
      //   this.pageIndex = res.subjectBodyList.current
      //   this.pageSize = res.subjectBodyList.size
      //   this.total = res.subjectBodyList.total
      //   this.data = res.subjectBodyList
      //   this.list = res.subjectBodyList.records.map((res) => {
      //     return {
      //       ...res,
      //       edit: false,
      //       bool: false
      //     }
      //   })
      // })
      this.getElderList()
    },
    getElderList() {
      queryKnowledgeSubjectInfoList_controller({
        kid: this.knowId,
        page: this.pageIndex,
        rows: this.pageSize,
        param: this.onsearch
      }).then((res) => {
        this.baseBody = res.baseBody
        this.luck = res.baseBody.filter((res) => {
          if (res.kid === this.knowId) {
            return res.kName
          }
        })[0].kName
        this.pageIndex = res.subjectBodyList.current
        this.pageSize = res.subjectBodyList.size
        this.total = res.subjectBodyList.total
        this.data = res.subjectBodyList
        this.list = res.subjectBodyList.records.map((res) => {
          return {
            ...res,
            edit: false,
            bool: false
          }
        })
      })
    },
    // 下拉框
    handleChange(e) {
      this.knowId = e
      localStorage.setItem('knowId', this.knowId)
      queryKnowledgeSubjectInfoList_controller({
        kid: e,
        page: this.pageIndex,
        rows: this.pageSize
      }).then((res) => {
        console.log(res)
        this.baseBody = res.baseBody
        if (res.subjectBodyList) {
          this.pageIndex = res.subjectBodyList.current
          this.pageSize = res.subjectBodyList.size
          this.total = res.subjectBodyList.total
          this.data = res.subjectBodyList
          this.list = res.subjectBodyList.records.map((res) => {
            return {
              ...res,
              edit: false,
              bool: false
            }
          })
        } else {
          this.data = null
          this.list = null
        }
      })
    },
    //   分页器
    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.getElderList()
    },
    // 搜索事件
    // onSearch() {
    //   console.log('搜索')
    // },
    // 选择器
    handleMenuClick(e) {
      if (e.key === '2') {
        const url = `${window.location.origin}/equip-manage/mgr/equip/knowledge/subject/exportBaseInfo?kid=${this.knowId}`
        console.log(url)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', 'excel.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    // 展开
    max(index) {
      console.log(this.list[index])
      this.list.forEach((res, i) => {
        if (index === i) {
          res.bool = !res.bool
        }
      })
    },
    // 修改
    edit(item, index) {
      console.log(index)
      this.list.forEach((res, i) => {
        if (index === i) {
          res.edit = !res.edit
          res.bool = true
        }
      })

      console.log(this.list)
    },
    change(val, index, type) {
      this.saveQues = {
        qid: '',
        sid: '',
        question: ''
      }
      this.saveAns = {
        qid: '',
        sid: '',
        question: ''
      }
      console.log(val)
      // if(this.list[index])
      if (this.list[index].bool) {
        if (type) {
          val.push(this.saveQues)
          console.log(val)
          this.list[index].questionLists = val
        } else {
          val.push(this.saveAns)
          console.log(val)
          this.list[index].answerLists = val
        }
      } else {
        return
      }
    },
    gohome() {
      this.anslist = [
        {
          name: '',
          wenti: [''],
          daan: ['']
        }
      ]
      this.$router.go(-1)
    },
    // 取消
    unEdit(index) {
      if (this.list[index].edit) {
        this.list[index].edit = !this.list[index].edit
        this.getElderList()
      }
    },
    save(e) {
      console.log(e)
      let ans = []
      e.answerLists.filter((res) => {
        if (res.answer !== '') {
          ans.push(res.answer)
        }
      }) || []
      let ques = []
      e.questionLists.forEach((res) => {
        console.log(res, '===============')
        if (res.question !== '') {
          ques.push(res.question)
        }
      }) || []
      if (ans.length === 0 || (ans.length === 1 && ans[0] === undefined)) {
        this.$message.error('至少有一个答案')
        return
      }
      if (ques.length === 0 || (ques.length === 1 && ques[0] === undefined)) {
        this.$message.error('至少有一个问题')
        return
      }
      console.log(ans, '==========')
      console.log(ques, '==========')
      UpdateBaseInfoBySid_controller({
        sid: e.sid,
        sname: e.sName,
        answerList: ans,
        questionList: ques,
        kid: e.kid
      }).then((res) => {
        this.$message.success('修改成功')
        this.getElderList()
      })
    },
    addsave(e) {
      console.log(e.daan[0].length)
      let daan = []
      for (let i = 0; i < e.daan.length; i++) {
        if (e.daan[i].length === 0) {
          daan.push(e.daan[i])
        }
      }
      let wenti = []
      for (let i = 0; i < e.wenti.length; i++) {
        if (e.wenti[i].length === 0) {
          wenti.push(e.wenti[i])
        }
      }
      console.log(e.name)
      if (daan.length === 0 && wenti.length === 0 && e.name !== '') {
        createKnowledgeSubjectInfo_controller({
          kid: this.knowId,
          sname: e.name,
          answer: e.daan,
          problem: e.wenti
        }).then((res) => {
          this.knowId
          this.$message.success('添加成功')
          e.name = ''
          e.daan = ['']
          e.wenti = ['']
          this.getElderList()
        })
      } else if (e.name === '') {
        this.$message.error('主题不能为空')
      } else {
        this.$message.error('问题或者答案不能为空')
      }
    },
    calcel(e) {
      console.log(e)
      e.name = ''
      e.daan = ['']
      e.wenti = ['']
    },
    // 回车事件
    enter(e, index) {
      console.log(e)
      if (e === 1) {
        this.anslist[0].wenti.splice(index + 1, 0, null)
        console.log(this.anslist)
      } else {
        this.anslist[0].daan.splice(index + 1, 0, null)
      }
    },
    del(e) {
      console.log(e)
      let sids = []
      sids.push(e.sid)
      let kid = e.kid
      BatchRemoveKnowledgeSubjectInfo_controller({ sid: sids, kid: kid }).then(
        (res) => {
          this.$message.success('删除成功')
          this.getElderList()
        }
      )
    },

    // 上传文件
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = [...this.fileList]
      newFileList.splice(index, 1)
      this.fileList = newFileList
      console.log(this.fileList)
    },

    beforeUpload(file) {
      this.fileList = [...this.fileList, file]
      console.log(this.fileList)
    },

    upRequest(e) {
      // console.log(JSON.parse(localStorage.getItem('user')).organCode)
      for (let e of this.fileList) {
        let formData = new FormData()
        formData.append('file', e)
        formData.append('kid', this.knowId)
        formData.append(
          'organCode',
          JSON.parse(localStorage.getItem('user')).organCode
        )
        formData.append(
          'tenantCode',
          JSON.parse(localStorage.getItem('user')).tenantCode
        )
        formData.append('userId', JSON.parse(localStorage.getItem('user')).id)
        xhr_custom('/equip-manage/mgr/equip/knowledge/subject/ImportBaseInfo', {
          method: 'post',
          upload: true,
          data: formData
        })
          .then((res) => {
            this.fileList = []
            this.getElderList()
          })
          .catch(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>

<style scoped>
.box .ant-form-item {
  margin: 10px 0 !important;
}

.ant-row {
  display: flex;
  align-items: center;
}

.m-mt-4 {
  margin-top: 4px;
}
.m-box_box {
  width: 98%;
  border: 1px solid #ccc;
  padding: 10px;
  /* float: left; */
  margin-bottom: 10px;
  display: flex;
}
.margin {
  margin-bottom: 20px;
}
</style>
