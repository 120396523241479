/*
 * @Author: your name
 * @Date: 2021-03-08 15:51:05
 * @LastEditTime: 2021-04-07 16:15:53
 * @LastEditors:
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\controller\BedMgr.controller.js
 */
const {
  hospitalCount_service,
  userCount_service,
  birthCount_service,
  ageCount_service,
  levelCount_service
} = require('../service/WelfareMgr.service')
module.exports = {
  hospitalCount_controller(val) {
    return hospitalCount_service(val)
  },
  userCount_controller(val) {
    return userCount_service(val)
  },
  birthCount_controller(val) {
    return birthCount_service(val)
  },
  ageCount_controller(val) {
    return ageCount_service(val)
  },
  levelCount_controller(val) {
    return levelCount_service(val)
  }
}
