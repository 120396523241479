/*
 * @Author: your name
 * @Date: 2021-03-04 16:55:55
 * @LastEditTime: 2021-05-21 16:52:51
 * @LastEditors: Please set LastEditors
 * @Description: 正则表达式
 * @FilePath: \ioac-tenant-html\src\static\config\regular.js
 */
module.exports = {
  email: {
    test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    msg: '请输入正确的邮箱地址'
  },
  phone: {
    test: /^(?:(?:\+|00)86)?1\d{10}$/,
    msg: '请输入正确的手机格式'
  },
  idCrad: {
    test: /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
    msg: '请输入正确的身份证格式'
  },
  integer: {
    test: /^\d{1,}$/,
    msg: '请输入数字'
  },
  name: {
    test: /^(?:[\u4e00-\u9fa5·]{2,16})$/,
    msg: '请输入中文名称'
  }

}
