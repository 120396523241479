/*
 * @Author: your name
 * @Date: 2021-03-12 13:19:29
 * @LastEditTime: 2021-05-31 15:54:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\ServiceItemMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  addServiceMeal_api,
  queryServiceMeal_api,
  delServiceMeal_api,
  updateServiceMeal_api,
  getServiceMeal_api,
  updateStateServiceMeal_api
} = require('../api/ServiceMealMgr.api')

module.exports = {
  addServiceMeal_service(val) {
    return xhr(addServiceMeal_api, val)
  },
  queryServiceMeal_service(val) {
    return xhr(queryServiceMeal_api, val)
  },
  delServiceMeal_service(val) {
    return xhr(delServiceMeal_api, val)
  },
  updateServiceMeal_service(val) {
    return xhr(updateServiceMeal_api, val)
  },
  getServiceMeal_service(val) {
    return xhr(getServiceMeal_api, val)
  },
  updateStateServiceMeal_service(val) {
    return xhr(updateStateServiceMeal_api, val)
  }
}
