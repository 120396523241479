<template>
  <div class=" view-scroll">
    <div class="m-box col m-mt-10 box">
      <a-form
        :form="form"
        :label-col="{ span: 0 }"
        class="fff"
        :wrapper-col="{ span: 22 }"
        @submit="handleSearch"
      >
        <a-row type="flex">
          <!-- <a-col :span="4">
            <a-form-item label="">
              <a-input v-decorator="['mobile']" placeholder="请输入查询内容" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-select v-decorator="['OneType']" placeholder="使用状态">
                <a-select-option value="1">
                  普通老人
                </a-select-option>
                <a-select-option value="2">
                  居家养老服务补贴
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="3" class="ant-form-item">
            <a-button type="primary" class="m-mt-4" html-type="submit">
              查询
            </a-button>
          </a-col>
          <a-col :span="13" class="ant-form-item text-right"> -->

          <router-link :to="{ name: 'addrole' }">
            <a-button type="primary" class="m-mt-4" html-type="submit">
              添加权限
            </a-button>
          </router-link>
          <!-- </a-col> -->

        </a-row>
      </a-form>
    </div>

    <div class="m-box  m-mt-10">
      <a-table :columns="columns" :data-source="data">
        <div slot="action" slot-scope="record" class="text-3d9">
          <!-- <a-button
            type="primary"
            style="background: #38B26B; border-color:  #38B26B;"
            @click="setItem(record)"
          >授权</a-button> -->
          <router-link :to="{ name: 'addrole', query: { id: record.code } }">
            <a-button type="primary">编辑</a-button>
          </router-link>
          <a-popconfirm title="是否确认删除该账号?" ok-text="确认" cancel-text="取消" @confirm="confirm(record)">
            <a-button type="danger" class="m-ml-10">删除</a-button>
          </a-popconfirm>
        </div>
      </a-table>

    </div>

    <a-modal title="编辑人员" :visible="visible" :mask-closable="false" @ok="handleOk" @cancel="handleCancel">
      <a-form :form="addForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
        <a-form-item label="姓名">
          <a-input v-decorator="['name', {initialValue: addInfo.name}]" placeholder="请输入姓名" />
        </a-form-item>
        <a-form-item label="性别">
          <a-select v-decorator="['sex', {initialValue: addInfo.sex}]" placeholder="请选择">
            <a-select-option value="男">
              男
            </a-select-option>
            <a-select-option value="女">
              女
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="身份证号">
          <a-input v-decorator="['idNo', {initialValue: addInfo.idNo}]" placeholder="请输入身份证号" />
        </a-form-item>
        <a-form-item label="年龄">
          <a-input v-decorator="['age', {initialValue: addInfo.age}]" placeholder="请输入手机号码" />
        </a-form-item>
        <a-form-item label="大配餐">
          <a-select v-decorator="['isSetMeal', {initialValue: addInfo.isSetMeal}]" placeholder="请选择">
            <a-select-option value="是">
              是
            </a-select-option>
            <a-select-option value="否">
              否
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="座机号码">
          <a-input v-decorator="['tel', {initialValue: addInfo.tel}]" placeholder="请输入座机号码" />
        </a-form-item>
      </a-form>
    </a-modal>

  </div>
</template>

<script>
import {
  rolelist_controller,
  roleDelete_controller
} from '../../static/controller/UserRole.controller'
const columns = [{
  title: '角色编号',
  dataIndex: 'code',
  key: 'code',
  width: 120
}, {
  title: '权限名称',
  dataIndex: 'name'
},
{
  title: '权限描述',
  dataIndex: 'remark'
},
// {
//   title: '创建时间',
//   dataIndex: 'sn'
// },
// {
//   title: '使用状态',
//   dataIndex: 'mac'
// },
{
  title: '操作',

  scopedSlots: {
    customRender: 'action'
  }
}
]

const data = []
export default {
  filters: {

  },
  components: {},
  data() {
    return {
      data,
      columns,
      selectedRowKeys: [],
      form: this.$form.createForm(this, {
        name: 'evalutaion'
      }),
      addForm: this.$form.createForm(this, {
        name: 'customer_list'
      }),
      visible: false,
      pageIndex: 1, // 当前页码
      pageSize: 10,
      pagination: {}, // tab分页
      total: 0,
      where: {},
      addInfo: {},
      ids: []
    }
  },

  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },

  mounted() {
    this.initRolelist()
  },
  methods: {
    initRolelist() {
      rolelist_controller().then((res) => {
        this.data = res
        this.pagination = {
          total: res.length,
          current: 1
        }
      })
    },

    confirm(e) {
      roleDelete_controller({
        code: e.code
      }).then((res) => {
        this.$message.success('删除成功！')
        this.initRolelist()
      })
    },

    start() {
      this.loading = true
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },

    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      let ids = []
      for (let x of selectedRowKeys) {
        for (let i in this.data) {
          console.log(x, i)
          if (x === +i) {
            ids.push(this.data[i].id)
          }
        }
      }
      console.log(ids)
      this.ids = ids
      this.selectedRowKeys = selectedRowKeys
    },

    handleSearch(e) {
      e.preventDefault()
      this.form.validateFields((error, values) => {
        console.log(error, values)
        this.pageIndex = 1
        this.where = values
        this.getElderList()
      })
    },

    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.getElderList()
    },

    setItem(e) {
      console.log(e)
      this.addInfo = e
      this.visible = true
    },

    handleOk(e) {
      e.preventDefault()
      this.addForm.validateFields((error, values) => {
        if (error) {
          return
        }
        // updElderList_controller({
        //   ...values,
        //   id: this.addInfo.id
        // }).then((res) => {
        //   this.visible = false
        //   this.$message.success('修改成功')
        //   this.getElderList()
        // })
      })
    },

    handleCancel(e) {
      this.visible = false
    },

    updSuccess() {
      console.log('chenggong')
      this.getElderList()
    }

  }
}
</script>
<style scoped lang="less">
	.index-img {
		width: 42px;
		height: 42px;
		position: absolute;
		top: 0;
		right: 0;
	}

	.box .ant-form-item {
		margin: 10px 0 !important;
	}

	.ant-row {
		display: flex;
		align-items: center;
	}

	.m-mt-4 {
		margin-top: 4px;
	}
</style>
