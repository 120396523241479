/*
 * @Author: your name
 * @Date: 2021-06-30 16:29:35
 * @LastEditTime: 2021-06-30 16:30:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\HardwareDataMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')

const {
  listDataForSbj_api
} = require('../api/HardwareDataMgr.api')

module.exports = {
  listDataForSbj_service(val) {
    return xhr(listDataForSbj_api, val)
  }
}
