/*
 * @Author: your name
 * @Date: 2021-06-07 18:49:29
 * @LastEditTime: 2021-06-09 09:14:21
 * @LastEditors: Please set LastEditors
 * @Description: 客户评估问卷
 * @FilePath: \ioac-tenant-html\src\static\api\CustomerEvaluationMgr.api.js
 */
let {
  ic
} = require('../config/api_spec')

module.exports = {
  getCustomerEvaluationInfo_api: {
    url: `${ic}/mgr/evaluation/customerEvaluationInfo/get`,
    get: {
      id: '?'
    }
  },
  // mgr/v2/evaluation/customerEvaluationInfo/customer/batchSubmit
  batchSubmitCustomer_api: {
    url: `${ic}/mgr/v2/evaluation/customerEvaluationInfo/customer/batchSubmit`,
    post: {
      comment: '?',
      customerEvaluationId: '?',
      detailList: '?',
      remark: '?'
    }
  },
  // v2/evaluation/customerEvaluationInfo/evaluation
  evaluation_api: {
    url: `${ic}/mgr/v2/evaluation/customerEvaluationInfo/evaluation`,
    post: {
      result: '?',
      comment: '?',
      id: '?'
    }
  }
}
