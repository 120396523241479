/*
 * @Author: your name
 * @Date: 2021-05-25 16:54:55
 * @LastEditTime: 2021-05-25 17:16:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\api\TenantDynamicPageConfigMgr.api.js
 */

let {
  uc
} = require('../config/api_spec')

module.exports = {
  add2edit_api: {
    url: `${uc}/mgr/t/dynamicPage/add2edit`,
    method: 'POST',
    post: {
      name: '?',
      objCode: '?',
      pageContent: '?',
      remark: '?',
      state: '?'
    }
  },
  queryDynamicPage_api: {
    url: `${uc}/mgr/t/dynamicPage/query`,
    method: 'POST',
    post: {
      name: '?',
      objCode: '?',
      pageContent: '?',
      remark: '?',
      state: '?'
    }
  }
}
