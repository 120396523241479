/*
 * @Author: your name
 * @Date: 2021-06-07 18:55:35
 * @LastEditTime: 2021-06-08 19:52:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\CustomerEvaluationMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')
const {
  getCustomerEvaluationInfo_api,
  batchSubmitCustomer_api,
  evaluation_api
} = require('../api/CustomerEvaluationMgr.api')

module.exports = {
  getCustomerEvaluationInfo_service(val) {
    return xhr(getCustomerEvaluationInfo_api, val)
  },
  batchSubmitCustomer_service(val) {
    return xhr(batchSubmitCustomer_api, val)
  },
  evaluation_service(val) {
    return xhr(evaluation_api, val)
  }
}
