<template>
  <div class="view-scroll">
    <div class="m-box col m-mt-10 box">
      <a-form
        :form="form"
        :label-col="{ span: 0 }"
        class="fff"
        :wrapper-col="{ span: 22 }"
        @submit="handleSearch"
      >
        <a-row type="flex">
          <a-col :span="3">
            <a-form-item label="">
              <a-select
                v-decorator="['distribute']"
                placeholder="分配状态"
                allow-clear
              >
                <a-select-option value="0"> 已分配 </a-select-option>
                <a-select-option value="1"> 未分配 </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item>
              <a-cascader
                v-decorator="[
                  'organCode',
                  {
                    initialValue: [],
                    rules: [
                      {
                        type: 'array',
                        required: false,
                        message: '选择机构',
                      },
                    ],
                  },
                ]"
                placeholder="所有机构"
                change-on-select
                :options="companyData"
                :field-names="{
                  label: 'name',
                  value: 'companyNo',
                  children: 'children',
                }"
              />
            </a-form-item>
          </a-col>
          <a-col :span="1" class="ant-form-item">
            <a-button type="primary" class="m-mt-4" html-type="submit">查询</a-button>
          </a-col>
          <a-col :span="16" class="ant-form-item text-right">
            <a-button type="primary" class="m-mt-4 m-ml-10" @click="exportExcel()">导出注册码</a-button>
            <a-button type="primary" class="m-mt-4 m-ml-10" @click="generateParams()">生成注册码</a-button>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="3" class="ant-form-item">
            <a-popconfirm title="作废后将不能重新启用，确定作废已选中的注册码吗?" ok-text="确认" cancel-text="取消" @confirm="makeRegistrationCodeInvalid()">
              <a-button type="danger" danger class="m-mt-4">作废</a-button>
            </a-popconfirm>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="m-box m-mt-10">
      <a-table
        :row-selection="rowSelection"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :scroll="{ x: 1200 }"
        row-key="registrationCode"
      />
      <pagination
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        :total="total"
        class="paginationStyle"
        @pagination="getPages"
      />
    </div>
    <GenerateParams ref="generateParams" @refresh="getList" />
  </div>
</template>

<script>
import Base from '@/components/subscription/Base'
import {
  getRegistrationCodeLists_controller,
  makeRegistrationCodeInvalid_controller,
  export_controller
} from '@/static/controller/RegistrationCodeMgr.controller'
import { utils, writeFile } from 'xlsx'
import companyOptionsMixin from '@/mixins/companyOptionsMixin'
import organTreeSelectMixin from '@/mixins/organTreeSelectMixin'
export default {
  components: {
    GenerateParams: () => import('@/components/RegistrationCode/GenerateParams'),
    Pagination: () => import('@/components/Pagination')
  },
  extends: Base,
  mixins: [companyOptionsMixin, organTreeSelectMixin],
  data() {
    return {
      pageIndex: 1, // 当前页码
      pageSize: 10,
      pagination: {}, // tab分页
      total: 0,
      selectedRowKeys: [],
      where: {
        distribute: '',
        organCode: ''
      },
      bindVisible: false,
      form: this.$form.createForm(this, {
        name: 'evalutaion'
      }),
      columns: [
        {
          title: '注册码',
          dataIndex: 'registrationCode',
          width: 200
        },
        {
          title: '分配状态',
          width: 100,
          align: 'center',
          dataIndex: 'distributeStatus',
          customRender: (text, row, index) => {
            if (row.tenantCompanyInfoJ == null) {
              return '未分配'
            } else {
              return '已分配'
            }
          }
        },
        {
          title: '注册码生成时间',
          dataIndex: 'createTime',
          align: 'center',
          width: 160
        },
        {
          title: '分配机构名称',
          width: 240,
          dataIndex: 'companyName',
          customRender: (text, row, index) => {
            if (row.tenantCompanyInfoJ == null) {
              return '-'
            } else {
              return row.tenantCompanyInfoJ.name
            }
          }
        },
        {
          title: '状态',
          width: 80,
          align: 'center',
          dataIndex: 'status',
          customRender: (text, row, index) => {
            switch (row.status) {
              case 'NOT_USE':
                return '未使用'
              case 'IN_USE':
                return '使用中'
              case 'ABANDONED':
                return '已废弃'
            }
          }
        },
        {
          title: '账号',
          width: 120,
          dataIndex: 'userPhone',
          customRender: (text, row, index) => {
            if (row.user == null) {
              return ''
            } else {
              return row.user.phone
            }
          }
        },
        {
          title: '姓名',
          width: 180,
          dataIndex: 'userName',
          customRender: (text, row, index) => {
            if (row.user == null) {
              return ''
            } else {
              return row.user.name
            }
          }
        },
        {
          title: '注册时间',
          width: 160,
          align: 'center',
          dataIndex: 'userCreateTime',
          customRender: (text, row, index) => {
            if (row.user == null) {
              return ''
            } else {
              return row.user.createTime
            }
          }
        }
      ]
    }
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
        },
        getCheckboxProps: record => ({
          props: {
            disabled: !(record.tenantCompanyInfoJ != null && record.status === 'NOT_USE'),
            // Column configuration not to be checked
            name: 'registrationCode'
          }
        })
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {

    /**
     * 作废注册码
     */
    makeRegistrationCodeInvalid() {
      makeRegistrationCodeInvalid_controller({
        registrationCodeList: this.selectedRowKeys
      }).then(res => {
        this.getList()
      }).finally(() => {
        this.selectedRowKeys = []
      })
    },

    handleSearch(e) {
      e.preventDefault()
      this.pageIndex = 1
      this.setWhere()
      this.getList()
    },

    /**
     * 请求画面数据
     */
    getList() {
      getRegistrationCodeLists_controller({
        page: this.pageIndex,
        rows: this.pageSize,
        number: this.pageIndex,
        size: this.pageSize,
        distribute: this.where.distribute,
        organCode: this.where.organCode
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    },
    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.getList()
    },
    exportExcel() {
      this.setWhere()
      export_controller({
        distribute: this.where.distribute,
        organCode: this.where.organCode
      }).then((res) => {
        res = res.map((r) => {
          return {
            '注册码': r.registrationCode,
            '分配状态': r.distributeStatus,
            '注册码生成时间': r.createTime,
            '分配机构名称': r.orgName,
            '状态': r.status,
            '账号': r.phone,
            '姓名': r.userName,
            '用户注册时间': r.userRegTime
          }
        })
        const book = utils.book_new()
        const sheet = utils.json_to_sheet(res)
        utils.book_append_sheet(book, sheet)
        writeFile(book, '注册码.xlsx')
      })
    },
    /**
     * 生成注册码
     */
    generateParams() {
      this.$refs.generateParams.visible = true
    },

    /**
     * 将画面输入的检索条件设置到变量中
     */
    setWhere() {
      this.where.distribute = this.form.getFieldValue('distribute') ?? ''
      this.where.organCode = this.getSearchOrganCode(this.form.getFieldValue('organCode'))
    }
  }
}
</script>
