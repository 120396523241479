/*
 * @Author: 刘崇杰
 * @Date: 2021-03-02 13:46:23
 * @LastEditTime: 2021-03-03 16:22:46
 * @LastEditors: Please set LastEditors
 * @Description: 权限(菜单)管理
 * @FilePath: \ioac-tenant-html\src\static\api\PermissionMgr.api.js
 */
let {
  uc
} = require('../config/api_spec')

module.exports = {
  // 新增权限
  save_api: {
    url: `${uc}/mgr/user/permission/save`,
    post: {
      code: '?', // 权限编号
      name: '?', // 权限名称
      parentCode: '?', // 上级权限编号
      state: '?', // 菜单状态
      type: '?', // 权限分类（普通/菜单/按钮）
      url: '?', // 菜单路由
      showName: '?', // 菜单显示名称
      showPlatform: '?' // 菜单显示平台
    }
  },
  // 获取权限列表
  permissionQueryTree_api: {
    url: `${uc}/mgr/user/permission/queryTree`,
    post: {}
  }
}
