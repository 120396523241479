var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{staticClass:"ant-advanced-search-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSearch}},[_c('a-form-item',{attrs:{"label":"Form Layout"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `field`,
          {
            rules: [
              {
                required: true,
                message: 'Input something!',
              },
            ],
          },
        ]),expression:"[\n          `field`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Input something!',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"placeholder"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }