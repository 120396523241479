/*
 * @Author: your name
 * @Date: 2021-06-02 13:19:11
 * @LastEditTime: 2021-06-02 13:19:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\service\AccountInfoMgr.service.js
 */
const {
  xhr
} = require('../lib/xhr')

const {
  registrationCodeList_api,
  generateRegistrationCode_api,
  makeRegistrationCodeInvalid_api,
  distributeRegistrationCode_api,
  export_api
} = require('../api/RegistrationCodeMgr.api')

module.exports = {
  registrationCode_query_service(val) {
    console.log('service', val)
    return xhr(registrationCodeList_api, val)
  },
  generateRegistrationCode_service(val) {
    return xhr(generateRegistrationCode_api, val)
  },
  makeRegistrationCodeInvalid_service(val) {
    return xhr(makeRegistrationCodeInvalid_api, val)
  },
  distributeRegistrationCode_service(val) {
    return xhr(distributeRegistrationCode_api, val)
  },
  export_service(val) {
    return xhr(export_api, val)
  }
}
