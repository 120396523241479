/*
 * @Description:
 * @Author:
 * @Date: 2021-03-10 19:17:16
 * @LastEditTime: 2021-03-10 19:18:21
 * @LastEditors:
 */

let {
  deleteEvaluationApp_service
} = require('../service/EvaluationApp.service')

module.exports = {
  deleteEvaluationApp_controller(val) {
    return deleteEvaluationApp_service(val)
  }
}
