/*
 * @Author: your name
 * @Date: 2021-03-06 13:27:56
 * @LastEditTime: 2021-04-15 15:08:05
 * @LastEditors:
 * @Description: 机构管理
 * @FilePath: \ioac-tenant-html\src\static\api\OrganMgr.api.js
 */
let {
  ic
} = require('../config/api_spec')
module.exports = {
  getByType_api: {
    url: `${ic}/mgr/ioac/organTemplate/getByType`,
    get: {
      type: '?'
    }
  }
}
