/*
 * @Author: your name
 * @Date: 2021-02-24 15:17:51
 * @LastEditTime: 2022-01-06 10:40:17
 * @LastEditors:
 * @Description: 添加路由权限
 * @FilePath: \ioac-tenant-html\src\static\utils\auth\router.js
 */
let menuInfo = []

/**
 * 递归菜单
 * @param {Array} info
 */
function getMenuInfo(info) {
  if (info.length > 0) {
    info.forEach(item => {
      if (item.children.length > 0) {
        getMenuInfo(item.children)
      }
      if (item.url && item.componentPath) {
        menuInfo.push(item)
      }
    })
    return menuInfo
  }
}

module.exports = {
  /**
	 * 设置路由
	 * @param {*} router vue-router 对象
	 * @param {*} info 接口菜单信息
	 */
  setRouter: (router, info) => {
    let menu = getMenuInfo(info)

    // menu.forEach(item => {
    //   // let componentModule = require(`../../../${item.componentPath}`).default
    //   router.options.routes[0].children.push({
    //     name: item.name,
    //     path: item.url,
    //     component: require(`../../../${item.componentPath}`).default,
    //     meta: {
    //       title: item.showName
    //     }
    //   })
    // })
    // let error = require(`../../../views/result/404.vue`).default
    // router.options.routes.push({
    //   path: '*',
    //   name: '404',
    //   component: error
    // })
    // router.addRoutes(router.options.routes)

    let routers = {
      path: '/',
      name: 'Home',
      component: require('../../../views/Home').default,
      redirect: '/index',
      children: [
        {
          path: '/index',
          name: 'Home',
          meta: {
            title: '亿慈智能'
          },
          component: require('../../../views/health/healthCenter.vue').default
        },
        // 修复router >>
        {
          path: 'health/healthCenter',
          name: 'healthCenter',
          component: require('../../../views/health/healthCenter.vue').default
        },
        {
          path: 'health/details',
          name: 'details',
          component: require('../../../views/health/details.vue').default
        },
        {
          path: 'knowbase/operation',
          name: 'operation',
          component: require('../../../views/knowbase/operation.vue').default
        },
        {
          path: 'health/details',
          name: 'details',
          component: require('../../../views/health/details.vue').default
        },
        {
          path: 'equipment/addrole',
          name: 'addrole',
          component: require('../../../views/equipment/addrole.vue').default
        },
        // 修复router <<
        {
          path: '/result/403',
          name: '403',
          meta: {
            title: '403'
          },
          component: require('../../../views/result/403.vue').default
        }, {
          path: '/result/500',
          name: '500',
          meta: {
            title: '500'
          },
          component: require('../../../views/result/500.vue').default
        }
        // {
        //   path: '/set',
        //   name: 'set',
        //   meta: {
        //     title: '预警设置'
        //   },
        //   component: require('../../../views/warning/set.vue').default
        // },
        // {
        //   path: '/set/details',
        //   name: 'details',
        //   meta: {
        //     title: '详情'
        //   },
        //   component: require('../../../views/warning/details.vue').default
        // },

        // 写死固定护理页面
        // {
        //   path: '/hardware/inOut',
        //   name: 'inOut',
        //   meta: {
        //     title: '进出记录'
        //   },
        //   component: require('../../../views/hardware/inOut.vue').default
        // },
        // {
        //   path: '/hardware/setting',
        //   name: 'setting',
        //   meta: {
        //     title: '预警设置'
        //   },
        //   component: require('../../../views/hardware/setting.vue').default
        // },
        // {
        //   path: '/warning/bedmgr',
        //   name: 'bedmgr',
        //   meta: {
        //     title: '床位监控'
        //   },
        //   component: require('../../../views/warning/bedmgr.vue').default
        // },
        // {
        //   path: '/nursing/addLevel',
        //   name: 'addLevel',
        //   meta: {
        //     title: '护理计划模板'
        //   },
        //   component: require('../../../views/nursing/addLevel.vue').default
        // }
        // {
        // 	path: '/nursing/levelList',
        // 	name: 'levelList',
        // 	meta: {
        // 		title: '预警设置'
        // 	},
        // 	component: require('../../../views/nursing/levelList.vue').default
        // },
        // {
        //   path: '/nursing/frequencyList',
        //   name: 'frequencyList',
        //   meta: {
        //     title: '频次设置'
        //   },
        //   component: require('../../../views/nursing/frequencyList.vue').default
        // },
        // {
        //   path: '/nursing/addFrequency',
        //   name: 'addFrequency',
        //   meta: {
        //     title: '频次设置'
        //   },
        //   component: require('../../../views/nursing/addFrequency.vue').default
        // },
        // {
        //   path: '/nursing/templateList',
        //   name: 'templateList',
        //   meta: {
        //     title: '护理计划模板'
        //   },
        //   component: require('../../../views/nursing/templateList.vue').default
        // },
        // {
        //   path: '/nursing/addTemplate',
        //   name: 'addTemplate',
        //   meta: {
        //     title: '护理计划模板设置'
        //   },
        //   component: require('../../../views/nursing/addTemplate.vue').default
        // },
        // {
        //   path: '/nursing/addPlan',
        //   name: 'addPlan',
        //   meta: {
        //     title: '护理计划设置'
        //   },
        //   component: require('../../../views/nursing/addPlan.vue').default
        // }
        // {
        //   path: '/nursing/planList',
        //   name: 'planList',
        //   meta: {
        //     title: '护理计划'
        //   },
        //   component: require('../../../views/nursing/planList.vue').default
        // },
        // {
        //   path: '/nursing/addUser',
        //   name: 'addUser',
        //   meta: {
        //     title: '新增员工'
        //   },
        //   component: require('../../../views/nursing/addUser.vue').default
        // },
        // {
        //   path: '/nursing/userList',
        //   name: 'userList',
        //   meta: {
        //     title: '护理人员'
        //   },
        //   component: require('../../../views/nursing/userList.vue').default
        // },
        // {
        //   path: '/nursing/addTeam',
        //   name: 'addTeam',
        //   meta: {
        //     title: '小组设置'
        //   },
        //   component: require('../../../views/nursing/addTeam.vue').default
        // },
        // {
        //   path: '/nursing/teamList',
        //   name: 'teamList',
        //   meta: {
        //     title: '护理小组'
        //   },
        //   component: require('../../../views/nursing/teamList.vue').default
        // },
        // {
        //   path: '/nursing/nursingList',
        //   name: 'nursingList',
        //   meta: {
        //     title: '护理项目管理'
        //   },
        //   component: require('../../../views/nursing/nursingList.vue').default
        // },
        // {
        //   path: '/nursing/addNursing',
        //   name: 'addNursing',
        //   meta: {
        //     title: '护理项目设置'
        //   },
        //   component: require('../../../views/nursing/addNursing.vue').default
        // },
        // {
        //   path: '/nursing/addSchedule',
        //   name: 'addSchedule',
        //   meta: {
        //     title: '班次设置'
        //   },
        //   component: require('../../../views/nursing/addSchedule.vue').default
        // },
        // {
        //   path: '/nursing/scheduleList',
        //   name: 'scheduleList',
        //   meta: {
        //     title: '班次列表'
        //   },
        //   component: require('../../../views/nursing/scheduleList.vue').default
        // },
        // {
        //   path: '/nursing/workList',
        //   name: 'workList',
        //   meta: {
        //     title: '排班管理'
        //   },
        //   component: require('../../../views/nursing/workList.vue').default
        // },
        // {
        //   path: '/nursing/recordList',
        //   name: 'recordList',
        //   meta: {
        //     title: '护理记录'
        //   },
        //   component: require('../../../views/nursing/recordList.vue').default
        // },
        // {
        //   path: '/nursing/taskList',
        //   name: 'taskList',
        //   meta: {
        //     title: '护理任务列表'
        //   },
        //   component: require('../../../views/nursing/taskList.vue').default
        // },
        // {
        //   path: '/nursing/addTask',
        //   name: 'addTask',
        //   meta: {
        //     title: '护理任务设置'
        //   },
        //   component: require('../../../views/nursing/addTask.vue').default
        // },
        // {
        //   path: '/nursing/taskDetailList',
        //   name: 'taskDetailList',
        //   meta: {
        //     title: '护理项目详情'
        //   },
        //   component: require('../../../views/nursing/taskDetailList.vue').default
        // },
        // {
        //   path: '/nursing/screenDetail',
        //   name: 'screenDetail',
        //   meta: {
        //     title: '护理大屏'
        //   },
        //   component: require('../../../views/nursing/screenDetail.vue').default
        // }
        // {
        //   path: '/admission/addcheck',
        //   name: 'addcheck',
        //   meta: {
        //     title: '添加登记'
        //   },
        //   component: require('../../../views/admission/addcheck.vue').default
        // }
      ]
    }

    menu.forEach(item => {
      try {
        routers.children.push({
          name: item.name,
          path: item.url,
          component: require(`../../../${item.componentPath}`).default,
          meta: {
            title: item.showName
          }
        })
      } catch (error) {
        console.log(error)
      }
    })
    console.log('添加：', routers)
    router.options.routes.push(routers)
    console.log(router)
    router.addRoutes(router.options.routes)
  }
}
