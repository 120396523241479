<template>
  <div>
    <a-modal
      title="订阅详情"
      :visible="visible"
      :footer="null"
      :mask-closable="false"
      @cancel="handleCancel"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
        <a-form-item label="订阅名称">
          <a-input :value="data.name" disabled />
        </a-form-item>
        <a-form-item label="订阅剩余量">
          <div>
            <table style="width: 100%">
              <tr v-for="item in data.rules" :key="'tr_' + item.id">
                <td :key="'td_text_' + item.id" style="width: 70%">
                  <a-checkbox :checked="true" disabled>
                    {{ item.dictName }}
                  </a-checkbox>
                </td>
                <td :key="'td_number_' + item.id" style="30%">
                  <a-input
                    :value="noLimitConvert(item.metaLimit, item.metaCost + '/' + item.metaLimit)"
                    class="count-input"
                    disabled
                  />
                </td>
              </tr>
            </table>
          </div>
        </a-form-item>
        <a-form-item label="有效期限">
          <a-input :value="data.startDate + ' 至 ' + data.endDate" disabled />
        </a-form-item>
        <a-form-item label="关联合同编号">
          <a-input :value="data.contractNum" disabled />
        </a-form-item>
        <a-form-item style="display: flex; justify-content: center">
          <div style="display: flex; justify-content: space-around">
            <a-button
              type="primary"
              :size="size"
              style="background: #999; border-color: #999"
              @click="handleCancel"
            >
              取消
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import BaseModal from '@/components/subscription/BaseModal'
import { getSubscriptionDetail_controller } from '@/static/controller/Subscription.controller'
import subscriptMixin from '@/mixins/subscriptMixin'
export default {
  extends: BaseModal,
  mixins: [subscriptMixin],
  data() {
    return {
      data: [],
      id: null
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getDetail(this.id)
      }
    }
  },
  methods: {
    getDetail(id) {
      getSubscriptionDetail_controller({ id: id }).then((res) => {
        this.data = res
      })
    }
  }
}
</script>

<style>
</style>
