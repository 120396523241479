export default {
  methods: {
    /**
     * 解析机构搜索条件的数据结构
     * @param Array organCodes 输入框提供的机构数据
     * @retrun String organCode 解析后的机构编码
     */
    getSearchOrganCode(organCodes) {
      switch (organCodes.length) {
        case 0:
          return ''
        case 1:
          return organCodes[0]
        default:
          return organCodes[1]
      }
    }
  }
}
