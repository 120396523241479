/*
 * @Author: 用户权限校验
 * @Date: 2021-03-01 19:08:25
 * @LastEditTime: 2021-03-02 13:43:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ioac-tenant-html\src\static\api\UserPermissionCheck.api.js
 */
let {
  uc
} = require('../config/api_spec')

module.exports = {
  // 获取菜单
  getMenu_api: {
    url: `${uc}/app/user/checkPre/getMenu`,
    method: 'GET',
    get: {
      showPlatform: '?'
    }
  },
  getMenu2Button_api: {
    url: `${uc}/app/user/checkPre/getMenu2Button`,
    method: 'GET',
    post: {

    }
  },
  getUserPermCodes_api: {
    url: `${uc}/app/user/checkPre/getUserPermCodes`,
    method: 'GET',
    post: {

    }
  }
}
