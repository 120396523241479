export default {
  namespaced: true,
  state: () => ({
    templateMetaDict: [],
    templateOptions: []
  }),
  actions: {
    async getTemplateMetaDict({ commit }, fun) {
      const result = await fun()
      commit('setTemplateMetaDict', result)
    },
    async getTemplateOptions({ commit }, funObj) {
      const result = await funObj.fun(funObj.params)
      commit('setTemplateOptions', result)
    }
  },
  mutations: {
    setTemplateMetaDict(state, lists) {
      state.templateMetaDict = lists
    },
    setTemplateOptions(state, lists) {
      state.templateOptions = lists
    }
  },
  getters: {}
}
