/*
 * @Description:
 * @Author:
 * @Date: 2021-03-11 16:00:58
 * @LastEditTime: 2021-04-26 15:35:45
 * @LastEditors:
 */

module.exports = {
  addContract_api: {
    url: 'ioac-center/mgr/contract/cust/add',
    post: {
      contraceId: '?',
      contraceNo: '?',
      content: '?',
      contraceTemplateNo: '?', // 合同模板ID
      customerRegisterNo: '?', // 客户登记编号
      foodFee: '?',
      pettyFee: '?',
      organTrier: '?',
      trierStart: '?',
      trierEnd: '?',
      contractStart: '?',
      contractEnd: '?',
      careGrade: '?',
      careFee: '?',
      hasContrace: '?'
    }
  },
  detailContract_api: {
    url: 'ioac-center/mgr/contract/cust/getByRegisterId',
    get: {
      customerRegisterNo: '?'// 登记编号
    }
  },
  updateContract_api: {
    url: 'ioac-center/mgr/contract/cust/update',
    post: {
      contraceNo: '?',
      content: '?',
      contraceTemplateNo: '?', // 合同模板ID
      customerRegisterNo: '?', // 客户登记编号
      foodFee: '?',
      pettyFee: '?',
      organTrier: '?',
      trierStart: '?',
      trierEnd: '?',
      contractStart: '?',
      contractEnd: '?',
      careGrade: '?',
      careFee: '?'
    }
  },
  // 列表查询
  contractQuery_api: {
    url: '/ioac-center/mgr/contract/cust/query',
    post: {
      page: '?',
      rows: '?',
      name: '?',
      organTrier: '?'
    }
  },
  contractHtml_api: {
    url: '/ioac-center/mgr/contract/cust/getContractHtml',
    get: {
      id: '?'
    }
  },
  // 查询客户详情
  getByRegisterId_api: {
    url: '/ioac-center/mgr/contract/cust/getByRegisterId',
    get: {
      customerRegisterNo: '?'
    }
  },
  // 合同管理查询
  contractcustQuery_api: {
    url: '/ioac-center/mgr/contract/cust/list',
    post: {
      name: '?',
      page: '?',
      rows: '?',
      startDate: '?',
      endDate: '?',
      organTrier: '?'
    }
  },
  // 查看补录合同详情
  getSimplecust_api: {
    url: '/ioac-center/mgr/contract/cust/getSimpleByRegisterId',
    get: {
      customerRegisterNo: '?'
    }
  }

}
