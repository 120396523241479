<template>
  <!-- 档案 -->
  <div class="view-scroll">
    <div class="m-box col m-mt-10 box">
      <a-form
        :form="form"
        :label-col="{ span: 0 }"
        class="fff"
        :wrapper-col="{ span: 22 }"
        @submit="handleSearch"
      >
        <a-row type="flex">
          <a-col :span="4">
            <a-form-item label="">
              <a-input v-decorator="['mobile']" placeholder="请输入查询内容" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="">
              <a-select
                v-decorator="['OneType']"
                placeholder="性别"
                :allow-clear="true"
              >
                <a-select-option value="1"> 男 </a-select-option>
                <a-select-option value="2"> 女 </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-form-item label="">
              <a-select
                v-decorator="['OneType1']"
                placeholder="使用状态"
                :allow-clear="true"
              >
                <a-select-option value="1"> 完善 </a-select-option>
                <a-select-option value="0"> 未完善 </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="3" class="ant-form-item">
            <a-button type="primary" class="m-mt-4" html-type="submit">
              查询
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="m-box m-mt-10">
      <a-table :columns="columns" :data-source="data" :pagination="false">
        <div slot="photo" slot-scope="row" class="text-3d9">
          <img :src="row" class="photo">
        </div>
        <div slot="sex" slot-scope="row" class="text-3d9">
          <span style="color: rgba(0, 0, 0, 0.65)">{{
            row == 1 ? "男" : "女"
          }}</span>
        </div>
        <div slot="status" slot-scope="row" class="text-3d9">
          <span :style="row === '1' ? '' : 'color:#E16356'">{{
            row === "1" ? "已注册" : "待完善信息"
          }}</span>
        </div>
        <div slot="action" slot-scope="record" class="text-3d9">
          <a-button
            type="primary"
            class="m-ml-10"
            @click="setItem(record)"
          >查看档案</a-button>
          <a-button
            type="danger"
            class="m-ml-10"
            @click="update(record)"
          >编辑档案</a-button>
        </div>
      </a-table>

      <pagination
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        :total="total"
        class="paginationStyle"
        @pagination="getPages"
      />
    </div>
    <!-- 编辑 -->
    <a-modal
      title="编辑信息"
      :visible="visible1"
      :footer="null"
      :mask-closable="false"
      @cancel="handleCancel"
    >
      <a-form
        :form="updateFrom"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
        @submit="handleSearchUpate"
      >
        <a-form-item label="姓名">
          <a-input
            v-decorator="['name', { initialValue: addInfo.name }]"
            placeholder="请输入姓名"
          />
        </a-form-item>
        <!-- <a-form-item label="头像">
          <UploadImg
            :url.sync="addInfo.avatar"
            tip="上传照片"
            :width="280"
            :height="100"
          />
        </a-form-item> -->
        <!-- <a-form-item label="身份证">
          <a-input
            v-decorator="[
              'idCard',
              {
                rules: [
                  { required: true, message: '请输入身份证!' },
                  {
                    required: true,
                    validator: validatorIdCard,
                    trigger: 'blur',
                    message: '请输入正确的身份证号码！',
                  },
                ],
                initialValue: addInfo ? addInfo.idcard : '',
              },
            ]"
            placeholder="请输入身份证"
          />
        </a-form-item> -->
        <a-form-item label="性别">
          <a-select
            v-decorator="[
              'sex',
              {
                initialValue: addInfo ? addInfo.sex : '',
              },
            ]"
            placeholder="请选择性别"
          >
            <a-select-option :value="1"> 男 </a-select-option>
            <a-select-option :value="2"> 女 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="出生年月">
          <a-date-picker
            v-decorator="[
              'age',
              {
                rules: [
                  { required: true, message: '请选择生日!' },
                ],
                initialValue: addInfo ? addInfo.birth : '',
              },
            ]"
            :disabled-date="disabledDate"
            placeholder="选择生日"
          />
        </a-form-item>

        <a-form-item label="联系方式">
          <a-input
            v-decorator="['phone', { initialValue: addInfo.phone }]"
            placeholder="请输入联系方式"
            disabled
          />
        </a-form-item>
        <a-form-item label="身高">
          <a-input
            v-decorator="['height', { initialValue: addInfo.height }]"
            placeholder="请输入升高，单位cm"
            type="number"
          />
        </a-form-item>
        <a-form-item label="体重">
          <a-input
            v-decorator="['weight', { initialValue: addInfo.weight }]"
            placeholder="请输入体重，单位kg"
            type="number"
          />
        </a-form-item>
        <a-form-item label="其他">
          <a-textarea
            v-decorator="['remark', { initialValue: addInfo.remark }]"
            placeholder="其他情况"
          />
        </a-form-item>
        <a-form-item style="display: flex; justify-content: center">
          <div style="display: flex; justify-content: space-around">
            <a-button type="primary" html-type="submit" :size="size">
              确认
            </a-button>
            <a-button
              type="primary"
              :size="size"
              style="background: #999; border-color: #999"
              @click="handleCancel"
            >
              取消
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
// import { message } from 'ant-design-vue'
import { validateReg } from '../../static/utils/regular'
import {
  // enduser_query_controller,
  enduser_queryList_controller,
  enduser_update_controller
} from '../../static/controller/cost.controller'
const columns = [
  {
    title: '姓名',
    dataIndex: 'name'
  },
  {
    title: '头像',
    dataIndex: 'avatar',
    scopedSlots: {
      customRender: 'photo'
    }
  },
  {
    title: '联系方式',
    dataIndex: 'phone'
  },
  {
    title: '身份证号',
    dataIndex: 'idcard'
  },
  {
    title: '性别',
    dataIndex: 'sex',
    scopedSlots: {
      customRender: 'sex'
    }
  },
  {
    title: '注册状态',
    dataIndex: 'status',
    scopedSlots: {
      customRender: 'status'
    }
  },
  {
    title: '出生日期',
    dataIndex: 'birth'
  },
  {
    title: '注册时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const data = []
export default {
  filters: {},
  components: {
    pagination: () => import('../../components/Pagination')
    // UploadImg: () => import('../../components/UploadImg')
  },
  data() {
    return {
      data,
      columns,
      selectedRowKeys: [],
      form: this.$form.createForm(this, {
        name: 'evalutaion'
      }),
      updateFrom: this.$form.createForm(this, {
        name: 'customer_list'
      }),
      visible: false,
      pageIndex: 1, // 当前页码
      pageSize: 10,
      pagination: {}, // tab分页
      total: 0,
      where: {},
      addWhere: {},
      addInfo: {},
      ids: [],
      size: 'large',
      visiblepassword: false,
      visible1: false,
      frontUrl: ''
      // newImg:'',
    }
  },

  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },

  mounted() {
    this.getElderList()
  },
  methods: {
    moment,
    validatorIdCard(rule, value, callback) {
      let val = validateReg('idCrad', value)
      if (!val.validate) {
        callback(new Error('身份证号码格式不正确!'))
      } else {
        callback()
      }
    },
    disabledDate(current) {
      console.log(current)
      return current && current > moment().endOf('day')
    },
    getElderList() {
      enduser_queryList_controller({
        // name: this.where.mobile,
        fuzzySearch: this.where.mobile,
        sex: this.where.OneType,
        status: this.where.OneType1,
        page: this.pageIndex,
        rows: this.pageSize
      }).then((res) => {
        this.data = res.records
        this.pageIndex = res.current
        this.pageSize = res.size
        this.total = res.total
      })
    },

    start() {
      this.loading = true
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },

    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      let ids = []
      for (let x of selectedRowKeys) {
        for (let i in this.data) {
          console.log(x, i)
          if (x === +i) {
            ids.push(this.data[i].id)
          }
        }
      }
      console.log(ids)
      this.ids = ids
      this.selectedRowKeys = selectedRowKeys
    },

    handleSearch(e) {
      e.preventDefault()
      this.form.validateFields((error, values) => {
        console.log(error, values)
        this.pageIndex = 1
        this.where = values
        this.getElderList()
      })
    },
    handleSearchUpate(e) {
      e.preventDefault()
      this.updateFrom.validateFields((error, values) => {
        console.log(error, values)
        if (error) {
          return
        }
        enduser_update_controller({
          avatar: this.addInfo.avatar,
          id: this.addInfo.id,
          idcard: this.addInfo.idcard,
          name: values.name,
          birth: moment(values.age._d).format('L').replace(new RegExp('/', 'g'), '-'),
          phone: values.phone,
          sex: values.sex,
          weight: values.weight,
          height: values.height,
          remark: values.remark
        }).then(res => {
          this.$message.success('修改成功')
          this.getElderList()
          this.visible1 = false
        })
      })
    },
    getPages(params) {
      this.pageIndex = params.page
      this.pageSize = params.limit
      this.getElderList()
    },
    setItem(e) {
      this.$router.push({
        path: 'details',
        query: { id: e.id }
      })
    },
    update(e) {
      this.updateFrom.resetFields()
      this.visible1 = true
      this.addInfo = e
      // console.log(e)
    },
    add() {
      this.visible = true
    },

    handleCancel(e) {
      this.visible1 = false
      this.updateFrom.resetFields()
    }
  }
}
</script>
<style scoped lang="less">
.index-img {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 0;
  right: 0;
}

.box .ant-form-item {
  margin: 10px 0 !important;
}

.ant-row {
  display: flex;
  align-items: center;
}

.m-mt-4 {
  margin-top: 4px;
}

.photo {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
</style>
